import React, { useState , useEffect, useContext, Fragment } from "react";


import Dashboard from "views/Dashboard.js";
import Inversiones from "views/Inversiones.js";
import Ofertas from "views/Ofertas.js";
import Deposito from "views/Deposito.js";
import Retiro from "views/Retiro.js";
import Projectos from "views/Projectos";
import UserProfile from "views/UserProfile.js";  

import Frecuente from "views/Frecuente.js";  

import Gallery from "views/Gallery.js";  

import AuthContext from './context/autenticacion/authContext'




var routes = [
  {
    path: "/dashboard",
    name: "StrPrisma Dashboard",
    icon: "tim-icons icon-atom",
    component: Dashboard,
    layout: "/cliente"
  },
  // {
  //   path: "/projectos",
  //   name: "Proyectos",
    
  //   icon: "tim-icons icon-spaceship",
  //   component: Projectos,
  //   layout: "/cliente"
  // },
  // {
  //   path: "/inversiones",
  //   name: "Mis inversiones",
    
  //   icon: "tim-icons icon-chart-bar-32",
  //   component: Inversiones,
  //   layout: "/cliente",
    
  // },
  // {
  //   path: "/ofertas",
  //   name: "Mis ofertas",
    
  //   icon: "tim-icons icon-chart-pie-36",
  //   component: Ofertas,
  //   layout: "/cliente"
  // },
  {
    path: "/gallery-adu",
    name: "Colección de Artes",
    
    icon: "tim-icons icon-components",
    component: Gallery,
    layout: "/cliente"
  },
  {
    path: "/deposito",
    name: "Depósito",
    
    icon: "tim-icons icon-spaceship ",
    component: Deposito,
    layout: "/cliente"
  },
  {
    path: "/retiro",
    name: "Retiro",
    
    icon: "tim-icons icon-coins",
    component: Retiro,
    layout: "/cliente"
  },
  {
    path: "/user-profile",
    name: "Configuración",
    
    icon: "tim-icons icon-settings-gear-63",
    component: UserProfile,
    layout: "/cliente"
  },
  {
    path: "/preguntas-frecuentes",
    name: "Preguntas Frecuente",
    
    icon: "tim-icons icon-bulb-63",
    component: Frecuente,
    layout: "/cliente"
  },


 




]; 


var routespen = [

  {
    path: "/user-profile",
    name: "Mi Cuenta",
    
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/cliente"
  },
  
 




]; 











export default routes;
