import React from 'react';
import './Spinner-white.css'

const SpinnerWhite = () => {
    return ( 

        <div className="spinner-box-white">
            <div className="spinner-white"></div>
        </div>
            

     );
}
 
export default SpinnerWhite;