import React, { useState, useContext, useEffect, Fragment } from 'react';
import Modal from '@material-ui/core/Modal'
import { Link } from 'react-router-dom';
import {ReactComponent as Logo} from '../../prisma-items/logo-strprisma.svg'; 
import TextSpinner from '../spinners/TextSpinner';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ParticlesBackground from '../../components/Particles/ParticlesBackgroundWhite'


import Alerta from '../Alerta';

import AuthContext from '../../context/autenticacion/authContext.js'

import Spinner from '../spinners/Spinner' 

import Backdrop from '@material-ui/core/Backdrop';

import Fade from '@material-ui/core/Fade';  

import LogoPrisma from '../../prisma-items/Prisma-str-iso.svg' 


import Loading from '../Modales/Loading'




import {
    
    LIMPIAR_ALERTA,
  

} from '../../types'; 



import { 
    Row,
    Col,
    CardBody,
    CardHeader,
    CardFooter,
  } from "reactstrap"; 






const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'hidden',
    height:'100%',
    
    background: '#0000005c',
      
    },
    paper: {
      background: '#001c52',
      border: '2px solid #04a1ff',
      boxShadow: theme.shadows[5],
      
      
    },
  }));
  
  
  

const Login = (props) => {  


    document.oncontextmenu = function() {
        return false
     }
     function right(e) {
        var msg = "¡Hola Panthera aquí no puedes usar Click Derecho!";
        if (navigator.appName == 'Netscape' && e.which == 3) {
           
           return false;
        }
        else if (navigator.appName == 'Microsoft Internet Explorer' && event.button==2) {
           
        return false;
        }
            return true;
        }
        document.onmousedown = right;  
    
    

    const [ elcargando, elGuardarCargando] = useState(false);

    const [mensajedos, guardarMensaje] = useState(mensaje); 

    

    const elguardar = ( elcargando ) ? <Spinner/> :  
                                            
    <Fragment>
      Iniciar sesión
      </Fragment> 
  
  
    const classes = useStyles();
    const [open, setOpen] =useState(true);


    const [ cargando, guardarCargando] = useState(false);
     
  
      // ahora vamos a extraer los valores de registrar usuario del context
  
    const authContext = useContext(AuthContext);
    const { saldos, Saldos, mensaje, autenticado, iniciarSesion,userStatusFun, Limpiar, resendCorreo, proyectos_primario, status, usuario, filename,demo_status,mis_inversiones_activas, mis_inversiones_vendidas , mis_ofertas_oferente ,mis_ofertas_inversor,

        profile,
        userStatus, listDatos,cuentas_internacionales,cuentas_nacionales, cuentas_remesas, PrismaLoad, PrismaNoLoad,PrismaNoLoadApp,

        

            valor_total,
            equidad_total,
            margin_libre_total,
            flotante,
            ganancia_diaria,
            ganancia_esperada,
            flotante_hoy,
            margen_libre_hoy,
            cambio_actual
        
    
    } = authContext;  


        const [supermensaje , guardarSuperMensaje ] = useState('');


        
      

        const handleOpen = () => {
            setOpen(true);
        } 

        const handleClose = () => {
            setOpen(true);
            guardarMensaje(null);
        }

      

      useEffect( () => {

        

        guardarSuperMensaje(mensaje)
        
        

        // guardarCargando(false);
        // elGuardarCargando(false);

        if( autenticado && saldos && status && usuario  && cuentas_internacionales  && cuentas_nacionales  && cuentas_remesas

            
                
            // && status && usuario && filename && mis_inversiones_activas && mis_inversiones_vendidas && mis_ofertas_oferente && mis_ofertas_inversor
            ) { 

                props.history.push('/cliente')  
                 PrismaNoLoadApp(),
                 PrismaNoLoad()
            
            

            // if(status === 3){
            //     setTimeout( () => {
            //         guardarCargando(false);
            //         elGuardarCargando(false);
                
            //     props.history.push('/cliente');    
                
            //     },500);
            // } 

            //  if(status === 1 || status === 2){
            //     setTimeout( () => {
            //     guardarCargando(false);
            //         elGuardarCargando(false);
            //     props.history.push('/cliente');    
                
            //     },1000);
            // }
      
      
        } 

    } , [mensaje, status, usuario,autenticado, saldos, cuentas_nacionales, cuentas_internacionales, cuentas_remesas, props.history]
    );


    


    // estamos creando el state 


     const [usuariologin, guardarUsuario] = useState({
         email:'',
         password: ''
     });  



    /// manejador de evento del simulador
    // const [usuariologin, guardarUsuario] = useState({
    //     email:'usuariodemo@gmail.com',
    //     password: 'Militares8!'
    // });



    // extraer usuario del state

    const {email,password} = usuariologin; 


     const [ mostrarpassword , setMostrarPassword] = useState(false)



    const onChange = e => {

        // voy a colocar guardar usuario en el state

        guardarUsuario({
            ...usuariologin,
            [e.target.name] : e.target.value

        })
    }


    /// cuando el usuario quiere iniciar seseion
    const onSubmit = e => {
        e.preventDefault()
        // elGuardarCargando(true)
        
        window.scrollTo(0, 0)
  
        PrismaLoad()

        // validar que no haya campos vacios

        if(email.trim() === '' || password.trim() ==='') {
            
            return

        } 

        if (password.length < 6) {
            
            PrismaNoLoad()
        }

        
        iniciarSesion({ email,password}); 
        userStatusFun({email,password})
     

    }

    const onSubmitCorreo = e => {

        guardarCargando(true); 
        e.preventDefault(); 

        // validar que no haya campos vacios

        if(email.trim() === '' ) {
            
            return

        } 

        

        
        resendCorreo({ email});

    }



    /////  logica del componente//// mini wizard para iniciar sesion/////


    const [ inn, guardarInn ] = useState({
        holder_email:1,
        holder_password:0,
    });






    



    return ( 
    
    <>  

        <Loading/>    
        <div className="content  "> 

                <div className="prisma">

                <div className="windows-text d-none"> 

                    <div className="text-container">

                        <div className="prisma-picture">

                        <Logo className="animation-icon-in"/>

                        </div>



                    </div>




                </div>

                <div className="windows-login"> 
               

                <div className="register-container">
                <p class="text-grays text-center mb-0">¿Aún no tiene una cuenta?</p>
                <Link to={"/nueva-cuenta"} className="enlace-cuenta">
                <p> ¡Crear su cuenta ahora!</p>
                </Link> 


                </div>



                <div className="form-container">

                <div className="prisma-logo-media">
                    <img src={LogoPrisma} />

                </div>


                <h1 className=" animation-icon-in-dos line-block ">Iniciar sesión <span className="prisma-grad"><p>StrPrisma</p></span></h1> 
                



                {/* {mensaje && <Alerta/>} */} 
                {/* { autenticado ? <p className={`text-left  text-black animation-icon-in-dos ${usuario ? 'd-none':'d-flex'}`} > cargando...  </p> : null }
                { usuario ? <p className={`text-left  text-black animation-icon-in-dos ${ saldos ? 'd-none':'d-flex'}`}> Bienvenido {usuario.name}  </p> : null }
                { saldos ? <p className={`text-left  text-black animation-icon-in-dos `} >  cargando... Data Robot  </p> : null } */}

                <form onSubmit={onSubmit} > 
                <div className="auth-form">
                    {/* <h1 className="beta-text mb-0">Beta</h1>
                    <p className="text-dark text-center">Bienvenido a la version Beta de <strong> www.Panther7.com</strong></p> */}
               {inn.holder_email === 1 ?
                <>
                <div className="elemento-auth-one animation-icon-in-cinco">
                <label htmlFor="email" >Correo electrónico</label>

                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Escriba su correo aqui..."
                                onChange={onChange}
                                value={email}
                                required
                                autocomplete="true"
                                
                                />
                    </div>

                </> :null
               }




                    
                        { inn.holder_password === 1 ?         
                                <>
                                <div className=" elemento-auth-two animation-icon-in-cinco"> 
                        <label htmlFor="password" className="box-email-holder" > Contraseña <span className="email-holder-one"> de su cuenta <strong className="prisma-grad"><p>{usuariologin.email}</p></strong></span></label> 
                            {mostrarpassword ?  
                            <div className=" prismapassword"> 
                                <input
                                    type="text"
                                    id="password"
                                    name="password"
                                    placeholder="Mi contraseña"
                                    onChange={onChange}
                                    value={password}
                                    
                                    required
                                    autocomplete="true"
                                    
                                    />
                                <i  
                                    onClick={ () => ( 

                                        setMostrarPassword(false)

                                    )}
                                
                                
                                >
                                    <VisibilityIcon className="icon-vis-style" />
                                    
                                </i> 

                            </div> : 
                            <div className="prismapassword"> 
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Mi contraseña "
                                onChange={onChange}
                                value={password}
                                required
                                autocomplete="true"
                                />
                                <i 
                                    onClick={ () => ( 

                                        setMostrarPassword(true)

                                    )}    
                                >
                                    <VisibilityOffIcon className="icon-vis-style"/>
                                </i> 
                            </div> 
                            
                        } 
                    </div>


                                </>
                                :null
                        }
                    



                    <div className=" elemento-auth-tres animation-icon-in-cinco"> 

                    <Link to={"/forgot"} className=" text-dark">
                                                 <p>¿Olvidó su contraseña?  </p> 
                                                </Link> 

                                                
                    </div>

                </div>
                    


                    { inn.holder_email === 1 ?         
                                <>

                    <div className="footer-prisma-btn"> 
                    { usuariologin.email ?   
                        <button  onClick={ e =>{
                            e.preventDefault(),
                            guardarInn({
                                ...inn,
                                holder_email:0,
                                holder_password:1,
                            })

                        }}>
                            Siguiente...
                            </button>
                            :   <span className="block-cursor" disabled  >
                                    Siguiente
                            </span>
                        }
                        </div>
                            </>
                                :null
                        } 

                { inn.holder_password === 1 ?         
                        <>
                    <div className="footer-prisma-btn"> 
                    {/* { usuario ?   <button disabled type="submit" >
                            {elguardar}
                            </button>
                            :   <button type="submit" >
                            {elguardar}
                            </button>
                        } */}

                    { usuariologin.password ?   
                         <button type="submit" >
                         {elguardar}
                         </button>
                            :   <button  onClick={ e =>{
                                e.preventDefault(),
                                guardarInn({
                                    ...inn,
                                    holder_email:1,
                                    holder_password:0,
                                })
    
                            }}>
                                Atras...
                                </button>
                        }




                            </div>
                        </>
                                :null
                        }

                        
                    
                </form>

                <div className=" prisma-ai w-100 text-center"> 
                <small> PRISMA</small>
                <h1 className="text-center">AI</h1>
                <p clasName=" text-center w-100">
                Neuronal Machine Learning 

                </p>

                        <h2>Ultimate advanced Robo-advisor by StrPrisma <br/> 
                        All rights reserved 2021 StrPrisma.com
                        </h2>
                    </div>


                </div>

                </div> 

                {demo_status === "active" ? <p className="Hola">PLATAFORMA DEMO</p> :null }


                <div className="bg-particle-media">
                <ParticlesBackground/>
                </div>       
                </div>

                </div>


        

                        

                        {supermensaje === "El usuario debe confirmar la dirección de correo electrónico."  ?

                                    <Modal
                                                                                        
                                    className={classes.modal}
                                    open={open} 
                                    mensaje={supermensaje}
                                    disableScrollLock={true}

                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 900,
                                    }}
                                    >
                                    <Fade in={open}
                                    timeout={154}
                                    onClose={handleClose}>



                                    <div className="container-grid">
                                                                        <div  className={`modal-kyc-welcome padding-modal`}> 

                                                                        <div className="bg-modal-header">   
                                                                    <div  className=" fix-cuentaspropias-title ">
                                                                      <h5 className="title display-4 mb-0">Felicidades, Ya falta poco para termina tu registro</h5>
                                                                    </div>

                                                                    <div >
                                                                      <div className="cancel-box-btn">
                                                                          <div className="cancel-btn-panther" 
                                                                            onClick={ () => (
                                                                                              handleClose(),
                                                                                               Limpiar(),
                                                                                    guardarCargando(false),
                                                                                    elGuardarCargando(false)
                                                                            )}> 
                                                                              <i className="tim-icons icon-simple-remove"/>
                                                                          </div>
                                                                      </div>
                                                                    </div>    
                                                            </div>

                                                                            <div className="form-group form-control-deposito-container">
                                                                                <div className="modal-grid-row"> 

                                                                                <div> 
                                                                                        <h2 className="text-center mb-1">{supermensaje}</h2>

                                                                                </div>




                                                                                <div>
                                                                                      <form onSubmit={onSubmitCorreo}>
                                                                                      <div className="campo-form mt-3 justify-content-center">
                                                                                            <label htmlFor="email" className="text-help-send" >Email </label>

                                                                                            <input
                                                                                                type="email"
                                                                                                id="email"
                                                                                                name="email"
                                                                                                placeholder="Escribe tu correo electrónico"
                                                                                                onChange={onChange}
                                                                                                value={email}
                                                                                                
                                                                                                required
                                                                                                />
                                                                                    </div> 

                                                                                    <hr className="bg-info"/>
                                                                                    

                                                                                    <div className="campo-form "> 
                                                                                           <div className="container col-12">


                                                                                            <input
                                                                                            type="submit" className="btn btn-primario btn-block btn-panther-power"
                                                                                            value="Reenviar enlace de confirmación de cuenta"  /> 

                                                                                        </div>                                                                                            
                                                                                            

                                                                                    </div>
                                                                                    
                                                                                </form>
                                                                                </div>
                                                                                </div>    
                                                                                </div>
                                                                                </div>        
                                                                </div>



                                       
                                    </Fade>
                                    </Modal>     :null
                    
                                } 



                    


                                 {mensaje ?  
                                 
                                    <Modal
                                                                
                                                                className={`   ${classes.modal} ${supermensaje === "El usuario debe confirmar la dirección de correo electrónico." ?  'd-none':''} `}
                                                                open={open} 
                                                                mensaje={mensaje}
                                                                disableScrollLock={true}
                                                                supermensaje={supermensaje}
                                                                closeAfterTransition
                                                                BackdropComponent={Backdrop}
                                                                BackdropProps={{
                                                                    timeout: 900,
                                                                }}
                                                                >
                                                                <Fade in={open}
                                                                timeout={154}
                                                                onClose={handleClose}>
                                                                    

                                                                    <div className="container-grid">
                                                                        <div  className={`modal-kyc-welcome padding-modal`}> 

                                                                            

                                                                            <div className="form-group form-control-deposito-container">
                                                                                <div className="modal-grid-row"> 

                                                                                <div> 
                                                                                        <p className="text-center display-4 title my-3 font-light-weight-modal"> {mensaje}</p>

                                                                                </div>

                                                                                <div>
                                                                                    <button className="  btn mt-0 btn-block btn-info my-2 btn-panther-power" onClick={ ()=>(
                                                                                                                                handleClose(),
                                                                                                                                Limpiar(),
                                                                                                                                guardarCargando(false),
                                                                                                                                elGuardarCargando(false)
                                                                                                                                
                                                                                                                                

                                                                                                                        )}>
                                                                                                                Intente nuevamente
                                                                                    </button>
                                                                                </div>
                                                                                </div>    
                                                                                </div>
                                                                                </div>        
                                                                </div>




                                                                </Fade>
                                                                </Modal>            
                                : null 
                                
                                }

                   

                    </>


     );
}
 
export default Login;
