import React, { useContext, useState, useEffect, Fragment } from 'react';
import ConoceTuCliente from '../../components/IconsAnimation/ConoceTuCliente';
import clienteAxios from '../../config/axios';
import { makeStyles } from '@material-ui/core/styles'  
import Modal from '@material-ui/core/Modal'


import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import KycContainer from 'components/kyc/KycContainer';


import Spinner from '../../components/spinners/Spinner'


// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col
  } from "reactstrap"; 



function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


const Kyc = ({setValue,setRecargarData,userStatus, guardarAppBar, guardarAppBarKyc, appbar, appbarkyc, usuario,usuarioAutenticado}) => { 

  const [ cargando, guardarCargando] = useState(false);

  const guardar = ( cargando ) ? <Spinner/> :  
                                          
  <Fragment>
    Enviar documentos
    </Fragment> 





    const [ modalStyle ] =useState(getModalStyle);
    const [open, setOpen] =useState(false);

    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true);
    } 

    const handleClose = () => {
        setOpen(false);;
    }




    const [mensaje, guardarMensaje] = useState({
        message:""
    })

    const [kyc, guardarKyc] = useState({
        file_document:'',
        file_poa:'',
        file_selfie:'',
        file_bank:'',

    });  


    {kyc.file_document !== "" ?  'file_document':'' }
 
    const [file, setFile] = useState(undefined);

    const consultarApiKyc = async () => {
        const {file_id_front,
            file_id_reverse,file_selfie,
        
          
          } = kyc;  

          const config = {     
            headers: { 'content-type': 'multipart/form-data','Accept': 'application/json' },
            
            mimeType: 'multipart/form-data',
        } 


        const formData = new FormData();
       

        //form.append("file_selfie", fileInput.files[0], "20151022_204039.jpg");
        
        // formData.append('file_id_front',file.files[0],file_id_front);
        // formData.append('file_id_reverse',file.files[0],file_id_reverse);
        // formData.append('file_selfie',file.files[0],file_selfie);


        //formData.append('file_id_front',file_id_front);
        //formData.append('file_id_reverse',file_id_reverse);
        //formData.append('file_selfie',file_selfie);

// metodo jquery
        formData.append('file_document',document.getElementById('file_document').files[0],file_document);
        formData.append('file_selfie',document.getElementById('file_selfie').files[0],file_selfie);
        formData.append('file_bank',document.getElementById('file_bank').files[0],file_bank);
        formData.append('file_poa',document.getElementById('file_poa').files[0],file_poa);

        
     
        try {
            const respuesta = await clienteAxios.post('/api/client/kyc',formData,config);

                  
                  guardarMensaje(respuesta.data.message);
                    handleOpen();
                   
                   
            
        } catch (error) {
            
            
            guardarMensaje(error.response.data.message);
                    handleOpen();
                    
        }

      
      
      
          
      
      
      
    } 


  
    let formData = new FormData();




    const onChange = e => {

        // voy a colocar guardar usuario en el state

        guardarKyc({
            ...kyc,
            [e.target.name] : e.target.value
            
        }) 

        setFile(e.target.files[0])
    }


    

    const onSubmit = e => {
      guardarCargando(true)
        e.preventDefault();

        

              // if( kyc.file_document.trim() === '' || 
              //     kyc.file_selfie.trim() === '' || 
              //     kyc.file_poa.trim() === '' 
                  
              //    ) {
              //        return
              //   } 

        //  if (name.trim() === '') {
        //      mostrarAlerta('Coloca tu nombre', 'alerta-panther');
        //  }
        
        // // vamos a poner que el password minimo debe tener 6 caracteres
        //  if (password.length < 6) {
        //      mostrarAlerta('El Password debe ser de al menos 6 caracteres', 'alerta-panther');
        //  }

        // // // los 2 password tienen que ser iguales
        //  if (password !== confirmar) {
        
        //    mostrarAlerta('Los password no son iguales','alerta-panther');
        //  }

        // luego lo vamos a pasar al action

        

        consultarApiKyc({



          

          

            file_document,
            file_poa,
            file_selfie,
            file_bank,


    
        }); 

     
      
        
    

    }




    


    return ( 

      <>

       
            
            <div className="grid-kyc-container">
                {/* <h5 className="title display-4">Centro de verificación</h5> */}

                <div class="prisma-clip-2"><p> Centro de verificación</p></div>
                <p className="text-dark  pb-1">Por favor cargue los documentos solicitados para verificar su cuenta.</p>

                <KycContainer usuario={usuario} handleOpen={handleOpen}  guardarMensaje={guardarMensaje}/>


              
            
             </div>
              

            
          

          { mensaje ? <Modal
                
                className={classes.modal}
                open={open} 
                mensaje={mensaje}
                                
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 900,
                }}
              >
                <Fade in={open}
                timeout={154}
                onClose={handleClose}>



                
                                              <div className="container-grid">
                                                                        <div  className={`modal-kyc-welcome padding-modal`}> 
                                                                            <div className="form-group form-control-deposito-container">
                                                                                <div className="modal-grid-row"> 

                                                                                <div> 
                                                                                        <p className="text-center display-4 title my-3 font-light-weight-modal"> {mensaje}</p>

                                                                                </div>

                                                                                <div>
                                                                                    <button className="  btn mt-0 btn-block btn-info my-2 btn-panther-power" onClick={ () => (
                                                                                                        // setValue(2),
                                                                                                      //   guardarAppBar({
                                                                                                      //     ...appbar,
                                                                                                      //     primero:0,
                                                                                                      //     segundo:0,
                                                                                                      //     tercero:1,
                                                                                                      //   }),
                                                                                                      // guardarAppBarKyc({
                                                                                                      //   ...appbarkyc,
                                                                                                      //   primero:0,
                                                                                                      //   segundo:0,
                                                                                                      //   tercero:1,
                                                                                                      // }),
                                                                                                        userStatus(),
                                                                                                        guardarMensaje(null),
                                                                                                        usuarioAutenticado()
                                                                                                                                                                    
                                                                                                          )}>
                                                                                                                Aceptar
                                                                                    </button>
                                                                                </div>
                                                                                </div>    
                                                                                </div>
                                                                                </div>        
                                                                </div>
                                                            </Fade>
                                                    </Modal> : null}




    </>


     );
}
 
export default Kyc;