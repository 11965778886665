import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import routespen from "routespen.js";


import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "../../context/BackgroundColorContext"; 


import Modal from '@material-ui/core/Modal'
import ModalClick from '@material-ui/core/Modal'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'  

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade'; 

/// vamos a importar la dependencia de tawkto
import tawkTo from "tawkto-react";


/// aqui vamos a importar el token
import AuthContext from  '../../context/autenticacion/authContext'  

import SaldosContext from  '../../context/saldos/SaldosContext'  

import ParticlesBackground from "../../components/Particles/ParticlesBackground";




import { 
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap"; 


function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    
    
  };
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'hidden',
 
  
    
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    
  
  },
}));

const useStylesClick = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'hidden',
  height:'100%',
  
  background: '#000000c',
    
  },
  paper: {
    background: '#fff',
    border: '2px solid #04a1ff',
    boxShadow: theme.shadows[5],
    
    
  },
}));







var ps;

function Cliente(props) { 

  // ahora vamos a llamar al context de los saldos


  
  const saldosContext = useContext(SaldosContext);
   const { saldos, consultarApiSaldo } = saldosContext;    



  // extraer la informacion de autenticacion 
  const authContext = useContext(AuthContext);
    const { usuarioAutenticado, status, CerrarSesion, demo_status, collapse, usuario, recargandoapp } = authContext;    


    const [ mensaje, guardarMensaje ] = React.useState('');

    const [ modalStyle ] =React.useState(getModalStyle);
    const [open, setOpen] =React.useState(false);
  
    const classes = useStyles();
    
   
    const handleOpen = () => {
        setOpen(true);
    } 
   
    const handleClose = () => {
        setOpen(false);
        guardarMensaje(null);
    }
   


  const location = useLocation();
  const mainPanel = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  ); 




  const handleOpenClick = () => {
    setOpenClick(true);
} 

const handleCloseClick = () => {
    setOpenClick(false);
    
} 

const [openclick, setOpenClick] =useState(true);
const classesclick = useStylesClick();

const [mensajeclick, guardarMensajeClick] =useState(``);






  document.oncontextmenu = function() {
    return false
 }
 function right(e) {
    var msg = "¡Hola aquí no puedes usar Click derecho!";
    if (navigator.appName == 'Netscape' && e.which == 3) {
        handleOpenClick() //- Si no quieres asustar a tu usuario entonces quita esta linea...
        guardarMensajeClick(msg)
       return false;
    }
    else if (navigator.appName == 'Microsoft Internet Explorer' && event.button==2) {
        handleOpenClick() //- Si no quieres asustar al usuario que utiliza IE,  entonces quita esta linea...
                      //- Aunque realmente se lo merezca...
        
        guardarMensajeClick(msg)
    return false;
    }
        return true;
    }
    document.onmousedown = right;  







  // const tawkToPropertyId = '60a52d16185beb22b30ec256'

  // const tawkToKey = '943a9989d8f69ce46aa2305aad6b89d83af0c36e'


 React.useEffect(()=> { 


  // tawkTo(tawkToPropertyId, tawkToKey)

      usuarioAutenticado(); 
      consultarApiSaldo();

      if(usuario.paso === 0 ) {
        
        
          handleOpen()
        
      }   




      if(status === 0) {
        localStorage.removeItem('token');
        props.history.push('/portal');
      }   

      setTimeout(() => {
      
        CerrarSesion();
    },80400000);
    
  
   }, [recargandoapp,status,props.history])

   const [ siguiente, guardarSiguiente] = useState({
      primero:0,
      segundo:0,
      tercero:0,
      cuarto:0,
      quinto:0,
          sexto:0,
          septimo:0,
          octavo:0,
   }); 


   

   useEffect(() => {


    setTimeout(() => {
      guardarSiguiente({
        ...siguiente,
        primero:1,
        segundo:0,
        tercero:0,
        cuarto:0,
        quinto:0,
          sexto:0,
          septimo:0,
          octavo:0,
     }); 
    },1000);

      setTimeout(() => {
        guardarSiguiente({
          ...siguiente,
          primero:0,
          segundo:1,
          tercero:0,
          cuarto:0,
          quinto:0,
          sexto:0,
          septimo:0,
          octavo:0,
       }); 
      },2900);

      setTimeout(() => {
        guardarSiguiente({
          ...siguiente,
          primero:0,
          segundo:1,
          tercero:0,
          cuarto:0,
          quinto:0,
          sexto:0,
          septimo:0,
          octavo:0,
       }); 
      },4200);


      setTimeout(() => {
        guardarSiguiente({
          ...siguiente,
          primero:0,
          segundo:0,
          tercero:1,
          cuarto:0,
          quinto:0,
          sexto:0,
          septimo:0,
          octavo:0,
       }); 
      },6000);


      setTimeout(() => {
        guardarSiguiente({
          ...siguiente,
          primero:0,
          segundo:0,
          tercero:0,
          cuarto:1,
          quinto:0,
          sexto:0,
          septimo:0,
          octavo:0,
       }); 
      },7100);


      setTimeout(() => {
        guardarSiguiente({
          ...siguiente,
          primero:0,
          segundo:0,
          tercero:0,
          cuarto:0,
          quinto:1,
          sexto:0,
          septimo:0,
          octavo:0,
       }); 
      },8200);

      setTimeout(() => {
        guardarSiguiente({
          ...siguiente,
          primero:0,
          segundo:0,
          tercero:0,
          cuarto:0,
          quinto:0,
          sexto:1,
          septimo:0,
          octavo:0,
       }); 
      },9200);


      setTimeout(() => {
        guardarSiguiente({
          ...siguiente,
          primero:0,
          segundo:0,
          tercero:0,
          cuarto:0,
          quinto:0,
          sexto:0,
          septimo:1,
          octavo:0,
       }); 
      },10200);


      setTimeout(() => {
        guardarSiguiente({
          ...siguiente,
          primero:0,
          segundo:0,
          tercero:0,
          cuarto:0,
          quinto:0,
          sexto:0,
          septimo:0,
          octavo:1,
       }); 
      },11800);


      setTimeout(() => {
        guardarSiguiente({
          ...siguiente,
          primero:0,
          segundo:0,
          tercero:0,
          cuarto:0,
          quinto:0,
          sexto:0,
          septimo:0,
          octavo:0,
          ultimo:1,
       }); 
      },12600);



    }, [])




  // React.useEffect(() => { 

    

  //   if (navigator.platform.indexOf("Win") > -1) {
  //     document.documentElement.className += " perfect-scrollbar-on";
  //     document.documentElement.classList.remove("perfect-scrollbar-off");
  //     ps = new PerfectScrollbar(mainPanel.current, {
  //       suppressScrollX: true,
  //     });
  //     let tables = document.querySelectorAll(".table-responsive");
  //     for (let i = 0; i < tables.length; i++) {
  //       ps = new PerfectScrollbar(tables[i]);
  //     }
  //   }
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //       document.documentElement.classList.add("perfect-scrollbar-off");
  //       document.documentElement.classList.remove("perfect-scrollbar-on");
  //     }
  //   };
  // });

  ////////////////
  //   React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     document.documentElement.className += " perfect-scrollbar-on";
  //     document.documentElement.classList.remove("perfect-scrollbar-off");
  //     ps = new PerfectScrollbar(mainPanel.current, {
  //       suppressScrollX: true,
  //     });
  //     let tables = document.querySelectorAll(".table-responsive");
  //     for (let i = 0; i < tables.length; i++) {
  //       ps = new PerfectScrollbar(tables[i]);
  //     }
  //   }
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //       document.documentElement.classList.add("perfect-scrollbar-off");
  //       document.documentElement.classList.remove("perfect-scrollbar-on");
  //     }
  //   };
  // });

  //   if (navigator.platform.indexOf("Win") > -1) {
  //     let tables = document.querySelectorAll(".table-responsive");
  //     for (let i = 0; i < tables.length; i++) {
  //       ps = new PerfectScrollbar(tables[i]);
  //     }
  //   }


////////////////////

  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     let tables = document.querySelectorAll(".table-responsive");
  //     for (let i = 0; i < tables.length; i++) {
  //       ps = new PerfectScrollbar(tables[i]);
  //     }
  //   }
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   if (mainPanel.current) {
  //     mainPanel.current.scrollTop = 0;
  //   }
  // }, [location]);

  /////////////////

  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/cliente") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  }; 


  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor, backgroundColor, handleBgClick, ...props }) => (
        <React.Fragment>

          
          
          <div className="wrapper"> 
          
        


          { status === 1 ?  

                        <span
                        className={`${collapse === true ? 'd-none' :''}`}
                        > 
                              <Sidebar
              
                              routes={routespen}
                              bgColor={color}
                              className={`${collapse === true ? 'd-none' :''}`}
                              logo={{
                                outterLink: "https://www.panther7.com/",
                                walletLink: "http://34.219.66.47/wallet",
                                text: "Panther 7 ",
                                imgSrc: logo
                              }}
                              toggleSidebar={toggleSidebar}
                              {...props}
                            />
                            
                            </span>:null
                              
                              }  

          { status === 3 || status === 2 ?  

                              <span
                              className={`${collapse === true ? 'd-none' :''}`}
                              > 
                              
                              
                              <Sidebar
                              
                              routes={routes}
                              bgColor={color}
                              logo={{
                                outterLink: "https://www.panther7.com/",
                                walletLink: "http://34.219.66.47/wallet",
                                text: "Panther 7 ",
                                imgSrc: logo
                              }}
                              toggleSidebar={toggleSidebar}
                              {...props}
                            />
                            </span>
                            
                            :null
                              
                              }  
           
                              
            <div className={ `main-panel  ${collapse === true ? 'expandnav' :''}   ${demo_status === "active" ? 'main-panther-bg-demo' :'main-prisma-bg'}`} ref={mainPanel} data={color}>
              
              <AdminNavbar
                
                brandText={getBrandText(location.pathname)}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
                {...props}
              />

                { status === 1 ?  
                             <Switch>
                             {getRoutes(routes)} 
                             <Redirect from="*" to="/cliente/user-profile" />
                           </Switch>:null
                              
                              }  

              
                { status === 3 || status === 2 ?  
                             <Switch>
                             {getRoutes(routes)} 
                             <Redirect from="*" to="/cliente/dashboard" />
                           </Switch>:null
                              
                              }     
              
              {
                // we don't want the Footer to be rendered on map page
                location.pathname === "/admin/maps" ? null : <Footer fluid />
              }
            </div>
          </div>


          
          
          <FixedPlugin bgColor={color} handleBgClick={changeColor} />

              
        <Modal
                    props={props}
                    className={`Modal-backgroundStyle ${classes.modal}`}
                    open={open} 
                    mensaje={mensaje}
                    disableScrollLock={true}
                    usuario={usuario}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 900,
                    }}
                    >
                    <Fade in={open}
                    timeout={154} 
                    
                    > 

                    
                    

                    
                                          <div className="container-grid">
                                                                            <div  className={`modal-kyc-welcome no-bg padding-modal`}> 
                                                                                <div className="form-group form-control-deposito-container">

                                                                                <div className="header-welcome-grid"> 


                                                                                        <div className="Welcome-prisma-essencial ">

                                                                                          {siguiente.primero === 1 ? 
                                                                                              <> 
                                                                                              <p  className="animation-suge super-size"> 
                                                                                                Hola 
                                                                                              </p>
                                                                                              </> :null
                                                                                          }

                                                                                        {siguiente.segundo === 1 ? 
                                                                                              <> 
                                                                                                <p className="animation-suge">
                                                                                                  {usuario.name}
                                                                                                </p>

                                                                                                
                                                                                              </> :null
                                                                                          }

                                                                                          
                                                                                        { siguiente.tercero === 1 ? 
                                                                                              <> 
                                                                                                <p className="animation-suge">
                                                                                                VAMOS A CONOCERNOS
                                                                                                </p>
                                                                                              </> :null
                                                                                          }  


                                                                                      { siguiente.cuarto === 1 ?   
                                                                                              <> 
                                                                                                <p className="animation-suge">
                                                                                                ¡Esta muy cerca de completar 
                                                                                                </p>

                                                                                                
                                                                                              </> :null
                                                                                          } 


                                                                                        { siguiente.quinto === 1 ?   
                                                                                              <> 
                                                                                                <p className="animation-suge">
                                                                                                el registro de su cuenta!
                                                                                                </p>
                                                                                              </> :null
                                                                                          } 


                                                                                        { siguiente.sexto === 1 ?   
                                                                                              <> 
                                                                                                <p className="animation-suge">
                                                                                                Por favor complete 
                                                                                                </p>
                                                                                              </> :null
                                                                                          } 


                                                                                      { siguiente.septimo === 1 ?   
                                                                                              <> 
                                                                                                <p className="animation-suge">
                                                                                               todos los pasos 
                                                                                                </p>
                                                                                              </> :null
                                                                                          } 


                                                                                    { siguiente.octavo === 1 ?   
                                                                                              <> 
                                                                                                <p className="animation-suge">
                                                                                                correctamente
                                                                                                </p>
                                                                                              </> :null
                                                                                          } 

                                                                                      { siguiente.ultimo === 1 ?   
                                                                                              <> 
                                                                                                <p className="animation-suge">
                                                                                                ¡Muchísimas Gracias!
                                                                                                </p>
                                                                                              </> :null
                                                                                          } 



                                                                                          

                                                                                          

                                                                                          

                                                                                        </div>

                                                                                        { siguiente.ultimo === 1 ?   
                                                                                              <> 
                                                                                                <div className="text-hola animation-suge">
                                                                                          <h2 class="text-center mb-1">Su usuario ha sido creado con éxito</h2>
                                                                                          <h1 class="text-center mb-1">Robo-Advisor powered by StrPrisma</h1>
                                                                                      </div>
                                                                                              </> :null
                                                                                          } 
                              
                                                                                      {/* <h3 className="title display-4 mb-0 text-center">VAMOS A CONOCERNOS  </h3> */}
                                                                                      {/* <p className="text-center">WOW ¡Esta muy cerca de completar el registro de su cuenta!</p> */}

                                                                                    </div>

                                                                                    
                                                                                

                                                                                    <div className="modal-grid-row"> 
                                                                                    {/* <div className="text-hola">
                                                                                   
                                                                                      <h3 className="text-center mb-1 font-weight-900">Por favor complete su información personal</h3>
                                                                                      <h2 className="text-center mb-1"> para acceder a StrPrisma.</h2>
                                                                                    </div>  */}

                                                                                   

                                                                                    {/* <div> 
                                                                                          <p className="text-center display-4 title my-3 font-light-weight-modal"> {mensajeclick}</p>

                                                                                    </div> */}

                                                                                    <div> 

                                                                                    { siguiente.ultimo === 1 ?   
                                                                                              <> 
                                                                                                <button className=" mt-0 btn-block btn-continuar " onClick={handleClose}
                                                                                    
                                                                                    >
                                                                                    Continuar con el cuestionario 
                                                                                    </button>
                                                                                              </> :null
                                                                                          } 

                                                                                    


                                                                                    </div>

                                                                                    

                                                                                    </div>    
                                                                                    </div>
                                                                                    </div>        
                                                                    </div>





                    </Fade>
                    </Modal> 

                    
            {mensajeclick ?  
                                            
                                            <ModalClick
                                                                        
                                                                        className={classesclick.modal}
                                                                        open={openclick} 
                                                                        mensaje={mensajeclick}
                                                                        disableScrollLock={true}
                                                                        
                                                                        closeAfterTransition
                                                                        BackdropComponent={Backdrop}
                                                                        BackdropProps={{
                                                                            timeout: 900,
                                                                        }}
                                                                        >
                                                                        <Fade in={openclick}
                                                                        timeout={154}
                                                                        onClose={handleCloseClick}>
                                                                            <div className="container-grid">
                                                                            <div  className={`modal-kyc-welcome padding-modal`}> 
                                                                                <div className="form-group form-control-deposito-container">
                                                                                    <div className="modal-grid-row"> 

                                                                                    <div className="prisma-alert">
                                                                                              <img alt="..." src={require("../../assets/img/anime3.png")} hight="150px"/>
                                                                                    </div> 

                                                                                    <div> 
                                                                                          <p className="text-center display-4 title my-3 font-light-weight-modal"> {mensajeclick}</p>

                                                                                    </div>

                                                                                    <div>
                                                                                      <button className="  btn mt-0 btn-block btn-info my-2 btn-panther-power" onClick={ ()=>(
                                                                                            handleCloseClick()
                                                                                            
                                                                                            
                                                                                    )}
                                                                                                              
                                                                                                              
                                                                                                              >
                                                                                                                  ¡Muchas gracias!, ya no lo vuelvo a hacer!
                                                                                        </button>
                                                                                    </div>
                                                                                    </div>    
                                                                                    </div>
                                                                                    </div>        
                                                                    </div>
                                                                        </Fade>
                                                                        </ModalClick>            
                                        : null 
                            
                                        }


        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Cliente;