import React, {useState, useEffect, Fragment, useContext } from 'react';
import clienteAxios from '../config/axios';
import Spinner from '../components/spinners/SpinnerWhite' ;
import NumberFormat from 'react-number-format';
import AuthContext from '../context/autenticacion/authContext';





const Contrato = ({guardarParticipacion,participaciones,contrato,guardarContrato,montosavereset,guardarBtnDinamic,btndinamic,usuario,usuarioAutenticado, montosave, guardarMontoSave}) => { 

    // const authContext = useContext(AuthContext);
    // const { LastContract, last_contract } = authContext;




    const [ cargando, guardarCargando] = useState(false);
        const guardar = ( cargando ) ? <Spinner/> :  
    
        <Fragment>
            Generar Contrato
        </Fragment> 



    const [ mensaje, guardarMensaje]  = useState({})

  
  
     const [multiplicador, guardarMultiplicador] =useState()
    ////manejador de evento de las partcipaciones
                                            ////    aqui voy a colocar la participacion///
                        





         useEffect(() => {
             guardarMultiplicador(
                 parseInt(parseInt(participaciones.multiplicador) * 500)
             )
         }, [participaciones])





         useEffect(() => {

             guardarContrato({
                 amount: `${multiplicador}`,
             })
            
         }, [multiplicador])


    //// aqui vamos a guardar el url

    
    

 
    


 

    /// aqui vamos a hacer la funcion 

    const consultarApiContrato = async () => {

        

        try{
            const respuesta = await clienteAxios.post(`/api/client/contract/create`, {amount:` ${contrato.amount}`} );
            
             guardarMensaje(respuesta.data.message)
             guardarBtnDinamic(respuesta.data.data.url)
             guardarMontoSave(`${contrato.amount}`)

        }
        catch(error) {
            // guardarMensaje(error.response.data.message)
            console.log(error)

        }


    }


    const onChangeParticipacion = e => {
        
        guardarParticipacion({
            ...participaciones,
            [e.target.name] : e.target.value 
        }) 

    }


    const onChange = e => {  
        /// manejadores de eventos
        guardarContrato({
            ...contrato,
            [e.target.name] : e.target.value
            
        }) 
    
         
    }  


    const onSubmit = e => {
        e.preventDefault(); 

        consultarApiContrato()

    }



    return (  


            <>


                        <form onSubmit={onSubmit}>

                    <div className="contrato-dream grid-wizard"> 


                                    <div className={`   elemento3 first  ${usuario.paso >= 3 ? 'active':'select'}  `}> 
                                                                    <span>  
                                                                            <i className="tim-icons icon-paper">
                                                                            </i>

                                                                            <h4> </h4>
                                                                        </span>

                                                                        
                                                                        
                                                                        <h3> Contrato </h3>


                                                                        <p>
                                                                        {` ${usuario.paso >= 3 ? '¡Muchas Gracias!':'Descargue y complete el contrato de participación por su inversión'}  `}
                                                                            
                                                                            
                                                                            </p>

                                                                </div> 

                                                                <p className="advertencia">
                                                                        {` ${usuario.paso >= 3 ? '¡Este Paso fue completado Correctamente!':'Cada participación tiene un costo de $500 dolares'}  `}
                                                                            
                                                                            
                                                                            </p>


                        
                    </div> 



                    {btndinamic === "" ?

                    <>


                    <div className={` contrato-dream-dos ${usuario.paso >= 6 ? 'd-none':''}  `}> 

                     <h3 className="advertencia">
                        Cada participación tiene  un costo <br/> de <strong>$500 </strong> dolares                                                                                                                            
                    </h3>

                                  <div>

                                    <input type="number" 
                                            placeholder="0"
                                            id="multiplicador"
                                            name="multiplicador"
                                            onChange={onChangeParticipacion}
                                            value={participaciones.multiplicador} 
                                            required
                                            maxlength="1"
                                            min="0"
                                            className="form-control"      
                                    />

                            </div>


                            <div> 

                                <p>
                                    Escriba la cantidad de participaciones que desea!
                                </p>
                                



                            </div> 

              

                    </div>

                    {contrato.amount !== '0' ?
                        <>

                        {contrato.amount !== "NaN" ?


                    <div className="grid-form">
                        <div>
                            {/* <label> 
                                        Porfavor escriba su Monto , para generar el contrato     
                            </label> */}
                        <input type="text" 
                            placeholder="Digite el monto"
                            id="amount"
                            name="amount"
                            onChange={onChange}
                            value={contrato.amount} 
                            required
                            className="form-control text-right"      
                            step={500}
                            disabled
                            
                            />
                        </div>
                    </div> 

                        :null }
                    </>

                        :null
                                        
                        }

                    {contrato.amount !== '0' ?
                    
                    <>

                            {contrato.amount !== "NaN" ?
                            <>
                       <div className="animation-op " >
                       <h2 className="display-currency text-cente">
                       <p>Usted está haciendo un deposito inicial con el monto <span className="currency-bold"><NumberFormat value={contrato.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/> </span>  </p>
                       </h2>
                        </div> 

                        <button className={`btn-small-prisma w-100 ${montosave !== "" ? 'd-none': 'd-block'  }`} color="primary" type="submit">
                        {guardar}
                        </button> 
                            </>

                                :null
                                }
                    </>
                        
                    
                    :null
                  
                    }

                   
                     </>    

                     : null
            }


            {btndinamic !== "" ?
                    <>

                
                      
                      <p className="advertencia my-2"> Click para Descargar el contrato, guardelo, <br/> completelo y vuelva a subirlo en el siguiente paso de depósito </p>
                      


                        <div className="btn-box-gen">


                        

                
                    <button className="btn btn-primario btn-block btn-radius-panther-dos  " 
                        onClick={montosavereset}
                    
                        >
                        Generar otro contrato
                    </button>

                    <a className=" final-btn-prisma-mob text-center " href={ `${btndinamic}`} rel="noopener noreferrer" target="_blank" >
                                    { `Descargar tu contrato  $ ${montosave}` }
                        </a> 


                        </div>



                    




                      </>

                      : null
            }





                    </form>



            
                    

            </>


    );
}
 
export default Contrato;