import React from "react";
import Particles from "react-particles-js";
import ParticlesConfig from '../Particles/ParticlesConfig';




const ParticlesBackground = () => {
    return (  

        <Particles params={ParticlesConfig} > 
        
        
        </Particles>
    );
}
 
export default ParticlesBackground;