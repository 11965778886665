import React from "react";
import Particles from "react-particles-js";
import ParticlesConfigWhite from './ParticlesConfigWhite';




const ParticlesBackground = () => {
    return (  

        <Particles params={ParticlesConfigWhite} > 
        
        
        </Particles>
    );
}
 
export default ParticlesBackground;