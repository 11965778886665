import React, { useEffect, useState, useContext } from 'react';
import AuthContext from '../../context/autenticacion/authContext';
import { Route, Redirect } from 'react-router-dom';
import clienteAxios from 'config/axios'


const Bienvenido = () => { 


    const [usuario, guardarUsuario] = useState({
        id: '',
        name:'',
        last_c:'',


    }); 

    useEffect( () => {        
        const consultarApiProfile = async () => {
            const {id, name} = usuario; 
        
      
          const respuesta = await clienteAxios.post('/api/client/profile')
          
          
          
          guardarUsuario(respuesta.data)
          
          
        }
      
        consultarApiProfile()
      
        guardarUsuario(usuario);
      
       
       
        
      
      },[]);  
    
        
      const authContext = useContext(AuthContext);
      const { demo_status } = authContext;  

    

    return (  

      <> 
          <div className={` ${demo_status === "active" ? 'welcome-box-active': ' welcome-box'}`}>
          <div className="bienvenido"> 
            
            <strong> Hola 

            { demo_status === "active" ?  'al simulador' : null}
              
               </strong> <p> {usuario.name}, </p>  <span> Su última conexión fué {usuario.last_c} </span> 
         </div> 


          { demo_status === "active" ?  <p className="Hola2 ">PLATAFORMA DEMO</p> : null}
        

          </div>


           

            </>


    );
}
 
export default Bienvenido;