import React, {useState, useEffect, Fragment } from 'react';
import clienteAxios from '../config/axios';
import Spinner from '../components/spinners/SpinnerWhite' ;
import NumberFormat from 'react-number-format';




const AduGenerator = ({multiplicador, guardarMultiplicador, guardarParticipacion,participaciones,contrato,guardarContrato,montosavereset,guardarBtnDinamic,btndinamic,usuario,usuarioAutenticado, montosave, guardarMontoSave}) => { 






    const [ cargando, guardarCargando] = useState(false);
        const guardar = ( cargando ) ? <Spinner/> :  
    
        <Fragment>
            Generar A.D.U
        </Fragment> 



    const [ mensaje, guardarMensaje]  = useState({})

  
  
     
    ////manejador de evento de las partcipaciones
                                            ////    aqui voy a colocar la participacion///
                        





         useEffect(() => {
             guardarMultiplicador(
                 parseInt(parseInt(participaciones.multiplicador) * 500)
             )
         }, [participaciones])





         useEffect(() => {

             guardarContrato({
                 amount: `${multiplicador}`,
             })
            
         }, [multiplicador])


    //// aqui vamos a guardar el url

    
    


 

    /// aqui vamos a hacer la funcion 

    const consultarApiContrato = async () => {

        

        try{
            const respuesta = await clienteAxios.post(`/api/client/contract/get_images`, {amount:`${contrato.amount}`} );
            
             guardarMensaje(respuesta.data.message)
             guardarBtnDinamic(respuesta.data.data.contrato)
             guardarAdu(respuesta.data.data)
             guardarMontoSave(`${contrato.amount}`)

        }
        catch(error) {
            // guardarMensaje(error.response.data.message)
            console.log(error)

        }


    }


    const onChangeParticipacion = e => {
        
        guardarParticipacion({
            ...participaciones,
            [e.target.name] : e.target.value 
        }) 

    }


    const onChange = e => {  
        /// manejadores de eventos
        guardarContrato({
            ...contrato,
            [e.target.name] : e.target.value
            
        }) 
    
         
    }  


    const onSubmit = e => {
        e.preventDefault(); 

        consultarApiContrato()

    }


    const [adu, guardarAdu ] = useState([{
        contrato:'',
        
      
      }]);
       



    return (  


            <>


                        <form onSubmit={onSubmit}>

                    <div className="contrato-dream grid-wizard"> 


                                    <div className={`   elemento3 first  ${usuario.paso >= 3 ? ' size-adu':'select'}  `}> 
                                                                    <span>  
                                                                            <i className="tim-icons icon-components">
                                                                            </i>

                                                                            <h4> </h4>
                                                                        </span>

                                                                        
                                                                        
                                                                        <h3> A.D.U </h3>


                                                                        <p>
                                                                        {` ${usuario.paso >= 3 ? 'Arte Unico Digital':'Descargue y complete el contrato de participación por su inversión'}  `}
                                                                            
                                                                            
                                                                            </p>

                                                                </div> 

                                                                <p className="advertencia">
                                                                        {` ${usuario.paso >= 3 ? '¡La Memoria del Mercado !':'Cada participación tiene un costo de $500 dolares'}  `}
                                                                            
                                                                            
                                                                            </p>


                        
                    </div> 



                    {btndinamic === "" ?

                    <>


                    <div className={` contrato-dream-dos ${usuario.paso >= 9 ? 'd-none':''}  `}> 

                    

                                  <div>

                                    <input type="number" 
                                            placeholder="0"
                                            id="multiplicador"
                                            name="multiplicador"
                                            onChange={onChangeParticipacion}
                                            value={participaciones.multiplicador} 
                                            required
                                            maxlength="1"
                                            min="0"
                                            className="form-control"      
                                    />

                            </div>



              

                    </div>

                    
                    {contrato.amount !== '0' ?
                        <>

                        {contrato.amount !== "NaN" ?


                    <div className="grid-form">
                        <div>
                            {/* <label> 
                                        Porfavor escriba su Monto , para generar el contrato     
                            </label> */}
                        <input type="text" 
                            placeholder="Digite el monto"
                            id="amount"
                            name="amount"
                            onChange={onChange}
                            value={contrato.amount} 
                            required
                            className="form-control text-right"      
                            step={500}
                            disabled
                            
                            />
                        </div>
                    </div> 

                        :null }
                    </>

                        :null
                                        
                        }

                    {contrato.amount !== '0' ?
                    
                    <>

                            {contrato.amount !== "NaN" ?
                            <>
                       <div className="animation-op " >
                       <h2 className="display-currency text-cente">
                       <p>Usted está haciendo un deposito inicial con el monto <span className="currency-bold"><NumberFormat value={contrato.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/> </span>  </p>
                       </h2>
                        </div> 

                        <button className={`btn-small-prisma w-100 ${montosave !== "" ? 'd-none': 'd-block'  }`} color="primary" type="submit">
                        {guardar}
                        </button> 
                            </>

                                :null
                                }
                    </>
                        
                    
                    :null
                  
                    }

                   
                     </>    

                     : null
            }


            {btndinamic !== "" ?
                    <>

               
                      <p className="advertencia my-2"> Usted a Generado <strong> {`${multiplicador / 500}`} A.D.U </strong> <br/> que corresponden el valor total de <strong><NumberFormat value={contrato.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/></strong> </p>
                      


                        <div className="btn-box-gen-adu ">


                        {/* <button className="btn btn-primario btn-block btn-radius-panther-dos" 
                        onClick={usuarioAutenticado}
                    
                        >
                        Completado
                    </button> */}


                
                    <button className="btn btn-primario btn-block btn-radius-panther-dos " 
                        onClick={montosavereset}
                    
                        >
                        Generar un número diferente de A.D.U
                    </button>


                        </div> 




                             <>
                             
                             <div  className="box-adu"> 
                         {adu.map(adus => (
                                                                    <div  key={adus.contrato} className="box-imagen">


                                                                    <div className="elemento1-adu">
                                                                                <div class="imagen3">
                                                                                </div>
                                                                                <div class="imagen4">
                                                                                </div>
                                                                                <div class="imagen5">
                                                                                </div>
                                                                    </div>
                                                                    <div className="elemento2-adu">
                                                                            <div>
                                                                            </div>
                                                                            <div class="imagen7">
                                                                            </div>
                                                                            <div class="imagen6">
                                                                            </div>
                                                                    </div>
                                                                    <div className="elemento3-adu">
                                                                        <div class="imagen2">
                                                                        </div>
                                                                        <div class="imagen1">
                                                                        </div>
                                                                    </div>
                                                                    </div>

                        ) )}  
                                         </div>


                                                                                                            
                                                                                                            

                                                
                        </>

                                

                               



                    




                      </>

                      : null
            }





                    </form>



            
                    

            </>


    );
}
 
export default AduGenerator;