import React, {useContext, useState, useEffect} from 'react';
import AuthContext from '../../context/autenticacion/authContext.js'
import ParticlesBackground from '../../components/Particles/ParticlesBackgroundLoad'
import ParticleBg from '../../components/IconsAnimation/ParticleBg'





const Loading = () => {

    useEffect(() => {
    window.scrollTo(0, 0)
  }, [prisma_load])


    const authContext = useContext(AuthContext);

    const {  PrismaNoLoad, PrismaLoad, prisma_load , recargandoapp, PrismaLoadApp } = authContext;  
   
        
    
    return ( 

        <>

            


       { prisma_load === true ? 

                <>

                


                <div className={`loading ${recargandoapp === true ? 'loading-fx-null': ''} `}>

                {/* <div className="box-content-fx-fit-load waveform-x4">
                <ParticleBg/>

                </div>   */}
                        

                <div className="elemento1">
                    <img alt="..." src={require("../../assets/img/anime3.png")} />

                </div>




                <div className="elemento2">

                    

                <p>Cargando...</p>

                </div>

                {/* <div className="bg-particle-media-load ">
                    <ParticlesBackground/>
                </div>   */}








                </div>

                </>
                
                
                : null


       }


        </>


     );
}
 
export default Loading;