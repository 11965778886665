import React from 'react';

import Page1 from '../corporativo/Page1.jpg'
import Page2 from '../corporativo/Page2.jpg'
import Page3 from '../corporativo/Page3.jpg'
import Page4 from '../corporativo/Page4.jpg'
import Page5 from '../corporativo/Page5.jpg'
import Page6 from '../corporativo/Page6.jpg'
import Page7 from '../corporativo/Page7.jpg'
import Page8 from '../corporativo/Page8.jpg'
import Page9 from '../corporativo/Page9.jpg'
import Page10 from '../corporativo/Page10.jpg'
import Page11 from '../corporativo/Page11.jpg'
import Page12 from '../corporativo/Page12.jpg'
import Page13 from '../corporativo/Page13.jpg'
import Page14 from '../corporativo/Page14.jpg'
import Page15 from '../corporativo/Page15.jpg'
import Page16 from '../corporativo/Page16.jpg'
import Page17 from '../corporativo/Page17.jpg'
import Page18 from '../corporativo/Page18.jpg'
import Page19 from '../corporativo/Page19.jpg'
import Page20 from '../corporativo/Page20.jpg'
import Page21 from '../corporativo/Page21.jpg'
import Page22 from '../corporativo/Page22.jpg'
import Page23 from '../corporativo/Page23.jpg'

const Corporativo = () => {
    return (  



        <>

        <div className="grid-template-corporativo">

                <div>
                        <img src={Page1} />
                </div>

                <div>
                        <img src={Page2} />
                </div>
                <div>
                        <img src={Page3} />
                </div>
                <div>
                        <img src={Page4} />
                </div>
                <div>
                        <img src={Page5} />
                </div>
                <div>
                        <img src={Page6} />
                </div>
                <div>
                        <img src={Page7} />
                </div>
                <div>
                        <img src={Page8} />
                </div>
                <div>
                        <img src={Page9} />
                </div>
                <div>
                        <img src={Page10} />
                </div>
                <div>
                        <img src={Page11} />
                </div>
                <div>
                        <img src={Page12} />
                </div>
                <div>
                        <img src={Page13} />
                </div>
                <div>
                        <img src={Page14} />
                </div>

                <div>
                        <img src={Page15} />
                </div>

                <div>
                        <img src={Page16} />
                </div>

                <div>
                        <img src={Page17} />
                </div>

                <div>
                        <img src={Page18} />
                </div>

                <div>
                        <img src={Page19} />
                </div>

                <div>
                        <img src={Page20} />
                </div>

                <div>
                        <img src={Page21} />
                </div>

                <div>
                        <img src={Page22} />
                </div>

                <div>
                        <img src={Page23} />
                </div>



        </div>



        </>





    );
}
 
export default Corporativo;
