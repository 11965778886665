import React, { Fragment, useContext , useEffect, useState, useMemo } from 'react';
import Inversion from './Inversion';
import clienteAxios from '../../config/axios';
import DataTable, {theme} from 'react-data-table-component'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Link } from 'react-router-dom';

import { Doughnut } from "react-chartjs-2";

import styled from 'styled-components'


// importacion del modal
import Modal from '@material-ui/core/Modal'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'; 


/// fade del modal
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';



/// vamos a poner el tipo de dato
import PropTypes from 'prop-types';

import ModalPrimario from '../Modales/ModalPrimarioPro'
import ModalSecundario from '../Modales/ModalSecundario'

import AuthContext from '../../context/autenticacion/authContext'

/// chart
let chartPie = {
  data4: (canvas) => {
    let ctx = canvas.getContext("2d");
    
    
    // let modalInfo = [];
    // let enable = [];
    // let investment = [];
    
    // for (const dataObj of modalInfo) {
    //   enable.push(parseInt(dataObj.percentage_enable))
    //   investment.push(parseInt(dataObj.percentage_investment))
    // }
    

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
    let invertido=document.getElementById("pinvertido").value;
    let disponible=document.getElementById("pdisponible").value;

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    
    //console.log(canvas)
    console.log(Doughnut);
   //console.log(parseFloat(invertido)+" "+ parseFloat(disponible));
    return {
      type: 'doughnut',
      
      datasets: [
        {
          label: "Data",
          fill: true,
          backgroundColor:['#20c284', '#1720ac' ],
          borderColor: ['#00ff9d', '#1f8ef1' ],
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: [parseFloat(invertido),parseFloat(disponible)],
          weight: 300,
        } 
      ] ,

      labels: ['Monto Invertido','Monto Disponible'],


    };
  },
  options: chart_pie_options ,
  enable: pdisponible,
  investment: pinvertido


}; 


 


let pdisponible = pdisponible
let pinvertido = pinvertido

/// ahora va el option del chart
let chart_pie_options = {
  maintainAspectRatio: true,
  legend: {
    display: true
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        DoughnutPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "#00ffc33d",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ],
    xAxes: [
      {
        Percentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ]
  }
};






import { 
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Container,
  FormGroup,
  Input
} from "reactstrap"; 




/// aqui vamos a importar el spinner
import Spinner from '../spinners/Spinner'
import CerrarSesion from 'components/Auth/CerrarSesion';


// aqui solo aparece el responsive pequenho

const DivMobile = styled.div `
padding: 0rem !important;
margin: 0.2rem 0rem 2.0rem 0rem !important;
display:flex !important;
flex-direction:row;
justify-content:center;





@media(min-width:992px) {
  display:none !important;
  justify-content:center;
}



`;

// pantalla grande
const Divdesktop = styled.div `

display:none !important;






@media(min-width:992px) {
  display:block !important;
}



`; 





//// responsive
const Div = styled.div `
  padding: 0rem !important;
  margin: 0.2rem 0rem 2.0rem 0rem !important;
  display:flex !important;
  flex-direction:row;
  justify-content:center;
  
  
  
  
  
  @media(min-width:992px) {
    display:none !important;
    justify-content:center;
  }



`;

// pantalla grande
const DivModal = styled.div `

  display:none !important;
  flex-direction:row;
  
  
  
  
  
  @media(min-width:992px) {
    display:flex !important;
  }



`; 

/// aqui vamos a poner los estilos para el tap 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    outline:'none',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      outline:'none',
      backgroundColor: '#00ff9d',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);





/// aqui van los estilos de los tabs
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    alignItems:'center',
    marginRight: theme.spacing(0),
    borderRadius:'30px',
    transition:'all 500ms ease',
    padding:'0px 5px 0px 5px',
     margin:'0px 4px 0px 4px',
     outline:'none',
     alignItems:'center',
  
    '&:focus': {
      opacity: 0.8,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#005eff',
      outline:'none',
      borderRadius:'30px'
      
      
    },
    '&:hover': {
      opacity: 0.6,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#000',
      outline:'none',
      borderRadius:'30px'
    },

  }
  
  ,
}))((props) => <Tab disableRipple {...props} />);
///// aqui terminan los estilos del tap


function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));









const ListaProjectos = () => {  

  const authContext = useContext(AuthContext);

  const { 
    
    TodosOferentes, 
    TodosOfertas,
    TodosInversiones,
    TodosInversionesVendidas,

    TodosProyectosPrimario,
    TodosProyectosSecundario, 
    
    filtro_inversiones,
    filtro_proyectos,
    filtro_proyectos_pais,
    filtro_proyectos_tipo_oferta,
    filtro_proyectos_emisor,

    proyectos_primario_pagina,
    proyectos_secundarios_pagina,

    proyectos_secundarios_cantidad,
    proyectos_primario_cantidad,

    proyectos_primario,
    proyectos_secundarios,

    proyectos_primario_total_pagina,
    proyectos_secundarios_total_pagina,
    CerrarSesion,
    

   } = authContext;  


  


  /// aqui vamos a colocar el loader 
  const [ cargando, guardarCargando] = useState(false);

  
  const guardar = ( cargando ) ? <Spinner/> :  
                                          
                                          <Fragment>
                                            Búsqueda
                                            </Fragment> 




  // aqui vamos a crear el stado para las taps en responsive

  
  const theme = useTheme();
  const [value, setValue] = React.useState(0);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  //// evento del mercado secundario

  const [valuedos, setValueDos] = React.useState(0);

  const handleChangeMercado = (event, newValueDos) => {
    setValueDos(newValueDos);
  };

  const handleChangeIndexMercado = (index) => {
    setValueDos(index);
  }; 


  



  const [sumaporcentaje,guardarSumaPorcentaje ] = useState('')

  
  const [contratossec, guardarContratoSec] = useState([{
    amount:'', 
    amount_investment:'', 
    amount_reserved:'', 
    approval_date:'', 
    approved_by:'', 
    category:'', 
    category_id:'', 
    code:'', 
    contract_type:'', 
    country_iso:'', 
    created_at:'', 
    created_by:'',
    cuenta_negociador:'',
     currency_code:'', 
     date_max_investment:'', 
     delay_percentage:'', 
     email_sent:'', 
     end_date:'', 
     fee_total_contract:'', 
     file:'', 
     finished_date:'', 
     frequency_payment:'', 
    id:'', 
    issuer:'', 
    loan_type:'', 
    min_amount_start:'',
    name:'' , 
    nrocontrato:'', 
    oferente:'', 
    oferente_id:'', 
    quantity:'', 
    quantity:'', 
    risk:'', 
    start_date:'',
    status:'', 
    tax_price_cuota:'', 
    tax_referential_client:'', 
    tax_referential_panther:'',
    total_inversores:'', 
    updated_at:'', 
    updated_by:'', 
    warranty:'',
    disponible: '',
    ganancia:'',
    precioventa:'',
    fecha_fin:'',
    dias_pendientes:'',
    

  }]); 



  



  //// mercado primario
  const [contratos, guardarContrato] = useState([{
    amount:'', 
    amount_investment:'', 
    amount_reserved:'', 
    approval_date:'', 
    approved_by:'', 
    category:'', 
    category_id:'', 
    code:'', 
    contract_type:'', 
    country_iso:'', 
    created_at:'', 
    created_by:'',
    cuenta_negociador:'',
     currency_code:'', 
     date_max_investment:'', 
     delay_percentage:'', 
     email_sent:'', 
     end_date:'', 
     fee_total_contract:'', 
     file:'', 
     finished_date:'', 
     frequency_payment:'', 
    id:'', 
    issuer:'', 
    loan_type:'', 
    min_amount_start:'',
    name:'' , 
    nrocontrato:'', 
    oferente:'', 
    oferente_id:'', 
    quantity:'', 
    quantity:'', 
    risk:'', 
    start_date:'',
    status:'', 
    tax_price_cuota:'', 
    tax_referential_client:'', 
    tax_referential_panther:'',
    total_inversores:'', 
    updated_at:'', 
    updated_by:'', 
    warranty:'',
    disponible: '',
  }]); 


   /// vamos a hacer la busqueda
  // este es el primer estado del mercado primario
   const [ busqueda, guardarBusqueda ] = useState({
    pais:'',
    tipo:'',
    emisor:'',
    riesgo:'',
    vencimiento:'',
    rango:'',
  }); 



  //// aqui ahora vamos a colocazr lo del mercado secundario 

  /// este es el ffiltro del mercado secundario 
  const [ busquedasec, guardarBusquedaSec ] = useState({
    pais:'',
    tipo:'',
    emisor:'',
    riesgo:'',
    vencimiento:'',
    rango:'',
  });








const [ pais, guardarPais ] = useState([]);

const [ oferta, guardarOferta ] = useState([]);
/// aqui estamos colocando los valores, para buscar en la api
const [ emisor, guardarEmisor ] = useState([]); 

//// aqui vamos a hacer el paginador 
const [ totalpaginas, guardarTotalPaginas ] = useState({total_pages:''});

const [ totalpaginassec, guardarTotalPaginasSec ] = useState({total_pages:''});


/// aqui estamos colocando el contador de pagina, numero
const [ paginaactualcount, guardarPaginaActualCount ] = useState(paginaactualcount);

const [ paginaactualcountsec, guardarPaginaActualCountSec ] = useState(paginaactualcountsec);



// aqui estamos asignando el contador numero a la variable,mediante una cadena de  texto
const [ paginaactual, guardarPaginaActual ] = useState({
  
  page: paginaactual,

});  


const [ paginaactualsec, guardarPaginaActualSec ] = useState({
  
  page: paginaactualsec,

}); 



const [ paginacion , guardarPaginacion ]  = useState(paginaactual); 



const busquedaop = {...busqueda, ...paginaactual } ;

/// vamos a crear un estado donde va a cargar la busqueda

const [ apiload, guardarApiLoad] = useState(true);

const ApiLoad = () => {
  guardarApiLoad(false);
  setTimeout(() => {
    guardarApiLoad(true);
},50);
  
  
}


const [cantidad, guardarCantidades ] = useState({});
const [cantidadsec, guardarCantidadesSec ] = useState({});



const consultarApiBusqueda = async () => { 

  

   const {amount, amount_investment, amount_reserved, 
         approval_date, approved_by, category, category_id, 
         code, contract_type, country_iso, created_at, created_by,
         cuenta_negociador, currency_code, date_max_investment, delay_percentage
         ,email_sent, end_date, fee_total_contract, file, finished_date, frequency_payment, 
         id, issuer, loan_type, min_amount_start,name , nrocontrato, oferente, oferente_id, quantity, 
         risk, start_date, status, tax_price_cuota, tax_referential_client, tax_referential_panther,
         total_inversores, updated_at, updated_by, warranty, disponible,precioventa,fecha_fin,dias_pendientes
    
      
       } = contratos; 


 
 
  

  try {
       const respuesta = await clienteAxios.post('/api/client/projects',busqueda);
       
       guardarCargando(false)
       guardarContrato(respuesta.data.data)


///////////////////////////////////////////////////////////////
// vamos a comentar este lugar
///////////////////////////////////////////////////////////////

       ///// total de paginas

      //  guardarPaginaActualCount(respuesta.data.page)
      //  guardarTotalPaginas(respuesta.data.cantidad.total_pages) 
      // guardarCantidades(parseInt(respuesta.data.cantidad))

            ///////////////////////////////////////////////////////////////
            /// vamos a comentar este otro lugar
            ///////////////////////////////////////////////////////////////
            
      
  } catch (error) {
      
      CerrarSesion()
      
              
              
  }


}   




const onSubmitBusqueda = e => {
  e.preventDefault();
  guardarCargando(true)
  





  consultarApiBusqueda ({
    ...busqueda
    

  });



}  

//////////////////////////////////////////////////////////////////////////////////////////////////////


const consultarApiBusquedaSec = async () => { 

  

  const {amount, amount_investment, amount_reserved, 
        approval_date, approved_by, category, category_id, 
        code, contract_type, country_iso, created_at, created_by,
        cuenta_negociador, currency_code, date_max_investment, delay_percentage
        ,email_sent, end_date, fee_total_contract, file, finished_date, frequency_payment, 
        id, issuer, loan_type, min_amount_start,name , nrocontrato, oferente, oferente_id, quantity, 
        risk, start_date, status, tax_price_cuota, tax_referential_client, tax_referential_panther,
        total_inversores, updated_at, updated_by, warranty, disponible,precioventa,fecha_fin,dias_pendientes
   
     
      } = contratos; 




 

 try {
      const respuesta = await clienteAxios.post('/api/client/projects/secundario',busquedasec);
      guardarCargando(false)
      guardarContratoSec(respuesta.data.data)     
 } catch (error) {
     
     CerrarSesion()
     
             
             
 }


}  


const onSubmitBusquedaSec = e => {
  e.preventDefault();
  guardarCargando(true)
  





  consultarApiBusquedaSec ({
    ...busquedasec
    

  });



}  









const Next = e => {
  
  
  guardarPaginaActualCount( parseInt(paginaactual.page) + 1 )
  

 

} 



const Nextsec = e => {
  
  
  guardarPaginaActualCountSec( parseInt(paginaactualsec.page) + 1 )
  

 

} 



const Back = e => {
  
  
  guardarPaginaActualCount( parseInt(paginaactual.page) - 1  ) 

} 


const Backsec = e => {
  
  
  guardarPaginaActualCountSec( parseInt(paginaactualsec.page) - 1  ) 
  
} 



  

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////



const consultarApiPage = async (props) => {  

  
 try { 

 

   const respuesta = await clienteAxios.post('/api/client/projects', paginaactual);
  
  guardarCargando(false)

  // guardarPaginaActualCount(respuesta.data.page)

  
  guardarContrato(respuesta.data.data)

  ///// total de paginas
  guardarTotalPaginas(respuesta.data.total_pages) 

  guardarCantidades(respuesta.data.cantidad)
  
  // guardarCantidades(parseInt(respuesta.data.cantidad))


 
} catch (error) {
 
 CerrarSesion()
         
}
}  



const consultarApiPageSec = async (props) => {  

  
  try { 
 
  
 
    const respuestasec = await clienteAxios.post('/api/client/projects/secundario', paginaactualsec);
   
   guardarCargando(false)
 
   // guardarPaginaActualCount(respuesta.data.page)
 
   
   guardarContratoSec(respuestasec.data.data)
 
   ///// total de paginas
   guardarTotalPaginasSec(respuestasec.data.total_pages) 
 
   guardarCantidadesSec(respuestasec.data.cantidad)
   
   // guardarCantidades(parseInt(respuesta.data.cantidad))
 
 
  
 } catch (error) {
  
  CerrarSesion()
          
 }
 }  

/////// aqui estan los efectos de la paginacion
///////// aqui vemos la parte de llamar a la api
useEffect( (props) => { 

 
consultarApiPage ({
  ...paginaactual
  

});
  
    },[paginaactual]);

  
    /////// aqui estan los efectos de la paginacion la parte del guardado

useEffect( (props) => { 
  guardarPaginaActual({
    page:  paginaactualcount,
   })
    },[paginaactualcount]);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////



 useEffect( (props) => { 

 
  consultarApiPageSec ({
    ...paginaactualsec
    
  
  });
    
      },[paginaactualsec]);
 
    useEffect( (props) => { 
        guardarPaginaActualSec({
          page:  paginaactualcountsec,
         })
          },[paginaactualcountsec]);
      
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////



















  useEffect( (props) => { 

//// consultar api de filtros
    const consultarApiFiltros = async () => { 

      guardarPais(filtro_proyectos_pais);
      guardarOferta(filtro_proyectos_tipo_oferta);
      guardarEmisor(filtro_proyectos_emisor);

    }

    consultarApiFiltros();
    

    //// consultar api de filtros

    const consultarApiContratos = async (props) => {

      
     

      // const {amount, amount_investment, amount_reserved, 
      //   approval_date, approved_by, category, category_id, 
      //   code, contract_type, country_iso, created_at, created_by,
      //   cuenta_negociador, currency_code, date_max_investment, delay_percentage
      //   ,email_sent, end_date, fee_total_contract, file, finished_date, frequency_payment, 
      //   id, issuer, loan_type, min_amount_start,name , nrocontrato, oferente, oferente_id, quantity, 
      //   risk, start_date, status, tax_price_cuota, tax_referential_client, tax_referential_panther,
      //   total_inversores, updated_at, updated_by, warranty, disponible,precioventa,fecha_fin,dias_pendientes
    
      
      // } = contratos; 

      
      
      // // primario 
      // const respuesta = await clienteAxios.post('/api/client/projects',busquedaop)
      //   /// secundario 
      // const respuestasec = await clienteAxios.post('/api/client/projects/secundario ')

      

      guardarContratoSec(proyectos_secundarios)
      guardarContrato(proyectos_primario) 


      guardarTotalPaginas(proyectos_primario_total_pagina)
      guardarTotalPaginasSec(proyectos_secundarios_total_pagina)


      guardarCantidades(proyectos_primario_cantidad)
      guardarCantidadesSec(proyectos_secundarios_cantidad)

      guardarPaginaActualCount(proyectos_primario_pagina)
      guardarPaginaActualCountSec(proyectos_secundarios_pagina)

      
      
      
    }

    consultarApiContratos()

    
    
    

  },[!apiload,proyectos_primario,proyectos_secundarios]);



  // vamos a pasarle el la informacion de la fila al modal
  const [modalInfo, setModalInfo] = useState([]);
  const [chartstate, setChartState] = useState("data4");

  
  // vamos a extraer el context de emergencia para guardar los valores de la inversion 
  // despues 

  
  // vamos a hacer que el mensaje salga
  const [mensaje, guardarMensaje] = useState('');

  
   // configuracion del modal de material-ui
   const [ modalStyle ] =useState(getModalStyle);
   const [open, setOpen] =useState(false);
   

   const classes = useStyles();

   const handleOpen = () => {
       setOpen(true);
   } 

   const handleClose = () => {
       setOpen(false);
       guardarMensaje(null);
   }


   const [opendos, setOpenDos] =useState(false);

   const handleOpenDos = () => {
    setOpenDos(true);
  } 

    const handleCloseDos = () => {
        setOpenDos(false);
        guardarMensaje(null);
    }


    // estamos creando el state 
const [contratomonto, guardarContratoMonto] = useState({
      amount:''
      
      
});  


const [amountcalculo, setAmountCalculo ] = useState({
  amount:''

});

  

  const [project_id, guardarProjectid] = useState({
    project_id:''

  })

  /// aqui vamos a colocar la oferta del mercado secundario

  const [offer_id, guardarOfferid] = useState({
    offer_id:''

  })


  // extraer usuario del state

  const {amount} = parseInt(contratomonto) 
  
 

  
 
   


  const crearInversion = async inversion => {
       
    const {contratomonto,projectid} = inversion;
    
   
    
    var amount=document.getElementById('amount').value;

    
  
    try {
        const respuesta = await clienteAxios.post('/api/client/investments/create',{amount,project_id});
        
      
      
      guardarMensaje(respuesta.data.message); 
      guardarCargando(false)

      setTimeout(() => {
        guardarMensaje(null);
    },50000);
        
    } catch (error) {
      guardarMensaje(error.response.data.message);
      setTimeout(() => {
        guardarMensaje(null);
        setOpen(false);
    },50000);
       
    }
} 


const comprarOferta = async () => {


  try { 
    const respuestaoferta = await clienteAxios.post('/api/client/investments/create_secundary',{offer_id});
    guardarMensaje(respuestaoferta.data.message); 
    guardarCargando(false)

  } catch (error) {
    guardarMensaje(error.response.data.message);
  }



}



  /// aqui vamos a colocar la funcion para escuchar el evento
  const handleChangeInput = e => {

    setAmountCalculo({

      ...amountcalculo,
      [e.target.name] : (e.target.value)
    })

    
    
  } 

  const onChangeFilter = e => {
    

    // voy a colocar guardar usuario en el state

    guardarBusqueda({
        ...busqueda,
        [e.target.name] : e.target.value

    }) 

    
} 


const onChangeFilterSec = e => {

  guardarBusquedaSec({
    ...busquedasec,
    [e.target.name] : e.target.value

}) 

 
  
}




function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}





  const onChange = e => {

      // voy a colocar guardar usuario en el state
      var x = parseFloat(document.getElementById("amount").value);
      
      document.getElementById("totalarecibir").innerHTML ="0";
      if (x!="")
        {
            var porcentaje_diario=modalInfo.porcentaje_diario;
            var dias=modalInfo.dias;
            var calculo=parseFloat(dias)*parseFloat(porcentaje_diario)*parseFloat(x);
            calculo=(calculo/100) + parseFloat(x);
            if (!Number.isNaN(calculo))       document.getElementById("totalarecibir").innerHTML = currencyFormat(calculo);
        }
        else
        document.getElementById("totalarecibir").innerHTML ="0";
      
      guardarContratoMonto({
        [e.target.name] : parseFloat(e.target.value)
        
          
      }) 


   
  }


  const onSubmitDos = e => {
    e.preventDefault(); 
    guardarCargando(true)
      

      comprarOferta({offer_id})

  }


  /// cuando el usuario quiere iniciar seseion
  const onSubmit = e => {
      e.preventDefault(); 
      guardarCargando(true)

      

      crearInversion({ contratomonto,project_id});

  }
    

  
  const [mostrarmercados, guardarMostrarMercados] = useState({
    mercadoprimario:1,
    mercadosecundario:0
    
  })


  const [appbar, guardarAppBar ] = useState({
    primero:1,
    segundo:0,
    
  })


    
      
    return (  




      <Fade in={open}
      onClose={handleClose}>  
    <Fragment>
      
    

       {/* <Row>
        <Col>
          <Card className="tabla_proyectos" >
          <BootstrapTable 
                    
                    keyField="id"
                    data={contratos}
                    columns={columns}
                    pagination={paginationFactory()}
                    rowEvents={rowEvents}
                    wrapperClasses="table-responsive ps text-center"
                    
                  />
          </Card>
        
        
        </Col>

       </Row>  */} 

        


                                  <div className={` help-box-proyectos ${mostrarmercados.mercadoprimario === 1 ? 'd-block':'d-none'}`}>
                                      <Container fluid className="d-flex justify-content-center">

                                      
                                        <Row> 
                                                <Col md="12"> 
                                                <form onSubmit={onSubmitBusqueda} >
                                                    <Card className="p-4 mb-0">
                                                            <CardHeader> 
                                                            <CardTitle className="display-4 title-bold-green-help" tag="h2" >Filtros de proyectos
                                                            <p>Utilice cualquiera de los siguientes filtros para encontrar la oferta deseada</p>
                                                            </CardTitle>
                                                            </CardHeader>
                                                            <CardBody>  
                                                            

                                                            <div className="grid-container-filtro-buscador-rango"> 

                                                            <Row>
                                                                                <Col className="text-center">
                                                                                    <label className="form-label mb-2 text-center " forHTML="rango" ><p className="text-filtro">{busqueda.rango} %</p></label>
                                                                                </Col>

                                                                       </Row>




                                                                                      <Row>
                                                                                          <Col className="text-center d-flex justify-content-between pr-4 pl-4 ">
                                                                                              <p className="form-label mb-2 " >Todos</p>
                                                                                              <p className="form-label mb-2 " >20%</p>
                                                                                          </Col>
                                                                                      </Row>



                                                                                      <Input
                                                                                      min="0"
                                                                                      max="20"
                                                                                      id="rango"
                                                                                      name="rango"
                                                                                      className="form-range"
                                                                                      type="range"
                                                                                      onChange={onChangeFilter}
                                                                                      value={busqueda.rango} 
                                                                                     
                                                                                      />

                                           






                                                              </div>

                                                              <div className="grid-container-filtro-buscador">

                                                                <strong>
                                                                
                                                                                  <select className="form-control text-center " 
                                                                                  id="pais"
                                                                                  name="pais"
                                                                                  onChange={onChangeFilter}
                                                                                  value={busqueda.pais} 
                                                                              
                                                                                   > 

                                                                                      <option value=""> - - -  País - - - </option>
                                                                                  {pais.map(paises => (
                                                                                      <option
                                                                                          key={paises.id}
                                                                                          value={paises.iso}
                                                                                          

                                                                                      >{paises.name}</option>

                                                                                  )



                                                                                  )}
                                                                                      
                                                                                  </select>
                                                                                  

                                                                </strong>

                                                                <strong>
                                                                
                                                                                  <select className="form-control text-center " 
                                                                                  id="tipo"
                                                                                  name="tipo"
                                                                                  onChange={onChangeFilter}
                                                                                  value={busqueda.tipo}
                                                                                  >
                                                                                  <option  value=""  > - - - Tipo de oferta - - - </option>
                                                                                  {oferta.map(ofertas => (
                                                                                      <option
                                                                                          key={ofertas.id}
                                                                                          value={ofertas.id}
                                                                                          
                                                                                        

                                                                                      >{ofertas.tipo}</option>

                                                                                  )



                                                                                  )}
                                                                                  </select>
                                                                                      
                                                                                  

                                                                </strong>

                                                                <strong>
                                                                          <select className="form-control  text-center " 
                                                                                          onChange={onChangeFilter}
                                                                                          value={busqueda.riesgo} 
                                                                                          id="riesgo"
                                                                                          name="riesgo"
                                                                                          
                                                                                  
                                                                                  >
                                                                                  <option  value="" >- - -  Clasificación - - -</option>
                                                                                      <option value="A++"   >A++</option>
                                                                                      <option value="A+"   >A+</option>
                                                                                      <option value="A"  >A</option>
                                                                                      <option value="Bajo"  >Bajo</option>
                                                                                      <option value="Medio"   >Medio</option>
                                                                                      <option value="Alto"  >Alto</option>
                                                                                  </select>
                                                                                      
                                                                                  

                                                                </strong>

                                                                <strong>
                                                                   <select className="form-control text-center " onChange={onChangeFilter}
                                                                                      id="emisor"
                                                                                      name="emisor"
                                                                                  value={busqueda.emisor}
                                                                                  onClick={ () => {
                                                                                    ApiLoad();    
                                                                                      }
                                                                                      } >
                                                                                  <option  value=""> - - - Emisor - - - </option>
                                                                                  {emisor.map(emisor => (
                                                                                      <option
                                                                                          key={emisor.issuer}
                                                                                          value={emisor.issuer}
                                                                                          onClick={ () => {
                                                                                            ApiLoad();    
                                                                                              }
                                                                                              }

                                                                                      >{emisor.issuer}</option>

                                                                                  )



                                                                                  )}
                                                                   </select>
                                                                </strong> 




                                                                        <strong>
                                                                                        <select className="form-control  text-center " 
                                                                                                  onChange={onChangeFilter}
                                                                                                  value={busqueda.vencimiento}
                                                                                                  id="vencimiento"
                                                                                                  name="vencimiento"

                                                                                                  >

                                                                                          <option  value="" > - - - Vencimiento - - -</option>
                                                                                              <option value="1" >Hoy</option>
                                                                                              <option value="2" > Próximos 7 días</option>
                                                                                              <option value="3" > Próximos 30 días</option>
                                                                                              <option value="4" >Próximos 360 días</option>
                                                                                          </select>

                                                                        </strong>










                                                              </div>


                                                              

                                                            </CardBody>


                                                            
                                                                      
                                                                      
                                                            <CardFooter className="d-flex justify-content-end ">
                                                          <Button type="submit" className=" btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power  mt-0 btn-block" >
                                                          
                                                          {guardar}
                                                          </Button>
                                                      </CardFooter>



                                                              </Card> 
                                                              </form>
                                                          </Col>
                                        </Row>
                                                      
      
                                      </Container>

                                      </div> 


                                      <div className={` help-box-proyectos ${mostrarmercados.mercadosecundario === 1 ? 'd-block':'d-none'}`} >  
                                          
                                      <Container fluid  className="d-flex justify-content-center">

                                      
                                        <Row> 
                                                <Col md="12"> 
                                                <form onSubmit={onSubmitBusquedaSec} >
                                                    <Card className="p-4 mb-0">
                                                            <CardHeader> 
                                                            <CardTitle className="display-4 title-bold-green-help" tag="h2" >Filtros de proyectos
                                                            <p>Utilice cualquiera de los siguientes filtros para encontrar la oferta deseada</p>
                                                            </CardTitle>
                                                            </CardHeader>
                                                            <CardBody>  
                                                            

                                                            <div className="grid-container-filtro-buscador-rango"> 

                                                            <Row>
                                                                                <Col className="text-center">
                                                                                    <label className="form-label mb-2 text-center " forHTML="rango" ><p className="text-filtro">{busquedasec.rango} %</p></label>
                                                                                </Col>

                                                                       </Row>
                                                                                      <Row>
                                                                                          <Col className="text-center d-flex justify-content-between pr-4 pl-4 ">
                                                                                              <p className="form-label mb-2 " >Todos</p>
                                                                                              <p className="form-label mb-2 " >20%</p>
                                                                                          </Col>
                                                                                          
                                                                    

                                                                                      </Row>
                                                                                      <Input
                                                                                      min="0"
                                                                                      max="20"
                                                                                      id="rango"
                                                                                      name="rango"
                                                                                      className="form-range"
                                                                                      type="range"
                                                                                      onChange={onChangeFilterSec}
                                                                                      value={busquedasec.rango} 
                                                                                     
                                                                                      />

                                           






                                                              </div>

                                                              <div className="grid-container-filtro-buscador">

                                                                <strong>
                                                                
                                                                                  <select className="form-control text-center " 
                                                                                  id="pais"
                                                                                  name="pais"
                                                                                  onChange={onChangeFilterSec}
                                                                                  value={busquedasec.pais} 
                                                                              
                                                                                   > 

                                                                                      <option value=""> - - -  País - - - </option>
                                                                                  {pais.map(paises => (
                                                                                      <option
                                                                                          key={paises.id}
                                                                                          value={paises.iso}
                                                                                          

                                                                                      >{paises.name}</option>

                                                                                  )



                                                                                  )}
                                                                                      
                                                                                  </select>
                                                                                  

                                                                </strong>
                                                                <strong>
                                                                
                                                                                  <select className="form-control text-center " 
                                                                                  id="tipo"
                                                                                  name="tipo"
                                                                                  onChange={onChangeFilterSec}
                                                                                  value={busquedasec.tipo}
                                                                                  >
                                                                                  <option  value=""  > - - - Tipo de oferta - - - </option>
                                                                                  {oferta.map(ofertas => (
                                                                                      <option
                                                                                          key={ofertas.id}
                                                                                          value={ofertas.id}
                                                                                          
                                                                                        

                                                                                      >{ofertas.tipo}</option>

                                                                                  )



                                                                                  )}
                                                                                  </select>
                                                                                      
                                                                                  

                                                                </strong>

                                                                <strong>
                                                                
                                                                                  <select className="form-control  text-center " 
                                                                                          onChange={onChangeFilterSec}
                                                                                          value={busquedasec.riesgo} 
                                                                                          id="riesgo"
                                                                                          name="riesgo"
                                                                                          
                                                                                  
                                                                                  >
                                                                                  <option  value="" >- - -  Clasificación - - -</option>
                                                                                      <option value="A++"   >A++</option>
                                                                                      <option value="A+"   >A+</option>
                                                                                      <option value="A"  >A</option>
                                                                                      <option value="Bajo"  >Bajo</option>
                                                                                      <option value="Medio"   >Medio</option>
                                                                                      <option value="Alto"  >Alto</option>
                                                                                  </select>
                                                                                      
                                                                                  

                                                                </strong>

                                                                <strong>
                                                                <select className="form-control text-center " onChange={onChangeFilterSec}
                                                                                      id="emisor"
                                                                                      name="emisor"
                                                                                  value={busquedasec.emisor}
                                                                                  onClick={ () => {
                                                                                    ApiLoad();    
                                                                                      }
                                                                                      } >
                                                                                  <option  value=""> - - - Emisor - - - </option>
                                                                                  {emisor.map(emisor => (
                                                                                      <option
                                                                                          key={emisor.issuer}
                                                                                          value={emisor.issuer}
                                                                                          onClick={ () => {
                                                                                            ApiLoad();    
                                                                                              }
                                                                                              }

                                                                                      >{emisor.issuer}</option>

                                                                                  )



                                                                                  )}
                                                                        </select>

                                                                </strong>

                                                                <strong>
                                                                <select className="form-control  text-center " 
                                                                                         onChange={onChangeFilterSec}
                                                                                          value={busquedasec.vencimiento}
                                                                                          id="vencimiento"
                                                                                          name="vencimiento"

                                                                                          >

                                                                                  <option  value="" > - - - Vencimiento - - -</option>
                                                                                      <option value="1" >Hoy</option>
                                                                                      <option value="2" > Próximos 7 días</option>
                                                                                      <option value="3" > Próximos 30 días</option>
                                                                                      <option value="4" >Próximos 360 días</option>
                                                                                  </select>

                                                                </strong>










                                                              </div>


                                                              

                                                            </CardBody>


                                                            
                                                                      
                                                                      
                                                            <CardFooter className="d-flex justify-content-end ">
                                                          <Button type="submit" className=" btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power  mt-0 btn-block" >
                                                          
                                                          {guardar}
                                                          </Button>
                                                      </CardFooter>



                                                              </Card> 
                                                              </form>
                                                          </Col>
                                        </Row>
                                                      
      
                                      </Container>
                                          </div> 

                                  <div className="grid-app-bar "> 
                                        
                                        <p > <strong> Listado de proyectos </strong> <br/> <span>Seleccione el tipo de mercado</span></p>
                                        
                                               
                                           
                                     <div>
                                                               <p className={` ${mostrarmercados.mercadoprimario === 1 ? 'active-selection-proyectos':''}`}
                                                                onClick={ ()=>(
                                                                  guardarMostrarMercados(({...mostrarmercados, mercadoprimario: 1, mercadosecundario: 0}))
                                                                )}
                                                               
                                                               > Mercado primario
                                                                <h4 >
                                                                  
                                                                </h4>
                                                               </p>

                                                               <p className={` ${mostrarmercados.mercadosecundario === 1 ? 'active-selection-proyectos':''}`}
                                                                  onClick={ ()=>(
                                                                    guardarMostrarMercados(({...mostrarmercados, mercadoprimario: 0, mercadosecundario: 1}))
                                                                  )}
                                                               
                                                               >
                                                                 Mercado secundario
                                                                 <h4 >
                                                                 
                                                                </h4>
                                                                 </p>
                                      </div>

                                     

                                       
                                                               
                                       

                                     </div>  

                                   

                                      <div className={` help-box-proyectos ${mostrarmercados.mercadoprimario === 1 ? 'd-block':'d-none'}`}> 

                                         <Container fluid>
                                            { cantidad > 0 ? <>

                                         <Row> 


                                                <Col sm="12" xl="12" lg="12" md="12">
                                                <Divdesktop> 

                                                <div className="grid-app-bar-simple-ben mb-2 "> 
                                        
                                        <div className="grid-items-3">
                                                                  <p className={` ${appbar.primero === 1 ? 'active-selection-proyectos':''}`}
                                                                   onClick={ ()=>(
                                                                     guardarAppBar(({...appbar, primero: 1, segundo: 0, tercero:0}))
                                                                   )}
                                                                  
                                                                  > VISTA CLÁSICA
                                                                   <h4 >
                                                                     
                                                                   </h4>
                                                                  </p>
   
                                                                  <p className={` ${appbar.segundo === 1 ? 'active-selection-proyectos':''}`}
                                                                     onClick={ ()=>(
                                                                       guardarAppBar(({...appbar, primero: 0, segundo: 1,tercero:0}))
                                                                     )}
                                                                  
                                                                  >
                                                                    ICONOS GRANDES
                                                                    <h4 >
                                                                    
                                                                   </h4>
                                                                    </p> 
   
   
   
                                                                 
                                         </div>
                                        </div>   

                                                  
                                                <CardBody className="class-grid-color " >
                                                          <div className="mercado-tittle">
                                                          <h2 >Bienvenido al mercado primario</h2>
                                                          <p>Puedes adquirir, ser dueño e invertir en tu participación preferida</p>

                                                          </div>
                                                          
                                                          
                                                        </CardBody> 
                                                        <div className={` grid-container-paginador  ${appbar.segundo === 1 ? 'd-grid':'d-none'}`}>
                                                        

                                                      

                                                            {paginaactualcount === 1 ?

                                                                      <button
                                                                      title=""
                                                                      className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                      onClick={Back}
                                                                      disabled
                                                                      > Anterior &laquo; </button>
                                                                      : 

                                                                      <button
                                                                          title=""
                                                                          className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                          onClick={Back}
                                                                        > Anterior &laquo; </button>
                                                                          }
                                                                        
                                                                        <div className="pagination-style">
                                                                        Página <strong>{paginaactualcount}</strong>  de <span>{totalpaginas}</span> 
                                                                      </div> 


                                                                        {paginaactualcount === totalpaginas ?

                                                                                  <button
                                                                                  title=""
                                                                                  disabled
                                                                                  className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                  onClick={Next}
                                                                                  
                                                                                  > Siguiente &raquo; </button>
                                                                              : 

                                                                                  <button
                                                                                      title=""
                                                                                      
                                                                                      className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                      onClick={Next}
                                                                                      
                                                                                    > Siguiente &raquo; </button>
                                                                                  }

                                                            </div>


                                                            <div className={` card-panther  ${appbar.segundo === 1 ? 'd-block':'d-none'}`}>
                                                  <div className="listado-proyectos-box card-smooth-primario" >
                                                  {contratos.map(contrato => (
                                                    
                                                    <div key={contrato.id} className="contenedor-grid  card-smooth-primario animation-cuentas" >

                                                    <div className="listado-proyectos-imagen ">   
                                                      
                                                      <img src={contrato.imagen} />

                                                      </div>


                                                    <div className="listado-proyectos-header "> 
                                                        <div >
                                                                  <strong>
                                                                     
                                                                      {contrato.name}
                                                                      </strong> <br/>
                                                                    Mercado primario 

                                                                   </div>
                                                    </div>
                                                  <div className="listado-proyectos  " >
                                                    
                                                                


                                                                  <div >
                                                                          <strong>
                                                                          
                                                                          Número de contrato <br/>
                                                                          <small> {contrato.nrocontrato}</small> 
                                                                            </strong>
                                                                          </div>  


                                                                  <div >
                                                                          <strong>
                                                                          
                                                                          País <br/>
                                                                          <small> {contrato.country_iso}</small> 
                                                                            </strong>
                                                                          </div>  
                                                                
                                                                  

                                                                   
                                                                  

                                                                  <div >
                                                                          <strong>
                                                                          
                                                                          Tipo <br/>
                                                                          <small> {contrato.tipo}</small> 
                                                                            </strong>
                                                                          </div>  



                                                                  <div >
                                                                          <strong>
                                                                          
                                                                          Categoria <br/>
                                                                          <small> {contrato.category}</small> 
                                                                            </strong>
                                                                          </div>  



                                                                  




                                                                  <div >
                                                                          <strong>
                                                                          
                                                                          Clasificación <br/>
                                                                          <small> {contrato.risk}</small> 
                                                                            </strong>
                                                                          </div>  

                                                                  


                                                                    
                                                                  <div >
                                                                          <strong>
                                                                          
                                                                          Garantia <br/>
                                                                          <small> {contrato.warranty}</small> 
                                                                            </strong>
                                                                          </div> 



                                                                  


                                                                        <div >
                                                                          <strong>
                                                                          <i class="tim-icons icon-calendar-60 p-2"/> 
                                                                          Interes  <br/>
                                                                          <small> {contrato.tax_referential_client} ( % )</small> 
                                                                            </strong>
                                                                          </div> 

                                                                  <div >
                                                                          <strong>
                                                                          <i class="tim-icons icon-calendar-60 p-2"/> 
                                                                          Vencimiento <br/>
                                                                          <small> {contrato.end_date}</small> 
                                                                            </strong>
                                                                          </div> 
                                                                  <div >
                                                                          <strong>
                                                                          <i class="tim-icons icon-coins p-2"/>
                                                                          Monto  <br/>
                                                                          <small> {contrato.amount_proyecto} ( $ )</small> 
                                                                            </strong>
                                                                          </div> 


                                                                  

                                                                          <div >
                                                                          <strong>
                                                                          Ganancia  periodo <br/>
                                                                          <small> {contrato.ganancia} ( % )</small> 
                                                                            </strong>
                                                                          </div> 

                                                                  <div >
                                                                  <strong>
                                                                  Disponibilidad <br/>
                                                                   <small> {contrato.disponible} ( $ )</small> 
                                                                    </strong>
                                                                  </div> 

                                                                


                                                                  
                                                                  
                                                                  <div >
                                                                  <i class="tim-icons icon-single-02 p-2"/>  

                                                                  
                                                                  <strong>
                                                                  Participantes <br/> 
                                                                  <small>{contrato.total_inversores}</small>
                                                                    </strong>
                                                                    
                                                                  </div>
                                                  </div>

                                                  <div className="listado-proyectos-footer "> 
                                                  <div onClick={ () => {
                                                                          handleOpen();
                                                                          guardarProjectid(contrato.id) 
                                                                          setModalInfo(contrato);
                                                                        }} className="oleos-invertir " >
                                                                  <strong className=" ">
                                                                  
                                                                  Invertir
                                                                    </strong>
                                                                  </div>
                                                  </div>
                                                  </div>
                                                  ) )}

                                                  </div>



</div>
                                                  <div className={` grid-container-paginador  ${appbar.segundo === 1 ? 'd-grid':'d-none'}`}> 

                                                      

                                                            {paginaactualcount === 1 ?

                                                                      <button
                                                                      title=""
                                                                      className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                      onClick={Back}
                                                                      disabled
                                                                      > Anterior &laquo; </button>
                                                                      : 

                                                                      <button
                                                                          title=""
                                                                          className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                          onClick={Back}
                                                                        > Anterior &laquo; </button>
                                                                          }
                                                                        
                                                                        <div className="pagination-style">
                                                                        Página <strong>{paginaactualcount}</strong>  de <span>{totalpaginas}</span> 
                                                                      </div>  


                                                                        {paginaactualcount === totalpaginas ?

                                                                                  <button
                                                                                  title=""
                                                                                  disabled
                                                                                  className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                  onClick={Next}
                                                                                  
                                                                                  > Siguiente &raquo; </button>
                                                                              : 

                                                                                  <button
                                                                                      title=""
                                                                                      
                                                                                      className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                      onClick={Next}
                                                                                      
                                                                                    > Siguiente &raquo; </button>
                                                                                  }

                                                            </div>

                                                  

                                                            <div className={` grid-container-paginador mb-3  ${appbar.primero === 1 ? 'd-grid':'d-none'}`}>  

                                                      

                                                                {paginaactualcount === 1 ?

                                                                          <button
                                                                          title=""
                                                                          className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                          onClick={Back}
                                                                          disabled
                                                                          > Anterior &laquo; </button>
                                                                          : 

                                                                          <button
                                                                              title=""
                                                                              className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                              onClick={Back}
                                                                            > Anterior &laquo; </button>
                                                                              }
                                                                            
                                                                            <div className="pagination-style">
                                                                              Página <strong>{paginaactualcount}</strong>  de <span>{totalpaginas}</span> 
                                                                            </div>  


                                                                            {paginaactualcount === totalpaginas ?

                                                                                      <button
                                                                                      title=""
                                                                                      disabled
                                                                                      className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                      onClick={Next}
                                                                                      
                                                                                      > Siguiente &raquo; </button>
                                                                                  : 

                                                                                      <button
                                                                                          title=""
                                                                                          
                                                                                          className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                          onClick={Next}
                                                                                          
                                                                                        > Siguiente &raquo; </button>
                                                                                      }

                                                                </div>
               
                                                <Card className={` card-proyectos-grid-bg  ${appbar.primero === 1 ? 'd-block':'d-none'}`}>

                                               
                                                  
                                                                                        
                                                <div> 

                                                

                                                        {contratos.map(contrato => (
                                                  <>    
                                                         <h2 class="disponible-map"> Panther 7 | Mercado primario  </h2>
                                                        <CardBody className="class-grid-color-body-primario card-smooth-primario" key={contrato.id} onClick={ () => {
                                                                          handleOpen();
                                                                          guardarProjectid(contrato.id) 
                                                                          setModalInfo(contrato);
                                                                        }


                                                                        }
                                                                                
                                                                                >     
                                                                                  <p className="w-100 text-center text-comprar-primario monto-dolares-tres"> <span>Click</span> para invertir en {contrato.name}  </p>
                                                                                  <div className="grid-container-label-proyecto grid-6-fr ">
                                                
                                                          <div >
                                                                     <strong className="line-fix">
                                                                     Número de contrato
                                                                      </strong>
                                                                  </div>
                                                                 <div >
                                                                      <strong className="line-fix">
                                                                      Código
                                                                      </strong>
                                                                  </div> 
                                                                  <div >
                                                                     <strong className="line-fix text-left">
                                                                     Nombre de la inversión
                                                                      </strong>
                                                                   </div>

                                                                   <div >
                                                                     <strong className="line-fix">
                                                                     País
                                                                      </strong>
                                                                  </div>
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                     Tipo
                                                                      </strong>
                                                                  </div>
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                     Categoria
                                                                      </strong>
                                                                  </div>
                                                          </div>
                                                          <div className="grid-container-tabla-proyecto-body-primario first-grid-primario">
                                                
                                                          <div >
                                                                     <strong className="line-fix">
                                                                     
                                                                     {contrato.nrocontrato}
                                                                      </strong>
                                                                  </div>
                                                                 <div >
                                                                      <strong className="line-fix">
                                                                      <i class="tim-icons icon-bank p-2"/>{contrato.code}
                                                                      </strong>
                                                                  </div> 
                                                                  <div >
                                                                     <strong className="line-fix text-left">
                                                                     {contrato.name}
                                                                      </strong>
                                                                   </div>

                                                                   <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.country_iso}
                                                                      </strong>
                                                                  </div>
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.tipo}
                                                                      </strong>
                                                                  </div>
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.category}
                                                                      </strong>
                                                                  </div>
                                                          </div> 


                                                          <div className="grid-container-label-proyecto grid-8-fr">

                                                                 
                                                                <div >
                                                                  <strong className="line-fix">
                                                                  Clasificación
                                                                    </strong>
                                                                  </div>
                                                                  <div >
                                                                    <strong className="line-fix">
                                                                    Garantia
                                                                      </strong>
                                                                  </div>
                                                                  <div >
                                                                  <strong className="line-fix">
                                                                    
                                                                    Interes ( % )
                                                                    </strong>
                                                                  </div>
                                                                  <div >
                                                                  <strong className="line-fix">
                                                                    <i class="tim-icons icon-calendar-60 p-2"/> 
                                                                    Vencimiento
                                                                    </strong>
                                                                  </div>

                                                                  <div >
                                                                  <strong className="line-fix">
                                                                  <i class="tim-icons icon-coins p-2"/>
                                                                    Monto
                                                                    </strong>
                                                                  </div>

                                                                  <div >
                                                                  <strong className="line-fix">
                                                                  Ganancia 
                                                                    </strong>
                                                                  </div>

                                                                  <div >
                                                                  <strong className="line-fix">
                                                                  Disponibilidad 
                                                                    </strong>
                                                                  </div>
                                                                  <div >
                                                                  <strong className="line-fix"> 
                                                                  <i class="tim-icons icon-single-02 p-2"/>  
                                                                  Participantes
                                                                    </strong>
                                                                  </div>


                                                                </div>

                                                          <div className="grid-container-tabla-proyecto-body-primario">

                                                                  

                                                  
                                                                  
                                                                
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.risk}
                                                                      </strong>
                                                                    </div>
                                                                    <div >
                                                                       <strong className="line-fix">
                                                                       {contrato.warranty}
                                                                        </strong>
                                                                    </div>
                                                                    <div >
                                                                     <strong className="line-fix">
                                                                      
                                                                      {contrato.tax_referential_client} ( % )
                                                                      </strong>
                                                                    </div>
                                                                    <div >
                                                                     <strong className="line-fix">
                                                                      <i class="tim-icons icon-calendar-60 p-2"/> 
                                                                      {contrato.end_date}
                                                                      </strong>
                                                                    </div>

                                                                    <div >
                                                                     <strong className="line-fix">
                                                                     
                                                                      {contrato.amount_proyecto} ( $ )
                                                                      </strong>
                                                                    </div>

                                                                    <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.ganancia} ( % )
                                                                      </strong>
                                                                    </div>

                                                                    <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.disponible} ( $ )
                                                                      </strong>
                                                                    </div>
                                                                    <div >
                                                                     <strong className="line-fix"> 
                                                                     <i class="tim-icons icon-single-02 p-2"/>  
                                                                     {contrato.total_inversores}
                                                                      </strong>
                                                                    </div>


                                                          </div>

                                                          
                                                        </CardBody>
                                                        


                                                                                  </>
                                                                                  ) )}

                                                  

                                          <CardFooter>
                                                      <div className="grid-container-paginador"> 

                                                      

                                                          {paginaactualcount === 1 ?

                                                                    <button
                                                                    title=""
                                                                    className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                    onClick={Back}
                                                                    disabled
                                                                    > Anterior &laquo; </button>
                                                                    : 

                                                                    <button
                                                                        title=""
                                                                        className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                        onClick={Back}
                                                                      > Anterior &laquo; </button>
                                                                        }
                                                                      
                                                                      <div className="pagination-style">
                                                                        Página <strong>{paginaactualcount}</strong>  de <span>{totalpaginas}</span> 
                                                                      </div>  


                                                                      {paginaactualcount === totalpaginas ?

                                                                                <button
                                                                                title=""
                                                                                disabled
                                                                                className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                onClick={Next}
                                                                                
                                                                                > Siguiente &raquo; </button>
                                                                            : 

                                                                                <button
                                                                                    title=""
                                                                                    
                                                                                    className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                    onClick={Next}
                                                                                    
                                                                                  > Siguiente &raquo; </button>
                                                                                }

                                                      </div>
                                                      

                                                    </CardFooter>
                                                 

                                                </div> 

                                                </Card>



                                                {/* ////////////////////// aqui vamos a colocar las el loader//////// */}
                                                </Divdesktop> 

                                                <DivMobile>




                                                <Card className="height-fix-ultimos-movimientos justify-content-center table-responsive">

                                                  


                                                <div className=" box-grid-pro"> 
                                                    <div className=" box-grid-pro-son">
                                                    
                                                    {contratos.map(contrato => (


                                                          <div className="proyectos-grid-pro animation-cuentas" key={contrato.id}>

                                                          <h3 className="text-center w-100">Mercado primario <br/>
                                                          
                                                            <span>www.panther7.com</span>
                                                           </h3>
                                                          
                                                     

                                                          

                                                          <div className="elemento2-pro">  
                                                          <div>

                                                                      <i class="tim-icons icon-calendar-60 p-2"/>

                                                                        <p > <strong>  Código</strong> <br/>
                                                                        {contrato.code}</p> 

                                                                      </div>

                                                                      <div>

                                                                      <i class="tim-icons icon-puzzle-10 p-2"/>

                                                                        <p > <strong> País</strong><br/> 
                                                                        {contrato.country_iso} </p>
                                                                        
                                                                      </div> 

                                                                      <div> 
                                                                            
                                                                            <i class="tim-icons icon-coins p-2"/>
                                                                                <p >
                                                                                <strong className="line-fix"> 
                                                                                
                                                                                Tipo</strong> <br/> 
                                                                                
                                                                                {contrato.tipo}</p>

                                                                            </div> 

                                                                            <div> 
                                                                                <i class="tim-icons icon-bank p-2"/>

                                                                                <p ><strong> Categoria:</strong> <br/>  {contrato.category}
                                                                                
                                                                                </p>
                                                                            </div>
                                                                            <div> 
                                                                                      <i class="tim-icons icon-bank p-2"/>

                                                                                      <p ><strong> Monto</strong> <br/>  {contrato.amount_proyecto}
                                                                                      
                                                                                      </p>

                                                                                  </div>


                                                                                      <div> 
                                                                                          
                                                                                      <i class="tim-icons icon-coins p-2"/>
                                                                                          <p >
                                                                                          <strong className="line-fix"> 
                                                                                          
                                                                                          Clasificación</strong> <br/> 
                                                                                          
                                                                                          {contrato.risk}</p>

                                                                                      </div> 

                                                                                      <div> 
                                                                                      <i class="tim-icons icon-bank p-2"/>

                                                                                      <p ><strong> Garantia</strong> <br/>  {contrato.warranty}
                                                                                      
                                                                                      </p>

                                                                                      </div>

                                                                                      <div> 
                                                                                      <i class="tim-icons icon-bank p-2"/>

                                                                                      <p ><strong> Interes </strong> <br/>  {contrato.fee_total_contract} ( % )
                                                                                      
                                                                                      </p>

                                                                                      </div>
                                                          <div> 
                                                                                      <i class="tim-icons icon-bank p-2"/>

                                                                                      <p ><strong> Vencimiento</strong> <br/>  {contrato.end_date}
                                                                                      
                                                                                      </p>

                                                                                      </div>

                                                            

                                                                                      <div> 
                                                                                      <i class="tim-icons icon-bank p-2"/>

                                                                                      <p ><strong> Ganancia </strong> <br/>  {contrato.tax_referential_client} ( % )
                                                                                      
                                                                                      </p>

                                                                                      </div>

                                                                                      <div> 
                                                                                      <i class="tim-icons icon-bank p-2"/>

                                                                                      <p ><strong> Disponibilidad </strong> <br/>  {contrato.disponible}
                                                                                      
                                                                                      </p>

                                                                                      </div>

                                                                                      <div> 
                                                                                      <i class="tim-icons icon-single-02 p-2"/>

                                                                                      <p ><strong> Participantes</strong> <br/>  {contrato.total_inversores}
                                                                                      
                                                                                      </p>

                                                                                      </div>
                                                          </div>

                                                          <div className="elemento3-pro">
                                                            <div>
                                                            
                                                              <p ><strong>   Nombre del proyecto:</strong><br/> {contrato.name}</p>
                                                            </div> 


                                                         

                                                            <div>
                                                            
                                                              <p ><strong>   Número de contrato:</strong><br/> {contrato.nrocontrato}</p>
                                                            </div>
                                                          </div>


                                                          <p className="card-responsive-05-inversiones btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1 bb-btn-grid"  onClick={ () => {
                                                                                                                          handleOpen();
                                                                                                                          guardarProjectid(contrato.id) 
                                                                                                                          setModalInfo(contrato);
                                                                                                                          
                                                                                                                        }
                                                                                                              }> Invertir</p>

                                                          </div> 


                                                                ) )}
                                                        </div>
                                                    </div>




                                                                                        
                                                                                        <div> 
                                                                              

                                                                                            


                                                                                            <CardFooter>
                                                      <div className="grid-container-paginador"> 

                                                      

                                                          {paginaactualcount === 1 ?

                                                                    <button
                                                                    title=""
                                                                    className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                    onClick={Back}
                                                                    disabled
                                                                    > Anterior &laquo; </button>
                                                                    : 

                                                                    <button
                                                                        title=""
                                                                        className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                        onClick={Back}
                                                                      > Anterior &laquo; </button>
                                                                        }
                                                                      
                                                                      <div className="pagination-style">
                                                                        Página <strong>{paginaactualcount}</strong>  de <span>{totalpaginas}</span> 
                                                                      </div>  


                                                                      {paginaactualcount === totalpaginas ?

                                                                                <button
                                                                                title=""
                                                                                disabled
                                                                                className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                onClick={Next}
                                                                                
                                                                                > Siguiente &raquo; </button>
                                                                            : 

                                                                                <button
                                                                                    title=""
                                                                                    
                                                                                    className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                    onClick={Next}
                                                                                    
                                                                                  > Siguiente &raquo; </button>
                                                                                }

                                                      </div>
                                                      

                                                    </CardFooter>
                                                                                  
                                                                                  
                                                                                  
                                                                                  
                                                                                          </div> 
                                                                                          
                                                                                          </Card>



                                                                              </DivMobile>





                                                                              </Col>



                                                </Row> 
                                          </> :

                              <Container fluid className="justify-content-center d-flex"> 
                                         <Row className="box-depositos-seleccion-alerta justify-content-center w-100"> 
                                         <Col md="12" className=" panther-open-titulo-alerta border-panther"> 
                                 
                                         
                                       {cantidad === 0 ?  <> 
                                       
                                               

                                                      { busqueda.pais === '' || busqueda.tipo === '' || busqueda.emisor === '' || busqueda.riesgo === '' || busqueda.vencimiento === '' || busqueda.rango === '' ? <>  
                                                      <i className="tim-icons  icon-spaceship animation-icon-in mb-1"/> 
                                                      <h2 className="animation-icon-in-dos mb-4">Listado de proyectos</h2>
                                                       <h3 class="animate-raya-03 mb-2">En breve se cargaran los proyectos disponibles</h3>
                                                         <p className="text-center animation-icon-in-cuatro ">www.panther7.com | Proyecto de oferentes Panther 7 </p>
                                                        
                                                       
                                                       </>
                                                       
                                                       :

                                                       <>


                                                       

                                                       
                                                       <i class="tim-icons icon-zoom-split"></i>
                                                        <h2 className="animation-icon-in-dos mb-4">Búsqueda</h2>
                                                       <h3 class="animate-raya-03 mb-2">No se encontró ningún producto</h3>
                                                       <p className="text-center animation-icon-in-cuatro ">www.panther7.com | Proyecto de oferentes Panther 7 </p> 

                                                       </>
                                                       

                                                      }

                                                       

                                                         
                                                         
                                                           </> :
                                                         <Spinner/>
                                                         
                                                         }
                                         </Col>
                                                     
                                         </Row>  
                                         </Container >
                                              }                                 
                                              </Container >

                                          
                                          </div> 

                                          <div className={` help-box-proyectos ${mostrarmercados.mercadosecundario === 1 ? 'd-block':'d-none'}`}>  
                                          
                                          <Container fluid >

                                              {cantidadsec > 0 ?

                                                 <Row> 


                                                        <Col sm="12" xl="12" lg="12" md="12">
                                                        <Divdesktop>
                                                        <Card>

                                                        <CardBody className="class-grid-color" >
                                                          <div className="mercado-tittle">
                                                          <h2 >Bienvenido al mercado secundario</h2>
                                                          <p>Es un lugar increíble donde puedes ofertar y vender tus inversiones</p>

                                                          </div>
                                                          {/* <div className="grid-container-tabla-proyecto first-grid">
                                                          <div >
                                                                     <strong className="line-fix">
                                                                      EMISOR
                                                                      </strong>
                                                                   </div>
                                                          <div >
                                                                      <strong className="line-fix">
                                                                      <i class="tim-icons icon-bank p-2"/>CODIGO
                                                                      </strong>
                                                                  </div> 
                                                                  
                                                                   <div >
                                                                     <strong className="line-fix">
                                                                      NOMBRE
                                                                      </strong>
                                                                  </div>
                                                                  
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                        PAIS
                                                                      </strong>
                                                                  </div>
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                      TIPO
                                                                      </strong>
                                                                  </div>
                                                          </div>

                                                          <div className="grid-container-tabla-proyecto">

                                                                  

                                                                  <div >
                                                                     <strong className="line-fix">
                                                                        CATEGORIA
                                                                      </strong>
                                                                  </div>
                                                                 
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                      RIESGO
                                                                      </strong>
                                                                    </div>
                                                                    <div >
                                                                       <strong className="line-fix">
                                                                      GARANTIA
                                                                        </strong>
                                                                    </div>
                                                                    <div >
                                                                     <strong className="line-fix">
                                                                      <i class="tim-icons icon-coins p-2"/>
                                                                      INTERES ( % )
                                                                      </strong>
                                                                    </div>
                                                                    <div >
                                                                     <strong className="line-fix">
                                                                      <i class="tim-icons icon-calendar-60 p-2"/> 
                                                                      FECHA FIN DE LA VENTA
                                                                      </strong>
                                                                    </div>

                                                                    <div >
                                                                     <strong className="line-fix">
                                                                      <i class="tim-icons icon-calendar-60 p-2"/> 
                                                                      DIAS RESTANTES
                                                                      </strong>
                                                                    </div>

                                                                    <div >
                                                                     <strong className="line-fix">
                                                                      <i class="tim-icons icon-coins p-2"/> PRECIO DE VENTA ( $ )
                                                                      </strong>
                                                                    </div>

                                                                    <div >
                                                                     <strong className="line-fix">
                                                                      GANANCIA PERIODO( % )
                                                                      </strong>
                                                                    </div>


                                                          </div> */}

                                                          
                                                        </CardBody> 
        
                                                        {contratossec.map(contrato => (
                                                  <>
                                                        <CardBody className="class-grid-color-body" key={contrato.id} onClick={ () => {
                                                                                  handleOpenDos();
                                                                                  guardarOfferid(contrato.offer_id) 
                                                                                  setModalInfo(contrato);
                                                                               

                                                                                }


                                                                                }
                                                                                
                                                                                >     
                                                                                  <p className="w-100 text-center text-comprar monto-dolares-tres"> <span>Click</span> para comprar </p>
                                                                                  <div className="grid-container-label-proyecto grid-5-fr ">
                                                
                                                          <div >
                                                                     <strong className="line-fix">
                                                                     Oferente
                                                                      </strong>
                                                                  </div>
                                                                 <div >
                                                                      <strong className="line-fix">
                                                                      Código
                                                                      </strong>
                                                                  </div> 
                                                                  <div >
                                                                     <strong className="line-fix text-left">
                                                                     Nombre de la inversión
                                                                      </strong>
                                                                   </div>

                                                                   <div >
                                                                     <strong className="line-fix">
                                                                     País
                                                                      </strong>
                                                                  </div>
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                     Tipo
                                                                      </strong>
                                                                  </div>
                                                                  
                                                          </div>
                                                          <div className="grid-container-tabla-proyecto-body first-grid">
                                                
                                                          <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.issuer}
                                                                      </strong>
                                                                  </div>
                                                                 <div >
                                                                      <strong className="line-fix">
                                                                      <i class="tim-icons icon-bank p-2"/>{contrato.code}
                                                                      </strong>
                                                                  </div> 
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.name}
                                                                      </strong>
                                                                   </div>

                                                                   <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.country_iso}
                                                                      </strong>
                                                                  </div>
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.tipo}
                                                                      </strong>
                                                                  </div>
                                                          </div> 

                                                          <div className="grid-container-label-proyecto grid-8-fr ">
                                                              
                                                              <div >
                                                                          <strong className="line-fix">
                                                                          Categoria
                                                                          </strong>
                                                                      </div>
                                                                      <div >
                                                                          <strong className="line-fix">
                                                                          Clasificación
                                                                          </strong>
                                                                      </div> 
                                                                      <div >
                                                                          <strong className="line-fix text-left">
                                                                          Garantia
                                                                          </strong>
                                                                        </div>

                                                                        <div >
                                                                          <strong className="line-fix">
                                                                          Interes
                                                                          </strong>
                                                                      </div>
                                                                      <div >
                                                                          <strong className="line-fix">
                                                                          Fecha fin de la venta
                                                                          </strong>
                                                                      </div>
                                                                      <div >
                                                                          <strong className="line-fix">
                                                                          Dias restantes
                                                                          </strong>
                                                                      </div>
                                                                      <div >
                                                                          <strong className="line-fix">
                                                                          Precio de venta
                                                                          </strong>
                                                                      </div>
                                                                      <div >
                                                                          <strong className="line-fix">
                                                                          Ganancia periodo
                                                                          </strong>
                                                                      </div>
                                                                      
                                                              </div>

                                                          <div className="grid-container-tabla-proyecto-body">

                                                                  

                                                  
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.category}
                                                                      </strong>
                                                                  </div>
                                                                
                                                                  <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.risk}
                                                                      </strong>
                                                                    </div>
                                                                    <div >
                                                                       <strong className="line-fix">
                                                                       {contrato.warranty}
                                                                        </strong>
                                                                    </div>
                                                                    <div >
                                                                     <strong className="line-fix">
                                                                      <i class="tim-icons icon-coins p-2"/>
                                                                      {contrato.fee_total_contract}
                                                                      </strong>
                                                                    </div>
                                                                    <div >
                                                                     <strong className="line-fix">
                                                                      <i class="tim-icons icon-calendar-60 p-2"/> 
                                                                      {contrato.fecha_fin}
                                                                      </strong>
                                                                    </div>

                                                                    <div >
                                                                     <strong className="line-fix">
                                                                      <i class="tim-icons icon-calendar-60 p-2"/> 
                                                                      {contrato.dias_pendientes}
                                                                      </strong>
                                                                    </div>

                                                                    <div >
                                                                     <strong className="line-fix">
                                                                      <i class="tim-icons icon-coins p-2"/> {contrato.precioventa} 
                                                                      </strong>
                                                                    </div>

                                                                    <div >
                                                                     <strong className="line-fix">
                                                                     {contrato.ganancia}
                                                                      </strong>
                                                                    </div>


                                                          </div>

                                                          
                                                        </CardBody>


                                                                                  </>
                                                                                  ) )}

                                                        <div> 
                                                          
                                                        <CardFooter>
                                                      <div className="grid-container-paginador"> 

                                                      

                                                          {paginaactualcountsec === 1 ?

                                                                    <button
                                                                    title=""
                                                                    className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                    onClick={Backsec}
                                                                    disabled
                                                                    > Anterior &laquo; </button>
                                                                    : 

                                                                    <button
                                                                        title=""
                                                                        className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                        onClick={Backsec}
                                                                      > Anterior &laquo; </button>
                                                                        }
                                                                      
                                                                      <div>
                                                                        Página {paginaactualcountsec} de {totalpaginassec}
                                                                      </div> 


                                                                      {paginaactualcountsec === totalpaginassec ?

                                                                                <button
                                                                                title=""
                                                                                disabled
                                                                                className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                onClick={Nextsec}
                                                                                
                                                                                > Siguiente &raquo; </button>
                                                                            : 

                                                                                <button
                                                                                    title=""
                                                                                    
                                                                                    className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                    onClick={Nextsec}
                                                                                    
                                                                                  > Siguiente &raquo; </button>
                                                                                }

                                                      </div>
                                                      

                                                    </CardFooter>
                                        

                                                        </div> 

                                                        </Card>
                                                        </Divdesktop> 

                                                        <DivMobile>

                                                        <Card className="height-fix-ultimos-movimientos justify-content-center table-responsive">
                                                                                                
                                                                                                <div>
                                                                                      

                                                                                                    <CardBody>
                                                                                                          <Container> 

                                                                                                            <Row> {contratossec.map(contrato => (
                                                                                                                  <>
                                                                                                              <Col xs="12" sm="6" md="6" key={contrato.id} >
                                                                                                                <Card className="card-responsive-bg"> 
                                                                                                                  <CardHeader>
                                                                                                                  <Row>
                                                                                                                  <Col xs="6" sm="6" md="6">
                                                                                                                      <p className="card-responsive-01-inversiones"> <strong>  Codigo </strong> <br/>
                                                                                                                      {contrato.code}</p> 
                                                                                                                        </Col>
                                                                                                                        <Col xs="6" sm="6" md="6">
                                                                                                                        <p className="card-responsive-02-inversiones"> <strong>  Pais </strong><br/> 
                                                                                                                        {contrato.country_iso}</p>
                                                                                                                        </Col>
                                                                                                                        <Col xs="6" sm="6" md="6">
                                                                                                                        <p className="card-responsive-02-inversiones"> <strong>  Tipo </strong><br/> 
                                                                                                                        {contrato.tipo}</p>
                                                                                                                        </Col>
                                                                                                                        <Col xs="6" sm="6" md="6">
                                                                                                                        <p className="card-responsive-02-inversiones"> <strong>  Categoria </strong><br/> 
                                                                                                                        {contrato.category}</p>
                                                                                                                        </Col>
                                                                                                                        </Row>
                                                                                                                  </CardHeader>

                                                                                                                  <CardBody className="card-responsive-07-inversiones">
                                                                                                                      <Row> 

                                                                                                                      <Col xs="12" sm="12" md="12">
                                                                                                                              <p className="card-responsive-03-inversiones"><strong>  Nombre del Proyecto :</strong> <br/> {contrato.name}</p>
                                                                                                                          </Col> 

                                                                                                                          <Col xs="12" sm="12" md="12">
                                                                                                                              <p className="card-responsive-03-inversiones"><strong>  Emisor :</strong> <br/> {contrato.issuer}</p>
                                                                                                                          </Col>
                                                                                                                          
                                                                                                                        
                                                                                                                          <Col xs="6" sm="6" md="6">
                                                                                                                              <p className="card-responsive-03-inversiones"><strong className="line-fix">  <i class="tim-icons icon-bank pr-1"/> Monto:</strong> <br/> {contrato.amount_proyecto}</p>
                                                                                                                          </Col>
                                                                                                                          <Col xs="6" sm="6" md="6">
                                                                                                                              <p className="card-responsive-03-inversiones"><strong>  Riesgo : </strong> <br/>{contrato.risk}</p>
                                                                                                                          </Col>
                                                                                                                          <Col xs="6" sm="6" md="6">
                                                                                                                          <p className="card-responsive-04-inversiones"><strong> Garantia :</strong> <br/> {contrato.warranty}</p>
                                                                                                                          </Col>

                                                                                                                          <Col xs="6" sm="6" md="6">
                                                                                                                          <p className="card-responsive-04-inversiones"><strong> Interes ( % )</strong> <br/> {contrato.fee_total_contract}</p>
                                                                                                                          </Col> 
                                                                                                                          <Col xs="6" sm="6" md="6">
                                                                                                                          <p className="card-responsive-04-inversiones"><strong> Vencimiento :</strong> <br/> {contrato.end_date}</p>
                                                                                                                          </Col>
                                                                                                                          <Col xs="6" sm="6" md="6">
                                                                                                                          <p className="card-responsive-04-inversiones"><strong> Ganancia ( % ):</strong> <br/> {contrato.tax_referential_client}</p>
                                                                                                                          </Col>
                                                                                                                          

                                                                                                                      </Row>


                                                                                                                  </CardBody>


                                                                                                                  <CardFooter>
                                                                                                                      <p className="card-responsive-05-inversiones btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  onClick={ () => {
                                                                                                                                  
                                                                                                                                  handleOpenDos();
                                                                                                                                  guardarOfferid(contrato.offer_id) 
                                                                                                                                  setModalInfo(contrato);
                                                                                                                                }
                                                                                                                      }> Comprar</p>
                                                                                                                  </CardFooter>
                                                                                                            
                                                                                                        
                                                                                                                      
                                                                                                                        
                                                                                                                      
                                                                                                                        
                                                                                                    
                                                                                                                </Card>
                                                                                                              </Col>
                                                                                                          </>


                                                                                                                  ) )}
                                                                                          
                                                                                                              
                                                                                                              





                                                                                                            </Row>
                                                                                                          </Container>
                                                                                                    </CardBody>


                                                                                                    <CardFooter>
                                                      <div className="grid-container-paginador"> 

                                                      

                                                          {paginaactualcountsec === 1 ?

                                                                    <button
                                                                    title=""
                                                                    className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                    onClick={Backsec}
                                                                    disabled
                                                                    > Anterior &laquo; </button>
                                                                    : 

                                                                    <button
                                                                        title=""
                                                                        className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                        onClick={Backsec}
                                                                      > Anterior &laquo; </button>
                                                                        }
                                                                      
                                                                      <div>
                                                                        Página {paginaactualcountsec} de {totalpaginassec}
                                                                      </div> 


                                                                      {paginaactualcountsec === totalpaginassec ?

                                                                                <button
                                                                                title=""
                                                                                disabled
                                                                                className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                onClick={Nextsec}
                                                                                
                                                                                > Siguiente &raquo; </button>
                                                                            : 

                                                                                <button
                                                                                    title=""
                                                                                    
                                                                                    className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                    onClick={Nextsec}
                                                                                    
                                                                                  > Siguiente &raquo; </button>
                                                                                }

                                                      </div>
                                                      

                                                    </CardFooter>
                                        
                                                                                          
                                                                                          
                                                                                                  </div> 
                                                                                                  
                                                                                                  </Card>



                                                                                      </DivMobile>





                                                                                      </Col>



                                                        </Row> : 
                                                        <Container fluid className="justify-content-center d-flex"> 
                                         <Row className="box-depositos-seleccion-alerta justify-content-center w-100 d-flex"> 
                                         <Col md="12" className=" panther-open-titulo-alerta border-panther"> 
                                 
                                         
                                       {cantidadsec === 0 ?  <> 
                                       
                                               <i className="tim-icons  icon-spaceship animation-icon-in mb-1"/>
                                                       <h2 className="animation-icon-in-dos mb-4">Listado del mercado secundario</h2>
                                                         <h3 class="animate-raya-03 mb-2">Proyectos ofertados por inversionistas, se agregan directamente al mercado secundario</h3>
                                                         <p className="text-center animation-icon-in-cuatro ">www.panther7.com | Proyecto de clientes Panther 7 </p>
                                                         
                                                           </> :
                                                         <Spinner/>
                                                         
                                                         }
                                         </Col>
                                                     
                                         </Row>  </Container>


                                                        }
                                          
                                          </Container>
                                          </div> 

                                  

                                      <ModalPrimario  modalInfo={modalInfo} 
                                                      onSubmit={onSubmit} 
                                                      onChange={onChange}
                                                      amount={amount}
                                                      handleOpen={handleOpen}      
                                                      handleClose={handleClose} 
                                                      open={open} 
                                                      mensaje={mensaje}         
                                                      cargando={cargando}    
                                                      guardarCargando={guardarCargando}       
                                                      apiload={apiload}               
                                                      ApiLoad={ApiLoad}
                                                      
                                                      /> 

                                    <ModalSecundario  modalInfo={modalInfo} 
                                                      onSubmitDos={onSubmitDos} 
                                                      onChange={onChange}
                                                      amount={amount}
                                                      handleOpenDos={handleOpenDos}      
                                                      handleCloseDos={handleCloseDos} 
                                                      opendos={opendos} 
                                                      mensaje={mensaje}                                    
                                                      sumaporcentaje={sumaporcentaje}
                                                      guardarSumaPorcentaje={guardarSumaPorcentaje}
                                                      cargando={cargando}                           
                                                      />
       

</Fragment>     
        

      </Fade>
     


        
        
          





     );
};

 
export default ListaProjectos; 