import { useLottie } from "lottie-react";
import Art from "../IconsAnimation/Art";
 
const ArtGenerator= () => {
  const options = {
    animationData: Art,
    loop: true,
    autoplay: true,
    height: 90,
  };
 
  const { View } = useLottie(options);
 
  return View;
};
 
export default ArtGenerator;