import React, { useState, useContext, useEffect, Fragment, useReducer } from 'react';
import { Link } from 'react-router-dom';
import {ReactComponent as Logo} from '../../prisma-items/logo-strprisma.svg'; 
import Modal from '@material-ui/core/Modal'

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 
import Spinner from '../spinners/Spinner' 

import ParticlesBackground from '../../components/Particles/ParticlesBackgroundWhite'

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';  

import {
   
    LIMPIAR_ALERTA,
    

} from '../../types'; 



import Alerta from '../Alerta';

import AuthContext from '../../context/autenticacion/authContext.js'
import AuthReducer from '../../context/autenticacion/authReducer.js';

import LogoPrisma from '../../prisma-items/Prisma-str-iso.svg'



const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'hidden',
    height:'100%',
    
    background: '#0000005c',
      
    },
    paper: {
      background: '#001c52',
      border: '2px solid #04a1ff',
      boxShadow: theme.shadows[5],
      
      
    },
  }));
  

const ResetPassword = (props) => {  

    
    
     
  
      // ahora vamos a extraer los valores de registrar usuario del context
  
      const authContext = useContext(AuthContext);
      const { mensaje, autenticado, iniciarSesion,userStatus , olvidoPassword ,  } = authContext; 

      const [ registrarseclick, setRegistrarseClick ] = useState(false);

      const classes = useStyles();
    const [open, setOpen] =useState(true);

    const handleOpen = () => {
        setOpen(true);
    } 

    

    const handleClose = () => {
        setOpen(true);
        
    }
      

      useEffect( () => {
        
        
     


            if(registrarseclick) {
           
      
                props.history.push('/');
                
           
        }



          


        


    } , [mensaje, autenticado,registrarseclick, props.history]
    );


  


    // estamos creando el state 
    const [usuario, guardarUsuario] = useState({
        email:'',
        
    });

    // extraer usuario del state

    const {email} = usuario;


    const onChange = e => {

        // voy a colocar guardar usuario en el state

        guardarUsuario({
            ...usuario,
            [e.target.name] : e.target.value

        })
    }


    /// cuando el usuario quiere iniciar seseion
    const onSubmit = e => {
        e.preventDefault(); 

        // validar que no haya campos vacios

        if(email.trim() === '' ) {
            
            return

        } 

        

        
        olvidoPassword({ email});

    }







    return ( 



        <>



                                    <div className="content"> 
                                    <div className="prisma">

                                    <div className="windows-text d-none"> 

                                        <div className="text-container">

                                            <div className="prisma-picture">

                                            <Logo className="animation-icon-in"/>

                                            </div>



                                                

                                                {/* <div>
                                                <strong>
                                                    Bienvenido
                                                </strong>

                                                        <p>
                                                        Saca más partido a tu dinero
                                                            
                                                        </p>
                                                



                                                </div>



                                                <div>
                                                    <small>
                                                    Robot advisor que con inteligencia artificial
                                                        generan buenos rendimiento mes tras mes!
                                                    </small>

                                                </div> */}


                                        </div>




                                    </div>

                                    <div className="windows-login"> 

                                    <div className="register-container">
                                    <Link to={"/portal"} className="enlace-cuenta text-dark">
                                    <p className="text-dark"> Iniciar sesión</p>
                                    </Link> 
                                    
                                    <Link to={"/nueva-cuenta"} className="enlace-cuenta">
                                    <p> ¡Crear su cuenta ahora!</p>
                                    </Link> 


                                    </div>



                                    <div className="form-container">

                                    <div className="prisma-logo-media">
                                        <img src={LogoPrisma} />

                                    </div>




                                    <h1 className=" animation-icon-in-dos ">Restablecer <span>StrPrisma</span></h1>     

                                    


                                    

                                    <form onSubmit={onSubmit} > 
                                    <div className="auth-form">
                                        {/* <h1 className="beta-text mb-0">Beta</h1>
                                        <p className="text-dark text-center">Bienvenido a la version Beta de <strong> www.Panther7.com</strong></p> */}
                                    <div className="elemento-auth-one animation-icon-in-cinco">
                                                <label htmlFor="email" >Correo electrónico</label>

                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    placeholder="Mi correo electrónico"
                                                    onChange={onChange}
                                                    value={email}
                                            
                                                    required
                                                    autocomplete="true"
                                                    
                                                    />
                                        </div>

                                        

                                        


                                      

                                    </div>
                                        

                                        <div className="footer-prisma-btn">

                                        <button
                                            type="submit" 
                                             >
                                                Enviar enlace para restablecer contraseña
                                                </button>

                                        
                                            
                                        </div>
                                        
                                    </form>


                                    </div>

                                    </div> 

                                    


                                    <div className="bg-particle-media">
                                    <ParticlesBackground/>
                                    </div> 
                                    </div>

                                    </div>

                                    {mensaje ?  
                                 
                                 <Modal
                                                             
                                                             className={classes.modal}
                                                             open={open} 
                                                             mensaje={mensaje}
                                                             disableScrollLock={true}
                                                             
                                                             closeAfterTransition
                                                             BackdropComponent={Backdrop}
                                                             BackdropProps={{
                                                                 timeout: 900,
                                                             }}
                                                             >
                                                             <Fade in={open}
                                                             timeout={154}
                                                             onClose={handleClose}>
                                                                 

                                                                 <div className="container-grid">
                                                                     <div  className={`modal-kyc-welcome padding-modal`}> 
                                                                         <div className="form-group form-control-deposito-container">
                                                                             <div className="modal-grid-row"> 

                                                                             <div> 
                                                                                     <p className="text-center display-4 title my-3 font-light-weight-modal"> {mensaje}</p>

                                                                             </div>

                                                                             <div>
                                                                                 <button className="  btn mt-0 btn-block btn-info my-2 btn-panther-power" onClick={ ()=>(
                                                                                                                             handleClose()
                                                                                                                             
                                                                                                                             
                                                                                                                             

                                                                                                                     )}>
                                                                                                             Intente nuevamente
                                                                                 </button>
                                                                             </div>
                                                                             </div>    
                                                                             </div>
                                                                             </div>        
                                                             </div>




                                                             </Fade>
                                                             </Modal>            
                             : null 
                             
                             }

        
        </>

     );
}
 
export default ResetPassword;
