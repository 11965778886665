
import Dashboard from "views/Dashboard.js";
import Inversiones from "views/Inversiones.js";
import Deposito from "views/Deposito.js";
import Retiro from "views/Retiro.js";
import Projectos from "views/Projectos";
import UserProfile from "views/UserProfile.js";  
import Frecuente from "views/Frecuente";
import Gallery from "views/Gallery.js";  



var routespen = [

  {
    path: "/user-profile",
    name: "Mi Cuenta",
    
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/cliente"
  },
  {
    path: "/gallery-adu",
    name: "Colección de Artes",
    
    icon: "tim-icons icon-components",
    component: Gallery,
    layout: "/cliente"
  },
  {
    path: "/preguntas-frecuentes",
    name: "Preguntas Frecuente",
    
    icon: "tim-icons icon-bulb-63",
    component: Frecuente,
    layout: "/cliente"
  },


]; 







export default routespen;
