import React from 'react';
import CambiarPassword from '../components/Profile/CambiarPassword';


const PrismaActiveBar = ({
    
    appbarkyc,ActivarPrimeroKyc,
    
    ActivarSecundoKyc,
    ActivarTerceroKyc,
    ActivarCuartoKyc,
    ActivarQuintoKyc,
    ActivarSextoKyc

}) => {


    return (  

            <> 

            <div className="profile-prisma-body d-none">
                                <div>

                                      <p className={` on-click-active ${appbarkyc.primero === 1 ? 'nav-active-prisma-profile':''}`}
                                                                        onClick={ActivarPrimeroKyc}> <span>1</span> <strong> Configuración de cuenta</strong></p>

                                      <p className={ `on-click-active ${appbarkyc.segundo === 1 ? 'nav-active-prisma-profile':''}`}
                                      onClick={ActivarSecundoKyc}  ><span>2</span> <strong>Centro de verificación</strong></p>

                                      <p className={`on-click-active ${appbarkyc.tercero === 1 ? 'nav-active-prisma-profile':''}`}
                                          onClick={ActivarTerceroKyc}> <span>3</span> <strong>Contratos</strong></p>

                                      <p className={`on-click-active ${appbarkyc.cuarto === 1 ? 'nav-active-prisma-profile':''}`}
                                      onClick={ActivarCuartoKyc}> <span>4</span> <strong>Depósito inicial</strong></p>


                                      <p className={`on-click-active ${appbarkyc.quinto === 1 ? 'nav-active-prisma-profile':''}`}
                                      onClick={ActivarQuintoKyc}> <span>5</span> <strong>Cuenta bancaria</strong></p>

                                      <p className={`on-click-active ${appbarkyc.sexto === 1 ? 'nav-active-prisma-profile':''}`}
                                        onClick={ActivarSextoKyc}> <span>6</span> <strong>Aprobación</strong></p>




                                      <CambiarPassword />

                                      
                                </div>

                            </div>
                            

            



            </>







    );
}
 
export default PrismaActiveBar;