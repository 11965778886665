
import clienteAxios from '../../config/axios';

import React, { useContext, useState, useEffect } from 'react';
import { ModalPasswordContext } from '../../context/ModalContext/ModalPasswordContext'

import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'  


import AuthContext from '../../context/autenticacion/authContext.js';
import Alerta from '../Alerta';



import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col,
    Table,
  } from "reactstrap";  


  function getModalStyle() {
    const top = 50 ;
    const left = 50;
  
    return {
      top: ``,
      left: ``,
      transform: ``,
      
    };
  }
  


const useStyles = makeStyles((theme) => ({
    root: {
      
      width: '70%',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow:'hidden',
      },
      paper: {
      
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        
        margin: '20% 1% 10% 1%',
      },
  })); 



const CambiarPassword = (props) => { 


    const [ modalStyle ] =useState(getModalStyle);
        const [open, setOpen] =useState(false);
    
        const classes = useStyles();
    
        const handleOpen = () => {
            setOpen(true);
        } 
    
        const handleClose = () => {
            setOpen(false);;
        }
    
    


    // extraer los valores del context 
    const { guardarIdInversion } =useContext(ModalPasswordContext); 


     //extraer los valoresa del context  para la alerta



    // ahora vamos a extraer los valores de registrar usuario del context

    const authContext = useContext(AuthContext);
    const { mensaje, autenticado, cambiarPassword } = authContext;

   

    


    // en caso de que el usuario se haya autenticado 
    // despues del rendering vamos a autenticar el usuario y pasarle las propiedades con los props
    useEffect( () => {

         if(mensaje) {
            

            setTimeout(() => {
                setOpen(false) 
            },16000)
         } 

        

    } , [mensaje,autenticado, props.history]
    );
 
    // estamos creando el state
    const [usuario, guardarUsuario] = useState({
        current_password:'',
        new_password:'',
        confirm_password: '',
        
    });

    // extraer usuario del state

    const {current_password,new_password,confirm_password} = usuario;

    


    const onChange = e => {

        // voy a colocar guardar usuario en el state

        guardarUsuario({
            ...usuario,
            [e.target.name] : e.target.value

        })
    }


    /// cuando el usuario quiere iniciar seseion
    const onSubmit = e => {
        e.preventDefault();

     

        //     if( name.trim() === '' || 
        //         email.trim() === '' || 
        //         password.trim() === '' || 
        //         confirmar.trim() === ''  ) {
        //             {mensaje}
        //         } 

        // if (name.trim() === '') {
        //     mostrarAlerta('Coloca tu nombre', 'alerta-panther');
        // }
        
        // // vamos a poner que el password minimo debe tener 6 caracteres
        // if (password.length < 6) {
        //     mostrarAlerta('El Password debe ser de al menos 6 caracteres', 'alerta-panther');
        // }

        // // los 2 password tienen que ser iguales
        // if (password !== confirmar) {
        //     mostrarAlerta('Los password no son iguales','alerta-panther');
        // }




        // luego lo vamos a pasar al action

        cambiarPassword({
            
            current_password,
            new_password,
            confirm_password
        });


    }




    return ( 

        <div className=" p-0 my-5  ">
                    <p className="on-click-active2 passwordprisma-btn"
                    onClick={() => {
                        
                        handleOpen();
                    }}
                    
                    >   <span> <i className="tim-icons icon-lock-circle"></i></span>
                        <strong>Cambiar contraseña</strong></p>
                    <Modal 
                    className={classes.modal}
                            open={open}
                            onClose={ () => {
                                handleClose();
                                guardarIdInversion(null);
                                
                            } }
                        >       


                            <div className="container-grid">
                              <div  className={`modal-kyc-welcome padding-modal`}> 
                                <form onSubmit={onSubmit}> 
                                  <div className="bg-modal-header">   
                                          <div  className=" fix-cuentaspropias-title fix-cancelarcuenta-title ">
                                          <h3 className="title mt-4 display-4">Cambio de contraseña  </h3> 
                                          </div>

                                          <div >
                                            <div className="cancel-box-btn">
                                                <div className="cancel-btn-panther" onClick={ () => (
                                                  handleClose()
                                                )}> 
                                                    <i className="tim-icons icon-simple-remove"/>
                                                </div>
                                            </div>
                                          </div>    
                                      </div> 

                                      {mensaje && <Alerta />}

                                      < div className="form-group form-control-deposito-container">
                                      <div className="modal-grid-row"> 

                                      <div className="my-2"> 
                                            <label htmlFor="current_password" >Contraseña actual</label>
                                            <input
                                                type="password"
                                                id="current_password"
                                                name="current_password"
                                                placeholder="Su antigua contraseña"
                                                onChange={onChange}
                                                value={current_password}
                                                autoComplete="off"
                                                className="form-control"
                                                />

                                        </div>

                                        <div className="my-2"> 
                                                    <label htmlFor="password" > Nueva contraseña</label>

                                                    <input
                                                        type="password"
                                                        id="new_password"
                                                        name="new_password"
                                                        placeholder="Su nueva contraseña"
                                                        onChange={onChange}
                                                        value={new_password}
                                                        autoComplete="off"
                                                        className="form-control"
                                                        />
                                            </div>

                                            <div className="my-2"> 
                                                    <label htmlFor="confirm_password" >Confirmar contraseña</label>

                                                    <input
                                                        type="password"
                                                        id="confirm_password"
                                                        name="confirm_password"
                                                        placeholder="Confirmar su nueva contraseña"
                                                        onChange={onChange}
                                                        value={confirm_password}
                                                        autoComplete="off"
                                                        className="form-control"
                                                        />
                                            </div>

                                            <div className="my-4"> 
                                                    <input type="submit" className="btn-small-prisma"
                                                    value="Guardar contraseña"/>

                                            </div>

                                      </div>    
                                      </div>
                                            
                                            </form>
                                      </div>        
                                  </div>
                        </Modal>
        </div>

     );
}
 
export default CambiarPassword;