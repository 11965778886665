import React, { useContext, useState, useEffect, Fragment } from 'react';
import clienteAxios from '../config/axios';
import { makeStyles } from '@material-ui/core/styles'  
import Modal from '@material-ui/core/Modal'
import ModalTodosDepositos from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';

import  GraficaDoughnut from './GraficaDoughnut';
import AduGenerator from './AduGenerator'
import DepositoAdu from './DepositoAdu'

import axios from 'axios'

import btcimagen from '../assets/img/qr.png'

import AuthContext from '../context/autenticacion/authContext'

import BootstrapTable from 'react-bootstrap-table-next' 

import Spinner from '../components/spinners/Spinner'

import NumberFormat from 'react-number-format';


// aqui vamos a iumportar las imagenes

import imagenes from '../assets/flags/ES.png'
import imagenen from '../assets/flags/USGB.png' 


import {ReactComponent as LogoPayPal} from '../logopaypal.svg';

import ModalDireccionesMaster from '../components/Modales/ModalDireccionesMaster';

import ParticleBg from '../components/IconsAnimation/ParticleBg'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

  
function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
} 



const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));





const Deposito = () => {   

  const [modalInfo, setModalInfo] = useState([]);


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const authContext = useContext(AuthContext);
  const { depositos_cantidad,depositos,TodosDepositos, filename, demo_status, usuario, usuarioAutenticado, userStatus } = authContext;  


  const [ cargando, guardarCargando] = useState(false);

  const guardar = ( cargando ) ? <Spinner/> :  
                                          
  <Fragment>
    Enviar
    </Fragment> 



  

  
////////////// vamos a jalar el stado

const [ listadepositos , guardarListaDeposito ]= useState([{
  id:'',
  fecha:'',
  note:'',
  currency_tag: '',
  amount:'',
  estado:'',

}]);
 

const [pdfdatos, guardarPdfDatos] = useState({

  file_name:'',
  file_name_eng:'',
  file_name_pa:'',
  
  
  
  });  



  const [ apiload, guardarApiLoad] = useState(true);

  const ApiLoad = () => {
    guardarApiLoad(false);
    setTimeout(() => {
      guardarApiLoad(true);
  },50);
    
    
  }
  



  // /// aqui vamos a usar el  useContext
  // const authContext = useContext(AuthContext);
  // const { depositList, depositos, retiros } = authContext; 
  
  const [ recargardata, setRecargarData  ] = useState(false);

  const [cantidad, guardarCantidades ] = useState({});


  useEffect((props) => { 


    


    const generarpdf = async () => {

     

      guardarPdfDatos(filename);
      
    } 
    
    
    generarpdf();
    
  



    const consultarApiListaDeDepositos = async () => {
      // const {amount, note, currency_tag, estado, created_at
    
      
      // } = listadepositos; 

      // const respuesta = await clienteAxios.post('/api/client/deposits/list')
        
      guardarListaDeposito(depositos)
      guardarCantidades(depositos_cantidad)
      
    }

    consultarApiListaDeDepositos()

    
    
    
    
  }, [!apiload,depositos_cantidad,depositos,TodosDepositos]) 



  const [mensaje, guardarMensaje] = useState({
    message:""
})

const [deposit, guardarDeposit] = useState({
    
    amount:'',
    type: '2',
    file:'',
    note:'',
    bank:'',
    fecha:'',

});  


  

  const consultarApiPaypal = async () => { 

  

   
    const type = 5;
    const  amount =  0 ;

    const paypaldeposit = { type, amount}
    
  
  
    
  
   
  
   try {
        const respuesta = await clienteAxios.post('/api/client/deposits/create',paypaldeposit);
        
        guardarCargando(false)
   } catch (error) {
       
    
    guardarMensaje(error.response.data.message);
    handleOpen();
               
               
   }
  }  
  


const [opendireccion, setOpenDireccion] =useState(false);



const handleOpenDireccion = () => {
    setOpenDireccion(true);
} 

const handleCloseDireccion = () => {
    setOpenDireccion(false);;
}


const [openlist, setOpenList] =useState(false);



const handleOpenList = () => {
    setOpenList(true);
} 

const handleCloseList = () => {
    setOpenList(false);
    guardarMensaje(null);
}








  const [ modalStyle ] =useState(getModalStyle);
  
    const [open, setOpen] =useState(false);

    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true);
    } 

    const handleClose = () => {
        setOpen(false);;
    }



const [pin, guardarPin ] = useState("");


const [ btnremesas, guardarBtnRemesas] = useState(true);




const [file, setFile] = useState(undefined);  





const [enviardepositobtn, guardarEnviarDepositoBtn] = useState(true);


const consultarApiDepositRemesas = async () => {
  const {
      amount,
      file,note,bank,fecha,type
    } = deposit;  

    const config = {     
      headers: { 'content-type': 'multipart/form-data','Accept': 'application/json' },
      
      mimeType: 'multipart/form-data',
  } 

  const formData = new FormData();
 

  //formData.append('file',document.getElementById('file').files[0],file);
  formData.append('amount',document.getElementById('amount').value);
  //formData.append('note',document.getElementById('note').value);
  formData.append('type',document.getElementById('type').value);

  //formData.append('bank',document.getElementById('bank').value);
  //formData.append('fecha',document.getElementById('fecha').value);
  

  try {
      const respuesta = await clienteAxios.post('/api/client/deposits/create',formData,config);

            guardarPin(respuesta.data.data.pin);
            
            guardarMensaje(respuesta.data.message);
            
            guardarBtnRemesas(false);
            setRecargarData(true);
            guardarCargando(false);
            
              

             
              
      
  } catch (error) {
      
      
      guardarMensaje(error.response.data.message);
      guardarCargando(false),
              handleOpen();
             
  }




} 




const consultarApiDeposit = async () => {
    const {
        amount,
        file,note,bank,fecha,type
    
      
      } = deposit;  

      const config = {     
        headers: { 'content-type': 'multipart/form-data','Accept': 'application/json' },
        
        mimeType: 'multipart/form-data',
    } 

    const formData = new FormData();
   
    formData.append('file',document.getElementById('file').files[0],file);
    formData.append('amount',document.getElementById('amount').value);
    // formData.append('note',document.getElementById('note').value);
    formData.append('type',document.getElementById('type').value);

    // formData.append('bank',document.getElementById('bank').value);
    // formData.append('fecha',document.getElementById('fecha').value);
    
 
 
    try {
        const respuesta = await clienteAxios.post('/api/client/deposits/create',formData,config);



              guardarPin(respuesta.data.data.pin)
              guardarMensaje(respuesta.data.message);
              setRecargarData(true);
              guardarCargando(false);

              guardarEnviarDepositoBtn(false);
              
                handleOpen(); 

              
             
        
    } catch (error) {
        
        
        guardarMensaje(error.response.data.message);
        guardarCargando(false),
                handleOpen();
              
    }

  
  
  
} 




function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}



const onChangeIndex = e => {
  var x = parseFloat(document.getElementById("amount").value);
      
  document.getElementById("totalarecibir").innerHTML ="0";
  if (x!="")
    {
        
        var calculo=parseFloat(x);
        
        if (!Number.isNaN(calculo))       document.getElementById("totalarecibir").innerHTML = currencyFormat(calculo);
    }
    else
    document.getElementById("totalarecibir").innerHTML ="0";
}


const onChange = e => {  

  


    // voy a colocar guardar usuario en el state
 
    guardarDeposit({
        ...deposit,
        [e.target.name] : e.target.value
        
    }) 

    setFile(e.target.files)
    

    
} 




const onSubmitRemesas = e => {
  e.preventDefault();
  guardarCargando(true)




  consultarApiDepositRemesas({
      amount,
      type,
   

    });

 

}  





////// vamos a hacer el pagamento de paypal

const [paypal, guardarPaypal] = useState('');


const onChangePaypal = e => {  

  


  // voy a colocar guardar usuario en el state

  guardarPaypal({
      ...paypal,
      [e.target.name] : e.target.value
      
  }) 

  
  

  
} 




const onSubmitPaypal = e => {
  e.preventDefault();

  PayPalPay(paypal);



}  



const PayPalPay = async () => {

     
  try {
    const respuestapaypal = await clienteAxios.post(`/api/client/deposits/pdf`);
      
    
    
    
   

    
  } catch (error) {
    
    guardarMensaje(error.response.data.message);
 
  }
} 





const paypallink = `http://54.212.52.47/paypal/pago.htm`









// const onSubmit = e => {
//     e.preventDefault();

//     guardarCargando(true)

 
//       consultarApiDeposit({
//         amount,
//         type,
//         file,
        
  
//       });

// } 


const [montosave, guardarMontoSave] = useState('')


const [multiplicador, guardarMultiplicador] =useState()




//////// en el componente de contrato, necesitamos colocar la logica, fuera porque vamos a hacerle reset en algunas acciones
const [btndinamic, guardarBtnDinamic] = useState('')
const [contrato, guardarContrato] = useState({
      amount:'',
  })

const [participaciones, guardarParticipacion] = useState({
          multiplicador:`0`,
})


 const montosavereset = e => {
      e.preventDefault()
      guardarMontoSave('')
      guardarBtnDinamic('')
      guardarContrato({
          amount: '',
      })

      guardarParticipacion({
          multiplicador:`0`,
      })
  }
  

 

  




  return (
    <>
    <div className="content">

    <div className="box-content-fx waveform-x4">
                <ParticleBg/>

                </div>  

    <div className="prisma-clip">


        <p>Depósito</p>

    </div>


    {/* <div className="prisma-open">
                     <div>
                       <h3>
                         Depósito de <small> {usuario.name} </small>
                          desde STR PRISMA <br/>
                         <small>Ultimate Robo-advisor</small>

                         {deposit.type === "" ? <p className="text-center  font-fix-tiitle  text-white animation-icon-in-dos"><i class="tim-icons icon-bank"></i> Sus depósitos hechos hasta la fecha </p> : null }
              {deposit.type === "1" ? <p className="text-center  font-fix-tiitle  text-white animation-icon-in-dos"> Para reportar depósitos hechos a la cuenta Strprisma en el exterior...</p> : null }
              {deposit.type === "3" ? <p className="text-center  font-fix-tiitle  text-white animation-icon-in-dos"> Para reportar depósitos vía remesadora...</p> : null }
              {deposit.type === "2" ? <p className="text-center  font-fix-tiitle  text-white animation-icon-in-dos"> Para reportar depósitos vía ACH / PANAMÁ...</p> : null }
              {deposit.type === "4" ? <p className="text-center  font-fix-tiitle  text-white animation-icon-in-dos"> Para reportar depósitos vía ACH / USA...</p> : null }
              {deposit.type === "5" ? <p className="text-center  font-fix-tiitle  text-white animation-icon-in-dos"> Para hacer depósitos vía PayPal...</p> : null }

                       </h3>

                     </div>

     </div> */}

     <div className="grid-dash-prisma-retiro">

                      

<div className="elemento-dash-prisma-one-retiro">

  <div className="retiro-prisma-body">

  <div className=""> 
  <div className="box-general">
                    <img alt="..." src={require("../assets/img/logo-bg.svg")} />

                </div>

  <div className="card-box-transparent  registrar-deposito ">
                        <h1 className="m-0">REPORTAR DEPÓSITO</h1>
                       
                        <hr class="my-1 border-info"/>
                        
                       

                        <div className="acc-deposito-container">
                        <div className="term mt-8 mb-4 mt-4">
                          <h5><strong className="">Banco:</strong> Banco General </h5>
                          </div>

                        

                     
                        <div className="term my-1">
                          <h5><strong className="">Titular: </strong> STR PRISMA HOLDING S.R.L </h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">Número de Cuenta: </strong> 03-29-01-139561-8 </h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">Tipo de Cuenta: </strong> CORRIENTE </h5>
                          </div> 

                        </div> 

                        
                      </div> 

  


  



  {deposit.type === "" ? <p className="mb-3 icon-maestro    "> Todos tus depositos</p> : null }
      {deposit.type === "1" ? <p className="mb-3 icon-maestro     "> Transferencia Nacional</p> : null }
      {deposit.type === "2" ? <p className="mb-3 icon-maestro     "> Transferencia Internacional</p> : null }
      


    

      <p className={deposit.type === "" ? ' nav-active-prisma-profile on-click-active ' : 'on-click-active' }     onClick={ () => { guardarDeposit({...deposit, type:'',amount:'',file:''}),TodosDepositos() }}><span><i class="tim-icons icon-bank"></i></span><strong>Todos mis depósitos</strong></p> 


        
        {/* <p className={deposit.type === "1" ? ' nav-active-prisma-profile monto-dolares-dos on-click-active ' : 'on-click-active' }    onClick={ () => { guardarDeposit({...deposit, type:'1',amount:'',file:''}),TodosDepositos() }}> <span><i class="tim-icons icon-bank"></i></span><strong> Depósito internacional</strong></p>  */}
        <p className={deposit.type === "2" ? '  nav-active-prisma-profile monto-dolares-dos on-click-active ' : 'on-click-active' }  onClick={ () => { guardarDeposit({...deposit, type:'2',amount:'',file:''}),TodosDepositos() }}>  <span> <i class="tim-icons icon-bank"></i></span> <strong>Depósito nacional</strong> </p> 
      {/* <div className={retiro.type === "3" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarRetiro({...retiro, type:'3',}) }}> <i class="tim-icons icon-bank panther-icon-style"></i> <p>Remesadora</p> </div> */}



      </div> 

  </div>

  
  

  

  

  


</div>


<div className="elemento-dash-prisma-dos-retiro"> 


{ deposit.type === "" ?  


<>

{cantidad > 0 ?<> 
            <div className=" shadow-sm rounded py-3 mb-3 " >
                                  <div className="  grid-container-super-depositos  depositos-tabla-grid-bg">
                                                    <div className="" data-tooltip="Código de referencia del depósito">
                                                                                            <strong className="">
                                                                                            CÓDIGO
                                                                                            </strong>
                                                                                            </div>
                                                                                            <div className="" data-tooltip="Fecha del depósito"> 
                                                                                            
                                                                                            <strong className="">
                                                                                              FECHA
                                                                                            </strong>
                                                                                            
                                                                                            </div>

                                                                                            <div className="justify-content-flex-start" data-tooltip="Información de depósito"> 
                                                                                            
                                                                                            <strong className=" align-items-flex-start">
                                                                                            INFORMACION
                                                                                            </strong>
                                                                                            
                                                                                            </div>
                                                                                            <div className="" data-tooltip="Moneda"> 

                                                                                            <strong className=" align-items-flex-start">
                                                                                            MONEDA

                                                                                            </strong>
                                                                                            
                                                                                            </div>
                                                                                            <div className="" data-tooltip="Monto">

                                                                                            <strong className="">
                                                                                            MONTO 
                                                                                            </strong>
                                                                                            
                                                                                            </div>
                                                                                            <div className="" data-tooltip="Estado del movimiento"> 
                                                                                            <strong className=" align-items-flex-end ">
                                                                                            ESTADO 
                                                                                            </strong>
                                                                                            
                                                                                            </div>
                                                                                      </div>
                                  {listadepositos.map(listadeposito => (
                                                                                    <>
                                                                                    <div key={listadeposito.id}>

                                                                                                            <div  className=" animation-op grid-container-super-depositos depositos-hover-tabla" onClick={ ()=> {
                                                                                                                
                                                                                                              handleOpenList();
                                                                                                               setModalInfo(listadeposito);
                                                                                                            }}>
                                            
                                                                                                                                                          
                                                                                                                                                                
                                                                                                                                                        <div className="" >
                                                                                                                                                              <strong className="line-fix">
                                                                                                                                                              {listadeposito.id}
                                                                                                                                                              </strong>
                                                                                                                                                              </div>
                                                                                                                                                              <div className="" > 
                                                                                                                                                              
                                                                                                                                                              <strong className="line-fix">
                                                                                                                                                              {listadeposito.fecha}
                                                                                                                                                              </strong>
                                                                                                                                                              
                                                                                                                                                              </div>
                                                                                                                                                              <div className="" > 
                                            
                                                                                                                                                              <strong className="line-fix">
                                                                                                                                                              {listadeposito.tipo}
                                                                                                                                                              </strong>
                                                                                                                                                              
                                                                                                                                                              </div>
                                                                                                                                                              <div className="" > 
                                            
                                                                                                                                                              <strong className="line-fix ">
                                                                                                                                                              {listadeposito.currency_tag}
                                                                                                                                                              </strong>
                                                                                                                                                              
                                                                                                                                                              </div>
                                                                                                                                                              <div className="" >
                                            
                                                                                                                                                              <strong className="line-fix">
                                                                                                                                                              {listadeposito.monto}
                                                                                                                                                              </strong>
                                                                                                                                                              
                                                                                                                                                              </div>
                                                                                                                                                              <div className="text-right" > 
                                                                                                                                                              <strong className="line-fix  ">
                                                                                                                                                              {listadeposito.estado}
                                                                                                                                                              </strong>
                                                                                                                                                              
                                                                                                                                                              </div>
                                                                                                                                                              
                                                                                                                                        
                                                                                                                                                        </div>

                                                                                                                                                        </div>

                                </>
                                                                        ) )}

                      </div>


     
        
        
        


   
    
 



                

                                  </>      :  

                                  <CardBody className="justify-content-center d-flex">
                                  <Row className="box-depositos-seleccion-alerta justify-content-center w-100"> 
                                  <Col md="12" className=" panther-open-titulo-alerta border-panther"> 


                                  {cantidad === 0 ?  <> 

                                      <i className="tim-icons  icon-money-coins animation-icon-in mb-1"/>
                                              <h2 className="animation-icon-in-dos mb-3">Sin depósitos</h2>
                                                <h3 class="animate-raya-03 mb-2">Todavía no ha hecho ningún depósito</h3>
                                                <p className="text-center animation-icon-in-cuatro ">Para depósitar dinero de su cuenta StrPrisma, porfavor seleccione el tipo de envío y siga intuitivamente los pasos </p>
                                                  </> :
                                                <Spinner/>
                                                
                                                }
                                  </Col>
                                            
                                  </Row>  
                                  </CardBody>



                                  }

      

                                          </>

                                          :null}  

   
{ deposit.type === "1" ? 
        
        <>  
         
     
              <div className="deposito-bg">
                  
                  

               
                  
                  
                  
                  <div md="12" className="justify-content-between d-none align-items-center text-center my-2 p-5"> 

                      <div className="flags-dad"> 
                            <div className="height-box-fit">
                            <img className="flags" src={imagenes}/>
                            </div>


                      <a className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1 datos-bancarios-btn" href={ `${pdfdatos.file_name}`} rel="noopener noreferrer" target="_blank" >

                                    
                                    Descarga tus datos bancarios
                                
                                  </a>
                      </div> 
                      <div className="ml-2 flags-dad" >
                      <div className="height-box-fit">
                            <img className="flags" src={imagenen}/>
                            </div>
                      <a className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1 datos-bancarios-btn" href={ `${pdfdatos.file_name_eng}`} rel="noopener noreferrer" target="_blank" >

                                    
                                    Download your bank data
                                
                                  </a>
                      </div>

                      </div>

                      {deposit.amount !== '' ?
                       <div className="animation-op " >
                       <h2 className="display-currency text-cente">
                       <p>Usted está haciendo un deposito con el monto <span className="currency-bold"><NumberFormat value={deposit.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/> </span>  </p>
                       </h2>
               </div> :null
                  
                    }

              </div> 




              <div className="grid-form">

                <div>

                      <label className="deposito-label-between" for="youSend">
                        <p>Monto <span className="text-danger">*</span></p>
                        <p>Elegir Moneda <span className="text-success"> *  </span></p>
                      </label> 

                      <input type="text" 
                        placeholder="Digite el monto"
                        id="amount"
                        name="amount"
                        onChange={onChange}
                        value={deposit.amount} 
                        required
                        className="form-control"      
                        />
                

                </div>

                <div>

                      <label>Cargar el comprobante <span className="text-danger">*</span> </label>
                      
                      <div className="custom-file-panther">
                      
                      {/* { kyc ? <p className="text-white text-center bg-info">  {kyc.file_selfie}</p> : null} */}
                      <label className="panther-input-file label-retiro " htmlFor="file"> 
                      <i className="tim-icons icon-paper p-2" />  Ejem .JPG .PDF 'Tamaño Maximo del archivo: 4mb'</label>
                       { deposit.file ? <p> <i className="tim-icons icon-paper p-2" /> Archivo cargado </p> :null }
                      <input type="file"
                          id="file"
                          name="file"
                          onChange={onChange}
                          value={deposit.file} 
                          required
                      className="custom-file-input-panther form-control"/>
                      </div>

                      
                  
                </div>
                
              </div>

              

            
            
         
           
             
            
          


          {
            demo_status === "active" ? 
            <CardFooter>
          <div className="row justify-content-center">
                 
                 <div  className="col-6 text-center">
                 Esto es una plataforma de demostración, el saldo y las operaciones no son reales.
                   <button className=" btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  type="submit" disabled>
                   {guardar}
                 </button>
                 </div>
            </div>
          </CardFooter> :
          <CardFooter>
          <div className="row justify-content-center">
                 
                 <div  className="col-6 text-center">
                 
                   <button className=" btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  type="submit" >
                   {guardar}
                 </button>
                 </div>
            </div>
          </CardFooter>

          }


          
          
        
      </>
      :null}  

      { deposit.type === "2" ? 
        
      
        <div className="animation-op"> 
        
        
         
          
            
     
     

              
                  
        <AduGenerator
          usuario={usuario}
          usuarioAutenticado={usuarioAutenticado}
          montosave={montosave}
          guardarMontoSave={guardarMontoSave}
          guardarBtnDinamic={guardarBtnDinamic}
          btndinamic={btndinamic}
          montosavereset={montosavereset}
          guardarContrato={guardarContrato}
          contrato={contrato}
          participaciones={participaciones}
          guardarParticipacion={guardarParticipacion}
          guardarMultiplicador={guardarMultiplicador}
          multiplicador={multiplicador}
          /> 


          <DepositoAdu

            usuario={usuario} 
                                                              
            montosave={montosave}
            guardarMontoSave={guardarMontoSave}
            

            guardarContrato={guardarContrato}
            guardarBtnDinamic={guardarBtnDinamic}
            guardarParticipacion={guardarParticipacion}
            multiplicador={multiplicador}
          
          />

               
                  
                  
               
             
                  
              

                

                

              
           
             
            
          
          
          
        
      </div> :null} 





                   


                        


                      




        
</div>

</div> 
        
      <div className="row d-flex justify-content-center ">
     

        <Col md="12" className="deposito-col-box d-none"> 
          <Card className="mb-1">
            <CardHeader>
              

              <Row className="mt-2 d-none">
                  <Col  md="12">
                    {/* <label className="mt-2">Selecciona método de recarga <span className="text-danger">*</span></label> */}
                  <select  className="custom-select form-control "  
                           id="type"
                          name="type"
                          onChange={onChange}
                          value={deposit.type} 
                          onClick={ () => {
                            guardarPin(null),
                            guardarBtnRemesas(true),
                            TodosDepositos(),
                            guardarDeposit({
                              ...deposit,
                              amount:''
                              })
                          }}

                  
                  >
                
                  
                  <option value="" className="text-center"> Todos mis depósitos </option>
                  <option value="1" className="text-center" selected 
                  onClick={ () => {
                    guardarPin(null),
                    guardarBtnRemesas(true),
                    guardarDeposit({
                      ...deposit,
                      amount:''
                      })
                  }} >Transferencia Bancaria Internacional</option>
              <option value="2" className="text-center" >ACH / PANAMÁ</option>  

                  {/* <option value="4" className="text-center" onClick={ () => {
                    guardarPin(null),
                    guardarBtnRemesas(true),
                    guardarDeposit({
                      ...deposit,
                      amount:''
                      })
                  }} >ACH / USA </option>  */}

                  {/* <option value="3" className="text-center" onClick={ () => {
                    guardarPin(null),
                    guardarBtnRemesas(true),
                    guardarDeposit({
                      ...deposit,
                      amount:''
                      })
                  }}>Remesa</option> */}
                  <option value="5" className="text-center" onClick={ () => {
                    guardarPin(null),
                    guardarBtnRemesas(true),
                    guardarDeposit({
                      ...deposit,
                      amount:''
                      })
                  }}>PayPal</option>
                    
                  
                  
               
                </select>
                  </Col>
              
                  
                </Row>  



              
              <p className="retiro-type-fix">Seleccione el tipo de depósito a realizar</p>
            </CardHeader>
            <CardBody> 
            {/* onChange={onChange} value={deposit.type}  id="type" name="type" */}
            <div className="grid-container grid-container-depositos" > 

              <div onChange={onChange} value="4" className={deposit.type === "" ? 'active-selection' : '' }   onClick={ () => { guardarDeposit({...deposit, type:'',amount:''}),TodosDepositos() }} >
              <p >Todos mis depósitos</p> 
              <span>Listado de depósitos </span>


              </div>

              
                
              <div value="1"    className={deposit.type === "1" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarDeposit({...deposit, type:'1',amount:''}),
                guardarPin(null),
                guardarBtnRemesas(true)
                 }}> 
                <i class="tim-icons icon-bank panther-icon-style">
                  </i> <p>Transferencia bancaria internacional</p> 
                  
              </div>

              <div value="2"    className={deposit.type === "2" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarDeposit({...deposit, type:'2',amount:''}),
                guardarPin(null),
                guardarBtnRemesas(true)
                 }}> 
                <i class="tim-icons icon-bank panther-icon-style">
                  </i> <p>ACH / PANAMÁ</p> 
                  
              </div>


              <div value="5"    className={deposit.type === "5" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarDeposit({...deposit, type:'5',amount:''}),
                guardarPin(null),
                guardarBtnRemesas(true) 
                }}> <i class="tim-icons icon-bank panther-icon-style"></i> <p>PayPal</p> </div>


              </div> 

              
             

           
              
            </CardBody>
       
          
          </Card>

        
          

        </Col> 




        
      </div>
        
    </div>  



    <ModalTodosDepositos
                              className={classes.modal}
                              open={openlist}
                              handleCloseList={handleCloseList}
                              modalInfo={modalInfo}
                              BackdropComponent={Backdrop}
                              BackdropProps={{
                                timeout: 900,
                              }}
                              >
                              <Fade in={openlist}
                              timeout={154}
                              handleCloseList={handleCloseList}
                              
                              
                              >  


                <div className="container-grid deposito-modal-informacion">
                              <div  className={`modal-kyc-welcome padding-modal`}> 
                              
                                      <div className="bg-modal-header">   
                                          <div  className=" fix-cuentaspropias-title ">
                                            <h5 className="title display-4 mb-0">Depósito <p> #{modalInfo.id}  </p> </h5>
                                          </div>

                                          <div >
                                            <div className="cancel-box-btn">
                                                <div className="cancel-btn-panther" onClick={ () => (
                                                  handleCloseList()
                                                )}> 
                                                    <i className="tim-icons icon-simple-remove"/>
                                                </div>
                                            </div>
                                          </div>    
                                      </div>

                                      <div className="form-group form-control-deposito-container">
                                          <div className="modal-grid-row"> 

                                                    <div> 

                                                      <p> 
                                                      Monto 
                                                  <span>{modalInfo.monto}</span>
                                                      </p>
                                                       
                                                    </div>

                                                    <div>

                                                    <p> 
                                                        
                                                    Moneda 
                                                    <span>{modalInfo.currency_tag}</span>
                                                      </p>

                                                       
                                                    </div>



                                                    <div>

                                                    <p> 
                                                    Fecha 
                                                    <span>{modalInfo.fecha}</span>
                                                        
                                                        </p>
                                                          
                                                    </div>


                                                    <div>

                                                    <p> 
                                                      Tipo de depósito 
                                                      <span>{modalInfo.tipo}</span>
                                                        
                                                        </p>

                                                          
                                                    </div>

                                                

                                                    <div> 

                                                    <p> 
                                                    Estado de depósito 
                                                    <span> {modalInfo.estado}</span>
                                                        
                                                        </p>
                                                    
                                                    </div>


                                          </div>
                                      </div>
                              
                              </div>
                              </div>

                              </Fade>
                              
                              </ModalTodosDepositos> 




  





{ mensaje ? <Modal
                                
                                className={classes.modal}
                                open={open} 
                                mensaje={mensaje}
                                
                                
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 900,
                                }}
                              >
                                <Fade in={mensaje}
                                timeout={154}
                                >     
                      <div className="container-grid">
                              <div  className={`modal-kyc-welcome padding-modal`}> 
                                  <div className="form-group form-control-deposito-container">
                                      <div className="modal-grid-row"> 

                                      <div> 
                                            <p className="text-center display-4 title my-3 font-light-weight-modal"> {mensaje}</p>

                                      </div>

                                      <div>
                                         <button className="  btn mt-0 btn-block btn-info my-2 btn-panther-power" onClick={ () => (
                                                                guardarCargando(false),
                                                                TodosDepositos(),
                                                                guardarMensaje(null),
                                                                handleClose(),
                                                                guardarDeposit({
                                            
                                                                  amount:'',
                                                                  type:'',
                                                                  file:'',
                                                                  note:'',
                                                                bank:'',
                                                                fecha:'',
                                                            }) 
                                                                )}>
                                                                    Aceptar
                                          </button>
                                      </div>
                                      </div>    
                                      </div>
                                      </div>        
                      </div>
                                                        </Fade>
                                                      </Modal> : null}



<ModalDireccionesMaster 
      opendireccion={opendireccion}
      handleCloseDireccion={handleCloseDireccion}
      handleOpenDireccion={handleOpenDireccion}

/> 





  </>


    
  );
}
 
export default Deposito;


