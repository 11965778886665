import React, { useState } from 'react';
import Id from './Id'
import Bank from './Bank'
import Poa from './Poa'
import Selfie from './Selfie'




const KycContainer = ({usuario , handleOpen , guardarMensaje}) => {





    const activeID = () => {
        guardarStep(
            {...step, 
            primero:true,
            segundo:false,
            tercero:false,
            cuarto:false,
            }
            )
    }

    const activePoa = () => {
        guardarStep(
            {...step, 
            primero:false,
            segundo:true,
            tercero:false,
            cuarto:false,
            }
            )
    }

    const activeSelfie = () => {
        guardarStep(
            {...step, 
            primero:false,
            segundo:false,
            tercero:true,
            cuarto:false,
            }
            )
    }

    const activeBank = () => {
        guardarStep(
            {...step, 
            primero:false,
            segundo:false,
            tercero:false,
            cuarto:true,
            }
            )
    }


    const [step, guardarStep] = useState({
        primero:true,
        segundo:false,
        tercero:false,
        cuarto:false,

    })

    return ( <>


        {/* <h2 className="text-dark grid-kyc-text"> ¡Vamos a conocernos! </h2> */}

        <p className="text-seleccion">
            <strong> 
            Seleccione con un click el documento a ingresar  
            </strong>

            <span> <i className="tim-icons icon-tap-02"></i></span>

        </p>

        <div className="app-bar-grid my-2">

            <p className={`   ${step.primero === true ? 'nav-active-prisma-profile  on-click-active ':' on-click-active ' }    `} onClick={activeID} >  <span> <i class="tim-icons icon-badge"></i></span> <strong>  Documento de Identificación </strong></p>
            <p className={` ${step.segundo === true ? 'nav-active-prisma-profile  on-click-active ':' on-click-active ' }    `}  onClick={activePoa} >  <span> <i class="tim-icons icon-square-pin"></i></span> <strong>  Prueba de dirección residencial </strong></p>
            <p className={` ${step.tercero === true ? 'nav-active-prisma-profile  on-click-active ':' on-click-active ' }    `}  onClick={activeSelfie} >  <span> <i class="tim-icons icon-camera-18"></i></span> <strong>  Foto Selfie </strong></p>
            <p className={` ${step.cuarto === true ? 'nav-active-prisma-profile  on-click-active ':' on-click-active ' }    `}  onClick={activeBank} >  <span> <i class="tim-icons icon-paper"></i></span> <strong>  Comprobante bancario </strong></p>

        </div>

        <div className="grid-kyc ">

            
            

            <div className={` ${step.primero === true ? 'd-grid':'d-none' }    `} >
                <Id usuario={usuario}  handleOpen={handleOpen}  guardarMensaje={guardarMensaje} />
            </div>


            <div className={` ${step.segundo === true ? 'd-grid':'d-none' }    `} >
                <Poa usuario={usuario} handleOpen={handleOpen}  guardarMensaje={guardarMensaje} />
            </div>


            <div className={` ${step.tercero === true ? 'd-grid':'d-none' }    `} >
                <Selfie usuario={usuario} handleOpen={handleOpen}  guardarMensaje={guardarMensaje} />
            </div>

            <div className={` ${step.cuarto === true ? 'd-grid':'d-none' }    `} >
                <Bank usuario={usuario} handleOpen={handleOpen}  guardarMensaje={guardarMensaje} />
            </div>


        </div>
    
            </>
     );
}
 
export default KycContainer;