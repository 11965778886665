import React, { useEffect } from 'react';
import CambiarPassword from '../components/Profile/CambiarPassword';


const PrismaActiveBarLogical = ({
    usuario,appbarkyc,ActivarPrimeroKyc,ActivarSecundoKyc,ActivarTerceroKyc,
    ActivarCuartoKyc,ActivarQuintoKyc,ActivarSextoKyc,}) => {

        useEffect(() => {

            
        
                if(usuario.paso === 0) {
                    ActivarPrimeroKyc()
                }

                if(usuario.paso === 1) {
                    ActivarSecundoKyc()
                }

                if(usuario.paso === 2) {
                    ActivarTerceroKyc()
                }

                if(usuario.paso === 3) {
                    ActivarCuartoKyc()
                }

                if(usuario.paso === 4) {
                    ActivarQuintoKyc()
                }

                if(usuario.paso === 5) {
                    ActivarSextoKyc()
                }


                
    

        }, [usuario])

    return (  

            <>      

                        { usuario.paso === 0 ? 


                        <div className="profile-prisma-body">
                        <div className="grid-wizard-bar">


                            <p className={` on-click-active ${appbarkyc.primero === 1 ? 'nav-active-prisma-profile':''}`}

                            onClick={ActivarPrimeroKyc}
                            > 
                                                                
                                                                <span>
                                                                    1
                                                        
                                                        </span> 
                                                                
                                                                <strong> Configuración de cuenta</strong></p>

                            <p className={ ` ${appbarkyc.segundo === 1 ? 'nav-active-prisma-profile':''}`}

                                
                            
                            >
                                
                                <span>2</span> 
                                
                                
                                <strong>Centro de verificación</strong></p>

                            <p className={`${appbarkyc.tercero === 1 ? 'nav-active-prisma-profile':''}`}
                                
                                >
                                    
                                    <span>3</span>
                                    
                                    
                                <strong>Contratos</strong></p>

                            <p className={` ${appbarkyc.cuarto === 1 ? 'nav-active-prisma-profile':''}`}
                            
                            >
                                
                                <span>4</span>
                                
                            <strong>Cuenta bancaria</strong>
                                
                            
                            
                            </p>


                            <p className={` ${appbarkyc.quinto === 1 ? 'nav-active-prisma-profile':''}`}
                            
                            
                            > 
                            
                            
                            <span>5</span> 
                            
                            
                            <strong>Depósito inicial</strong>
                            
                            </p>

                            <p className={` ${appbarkyc.sexto === 1 ? 'nav-active-prisma-profile':''}`}
                                
                                >
                                    
                                    
                                    <span>6</span>
                                    
                                    
                                    <strong>Aprobación</strong></p>




                            <CambiarPassword />

                            
                        </div>

                        </div> 
                        :null




                        } 




                    { usuario.paso === 1 ? 


                                    <div className="profile-prisma-body">
                                    <div className="grid-wizard-bar">


                                        <p className={` on-click-active-agree ${appbarkyc.primero === 1 ? 'nav-active-prisma-profile-agree':''}`}

                                        onClick={ActivarPrimeroKyc}
                                        > 
                                                                            
                                                                            <span>
                                                                                <i className="tim-icons icon-check-2">
                                                                    </i>
                                                                    
                                                                    </span> 
                                                                            
                                                                            <strong> Configuración de cuenta</strong></p>

                                        <p className={ `on-click-active ${appbarkyc.segundo === 1 ? 'nav-active-prisma-profile':''}`}

                                            onClick={ActivarSecundoKyc}
                                        
                                        >
                                            
                                            <span>2</span> 
                                            
                                            
                                            <strong>Centro de verificación</strong></p>

                                        <p className={`${appbarkyc.tercero === 1 ? 'nav-active-prisma-profile':''}`}
                                            
                                            >
                                                
                                                <span>3</span>
                                                
                                                
                                            <strong>Contratos</strong></p>

                                        <p className={` ${appbarkyc.cuarto === 1 ? 'nav-active-prisma-profile':''}`}
                                        
                                        >
                                            
                                            <span>4</span>
                                            
                                            
                                            
                                        
                                        <strong>Cuenta bancaria</strong>
                                        </p>


                                        <p className={` ${appbarkyc.quinto === 1 ? 'nav-active-prisma-profile':''}`}
                                        
                                        
                                        > 
                                        
                                        
                                        <span>5</span> 
                                        
                                        
                                        <strong>Depósito inicial</strong>
                                        </p>

                                        <p className={` ${appbarkyc.sexto === 1 ? 'nav-active-prisma-profile':''}`}
                                            
                                            >
                                                
                                                
                                                <span>6</span>
                                                
                                                
                                                <strong>Aprobación</strong></p>




                                        <CambiarPassword />

                                        
                                    </div>

                                    </div> 
                                    :null



 
                    } 





                    { usuario.paso === 2 ? 


                    <div className="profile-prisma-body">
                    <div className="grid-wizard-bar">


                        <p className={` on-click-active-agree ${appbarkyc.primero === 1 ? 'nav-active-prisma-profile-agree':''}`}

                        onClick={ActivarPrimeroKyc}
                        > 
                                                            
                                                            <span>
                                                                <i className="tim-icons icon-check-2">
                                                    </i>
                                                    
                                                    </span> 
                                                            
                                                            <strong> Configuración de cuenta</strong></p>

                        <p className={ `on-click-active-agree ${appbarkyc.segundo === 1 ? 'nav-active-prisma-profile-agree':''}`}

                            onClick={ActivarSecundoKyc}
                        
                        >
                            
                            <span>
                                                                <i className="tim-icons icon-check-2">
                                                    </i>
                                                    
                                                    </span> 
                                                            
                            
                            
                            <strong>Centro de verificación</strong></p>

                        <p className={` on-click-active ${appbarkyc.tercero === 1 ? 'nav-active-prisma-profile':''}`}
                                    onClick={ActivarTerceroKyc}

                            
                            >
                                
                                <span>3</span>
                                
                                
                            <strong>Contratos</strong></p>

                        <p className={` ${appbarkyc.cuarto === 1 ? 'nav-active-prisma-profile':''}`}
                        
                        >
                            
                            <span>4</span>
                            
                            
                            
                        
                        <strong>Cuenta bancaria</strong>
                        </p>


                        <p className={` ${appbarkyc.quinto === 1 ? 'nav-active-prisma-profile':''}`}
                        
                        
                        > 
                        
                        
                        <span>5</span> 
                        
                        
                        <strong>Depósito inicial</strong>
                        
                        </p>

                        <p className={` ${appbarkyc.sexto === 1 ? 'nav-active-prisma-profile':''}`}
                            
                            >
                                
                                
                                <span>6</span>
                                
                                
                                <strong>Aprobación</strong></p>




                        <CambiarPassword />

                        
                    </div>

                    </div> 
                    :null




                    } 




                    { usuario.paso === 3 ? 


                    <div className="profile-prisma-body">
                    <div className="grid-wizard-bar">


                        <p className={` on-click-active-agree ${appbarkyc.primero === 1 ? 'nav-active-prisma-profile-agree':''}`}

                        onClick={ActivarPrimeroKyc}
                        > 
                                                            
                                                            <span>
                                                                <i className="tim-icons icon-check-2">
                                                    </i>
                                                    
                                                    </span> 
                                                            
                                                            <strong> Configuración de cuenta</strong></p>

                        <p className={ `on-click-active-agree ${appbarkyc.segundo === 1 ? 'nav-active-prisma-profile-agree':''}`}

                            onClick={ActivarSecundoKyc}
                        
                        >
                            
                            <span>
                                                                <i className="tim-icons icon-check-2">
                                                    </i>
                                                    
                                                    </span> 
                            
                            
                            <strong>Centro de verificación</strong></p>

                        <p className={` on-click-active-agree ${appbarkyc.tercero === 1 ? 'nav-active-prisma-profile-agree':''}`}
                                    onClick={ActivarTerceroKyc}

                            
                            >
                                
                                <span>
                                                                <i className="tim-icons icon-check-2">
                                                    </i>
                                                    
                                                    </span> 
                                
                                
                            <strong>Contratos</strong></p>

                        <p className={` on-click-active ${appbarkyc.cuarto === 1 ? 'nav-active-prisma-profile':''}`}
                        onClick={ActivarCuartoKyc}
                        
                        >
                            
                            <span>4</span>
                            
                            
                            
                            <strong>Cuenta bancaria</strong>
                        
                        </p>


                        <p className={` ${appbarkyc.quinto === 1 ? 'nav-active-prisma-profile':''}`}
                        
                        
                        > 
                        
                        
                        <span>5</span> 
                        
                        
                        
                        <strong>Depósito inicial</strong>
                        
                        </p>

                        <p className={` ${appbarkyc.sexto === 1 ? 'nav-active-prisma-profile':''}`}
                            
                            >
                                
                                
                                <span>6</span>
                                
                                
                                <strong>Aprobación</strong></p>




                        <CambiarPassword />

                        
                    </div>

                    </div> 
                    :null




                    }   



                            { usuario.paso === 4 ? 
                                            <div className="profile-prisma-body">
                                            <div className="grid-wizard-bar">


                                                <p className={` on-click-active-agree ${appbarkyc.primero === 1 ? 'nav-active-prisma-profile-agree':''}`}

                                                onClick={ActivarPrimeroKyc}
                                                > 
                                                                                    
                                                                                    <span>
                                                                                        <i className="tim-icons icon-check-2">
                                                                            </i>
                                                                            
                                                                            </span> 
                                                                                    
                                                                                    <strong> Configuración de cuenta</strong></p>

                                                <p className={ `on-click-active-agree ${appbarkyc.segundo === 1 ? 'nav-active-prisma-profile-agree':''}`}

                                                    onClick={ActivarSecundoKyc}
                                                
                                                >
                                                    
                                                    <span>
                                                                                        <i className="tim-icons icon-check-2">
                                                                            </i>
                                                                            
                                                                            </span> 
                                                    
                                                    
                                                    <strong>Centro de verificación</strong></p>

                                                <p className={`on-click-active-agree ${appbarkyc.tercero === 1 ? 'nav-active-prisma-profile-agree':''}`}
                                                            onClick={ActivarTerceroKyc}

                                                    
                                                    >
                                                        
                                                        <span>
                                                                                        <i className="tim-icons icon-check-2">
                                                                            </i>
                                                                            
                                                                            </span> 
                                                        
                                                        
                                                    <strong>Contratos</strong></p>

                                                <p className={`on-click-active-agree ${appbarkyc.cuarto === 1 ? 'nav-active-prisma-profile-agree':''}`}
                                                onClick={ActivarCuartoKyc}
                                                
                                                >
                                                    
                                                    <span>
                                                                                        <i className="tim-icons icon-check-2">
                                                                            </i>
                                                                            
                                                                            </span> 
                                                    
                                                    
                                                    
                                                
                                                <strong>Cuenta bancaria</strong>
                                                </p>


                                                <p className={`on-click-active ${appbarkyc.quinto === 1 ? 'nav-active-prisma-profile':''}`}
                                                    onClick={ActivarQuintoKyc}
                                                
                                                > 
                                                
                                                
                                                <span>5</span> 
                                                
                                                
                                                <strong>Depósito inicial</strong>
                                                
                                                </p>

                                                <p className={` ${appbarkyc.sexto === 1 ? 'nav-active-prisma-profile':''}`}
                                                    
                                                    >
                                                        
                                                        
                                                        <span>6</span>
                                                        
                                                        
                                                        <strong>Aprobación</strong></p>




                                                <CambiarPassword />

                                                
                                            </div>

                                            </div> 
                                            :null




                                            } 


                                            { usuario.paso === 5 ? 
                                            <div className="profile-prisma-body">
                                            <div className="grid-wizard-bar">


                                                <p className={` on-click-active-agree ${appbarkyc.primero === 1 ? 'nav-active-prisma-profile-agree':''}`}

                                                onClick={ActivarPrimeroKyc}
                                                > 
                                                                                    
                                                                                    <span>
                                                                                        <i className="tim-icons icon-check-2">
                                                                            </i>
                                                                            
                                                                            </span> 
                                                                                    
                                                                                    <strong> Configuración de cuenta</strong></p>

                                                <p className={ `on-click-active-agree ${appbarkyc.segundo === 1 ? 'nav-active-prisma-profile-agree':''}`}

                                                    onClick={ActivarSecundoKyc}
                                                
                                                >
                                                    
                                                                    <span>
                                                                                        <i className="tim-icons icon-check-2">
                                                                            </i>
                                                                            
                                                                            </span> 
                                                    
                                                    
                                                    <strong>Centro de verificación</strong></p>

                                                <p className={`on-click-active-agree ${appbarkyc.tercero === 1 ? 'nav-active-prisma-profile-agree':''}`}
                                                            onClick={ActivarTerceroKyc}

                                                    
                                                    >
                                                        
                                                        <span>
                                                                                        <i className="tim-icons icon-check-2">
                                                                            </i>
                                                                            
                                                                            </span> 
                                                        
                                                        
                                                    <strong>Contratos</strong></p>

                                                <p className={`on-click-active-agree ${appbarkyc.cuarto === 1 ? 'nav-active-prisma-profile-agree':''}`}
                                                onClick={ActivarCuartoKyc}
                                                
                                                >
                                                    
                                                    
                                                
                                                <span>
                                                                                        <i className="tim-icons icon-check-2">
                                                                            </i>
                                                                            
                                                                            </span> 
                                                
                                                
                                                <strong>Cuenta bancaria</strong>
                                                
                                                </p>


                                                <p className={`on-click-active-agree ${appbarkyc.quinto === 1 ? 'nav-active-prisma-profile-agree':''}`}
                                                    onClick={ActivarQuintoKyc}
                                                
                                                > 
                                                
                                                
                                                <span>
                                                                                        <i className="tim-icons icon-check-2">
                                                                            </i>
                                                                            
                                                                            </span> 
                                                    
                                                    
                                                    
                                                <strong>Depósito inicial</strong>
                                                
                                                
                                                </p>

                                                <p className={`on-click-active ${appbarkyc.sexto === 1 ? 'nav-active-prisma-profile':''}`}
                                                        onClick={ActivarSextoKyc}
                                                    >
                                                        
                                                        
                                                        <span>6</span>
                                                        
                                                        
                                                        <strong>Aprobación</strong></p>




                                                <CambiarPassword />

                                                
                                            </div>

                                            </div> 
                                            :null




                                            }










            



            </>







    );
}
 
export default PrismaActiveBarLogical;