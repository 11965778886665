import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../../context/autenticacion/authContext';

const RutaPrivada = ({ component: Component, ...props  }) => {

    const authContext = useContext(AuthContext);
    const { Movimientos, autenticado, Saldos, usuarioAutenticado, userStatus,ListaNacionales, ListaInternacionales, ListaRemesas,TodosRetiros, TodosDepositos,TodosPdfDepositos,TodosOfertas,TodosOferentes, TodosInversiones,TodosInversionesVendidas,TodosProyectosSecundario,
        TodosProyectosPrimario, PrismaNoLoad, PrismaLoad, PrismaLoadApp, Faq, galleryAduClient,LastContract, collapse, ExpandNav, CollapseNav } = authContext;

    useEffect(() => {

        


        { collapse === true ?  ExpandNav(): CollapseNav()}
        PrismaLoadApp();
        PrismaLoad();
        userStatus();
        usuarioAutenticado();
        LastContract();
        galleryAduClient();
         ListaNacionales();
         ListaInternacionales();
         ListaRemesas();
         TodosRetiros();
         TodosDepositos();
        //  TodosPdfDepositos();
         Saldos();
         Movimientos();
         Faq();
         

        //  if(collapse === true) {
        //      CollapseNav()
        //     }
        //     if(collapse === false) {
        //         ExpandNav()
        //         }
            
            
         
         
         
        // TodosOfertas();
        // TodosOferentes();
        // TodosInversiones();
        // TodosInversionesVendidas();
        // TodosProyectosSecundario();
        // TodosProyectosPrimario();
        
        

        // eslint-disable-next-line
    }, []);

    return ( 
        <Route { ...props } render={ props => !autenticado ?  (
            <Redirect to="/" />
        )  : ( 
            <Component {...props} />
        ) } />

     );
}
 
export default RutaPrivada;