import React, {useContext,useEffect, useState} from "react";
import AuthContext from  '../../context/autenticacion/authContext';

import styled from 'styled-components'

const PrismaLogoStatic = styled.div `

@media screen and (min-width:992px) and (max-width:8000px) {
  display:none !important;
}




`;


const PrismaLogoDinamic = styled.div `

@media screen and (min-width:0px) and (max-width:992px) {
  display:none !important;
}


`;



const PrismaLogo = () => {

    const authContext = useContext(AuthContext);
    const { collapse,
        CollapseNav,
        ExpandNav 
    
    
    } = authContext; 

    const open = () => {
        if (collapse === true) {
            CollapseNav()
        }

        if (collapse === false) {
            ExpandNav()
        }
    }


    return ( 
            <>
            <PrismaLogoDinamic>
            <div className="photo logo-prisma-hover" onClick={open} data-tooltip={`${collapse === true ? 'Expandir' :'Ocultar'}`}>
                      <img alt="..." src={require("assets/img/anime3.png")} />
            </div> 
            </PrismaLogoDinamic>


            <PrismaLogoStatic>
            <div className="photo ">
                      <img alt="..." src={require("assets/img/anime3.png")} />
            </div> 
            </PrismaLogoStatic>

            </> 
    
    );
}
 
export default PrismaLogo;