import React, { Fragment, useContext , useEffect, useState, useMemo } from 'react';
import Inversion from './Inversion';
import clienteAxios from '../../config/axios';
import DataTable, {theme} from 'react-data-table-component'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Link } from 'react-router-dom';

import { Doughnut } from "react-chartjs-2";

import styled from 'styled-components'


// importacion del modal
import Modal from '@material-ui/core/Modal' 

import ModalMensaje from '@material-ui/core/Modal' 


import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 



import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'; 

/// react table import

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import * as ReactBootStrap from 'react-bootstrap';  

import Spinner from '../spinners/Spinner'
/// vamos a poner el tipo de dato
import PropTypes from 'prop-types';

import AuthContext from '../../context/autenticacion/authContext'


import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Container,
} from "reactstrap"; 


// aqui solo aparece el responsive pequenho
const DivMobile = styled.div `
padding: 0rem !important;
margin: 0.2rem 0rem 2.0rem 0rem !important;
display:flex !important;
flex-direction:row;
justify-content:center;





@media(min-width:992px) {
  display:none !important;
  justify-content:center;
}



`;

// pantalla grande
const Divdesktop = styled.div `

display:none !important;






@media(min-width:992px) {
  display:block !important;
}



`; 





//// responsive
const Div = styled.div `
  padding: 0rem !important;
  margin: 0.2rem 0rem 2.0rem 0rem !important;
  display:flex !important;
  flex-direction:row;
  justify-content:center;
  
  
  
  
  
  @media(min-width:992px) {
    display:none !important;
    justify-content:center;
  }



`;

// pantalla grande
const DivModal = styled.div `

  display:none !important;
  flex-direction:row;
  
  
  
  
  
  @media(min-width:992px) {
    display:flex !important;
  }



`; 

/// aqui vamos a poner los estilos para el tap 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#00ff9d',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);





/// aqui van los estilos de los tabs
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    alignItems:'center',
    marginRight: theme.spacing(0),
    borderRadius:'30px',
    transition:'all 500ms ease',
    padding:'0px 5px 0px 5px',
     margin:'0px 4px 0px 4px',
     alignItems:'center',
  
    '&:focus': {
      opacity: 0.8,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#005eff',
      borderRadius:'30px'
      
      
    },
    '&:hover': {
      opacity: 0.6,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#000',
      borderRadius:'30px'
    },

  }
  
  ,
}))((props) => <Tab disableRipple {...props} />);
///// aqui terminan los estilos del tap





function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));







/// chart
let chartPie = {
  data4: (canvas) => {
    let ctx = canvas.getContext("2d");
    
    
    let modalInfo = [];
    let enable = [];
    let investment = [];
    
    // for (const dataObj of modalInfo) {
    //   enable.push(parseInt(dataObj.percentage_enable))
    //   investment.push(parseInt(dataObj.percentage_investment))
    // }
    

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    
    

    return {
      type: 'doughnut',
      
      datasets: [
        {
          label: "Data",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: [10,80 ],
          weight: 300,
        }
      ] ,

      labels: ['Monto Disponible','Monto Invertido'],


    };
  },
  options: chart_pie_options
}; 



/// ahora va el option del chart
let chart_pie_options = {
  maintainAspectRatio: true,
  legend: {
    display: true
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        DoughnutPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "#00ffc33d",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ],
    xAxes: [
      {
        Percentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ]
  }
};







const ListaInversion = () => { 

  ///aqui vamos a exrportar el context
  const authContext = useContext(AuthContext);
  const { TodosProyectosPrimario, TodosProyectosSecundario, TodosOferentes, TodosOfertas,TodosInversiones,TodosInversionesVendidas, mis_inversiones_activas, mis_inversiones_activas_totales_paginas,filtro_inversiones } = authContext;  

    // aqui vamos a crear el stado para las taps en responsive

    
    
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  
  
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    }; 


  
const [ apiload, guardarApiLoad] = useState(true);

const ApiLoad = () => {
  guardarApiLoad(false);
  setTimeout(() => {
    guardarApiLoad(true);
},50);
  
  
}


  /// aqui estamos colocando el state 

  const [ elcargando, elGuardarCargando] = useState(false);

    const elguardar = ( elcargando ) ? <Spinner/> :  
                                            
    <Fragment>
      ¡VENDER AHORA!
      </Fragment> 
  
  




  const [proyectoti, guardarProyectotis] = useState([{
    description:'',
    imagen:'',
    nrocontrato:'',
    currency_code:'',
    name:'',
    tax_referential_client:'',
    end_date:'',


  }])

  const [inversiones, guardarInversiones] = useState([{
    mercado_secundario:'',
    amount:'', 
    amount_investment:'', 
    amount_invertido:'',
    amount_reserved:'', 
    approval_date:'', 
    approved_by:'', 
    category:'', 
    category_id:'', 
    amount_proyecto:'',
    code:'', 
    contract_type:'', 
    country_iso:'', 
    created_at:'', 
    created_by:'',
    cuenta_negociador:'',
     currency_code:'', 
     date_max_investment:'', 
     delay_percentage:'', 
     email_sent:'', 
     end_date:'', 
     fee_total_contract:'', 
     file:'', 
     finished_date:'', 
     frequency_payment:'', 
    id:'', 
    issuer:'', 
    loan_type:'', 
    min_amount_start:'',
    name:'' , 
    nrocontrato:'', 
    oferente:'', 
    oferente_id:'', 
    quantity:'', 
    quantity:'', 
    risk:'', 
    start_date:'',
    status:'', 
    tax_price_cuota:'', 
    tax_referential_client:'', 
    tax_referential_panther:'',
    total_inversores:'', 
    updated_at:'', 
    updated_by:'', 
    warranty:'',
    disponible: '',
    rendimiento: '',
    vender:'',
    saldo:'',
    investment_amount:'',
    offers_amount:'',
    disponiblewallet:'',
    project:{
      description:'',
      imagen:'',
      nrocontrato:'',
      currency_code:'',
      name:'',
      tax_referential_client:'',
      end_date:'',
  
  
    },
    redimientofuturo:'',
  }]); 

     /// vamos a hacer la busqueda

     const [ busqueda, guardarBusqueda ] = useState({
      proyecto:'',
      vencimiento:'',
      monto:'',
      
  });
  
  
  
  const [ proyecto, guardarProyecto ] = useState([]);
  
  
  
 /// aqui estamos colocando el contador de pagina, numero
 const [ paginaactualcount, guardarPaginaActualCount ] = useState(1);
 // aqui estamos asignando el contador numero a la variable,mediante una cadena de  texto
 const [ paginaactual, guardarPaginaActual ] = useState({
    page:paginaactual,
   
 }); 

 

 const busquedaop = {...busqueda, ...paginaactual } ;

 const [ pdf, guardarDocumentacionPdf ] = useState({
   
   pdf:'',

 })
 const [ csv, guardarDocumentacionCsv ] = useState({
   
   csv:'',

 })

   
const [totales, guardarTotales ] = useState({
  cantidad:'',
  total_monto:'',
  total_rendimiento:'',
  total_saldo:'',
  
});
  
  
  
  
  


  useEffect( (props) => { 

    const consultarApiFiltros = async () => { 


      guardarProyecto(filtro_inversiones);
   
     
    }

    consultarApiFiltros();





    const consultarApiInversiones = async () => {
      // const {amount, amount_investment, amount_reserved, 
      //   approval_date, approved_by, category, category_id, 
      //   code, contract_type, country_iso, created_at, created_by,
      //   cuenta_negociador, currency_code, date_max_investment, delay_percentage
      //   ,email_sent, end_date, fee_total_contract, file, finished_date, frequency_payment, 
      //   id, issuer, loan_type, min_amount_start,name , nrocontrato, oferente, oferente_id, quantity, 
      //   risk, start_date, status, tax_price_cuota, tax_referential_client, tax_referential_panther,
      //   total_inversores, updated_at, updated_by, warranty, disponible,rendimiento, project,vender,redimientofuturo,mercado_secundario
      
      // } = inversiones; 

   


      // const respuesta = await clienteAxios.post('/api/client/investments', busquedaop)
      
      
      guardarInversiones(mis_inversiones_activas)
      guardarTotales(mis_inversiones_activas_totales_paginas)

      guardarPaginaActual({
        page:`${paginaactualcount}`,
      }) 

            
     
      
      
    }

    consultarApiInversiones()
    
    
    

  },[!apiload,mis_inversiones_activas,mis_inversiones_activas_totales_paginas]);

  // [busqueda,paginaactual]

  // vamos a hacer la parte de venta
  const [venta, guardarVenta] = useState({

    amount:'',
    type: '',
    date:'',

    investment_id:'',
    amount_percentage:'',
    date_type:'',
    date_value:'',
    rendimiento:'',
    
    
    });  




  // vamos a pasarle el la informacion de la fila al modal
  const [modalInfo, setModalInfo] = useState([]);
  const [modalInfoPro, setModalInfoPro] = useState([]);
  

  

  // vamos a extraer el context de emergencia para guardar los valores de la inversion 
  // despues 

  
  // vamos a hacer que el mensaje salga
  const [mensaje, guardarMensaje] = useState('');

  
   // configuracion del modal de material-ui
   const [ modalStyle ] =useState(getModalStyle);
   const [open, setOpen] =useState(false);

   const classes = useStyles();

   const handleOpen = () => {
       setOpen(true);
   } 

   const handleClose = () => {
       setOpen(false);
       guardarMensaje(null);
   }







  

    // estamos creando el state 
    const [contratomonto, guardarContratoMonto] = useState({
      amount:''
      
      
}); 

  

  const [project_id, guardarProjectid] = useState({
    project_id:''

  })

  const [projectid, guardarProjectidTrue] = useState({
    id:''

  })

  // extraer usuario del state

  const {amount} = parseInt(contratomonto) 
  
 

  const crearVenta = async venta => {
       
    // const {contratomonto,projectid} = venta;
    
   
    
    // var amount=document.getElementById('amount').value;
  
    try {
        const respuesta = await clienteAxios.post('/api/client/offers/create ',venta);
        
      
        elGuardarCargando(false);
      guardarMensaje(respuesta.data.message); 
      

        
    } catch (error) {
      guardarMensaje(error.response.data.message);
    
       
    }
}


  const crearInversion = async inversion => {
       
    const {contratomonto,projectid} = inversion;
    
   
    
    var amount=document.getElementById('amount').value;
  
    try {
        const respuesta = await clienteAxios.post('/api/client/investments/create',{amount,project_id});
        
      
      
      guardarMensaje(respuesta.data.message); 

      setTimeout(() => {
        guardarMensaje(null);
    },5000);
        
    } catch (error) {
      guardarMensaje(error.response.data.message);
      setTimeout(() => {
        guardarMensaje(null);
        setOpen(false);
    },5000);
       
    }
}

const onChangeFilter = e => {

  // voy a colocar guardar usuario en el state

  guardarBusqueda({
      ...busqueda,
      [e.target.name] : e.target.value

  })
}


function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}



  const onChange = e => { 

/// aqui vamos a colocar el calculo
var x = parseFloat(document.getElementById("amount_percentage").value);
      
      document.getElementById("totalarecibir").innerHTML ="0";
      if (x!="")
        { 

            var rendimiento = modalInfo.rendimiento;
            console.log(rendimiento)
            var monto = modalInfo.amount;
            console.log(monto)
            var calculo= (parseFloat(x)*(parseFloat(monto)+parseFloat(rendimiento)) )/ 100
            console.log(calculo)
            console.log(x)

            
            if (!Number.isNaN(calculo))       
            document.getElementById("totalarecibir").innerHTML = currencyFormat(calculo);
        }
        else
        document.getElementById("totalarecibir").innerHTML ="0";



      // voy a colocar guardar usuario en el state
      guardarVenta({
         ...venta,
        [e.target.name] : (e.target.value)
    })


      guardarContratoMonto({
         
          [e.target.name] : parseFloat(e.target.value)
      })
  }


  /// cuando el usuario quiere iniciar seseion
  const onSubmit = e => {
    elGuardarCargando(true);
      e.preventDefault(); 

      

      crearVenta(venta);

  }

   
  const [ mostrar, setMostrar ] = useState(false);
    
    
      
    return (  
    <Fragment> 

    <Row > 
                <Col md="12" className="p-3"> 
                <form  >
                    <Card className="p-4 mb-0">
                            <CardHeader> 
                            <CardTitle className="display-4" tag="h2" >Listado de inversiones activas
                            
                            </CardTitle>
                               
                            </CardHeader>
                            <CardBody>
                                    <Row>
                                        <Col md="6">
                                        <select className="form-control text-center mb-3" 
                                        id="proyecto"
                                        name="proyecto"
                                        onChange={onChangeFilter}
                                        value={busqueda.proyecto} 
                                        onClick={ () => {
                                          ApiLoad();    
                                            }
                                            }> 

                                            <option value=""  onClick={ () => {
                                                  ApiLoad();    
                                                    }
                                                    }> - - -  Nombre del Proyecto - - - </option>
                                         {proyecto.map(proyectos => (
                                            <option
                                                key={proyectos.name}
                                                value={proyectos.name}
                                                onClick={ () => {
                                                  ApiLoad();    
                                                    }
                                                    }

                                            >{proyectos.name}</option>
                                         )
                                         )}
                                            
                                        </select>
                                        </Col>
                        
                                  
                                        <Col md="6">
                                        <select className="form-control  text-center mb-3" 
                                                onChange={onChangeFilter}
                                                value={busqueda.vencimiento}
                                                onClick={ () => {
                                                  ApiLoad();    
                                                    }
                                                    }
                                                id="vencimiento"
                                                name="vencimiento"

                                                >

                                        <option  value="" onClick={ () => {
                                                                                        ApiLoad();    
                                                                                          }
                                                                                          }> - - - Periodo de Vencimiento - - -</option>
                                            <option value="1" onClick={ () => {
                                                                                        ApiLoad();    
                                                                                          }
                                                                                          }>Hoy</option>
                                            <option value="2" onClick={ () => {
                                                                                        ApiLoad();    
                                                                                          }
                                                                                          }> Próximos 7 días</option>
                                            <option value="3" onClick={ () => {
                                                                                        ApiLoad();    
                                                                                          }
                                                                                          }> Próximos 30 días</option>
                                            <option value="4" onClick={ () => {
                                                                                        ApiLoad();    
                                                                                          }
                                                                                          }>Próximos 360 días</option>
                                        </select>
                                            
                                        </Col>
                                    </Row>
                            </CardBody>
                            {/* <CardFooter className="d-flex justify-content-end">
                            <Button  className="btn-block  btn-radius-panther mt-0" color="default" data-toggle="modal" data-target="#acc-config" data-whatever="Editar" type="submit">
                                Buscar
                            </Button>
                            </CardFooter> */}



                    </Card> 
                    </form>
                </Col>
            </Row>
             
      
    
       

       <Row> 


       <Col sm="12" xl="12" lg="12" md="12">
        <Divdesktop> 

        {totales.cantidad > 0 ?  
          
          <>  
          
        <Card className="height-fix-ultimos-movimientos justify-content-center ">
                                                
            <div> 
          

            <CardBody className="class-grid-color-primario header-inversiones-grid " >
                                                

                                                          <div className="grid-container-tabla-inversiones">

                                                                  

                                                                 
                                                                 
                                                                  
                                                          <div className="" data-tooltip="Aquí encontraras el nombre del proyecto">
                                                                <strong className="line-fix">
                                                                <i class="tim-icons icon-bank p-2"/>NOMBRE <br/> DE PROYECTO
                                                                </strong>
                                                                </div>
                                                                <div className="" data-tooltip="Fecha inicio de la inversión"> 
                                                                
                                                                <strong className="line-fix">
                                                                  <i class="tim-icons icon-calendar-60 p-2"/> FECHA DE <br/> INVERSIÓN
                                                                </strong>
                                                                
                                                                </div>
                                                                <div className="" data-tooltip="Fecha de vencimiento de su inversión"> 

                                                                <strong className="line-fix">
                                                                  <i class="tim-icons icon-calendar-60 p-2"/> FECHA FIN <br/> DE INVERSIÓN
                                                                </strong>
                                                                
                                                                </div>
                                                                <div className="" data-tooltip="Monto de la inversión">

                                                                <strong className="line-fix">
                                                                  <i class="tim-icons icon-coins p-2"/> MONTO ( $ )
                                                                </strong>
                                                                
                                                                </div>
                                                                <div className="" data-tooltip="Tasa de rendimiento de todo el proyecto anual"> 
                                                                <strong className="line-fix">
                                                                <i class="tim-icons icon-chart-bar-32 p-2 "/> TASA DE <br/> RENDIMIENTO   ( % )     
                                                                </strong>
                                                                
                                                                </div>
                                                                <div className="" data-tooltip="Dinero recolectado hasta la fecha. ¡tus ganancias!">

                                                                <strong className="line-fix">
                                                                <i class="tim-icons icon-chart-bar-32 p-2"/> RENDIMIENTO <br/> ACTUAL ( $ )
                                                                </strong>
                                                                
                                                                </div> 
                                                                <div className="" data-tooltip="el valor total de tu inversion hasta la fecha">

                                                                <strong className="line-fix">
                                                                <i class="tim-icons icon-coins p-2"/>SALDO
                                                                </strong>
                                                                
                                                                </div> 

                                                                <div className="" data-tooltip="Proyeccion final de la inversión">

                                                                  <strong className="line-fix">
                                                                  <i class="tim-icons icon-coins p-2"/>RENDIMIENTO <br/>
                                                                  FUTURO
                                                                  </strong>

                                                                  </div> 


                                                                <div className="" data-tooltip="Ofertar en mercado secundario">

                                                                <strong className="line-fix">
                                                                <i class="tim-icons icon-tap-02 p-2"/> OFERTAR
                                                                </strong>
                                                                
                                                                </div>
                                                                    


                                                          </div>

                                                          
           </CardBody> 

           

{inversiones.map(inversion => (
                                                  <>
              
           <CardBody className={`class-grid-color-inversiones class-grid-color-body-inversiones card-smooth-inversiones `}  key={inversion.id}  >
                                                

                                                          <div 
                                                            className={`grid-container-tabla-inversiones grid-body-inversiones body-inversiones-shadow
                                                            ${inversion.mercado_secundario === 1 ? 'ofertada' : null }
                                                            `}
                                                          >

                                                                  

                                                                 
                                                                 
                                                                  
                                                          <div className="" data-tooltip="Aquí encontraras el nombre del proyecto">
                                                                <strong className="line-fix">
                                                                {inversion.name}
                                                                </strong>
                                                                </div>
                                                                <div className="" data-tooltip="Fecha inicio de la inversión"> 
                                                                
                                                                <strong className="line-fix">
                                                                {inversion.date_investment}
                                                                </strong>
                                                                
                                                                </div>
                                                                <div className="" data-tooltip="Fecha de vencimiento de su inversión"> 

                                                                <strong className="line-fix">
                                                                {inversion.end_date}
                                                                </strong>
                                                                
                                                                </div>
                                                                <div className="" data-tooltip="Monto de la inversión">

                                                                <strong className="line-fix">
                                                                {inversion.investment_amount} 
                                                                </strong>
                                                                
                                                                </div>
                                                                <div className="" data-tooltip="Tasa de rendimiento de todo el proyecto anual"> 
                                                                <strong className="line-fix">
                                                                {inversion.tax_referential_client}  
                                                                </strong>
                                                                
                                                                </div>
                                                                <div className="" data-tooltip="Dinero recolectado hasta la fecha. ¡tus ganancias!">

                                                                <strong className="line-fix">
                                                                {inversion.rendimiento}
                                                                </strong>
                                                                
                                                                </div> 
                                                                <div className="" data-tooltip="el valor total de tu inversion hasta la fecha">

                                                                <strong className="line-fix">
                                                                {inversion.saldo}
                                                                </strong>
                                                                
                                                                </div> 

                                                                <div className="" data-tooltip="Proyeccion final de la inversión">

                                                                  <strong className="line-fix">
                                                                  
                                                                  {inversion.rendimientofuturo}
                                                                  </strong>

                                                                  </div> 




                                                                <div className="" >

                                                                {inversion.mercado_secundario === 1 ?  
                      
                                                                  <button className="btn panther-btn-ofertada  btn-block btn-panther-power-sm" desabled onClick={ () => {
                                                                    
                                                                  }


                                                                  } >Ofertada</button>
                                                                    
                                                                  
                                                                  :  <button className="btn btn-primario btn-block btn-panther-power-sm panther-btn-ofertar" onClick={ () => {
                                                                    handleOpen();
                                                                    
                                                                    guardarVenta({rendimiento:`${inversion.rendimiento}`,
                                                                                  investment_id:`${inversion.id}`
                                                                  })
                                                                    
                                                                    setModalInfo(inversion);
                                                                  }


                                                                  }>Ofertar</button>}
                                                                
                                                                </div>
                                                                    


                                                          </div>


                                                          

                                                          
            </CardBody> 

            </>
                                                                                  ) )}
      

              <CardBody>
              <div className="grid-container-tabla-inversiones footer-inversiones-grid"
                                                          >
                          <div className="sortable">

                            TOTALES                      

                          </div>
                          <div className="sortable"> 
                          
                          
                          </div>
                          <div className="sortable"> 
                          
                          
                          </div>
                          <div className="sortable">

                          {totales.total_monto}
                          
                          </div>
                          <div className="sortable"> 
                          
                          </div>
                          <div className="sortable">
                          {totales.total_rendimiento}
                          </div> 
                          <div className="sortable">

                          {totales.total_saldo}
                          
                          </div> 
                          <div className="sortable">

                          
                          
                          </div>

                          <div className="sortable">

                          
                          
                          </div>

                        </div>
              </CardBody>
      
       




              <CardFooter>
              <Container className="border-top border-info pt-5 "> 
                    <Row className="justify-content-center">
                  { (paginaactualcount === 1 || paginaactualcount === 0 ) ? 
                        <Col sm="5" lg="5" xl="5" md="5">
                            <button
                                title=""
                                type="button"
                                className="btn btn-primario btn-block   btn-panther-power mt-1"
                                disabled
                              > Primera Página </button> </Col>: (
                  <Col sm="5" lg="5" xl="5" md="5">
                      <button
                                title=""
                                type="button"
                                className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                disabled
                                onClick={() => 
                                  
                                  guardarPaginaActualCount(paginaactualcount - 1) 
                                              
                                
                                }
                              > Anterior &laquo; </button>
                  </Col>
                  )}
                  <Col md="2" sm="2" lg="2" xl="2" className="box-pagination-father">
                  <span className="box-pagination btn-block">
                            {paginaactualcount}
                  </span>
                 
                  </Col>

                  <Col sm="5" lg="5" xl="5" md="5">
                  <button
                                title=""
                                type="button"
                                className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                onClick={() => guardarPaginaActualCount(paginaactualcount + 1) }
                                disabled
                              > Siguiente &raquo; </button>
                  
                  </Col>


              </Row>
              
              
              </Container>



              

            </CardFooter> 
          
     
       
            

        </div> 
       
        </Card> 

        </> 
            
            : 
            
            
            
            <Row className="box-depositos-seleccion-alerta justify-content-center"> 
                                      <Col md="12" className=" panther-open-titulo-alerta border-panther"> 

                                    {totales.cantidad === 0 ?  <> 
                                    
                                    <i className="tim-icons icon-chart-bar-32 animation-icon-in"/>
                                            <h2 className="animation-icon-in-dos">¡Ups, sin inversiones activas!</h2>
                                              <h3 class="animate-raya-03">Todavía no ha hecho ninguna inversión</h3>
                                              <p className="text-center animation-icon-in-cuatro">Puede elegir entre mercado primario y mercado secundario en la sección de proyectos</p>  </> :
                                              <Spinner/>
                                              
                                              }
            </Col>
                                          
            </Row>  
                                      
                                      
                                      }
                            



      </Divdesktop> 

      <DivMobile>

        
      {totales.cantidad > 0 ?  
                                                        <>

      <Card className="height-fix-ultimos-movimientos justify-content-center table-responsive">
                                                
                                                <div>
                                      
                                                    <CardBody>
                                                          <Container> 

                                                            <Row>




                                                                {inversiones.map(inversion => (
                                                                  <>
                                                              <Col xs="12" sm="12" md="12" key={inversion.id} >
                                                                <Card className="card-responsive-bg"> 
                                                                  <CardHeader>
                                                                  <Row>
                                                                  <Col xs="6" sm="6" md="6">
                                                                      <p className="card-responsive-01-inversiones"> <strong>  Fecha </strong> <br/>
                                                                      {inversion.date_investment}</p> 
                                                                        </Col>
                                                                        <Col xs="6" sm="6" md="6">
                                                                        <p className="card-responsive-02-inversiones"> <strong>  Fecha Fin </strong><br/> 
                                                                        {inversion.end_date}</p>
                                                                        </Col>
                                                                        </Row>
                                                                  </CardHeader>

                                                                  <CardBody className="card-responsive-07-inversiones">
                                                                      <Row> 

                                                                      <Col xs="12" sm="12" md="12">
                                                                              <p className="card-responsive-03-inversiones"><strong>  Nombre del Proyecto :</strong> <br/> {inversion.name}</p>
                                                                          </Col>
                                                                          
                                                                        
                                                                          <Col xs="6" sm="6" md="6">
                                                                              <p className="card-responsive-03-inversiones"><strong className="line-fix">  <i class="tim-icons icon-bank pr-1"/> Monto:</strong> <br/> {inversion.investment_amount}</p>
                                                                          </Col>
                                                                          <Col xs="6" sm="6" md="6">
                                                                              <p className="card-responsive-03-inversiones"><strong>  Tasa de rendimiento:</strong> <br/> {inversion.tax_referential_client}</p>
                                                                          </Col>
                                                                          <Col xs="6" sm="6" md="6">
                                                                          <p className="card-responsive-04-inversiones"><strong> Rendimiento :</strong> <br/> {inversion.rendimiento}</p>
                                                                          </Col>

                                                                          <Col xs="6" sm="6" md="6">
                                                                          <p className="card-responsive-04-inversiones"><strong> Saldo Total:</strong> <br/> {inversion.saldo}</p>
                                                                          </Col>

                                                                      </Row>


                                                                  </CardBody>


                                                                  <CardFooter>
                                                                      <p className="card-responsive-05-inversiones btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  onClick={ () => {
                                                                                  handleOpen();
                                                                                  
                                                                                  setModalInfo(inversion);
                                                                                }
                                                                      }> {inversion.vender}</p>
                                                                  </CardFooter>
                                                           
                                                       
                                                                     
                                                                        
                                                                      
                                                                        
                                                   
                                                                </Card>
                                                              </Col>
                                                          </>


                                                                  ) )}
                                          
                                                              
                                                              





                                                            </Row>
                                                          </Container>
                                                    </CardBody>


                                                      <CardFooter>
                                                        <Container className="border-top border-info pt-5 "> 
                                                              <Row className="justify-content-center">
                                                            { (paginaactualcount === 1 || paginaactualcount === 0 ) ? 
                                                                  <Col xs="5" sm="5" lg="5" xl="5" md="5">
                                                                      <button
                                                                          title=""
                                                                          type="button"
                                                                          className="btn btn-primario btn-block   btn-panther-power mt-1"
                                                                          disabled
                                                                        > Primera Página </button> </Col>: (
                                                            <Col xs="5" sm="5" lg="5" xl="5" md="5">
                                                                <button
                                                                          title=""
                                                                          disabled
                                                                          type="button"
                                                                          className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                          onClick={() => 
                                                                            
                                                                            guardarPaginaActualCount(paginaactualcount - 1) 
                                                                                        
                                                                          
                                                                          }
                                                                        > Anterior &laquo; </button>
                                                            </Col>
                                                            )}
                                                            <Col xs="2" md="2" sm="2" lg="2" xl="2" className="box-pagination-father">
                                                            <span className="box-pagination btn-block">
                                                                      {paginaactualcount}
                                                            </span>
                                                           
                                                            </Col>
                                          
                                                            <Col xs="5" sm="5" lg="5" xl="5" md="5">
                                                            <button
                                                                          title=""
                                                                          type="button"
                                                                          className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                          onClick={() => guardarPaginaActualCount(paginaactualcount + 1) }
                                                                          disabled
                                                                        > Siguiente &raquo; </button>
                                                            
                                                            </Col>
                                          
                                          
                                                        </Row>
                                                        
                                                        
                                                        </Container>
                                          
                                          
                                          
                                                        
                                          
                                                      </CardFooter>

                                                     
                                                                   
                                          
                                          
                                          
                                          
                                                  </div> 
                                                 
                                                  </Card> 

                                                  </> 
                                                   : 
            
            
            
                                                   <Row className="box-depositos-seleccion-alerta justify-content-center"> 
                                                                             <Col md="12" className=" panther-open-titulo-alerta border-panther"> 
                                       
                                                                           {totales.cantidad === 0 ?  <> 
                                                                           
                                                                           <i className="tim-icons icon-chart-bar-32"/>
                                                                                   <h3>¡Ups, sin inversiones activas!</h3>
                                                                                     <h3>Todavía no ha hecho ninguna inversión</h3>
                                                                                     <p className="text-center">Puede elegir entre mercado primario y mercado secundario en la sección de proyectos </p>  </> :
                                                                                     <Spinner/>
                                                                                     
                                                                                     }
                                                   </Col>
                                                                                 
                                                   </Row>  
                                                                             
                                                                             
                                                                             }



      </DivMobile>



      
      
      </Col>

                                                                                 
                        { mensaje ? <ModalMensaje
                                                
                                                className={classes.modal}
                                                open={open} 
                                                mensaje={mensaje}
                                                                  
                                                onClose={handleClose}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{
                                                  timeout: 900,
                                                }}
                                              >
                                                <Fade in={open}
                                                timeout={154}
                                                onClose={handleClose}>
                                                  <div className="container">
                                                <div className="row">
                                                  <div className="col-12 d-flex justify-content-center">
                                                  <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>
                                                  
                                                  <CardBody>
                                                                      <Row className="d-flex justify-content-center">
                                                                              <Col sm="12" md="6"> 
                                                                                  <p className="text-center display-4 title font-light-weight-modal"> {mensaje}</p>
                                                                              </Col>       
                                                                      </Row>
                                                                    </CardBody> 
                                                                    <CardFooter>
                                                      <div className="row">
                                                          
                                                      <div  className="col-4">
                                                          </div>
                                                          <div  className="col-4">
                                                            <button className=" btn mt-0 btn-block btn-info"  onClick={() => (
                                                                                    guardarMensaje(null),
                                                                                    ApiLoad(),
                                                                                    handleClose()
                                                                  )} >
                                                            Aceptar
                                                          </button>
                                                          </div>
                                                                
                                                      <div  className="col-4">
                                                          </div>
                                                      </div>
                                                  </CardFooter>
                                                                    
                                                                
                                                  

                                                </div>

                                                  </div>

                                                </div>
                                                  </div>
                                                </Fade>
                                              </ModalMensaje> : null}



       </Row> 


       <Modal 
                            open={open}
                            className="modal-responsive-fix-invertir"
                       
                        >
                            <div  className={`  card  Modal-inversion  invertir-fix-mobile m-0`}>
                            <form onSubmit={onSubmit}>
                            <CardHeader className="bg-modal-invertir ">

                              <Row>
                              <Col xs="10" sm="10" md="10" lg="10" className="btn-cancel-justify-center">
                              <h3 className="title display-5 text-left my-4">Detalle de la inversion de {modalInfo.name}</h3>
                                                    </Col>
                                
                              <Col xs="2" sm="2" md="2" lg="2" className="btn-cancel-justify-center">
                                                      <div className="cancel-box-btn">
                                                          <div className="cancel-btn-panther" onClick={ () => (
                                                            handleClose()
                                                          )}> 
                                                              <i className="tim-icons icon-simple-remove"/>
                                                          </div>
                                                      </div>
                                                    </Col>

                              </Row>
                                
                            </CardHeader>
                                  <CardBody className="min-height">
                                    
                                  <p className="text-center proyect-icon">
                                       Sabias que puedes vender tus inversiones <br/> y sacar beneficios de eso! 
                                      </p>
                                           
                                        
                                                           {/* responsive start */}
                                  <Div >
                                    <div className="responsive-width-fix">
                                        <AppBar position="static" color="default" className="app-panther-bar">
                                        <StyledTabs value={value} 
                                                  onChange={handleChange} 
                                                  aria-label="styled tabs example"
                                                  className=" pr-0 pl-0 justify-content-center"
                                                  >

                                            <StyledTab  label="Infomación de la inversión" {...a11yProps(0)} />
                                            <StyledTab label="Procesar venta" {...a11yProps(1)} />
                                            
                                            
                                        </StyledTabs>


                                          
                                        </AppBar>
                                        <SwipeableViews
                                          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                          index={value}
                                          onChangeIndex={handleChangeIndex}
                                         
                                          
                                        >
                                          <TabPanel value={value} index={0} dir={theme.direction} >

                                            
                                          <Row >
                                        
                                          <Col sm="12" md="12">
                                                
                                                  
                                                <Card className="card"> 
                                                <CardHeader>
                                                 <div className="imagen-mis-inversiones-fix">
                                                 <div className="col-3">
                                                 
                                                 <img src={modalInfo.imagen} /> 
                                                 </div>
                                                 <div className="col-9">
                                                 <h5 className="display-mis-inversiones">{modalInfoPro.name} </h5>
                                                 <p className="text-success border-success border-bottom pb-2">{modalInfo.description}</p>
                                                 </div>
                                                 </div>
                                                </CardHeader>
                                                
                                                <CardBody>
                                                    
                                                    <div className="acc-config-panther">


                                                    <ul>

                                                            <li>NOMBRE DEL PROYECTO: <strong> {modalInfo.name}  </strong> </li>
                                                            <li>FECHA DE INICIO DE INVERSION:   <strong> {modalInfo.date_investment}</strong>   </li>
                                                           
                                                            <li>FECHA FIN DE LA INVERSION: <strong> {modalInfo.end_date}  </strong> </li>
                                                            <li>TASA DE RENDIMIENTO: <strong> {modalInfo.tax_referential_client} % </strong> </li>
                                                            <li>RENDIMIENTO ACTUAL: <strong> {modalInfo.rendimiento} $ </strong> </li>
                                                            <li>RENDIMIENTO FUTURO: <strong> {modalInfo.rendimientofuturo} $ </strong> </li>
                                                            
                                                           
                                                            
                                                            
                                                        

                                                    </ul> 
                                                
                                                
                                                    </div>
                                                </CardBody> 
                                            
                                            
                                            
                                                </Card>
                                            </Col> 

                                          </Row>
                                          </TabPanel>

                                          <TabPanel value={value} index={1} dir={theme.direction}>

                                            <Row >
                                            <Col sm="12" md="12">
                                                

                                                <Card className="card">
                                                <CardHeader>
                                                   
                                                    <p className="title display-4">Monto Invertido {modalInfo.investment_amount}$ </p>
                                                   
                                                </CardHeader>
                                                <CardBody>
                                                    
                                                    <div className="acc-config-panther">


                                                    <div>

                                                           
                                                    <Col className="pr-md-1" md="12">
                                                              <FormGroup>
                                                                  <label>Seleccione su fecha Fin de Venta</label>
                                                                  <select className="form-control  pointer-cursor"  required 
                                                            id="date_type"
                                                              name="date_type"
                                                              onChange={onChange}
                                                              value={venta.date_type} >
                                                            <option value="" onClick={ () => (setMostrar(false))} className="text-center option-fix"> --- Selecciona Fecha de Vencimiento --- </option>
                                                                                    <option value="1" onClick={ () => (setMostrar(false))}>Hoy</option>
                                                                                    <option value="2" onClick={ () => (setMostrar(true))}>Definido</option>
                                                                                    <option value="3" onClick={ () => (setMostrar(false))}>Sin vencimiento</option>
                                                                                    
                                                                                
                                                          </select>  
                                                              </FormGroup> 
                                                              </Col>
                                                            
                                                           
                                                          { mostrar === true ? 
                                                              <Col className="pr-md-1" md="12">
                                                              <FormGroup>
                                                                  <label>Definir fecha especifica </label>
                                                                  <Input
                                                                  
                                                                  
                                                                  placeholder="XX / XX / XXXX "
                                                                  type="date"
                                                                  id="date_value"
                                                                  name="date_value"
                                                                  onChange={onChange}
                                                                  value={venta.date_value}
                                                                  required
                                                                  
                                                                  />
                                                              </FormGroup> 
                                                              </Col>
                                                                :  null} 
                                                             


                                                      <Col className="pr-md-1" md="12">
                                                              <FormGroup>
                                                              <label htmlFor="amount" >Digite su porcentage de venta</label>
                                                                <input
                                                                    type="text"
                                                                    id="amount_percentage"
                                                                    name="amount_percentage"
                                                                    placeholder="% % %"
                                                                    onChange={onChange}
                                                                    required
                                                                    value={venta.amount_percentage}
                                                                    className="btn-block display-4  text-center input-invertir"
                                                                    step="0.01"
                                                                    /> 
                                                            
                                                           
                                                              </FormGroup> 
                                                              </Col> 

                                                              <Row className="justify-content-between venta-calculo-box">

                                                                
                                                              <Col md="7">
                                                              <p>Precio final venta </p>
                                                              

                                                              </Col>
                                                              
                                                              <Col md="5">
                                                              
                                                              <p id="totalarecibir">0</p>
                                                              </Col>

                                                              </Row>




                                                              
                                                            
                                                            
                                                            
                                                        

                                                    </div> 
                                                
                                                
                                                    </div>
                                                </CardBody> 
                                                <CardFooter className="d-flex justify-content-end ">
                                                            <Button type="submit" className=" btn-invertir  btn-block" color="default" data-toggle="modal" data-target="#acc-config" data-whatever="Editar">
                                                            {elguardar}
                                                            </Button>
                                                        </CardFooter>
                                            
                                            
                                                </Card>
                                            </Col>  
                                                </Row> 

                                          </TabPanel> 
                                        
                                    
                                        </SwipeableViews>
                                      </div>      


                                  </Div>

                                  {/* responsive end */}

                                  <DivModal >
                                           
                                            
                                            <Col sm="6" md="6">
                                                
                                                  
                                                <Card className="card"> 
                                                <CardHeader>
                                                 <div className="imagen-mis-inversiones-fix">
                                                 <div className="col-3">
                                                 
                                                 <img src={modalInfo.imagen} /> 
                                                 </div>
                                                 <div className="col-9">
                                                 <h5 className="display-mis-inversiones">{modalInfoPro.name} </h5>
                                                 <p className="text-success border-success border-bottom pb-2">{modalInfo.description}</p>
                                                 </div>
                                                 </div>
                                                </CardHeader>
                                                
                                                <CardBody>
                                                    
                                                    <div className="acc-config-panther">


                                                    <ul>

                                                            <li>NOMBRE DEL PROYECTO: <strong> {modalInfo.name}  </strong> </li>
                                                            <li>FECHA DE INICIO DE INVERSION:   <strong> {modalInfo.date_investment}</strong>   </li>
                                                           
                                                            <li>FECHA FIN DE LA INVERSION: <strong> {modalInfo.end_date}  </strong> </li>
                                                            <li>TASA DE RENDIMIENTO: <strong> {modalInfo.tax_referential_client} % </strong> </li>
                                                            <li>RENDIMIENTO ACTUAL: <strong> {modalInfo.rendimiento} $ </strong> </li>
                                                            <li>RENDIMIENTO FUTURO: <strong> {modalInfo.rendimientofuturo} $ </strong> </li>
                                                            
                                                           
                                                            
                                                            
                                                        

                                                    </ul> 
                                                
                                                
                                                    </div>
                                                </CardBody> 
                                            
                                            
                                            
                                                </Card>
                                            </Col>  

                                            
                                            <Col sm="6" md="6">
                                                

                                                <Card className="card">
                                                <CardHeader>
                                                   
                                                    <p className="title display-4">Monto Invertido {modalInfo.investment_amount}$ </p>
                                                   
                                                </CardHeader>
                                                <CardBody>
                                                    
                                                    <div className="acc-config-panther">


                                                    <div>

                                                           
                                                    <Col className="pr-md-1" md="12">
                                                              <FormGroup>
                                                                  <label>Seleccione su fecha Fin de Venta</label>
                                                                  <select className="form-control  pointer-cursor"  required 
                                                            id="date_type"
                                                              name="date_type"
                                                              onChange={onChange}
                                                              value={venta.date_type} >
                                                            <option value="" onClick={ () => (setMostrar(false))} className="text-center option-fix"> --- Selecciona Fecha de Vencimiento --- </option>
                                                                                    <option value="1" onClick={ () => (setMostrar(false))}>Hoy</option>
                                                                                    <option value="2" onClick={ () => (setMostrar(true))}>Definido</option>
                                                                                    <option value="3" onClick={ () => (setMostrar(false))}>Sin vencimiento</option>
                                                                                    
                                                                                
                                                          </select>  
                                                              </FormGroup> 
                                                              </Col>
                                                            
                                                           
                                                          { mostrar === true ? 
                                                              <Col className="pr-md-1" md="12">
                                                              <FormGroup>
                                                                  <label>Definir fecha especifica </label>
                                                                  <Input
                                                                  
                                                                  
                                                                  placeholder="XX / XX / XXXX "
                                                                  type="date"
                                                                  id="date_value"
                                                                  name="date_value"
                                                                  onChange={onChange}
                                                                  value={venta.date_value}
                                                                  required
                                                                  
                                                                  />
                                                              </FormGroup> 
                                                              </Col>
                                                                :  null} 
                                                             


                                                      <Col className="pr-md-1" md="12">
                                                              <FormGroup>
                                                              <label htmlFor="amount" >Digite su porcentage de venta</label>
                                                                <input
                                                                    type="text"
                                                                    id="amount_percentage"
                                                                    name="amount_percentage"
                                                                    placeholder="% % %"
                                                                    onChange={onChange}
                                                                    required
                                                                    value={venta.amount_percentage}
                                                                    className="btn-block display-4  text-center input-invertir"
                                                                    /> 
                                                            
                                                           
                                                              </FormGroup> 
                                                              </Col> 

                                                              <Row className="justify-content-between venta-calculo-box">

                                                                
                                                              <Col md="7">
                                                              <p>Precio final venta </p>
                                                              

                                                              </Col>
                                                              
                                                              <Col md="5">
                                                              
                                                              <p id="totalarecibir">0</p>
                                                              </Col>

                                                              </Row>




                                                              
                                                            
                                                            
                                                            
                                                        

                                                    </div> 
                                                
                                                
                                                    </div>
                                                </CardBody> 
                                                <CardFooter className="d-flex justify-content-end ">
                                                            <Button type="submit" className=" btn-invertir  btn-block" color="default" data-toggle="modal" data-target="#acc-config" data-whatever="Editar">
                                                            {elguardar}
                                                            </Button>
                                                        </CardFooter>
                                            
                                            
                                                </Card>
                                            </Col>  



                                    </DivModal>

                                    {/* {mensaje ? ( <span className="text-center mensaje-inversion"> <br/> {mensaje}</span>) : null} */}
                                    { mensaje ? <Modal
                                                
                                                className={classes.modal}
                                                open={open} 
                                                mensaje={mensaje}
                                                                  
                                                onClose={handleClose}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{
                                                  timeout: 900,
                                                }}
                                              >
                                                <Fade in={open}
                                                timeout={154}
                                                onClose={handleClose}>
                                                  <div className="container">
                                                <div className="row">
                                                  <div className="col-12 d-flex justify-content-center">
                                                  <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>
                                                  
                                                  <CardBody>
                                                                      <Row className="d-flex justify-content-center">
                                                                              <Col sm="12" md="6"> 
                                                                                  <p className="text-center display-4 title font-light-weight-modal"> {mensaje}</p>
                                                                              </Col>       
                                                                      </Row>
                                                                    </CardBody> 
                                                                    <CardFooter>
                                                      <div className="row">
                                                          
                                                      
                                                          <div  className="col-12">
                                                            <button className=" btn mt-0 btn-block btn-info" onClick={ () => {
                                                                                        ApiLoad(),
                                                                                        handleClose(),
                                                                                        TodosInversiones(),
                                                                                        TodosOferentes(),
                                                                                        TodosOfertas(),
                                                                                        TodosInversionesVendidas(),
                                                                                        TodosProyectosPrimario(),
                                                                                        TodosProyectosSecundario()
                                                                                          }
                                                                                          }
                                                                                        >
                                                            Aceptar
                                                          </button>
                                                          </div>
                                                                
                                                      </div>
                                                  </CardFooter>
                                                                    
                                                                
                                                  

                                                </div>

                                                  </div>

                                                </div>
                                                  </div>
                                                </Fade>
                                              </Modal> : null} 

                                              


                                  </CardBody> 
                                  </form>
                                  
                            </div>
                        </Modal>
       


   


                      
       

</Fragment>     
        


     


        
        
          





     );
};

 
export default ListaInversion; 

{/* <Modal 
                            open={open}

                            onClose={ () => {
                                handleClose();
                                
                                
                            } }
                        >
                            <div style={modalStyle} className={`  card ${classes.paper} Modal-inversion`}> 

                            <form onSubmit={onSubmit}>
                            <CardHeader className="bg-modal-invertir ">
                                <h3 className="title display-4 text-center mis-inversiones-h5 ">Detalle de la inversion de <br/> {modalInfo.name}</h3>
                                <h5 className=" text-center">Tienes la capacidad de Vender tu inversion al instante en el Mercado secundario de Panther</h5>
                            </CardHeader>
                                  <CardBody>
                                        
                                  
                                            

                                  <Row > 
                                            <Col sm="6" md="6">
                                                
                                                  
                                                <Card className="card"> 
                                                <CardHeader>
                                                 <div className="imagen-mis-inversiones-fix">
                                                 <div className="col-3">
                                                 
                                                 <img src={modalInfo.imagen} /> 
                                                 </div>
                                                 <div className="col-9">
                                                 <h5 className="display-mis-inversiones">{modalInfoPro.name} </h5>
                                                 <p className="text-success border-success border-bottom pb-2">{modalInfo.description}</p>
                                                 </div>
                                                 </div>
                                                </CardHeader>
                                                
                                                <CardBody>
                                                    
                                                    <div className="acc-config-panther">


                                                    <ul>

                                                            <li>NOMBRE DEL PROYECTO: <strong> {modalInfo.name}  </strong> </li>
                                                            <li>FECHA DE INICIO DE INVERSION:   <strong> {modalInfo.date_investment}</strong>   </li>
                                                           
                                                            <li>FECHA FIN DE LA INVERSION: <strong> {modalInfo.end_date}  </strong> </li>
                                                            <li>TASA DE RENDIMIENTO: <strong> {modalInfo.tax_referential_client} % </strong> </li>
                                                            <li>RENDIMIENTO ACTUAL: <strong> {modalInfo.rendimiento} $ </strong> </li>
                                                            
                                                           
                                                            
                                                            
                                                        

                                                    </ul> 
                                                
                                                
                                                    </div>
                                                </CardBody> 
                                            
                                            
                                            
                                                </Card>
                                            </Col>  

                                        


                                            <Col sm="6" md="6">
                                                

                                                <Card className="card">
                                                <CardHeader>
                                                   
                                                    <p className="title display-4">Monto Invertido {modalInfo.investment_amount}$ </p>
                                                   
                                                </CardHeader>
                                                <CardBody>
                                                    
                                                    <div className="acc-config-panther">


                                                    <div>

                                                           
                                                    <Col className="pr-md-1" md="12">
                                                              <FormGroup>
                                                                  <label>Seleccione su fecha Fin de Venta</label>
                                                                  <select className="form-control  pointer-cursor"  required 
                                                            id="date_type"
                                                              name="date_type"
                                                              onChange={onChange}
                                                              value={venta.date_type} >
                                                            <option value="" onClick={ () => (setMostrar(false))} className="text-center option-fix"> --- Selecciona Fecha de Vencimiento --- </option>
                                                                                    <option value="1" onClick={ () => (setMostrar(false))}>Hoy</option>
                                                                                    <option value="2" onClick={ () => (setMostrar(true))}>Definido</option>
                                                                                    <option value="3" onClick={ () => (setMostrar(false))}>Sin vencimiento</option>
                                                                                    
                                                                                
                                                          </select>  
                                                              </FormGroup> 
                                                              </Col>
                                                            
                                                           
                                                          { mostrar === true ? 
                                                              <Col className="pr-md-1" md="12">
                                                              <FormGroup>
                                                                  <label>Definir fecha especifica </label>
                                                                  <Input
                                                                  
                                                                  
                                                                  placeholder="XX / XX / XXXX "
                                                                  type="date"
                                                                  id="date_value"
                                                                  name="date_value"
                                                                  onChange={onChange}
                                                                  value={venta.date_value}
                                                                  required
                                                                  
                                                                  />
                                                              </FormGroup> 
                                                              </Col>
                                                                :  null} 
                                                             


                                                      <Col className="pr-md-1" md="12">
                                                              <FormGroup>
                                                              <label htmlFor="amount" >Digite su porcentage de venta</label>
                                                                <input
                                                                    type="text"
                                                                    id="amount_percentage"
                                                                    name="amount_percentage"
                                                                    placeholder="% % %"
                                                                    onChange={onChange}
                                                                    required
                                                                    value={venta.amount_percentage}
                                                                    className="btn-block display-4  text-center input-invertir"
                                                                    /> 
                                                            
                                                           
                                                              </FormGroup> 
                                                              </Col> 

                                                              <Row className="justify-content-between venta-calculo-box">

                                                                
                                                              <Col md="7">
                                                              <p>Precio final venta </p>
                                                              

                                                              </Col>
                                                              
                                                              <Col md="5">
                                                              
                                                              <p id="totalarecibir">0</p>
                                                              </Col>

                                                              </Row>




                                                              
                                                            
                                                            
                                                            
                                                        

                                                    </div> 
                                                
                                                
                                                    </div>
                                                </CardBody> 
                                                <CardFooter className="d-flex justify-content-end ">
                                                            <Button type="submit" className=" btn-invertir  btn-block" color="default" data-toggle="modal" data-target="#acc-config" data-whatever="Editar">
                                                            {elguardar}
                                                            </Button>
                                                        </CardFooter>
                                            
                                            
                                                </Card>
                                            </Col> 
                                         
                                         
                                    </Row>

                                    

                                  </CardBody> 
                                  

                       

                              
                                  </form>
                            </div> 
                            
                        </Modal>  */}



{/* <tr className="mercadoprimario-style"> 
        
<th>1000076-P1002</th>
  <th>BHR</th>
  <th>Emprendimientos</th>
  <th>Parcelas</th>
  <th>SEGUROS LA OCCIDENTAL</th>
  <th className=" montoplus">A++</th>
  <th>+++<i className="tim-icons icon-check-2 montoplus" /></th>
  <th>3%</th>
  <th>23/01/21 (16 dias)</th>
  <th>83.33</th>
  <th>83.44</th>
  <th>	0.11 / 0.13%</th>
  <th>
        <div class="progress">
      <div className="progress-bar bg-success" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
  </th>
  <Inversion/>
</tr> 
 */}
