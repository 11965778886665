import React, { useContext,useState, useEffect } from 'react';
import AuthContext from '../context/autenticacion/authContext'
import ParticleBg from '../components/IconsAnimation/ParticleBg'
import GalleryInfo from 'components/Modales/GalleryInfo';
import NumberFormat from 'react-number-format';


const Gallery = () => {

    
    const authContext = useContext(AuthContext);
    const { galleryAduClient, gallery_data } = authContext; 


    const  [modalopen, guardarModalOpen] = useState(false);

    const [artes, guardarArtes]  = useState([{
        description:'',
        url:'',
        nrocontrato:'',
        currency_code:'',
        name:'',
        tax_referential_client:'',
        end_date:'',
      }]);



    //// aqui vamos a colocar la informacion del modal info
    const [modalInfo, setModalInfo] = useState([]);

    
    useEffect( () => { 
        const consultarArtesData = async () => { 
          try {        
            
            guardarArtes(gallery_data)

           
            } catch (error) { 
                return

                
            
        }

        }
    
        consultarArtesData()
    
    
      },[]); 

    //   const MostrarArte = (artesss) => {
          
    //       setModalInfo(artesss)
    //       guardarModalOpen(true)
    //   }




    return (  

    
    <> 
            <div className="content">

            <div className="box-content-fx waveform-x4">
                <ParticleBg/>

                </div>  

                <div className="prisma-clip">


                    <p> Colección de Artes</p>

                    </div>


                <div className="leyenda-artes">
                    <p>

                        
                    <small> SU COLECCIÓN DE ARTE TIENE UN VALOR TOTAL DE </small> 
                    <strong><NumberFormat value={ parseInt(`${artes.length}`) * 500 }  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/></strong>

                    
                    <br/>

                    Usted actualmente posee {
                        `${artes.length}`
                    } ADUS's con el valor de $500 cada pieza.
                    


                    </p>



                </div>

                
            <div className="gallery-objetos"> 

                    <div className="adu-section">

                                

                                <div className="adu-box-placeholder adu-responsive">
                                {artes.map(artesss => {

                                    return (
                                        <>
                                        <div className="adu-box" key={artesss.id}>

                                            <img src={artesss.url} onClick={
                                                () => {                      
                                                        setModalInfo(artesss),
                                                        guardarModalOpen(true)
                                                }
                                            }/>

                                             <strong>ADU #{artesss.adu}</strong>   
                                            <p>www.strprisma.com</p>
                                        </div>


                                        </>

                                    )
                                        

                                })}

                                </div>
                                </div>
                    </div>

            </div> 


            {modalopen === true ? 
            <GalleryInfo
            modalInfo={modalInfo}
            guardarModalOpen={guardarModalOpen}
                modalopen={modalopen}

                />      
                : null
            }

            

            
    </>
    
    );
}
 
export default Gallery;