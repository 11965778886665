import { useLottie } from "lottie-react";
import Machine from "../IconsAnimation/Machine.json";
 
const MachineX= () => {
  const options = {
    animationData: Machine,
    loop: true,
    autoplay: true,
    height: 90,
  };
 
  const { View } = useLottie(options);
 
  return View;
};
 
export default MachineX;