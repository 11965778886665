
import React, { useContext, useState, useEffect, Fragment } from 'react';
import ActualizarPerfil from '../components/Profile/ActualizarPerfil';
import ActualizarPerfilPro from '../components/Profile/ActualizarPerfilPro';
import Kyc from '../components/Profile/Kyc'
import Wizardprofile  from './Wizardprofile'
import Deposito  from './DepositoInicial'

import Contrato from './Contrato'
import Final from './Final'
import TarjetaFondeo from '../components/Profile/TarjetaFondeo'
import CuentaBancariaRetiro from '../components/Profile/CuentaBancariaRetiro'
import ScrollDown from '../components/IconsAnimation/ScrollDown'
import clienteAxios from '../config/axios';
import ParticlesBackground from '../components/Particles/ParticlesBackgroundWhite'
import PrismaActiveBar from './PrismaActiveBar'
import PrismaActiveBarLogical from './PrismaActiveBarLogical'
import ParticleBg from '../components/IconsAnimation/ParticleBg'


// aqui vamos a colocar el context , de donde viene
import AuthContext from '../context/autenticacion/authContext'


///* estilos del carousel


import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'; 
import CambiarPassword from '../components/Profile/CambiarPassword';
import Grafica from './Grafica';



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  
  Row,
  Col
} from "reactstrap";




function getModalStyle() {
    const top = 50 ;
    const left = 50;
  
    return {
      top: ``,
      left: ``,
      transform: ``,
      
    };
  }
  
  
  const useStyles = makeStyles((theme) => ({
    root:{
        background: 'none',
        backgroundColor: 'none',
        width: '100%',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'hidden',
    },
    paper: {
      
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      
      margin: '20% 1% 10% 1%',
    },
  }));
  
  
  


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    outline:'none',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#00ff9d',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);




const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    alignItems:'center',
    marginRight: theme.spacing(0),
    borderRadius:'30px',
    outline:'none',
    transition:'all 500ms ease',
    padding:'0px 5px 0px 5px',
     margin:'0px 4px 0px 4px',
     alignItems:'center',
  
    '&:focus': {
      opacity: 0.8,
      color: '#f5f5f5',
      border: 'none',
      outline:'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#005eff',
      borderRadius:'30px'
      
      
    },
    '&:hover': {
      opacity: 0.6,
      color: '#f5f5f5',
      border: 'none',
      outline:'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#000',
      borderRadius:'30px'
      
    },

  },
}))((props) => <Tab disableRipple {...props} />);


/// estilos para el carousel

const styles = {
  tabs: {
    background: '#fff',
  },
  slide: {
    padding: 15,
    minHeight: 700,
    color: '#fff',
  },
  slide1: {
    
    border: '1px solid #0047ff',
    borderRadius: '12px'
  },
  slide2: {
    
    border: '1px solid #0047ff',
    borderRadius: '12px'
  }
}; 


// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);





const UserProfile = (props) => {  

  const [mensajeDos, guardarMensajeDos] = useState('');

  const authContext = useContext(AuthContext);
    const { status,userStatus,  usuario, listDatos,cuentas_internacionales,cuentas_nacionales, cuentas_remesas, ListaNacionales, ListaInternacionales, ListaRemesas, usuarioAutenticado, LastContract, last_contract, depositos } = authContext;  

    
 /// const api load 
  const ApiLoad = () => {
    guardarApiLoad(false);
    setTimeout(() => {
      guardarApiLoad(true);
  },3);
  }

  const [ apiload, guardarApiLoad] = useState(true);
//// aqui termina el api load 




    

  const [ recargardata, setRecargarData  ] = useState(false);


  const [ cuentas, setCuentas ] = useState([

    {
      id: '',
      client_id: '',
      bank_name: '',
      bank_account: '',
      bank_swift:'',
      created_at: '',
      updated_at: '',
      bank_client: '',
      bank_status:'',
      active:'',
      country_id:'',
      bank_personal:'',
  }

  ]); 

  
   
  
   const [ cuentaspropias, setCuentasPropias ] = useState([

     {
       id: '',
       client_id: '',
       bank_name: '',
       bank_account: '',
       bank_swift:'',
       created_at: '',
       updated_at: '',
       bank_client: '',
       bank_status:'',
       active:'',
       country_id:'',
      
   }

    ]); 
   
  
   const [ cuentasterceros, setCuentasTerceros ] = useState([

     {
       id: '',
       client_id: '',
       bank_name: '',
       bank_account: '',
       bank_swift:'',
       created_at: '',
       updated_at: '',
       bank_client: '',
       bank_status:'',
       active:'',
       country_id:'',
       bank_aba:'',
      
   }

   ]); 

  
   const [ cuentasremesas, setCuentasRemesas ] = useState([

     {
       id: '',
       client_id: '',
       bank_name: '',
       bank_account: '',
       bank_swift:'',
       created_at: '',
       updated_at: '',
       bank_client: '',
       bank_status:'',
       active:'',
       country_id:'',
      
   }

    ]); 



    const [appbar, guardarAppBar ] = useState({
      primero:1,
      segundo:0,
      tercero:0,
      cuarto:0,
      quinto:0,
      sexto:0,
    }) 


    const [ appbarkyc, guardarAppBarKyc] = useState({
      primero:1,
      segundo:0,
      tercero:0,
      cuarto:0,
      quinto:0,
      sexto:0,
    }) 


    //// luego vamos a colocar las funciones de flecha, para usarlas en el navbar
    


    const ActivarPrimero = () => {
        guardarAppBar({...appbar,
          primero:1,
          segundo:0,
          tercero:0,
          cuarto:0,
          quinto:0,
          sexto:0,
        })
      }

    const ActivarSecundo = () => {
        guardarAppBar({          
          ...appbar,
          primero:0,
          segundo:1,
          tercero:0,
          cuarto:0,
          quinto:0,
          sexto:0,
        })
    } 

    const ActivarTercero = () => {
          guardarAppBar({
            ...appbar,
            primero:0,
            segundo:0,
            tercero:1,
            cuarto:0,
            quinto:0,
            sexto:0,
          })
      }  
      

//////////////////////////// kyc
      const ActivarPrimeroKyc = () => {
        guardarAppBarKyc({
          ...appbar,
          primero:1,
          segundo:0,
          tercero:0,
          cuarto:0,
          quinto:0,
          sexto:0,
        })
      }

    const ActivarSecundoKyc = () => {
        guardarAppBarKyc({          
          ...appbarkyc,
              primero:0,
              segundo:1,
              tercero:0,
              cuarto:0,
              quinto:0,
              sexto:0,
        })
    } 

    const ActivarTerceroKyc = () => {
          guardarAppBarKyc({
            ...appbarkyc,
                primero:0,
                segundo:0,
                tercero:1,
                cuarto:0,
                quinto:0,
                sexto:0,
          })
      } 

    const ActivarCuartoKyc = () => {
      guardarAppBarKyc({
        ...appbarkyc,
            primero:0,
            segundo:0,
            tercero:0,
            cuarto:1,
            quinto:0,
            sexto:0,

      })
    } 


    const ActivarQuintoKyc = () => {
      guardarAppBarKyc({
        ...appbarkyc,
            primero:0,
            segundo:0,
            tercero:0,
            cuarto:0,
            quinto:1,
            sexto:0,

      })
    } 

    const ActivarSextoKyc = () => {
      guardarAppBarKyc({
        ...appbarkyc,
            primero:0,
            segundo:0,
            tercero:0,
            cuarto:0,
            quinto:0,
            sexto:1,

      })
    }







//   const [usuario, guardarUsuario] = useState({
//     id: '',
//     name:'',
//     email:'',
//     c:'',
//     client_type:'',
//     identity:'',
//     birthday_date:'',
//     total_rent:'',
//     status:'',
//     profession_id:'',
//     user_id:'',
//     telephone:'',
//     representative_name:'',
//     representative_cpf:'',
//     company_activity:'',
//     created_at:'',
//     updated_at:'',
//     address_street:'',
//     address_number:'',
//     address_city:'',
//     address_zipcode:'',
//     address_zone:'',
//     address_country:'',
//     is_ofertante:'',
//     comercial_name:'',
//     referido:'',
//     cpf_no_activo:'',
//     cpf:'',
//     first_name:'',
//     last_name:'',
//     middle_name:'',

// }); 

useEffect( () => {  


  const consultarApiBeneficiarios = async () => {
     
   
    // const propiasbank = {bank_personal:'1'}
    // const tercerosbank = {bank_personal:'2'}
    // const remesabank = {bank_personal:'3'}

    // const respuesta = await clienteAxios.post('/api/client/beneficiarios')
    // const respuestapropias = await clienteAxios.post('/api/client/beneficiarios',propiasbank)
    // const respuestaterceros = await clienteAxios.post('/api/client/beneficiarios',tercerosbank)
    // const respuestaremesa = await clienteAxios.post('/api/client/beneficiarios',remesabank)
    
    
    
    
     setCuentasTerceros(cuentas_internacionales)
     setCuentasPropias(cuentas_nacionales)
     setCuentasRemesas(cuentas_remesas)
    //  guardarProyectotis(respuesta.data.data.proyect)
    
    
  }

  consultarApiBeneficiarios()
  
  
  

 
 
  

},[!apiload, cuentas_internacionales,cuentas_nacionales,cuentas_remesas]);  



  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // const [ value, setValue] = useState(0);
  // const handleTabs = (e , val) => {
  //   setValue(val)
  // }

  // const [toggleState, setToggleState ] = useState(1);
  // const toggleTab = (index) => {
  //   setToggleState(index);
  // }


  ///aqui vamos a colocar los manejadores de eventos para abrir y cerrar el modal

  const [openpro, guardarOpenPro ] = useState(false);


  //// aqui vamos a colocar el manejador de evento, que bloquea el deposito el monto del mismo
  const [montosave, guardarMontoSave] = useState('')




  //////// en el componente de contrato, necesitamos colocar la logica, fuera porque vamos a hacerle reset en algunas acciones
  const [btndinamic, guardarBtnDinamic] = useState('')
  const [contrato, guardarContrato] = useState({
        amount:'',
    })

  const [participaciones, guardarParticipacion] = useState({
            multiplicador:`0`,
  })


   const montosavereset = e => {
        e.preventDefault()
        guardarMontoSave('')
        guardarBtnDinamic('')
        guardarContrato({
            amount: '',
        })

        guardarParticipacion({
            multiplicador:`0`,
        })
    } 


       
    useEffect(() => {

      LastContract()

      // if(last_contract.amount !== "") {
      //     guardarBtnDinamic(last_contract.url)
      //     guardarMontoSave(`${last_contract.amount}`)
      // }
      if(last_contract !== null) {

        if(last_contract.amount !== "") {
          guardarBtnDinamic(last_contract.url)
          guardarMontoSave(`${last_contract.amount}`)
      }
      }
      
  }, [])
  
    
//////// en el componente de contrato, necesitamos colocar la logica, fuera porque vamos a hacerle reset en algunas acciones


  return (
    <> 
    <div className="box-content-fx waveform-x3">
                <ParticleBg/>

                </div>  


      <ActualizarPerfilPro guardarOpenPro={guardarOpenPro} openpro={openpro} usuario={usuario}  setValue={setValue} listDatos={listDatos} guardarAppBar={guardarAppBar} guardarAppBarKyc={guardarAppBarKyc} appbar={appbar} appbarkyc={appbarkyc} /> 
      <div className="content"> 

      

            <div className={classes.root}> 

                  { status === 1 || status === 2 ? 

                  <>  

                    <div className="prisma-clip">


                    <p>Activación de su cuenta</p>

                    </div>
                   

                   {/* <div className="prisma-open">
                     <div>
                       <h3>
                         Bienvenido <small> {usuario.name} </small>
                          a STR PRISMA <br/>
                         <small>Ultimate Robo-advisor</small>

                       </h3>

                     </div>

                    </div>  */}

                    <Wizardprofile
                        usuario={usuario}
                    />

                   <div className="grid-dash-prisma-profile">
                          <div className="elemento-dash-prisma-one-profile">

                          <PrismaActiveBar
                          appbarkyc={appbarkyc}
                          ActivarPrimeroKyc={ActivarPrimeroKyc}
                          ActivarSecundoKyc={ActivarSecundoKyc}
                          ActivarTerceroKyc={ActivarTerceroKyc}
                          ActivarCuartoKyc={ActivarCuartoKyc}
                          ActivarQuintoKyc={ActivarQuintoKyc}
                          ActivarSextoKyc={ActivarSextoKyc}
                          />

                  <PrismaActiveBarLogical
                          appbarkyc={appbarkyc}
                          ActivarPrimeroKyc={ActivarPrimeroKyc}
                          ActivarSecundoKyc={ActivarSecundoKyc}
                          ActivarTerceroKyc={ActivarTerceroKyc}
                          ActivarCuartoKyc={ActivarCuartoKyc}
                          ActivarQuintoKyc={ActivarQuintoKyc}
                          ActivarSextoKyc={ActivarSextoKyc}
                          usuario={usuario}
                          />



                          </div>


                          <div className="elemento-dash-prisma-dos-profile"> 

                          
                          { status === 1 || status === 2 ? 
                                    <> 

                                              <div className={` text-white ${appbarkyc.primero === 1 ? 'd-block':'d-none'}`} >
                                              <div>           
                                                            <div >
                                                                

                                                                <div class="prisma-clip-2"><p> Mis datos personales</p></div>
                                                                    <p >Complete su información personal con datos actualizados y fidedignos.</p>
                                                                {/* <p className="class-black "> Los campos con asteriscos son obligatorios <span className="alerta-texto-profile-green">* </span></p> */}
                                                            </div>                        <Row className="profile-fix-input ">
                                                                
                                                                    <Col md="12 my-2">
                                                                    
                                                                        <label>Nombre</label>
                                                                        <p>{usuario.name}</p>
                                                                        
                                                                    
                                                                    
                                                                    </Col> 

                                                                    <Col md="12 my-2">
                                                                    
                                                                        <label>TU CÒDIGO DE REFERIDO </label>
                                                                        <p> {usuario.referrer} </p>
                                                                        
                                                                    
                                                                    
                                                                    </Col>


                                                                </Row> 
                                                                
                                                                
                                                                  {usuario.status === 3 ? 

                                                                  <button className="btn-small-prisma mt-3" color="primary"
                                                                  onClick={ () => {
                                                                    guardarOpenPro(true)
                                                                    
                                                                  }


                                                                  }

                                                                  >
                                                                  Actualizar información KYC
                                                                  </button> :

                                                                  <button className="btn-small-prisma mt-3" color="primary"
                                                                                                onClick={ () => {
                                                                                                  guardarOpenPro(true)
                                                                                                  
                                                                                                }


                                                                                                }
                                                                                          
                                                                                            >
                                                                                          Completar información KYC
                                                                                        </button> 
                                                                  }

                                                          

                                                              </div>

                                                  </div> 

                                                  <div className={` text-white ${appbarkyc.segundo === 1 ? 'd-block':'d-none'}`} >
                                                  <Row >
                                                        <Kyc  setValue={setValue} recargardata={recargardata} setRecargarData={setRecargarData} userStatus={userStatus} guardarAppBar={guardarAppBar} guardarAppBarKyc={guardarAppBarKyc} appbar={appbar} appbarkyc={appbarkyc} usuario={usuario} usuarioAutenticado={usuarioAutenticado}/>
                                                    

                                                       
                                                      </Row> 

                                                  </div> 



                                                  <div className={` text-white ${appbarkyc.tercero === 1 ? 'd-block' : 'd-none' }`}>

                                                    <Contrato usuario={usuario} 
                                                      usuarioAutenticado={usuarioAutenticado}
                                                      montosave={montosave}
                                                      guardarMontoSave={guardarMontoSave}
                                                      guardarBtnDinamic={guardarBtnDinamic}
                                                      btndinamic={btndinamic}
                                                      montosavereset={montosavereset}
                                                      guardarContrato={guardarContrato}
                                                      contrato={contrato}
                                                      participaciones={participaciones}
                                                      guardarParticipacion={guardarParticipacion}
                                                    
                                                    />


                                                  </div>


                                                  <div className={` text-white ${appbarkyc.cuarto === 1 ? 'd-block':'d-none'}`} >

                                                      <Row > 
                                                        <CuentaBancariaRetiro 
                                                                    mensajeDos={mensajeDos} 
                                                                  guardarMensajeDos={guardarMensajeDos} 
                                                                  cuentaspropias={cuentaspropias}
                                                                    cuentasterceros={cuentasterceros}
                                                                    cuentas={cuentas} 
                                                                    setCuentas={setCuentas} 
                                                                    recargardata={recargardata} 
                                                                    setRecargarData={setRecargarData}
                                                                    apiload={apiload} 
                                                                    ApiLoad={ApiLoad} 
                                                                    cuentasremesas={cuentasremesas}
                                                                      usuario={usuario}
                                                                    ListaInternacionales={ListaInternacionales}
                                                                    ListaRemesas={ListaRemesas}
                                                                    ListaNacionales={ListaNacionales}
                                                                    usuarioAutenticado={usuarioAutenticado}
                                                              />
                                                        </Row>                                                   
                                                    </div>




                                                  <div className={` text-white ${appbarkyc.quinto === 1 ? 'd-block':'d-none'}`} >

                                                  <Deposito  usuario={usuario} 
                                                  
                                                  montosave={montosave}
                                                  guardarMontoSave={guardarMontoSave}
                                                  ActivarTerceroKyc={ActivarTerceroKyc}
                                                  
                                                  guardarContrato={guardarContrato}
                                                  guardarBtnDinamic={guardarBtnDinamic}
                                                  guardarParticipacion={guardarParticipacion}
                                                  
                                                  />
                                                    
                                                  </div>

                                                  <div className={` text-white ${appbarkyc.sexto === 1 ? 'd-block':'d-none'}`} >

                                                      {/* <div className="bg-particle-media">
                                                      <ParticlesBackground/>
                                                      </div>    */}


                                                      <Final usuario={usuario} 
                                                      usuarioAutenticado={usuarioAutenticado}
                                                        userStatus={userStatus}
                                                        depositos={depositos}
                                                    />


                                                  </div>

                                    </>:null
                                    }    


              
                          </div>
                    </div> 
                   </>


                  : null}  


            { status === 3 ?

                <>  

                {/* <div className="prisma-open">
                     <div>
                       <h3>
                         Bienvenido <small> {usuario.name} </small>
                         a STR PRISMA <br/>
                         <small>Ultimate Robo-advisor</small>

                       </h3>

                     </div>

                    </div> */}
                    <div className="prisma-clip">


                      <p>Configuración</p>

                      </div>
                 <div className="grid-dash-prisma-profile">

                                              <div className="elemento-dash-prisma-one-profile">
                                                

                                                <div className="profile-prisma-body">

                                                <div className=" prisma-ai-profile w-100 text-center"> 
                                                  <small> PRISMA</small>
                                                  <h1 className="text-center">AI</h1>
                                                  <h4 clasName=" text-center w-100">
                                                    Neuronal Machine Learning 
                                                  </h4>
                                                  <h2>Ultimate advanced Robo-advisor by StrPrisma <br/> 
                                                          All rights reserved 2021 StrPrisma.com
                                                          </h2>
                                                      </div>              
                                                                          
                                                    <div> 
                                                          <p className={` on-click-active ${appbar.primero === 1 ? 'nav-active-prisma-profile':''}`}
                                                          onClick={ActivarPrimero}

                                                          ><span><i className="tim-icons icon-settings-gear-63"></i></span> <strong> Configuración de cuenta</strong></p>

                                                          <p className={`on-click-active ${appbar.segundo === 1 ? 'nav-active-prisma-profile':''}`} 
                                                          onClick={ActivarSecundo}
                                                          
                                                          >
                                                            <span><i className="tim-icons icon-bank"></i></span> <strong>Cuentas bancarias</strong></p>

                                                            <CambiarPassword />

                                                          {/* <li>Cambiar contraseña</li>
                                                          <li>Perfil Social</li>
                                                          <li>Notificaciones</li> */}
                                                    </div>

                                                </div>

                                                <div className="bg-particle-media">
                                                  <ParticlesBackground/>
                                                  </div>  
                                              </div>
                                              <div className="elemento-dash-prisma-dos-profile"> 
                                                            { status === 3 ?  
                                                                  <> 

                                                                              <div className={` text-white ${appbar.primero === 1 ? 'd-block':'d-none'}`} >
                                                                              <div>           
                                                            <div >
                                                            <div class="prisma-clip-2"><p> Mis datos personales</p></div>
                                                                    <p >Complete su información personal con datos actualizados y fidedignos.</p>
                                                                {/* <p className="class-black "> Los campos con asteriscos son obligatorios <span className="alerta-texto-profile-green">* </span></p> */}
                                                            </div>
                                                              <Row className="profile-fix-input ">
                                                                
                                                                    <Col md="12 my-2">
                                                                    
                                                                        <label>Nombre</label>
                                                                        <p>{usuario.name}</p>
                                                                    </Col> 

                                                                    <Col md="12 my-2">
                                                                    
                                                                        <label>TU CÒDIGO DE REFERIDO </label>
                                                                        <p> {usuario.referrer} </p>
                                                                    </Col>
                                                                </Row> 
                                                                  {usuario.status === 3 ? 

                                                                  <button className="btn-small-prisma mt-3" color="primary"
                                                                  onClick={ () => {
                                                                    guardarOpenPro(true)
                                                                    
                                                                  }
                                                                  }
                                                                  >
                                                                  Actualizar información KYC
                                                                  </button> :
                                                                  <button className="btn-small-prisma mt-3" color="primary"
                                                                                                onClick={ () => {
                                                                                                  guardarOpenPro(true)
                                                                                                }
                                                                                                }
                                                                                            >
                                                                                          Completar información KYC
                                                                                        </button> 
                                                                  }

                                                          

                                                              </div>

                                                                              </div>
                                                                              <div className={`  ${appbar.segundo === 1 ? 'd-block':'d-none'}`}>
                                                                              
                                                                              <CuentaBancariaRetiro 
                                                                                  mensajeDos={mensajeDos} 
                                                                                  guardarMensajeDos={guardarMensajeDos} 
                                                                                  cuentaspropias={cuentaspropias}
                                                                                    cuentasterceros={cuentasterceros}
                                                                                    cuentas={cuentas} 
                                                                                    setCuentas={setCuentas} 
                                                                                    recargardata={recargardata} 
                                                                                    setRecargarData={setRecargarData}
                                                                                    apiload={apiload} 
                                                                                    ApiLoad={ApiLoad} 
                                                                                    cuentasremesas={cuentasremesas}
                                                                                    usuario={usuario}
                                                                                      
                                                                                    ListaInternacionales={ListaInternacionales}
                                                                                    ListaRemesas={ListaRemesas}
                                                                                    ListaNacionales={ListaNacionales}
                                                                                    usuarioAutenticado={usuarioAutenticado}
                                                                                  />


                                                                              </div>
                                                            </>
                                                              

                                                            :null
                                                            }
                                            </div>


                                </div> 


                </>

                  : null
                            
                          }                  
                  

                
        

                </div>      

        
        
     
    
        

       

      </div>
    </> 

    
  );
}
 
export default UserProfile;
