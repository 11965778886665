import React, { Fragment, useContext , useEffect, useState, useMemo } from 'react';
import clienteAxios from '../config/axios';
import Spinner from '../components/spinners/Spinner'

/// fade del modal
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';


import { Route, Switch, Redirect } from "react-router-dom"; 



import { Doughnut } from "react-chartjs-2";

import styled from 'styled-components'


// importacion del modal
import Modal from '@material-ui/core/Modal'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'; 


/// react table import

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import * as ReactBootStrap from 'react-bootstrap';


/// vamos a poner el tipo de dato
import PropTypes from 'prop-types'; 


import ModalPrimario from '../components/Modales/ModalPrimarioPro' 
import AuthContext from '../context/autenticacion/authContext.js'





import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import DefaultPanther from '../components/bg.jpg'

import { autoPlay } from 'react-swipeable-views-utils'; 


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


/// chart
let chartPie = {
  data4: (canvas) => {
    let ctx = canvas.getContext("2d");
    
    
    // let modalInfo = [];
    // let enable = [];
    // let investment = [];
    
    // for (const dataObj of modalInfo) {
    //   enable.push(parseInt(dataObj.percentage_enable))
    //   investment.push(parseInt(dataObj.percentage_investment))
    // }
    

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
    let invertido=document.getElementById("pinvertido").value;
    let disponible=document.getElementById("pdisponible").value;

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    
    //console.log(canvas)
    
   //console.log(parseFloat(invertido)+" "+ parseFloat(disponible));
    return {
      type: 'doughnut',
      
      datasets: [
        {
          label: "Data",
          fill: true,
          backgroundColor:['#20c284', '#1720ac' ],
          borderColor: ['#00ff9d', '#1f8ef1' ],
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: [parseFloat(invertido),parseFloat(disponible)],
          weight: 300,
        } 
      ] ,

      labels: ['Monto Invertido','Monto Disponible'],


    };
  },
  options: chart_pie_options ,
  enable: pdisponible,
  investment: pinvertido


}; 


 


let pdisponible = pdisponible
let pinvertido = pinvertido

/// ahora va el option del chart
let chart_pie_options = {
  maintainAspectRatio: true,
  legend: {
    display: true
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        DoughnutPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "#00ffc33d",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ],
    xAxes: [
      {
        Percentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ]
  }
};





import { 
    
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Container,
    Table,
    Row,
  
    Col,
    UncontrolledTooltip
  } from "reactstrap"; 

  
  
  const DivTable = styled.div `
    padding: 0rem !important;
    margin: 0.2rem 0rem 2.0rem 0rem !important;
    
    
    
    
    
    @media(min-width:992px) {
        margin-top:5rem;
    }

   



`;







 //// responsive
const Div = styled.div `
padding: 0rem !important;
margin: 0.2rem 0rem 2.0rem 0rem !important;
display:flex !important;
width: 100% !important;
flex-direction:row;
justify-content:center;





@media(min-width:992px) {
  display:none !important;
  justify-content:center;
  width: 100% !important;
}



`;

// pantalla grande
const DivModal = styled.div `

  display:none !important;
  flex-direction:row;
  
  
  
  

  @media(min-width:992px) {
    display:flex !important;
  }



`; 




/// aqui vamos a poner los estilos para el tap 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    alignItems:'center',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#00ff9d',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(11),
    padding:'0px 5px 0px 5px',
    margin:'0px 3px 0px 3px',
    alignItems:'center',
    
    
    display:'flex',
    marginRight: theme.spacing(0),
    '&:focus': {
      opacity: 0.5,
      background: '#000',
    },
  },
}))((props) => <Tab disableRipple {...props} />);
///// aqui terminan los estilos del tap





function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  rootlinear: {
    width: '100%',
    
  },
}));


const P = styled.p `
  
  background: #17ff6c;

`;

 



const ProductosDeInversion = ({apiload,ApiLoad}) => {   

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  

  const authContext = useContext(AuthContext);
  const { 
    proyectos_primario_cantidad,
    proyectos_primario,
    CerrarSesion,
   } = authContext;  



  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  const [ cargando, guardarCargando] = useState(false);

     

    const [proyecto, guardarProyectos] = useState([{
        amount:'', 
        amount_investment:'', 
        amount_invertido:'',
        amount_reserved:'', 
        approval_date:'', 
        approved_by:'', 
        category:'', 
        category_id:'', 
        code:'', 
        contract_type:'', 
        country_iso:'', 
        created_at:'', 
        created_by:'',
        cuenta_negociador:'',
         currency_code:'', 
         date_max_investment:'', 
         delay_percentage:'', 
         email_sent:'', 
         end_date:'', 
         fee_total_contract:'', 
         file:'', 
         finished_date:'', 
         frequency_payment:'', 
        id:'', 
        issuer:'', 
        loan_type:'', 
        min_amount_start:'',
        name:'' , 
        nrocontrato:'', 
        oferente:'', 
        oferente_id:'', 
        quantity:'', 
        quantity:'', 
        risk:'', 
        start_date:'',
        status:'', 
        tax_price_cuota:'', 
        tax_referential_client:'', 
        tax_referential_panther:'',
        total_inversores:'', 
        updated_at:'', 
        updated_by:'', 
        warranty:'',
        disponible: '',
        imagen:'',
        ganancia:'',
      }]);  


      

      /////// estilos del carousel de p3roductos 
  
  const [activeStep, setActiveStep] = React.useState(0);
  

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  }; 
   





      const [cantidad, guardarCantidades ] = useState({});
    


    useEffect( () => { 
      

        const consultarApiProductos = async () => { 

          
        
          // const {amount, amount_investment, amount_reserved, 
          //   approval_date, approved_by, category, category_id, 
          //   code, contract_type, country_iso, created_at, created_by,
          //   cuenta_negociador, currency_code, date_max_investment, delay_percentage
          //   ,email_sent, end_date, fee_total_contract, file, finished_date, frequency_payment, 
          //   id, issuer, loan_type, min_amount_start,name , nrocontrato, oferente, oferente_id, quantity, 
          //   risk, start_date, status, tax_price_cuota, tax_referential_client, tax_referential_panther,
          //   total_inversores, updated_at, updated_by, warranty, disponible,imagen
        
          
          // } = proyecto; 


          try {
            
          const respuesta = await clienteAxios.post('/api/client/projects/all_projects') 

          
          
          // guardarCantidades(proyectos_primario_cantidad)

          /// vamos a guardar la cantidad de los proyectos aqui
           guardarCantidades(parseInt(respuesta.data.cantidad))
          
          
          // guardar Balance 
          /// logica con el use reducer
          // guardarProyectos(proyectos_primario)
          guardarProyectos(respuesta.data.data)

                  
           
            
        } catch (error) { 
            
           
            CerrarSesion()
            
        }

    
          
         
        }
    
        consultarApiProductos()
    
        // guardarProyectos(proyecto);
        
        
        
    
      },[!apiload,proyectos_primario]); 




      const maxSteps = cantidad;


  // vamos a pasarle el la informacion de la fila al modal
  const [modalInfo, setModalInfo] = useState([]);
  const [chartstate, setChartState] = useState("data4");

  

  // vamos a extraer el context de emergencia para guardar los valores de la inversion 
  // despues 

  
  // vamos a hacer que el mensaje salga
  const [mensaje, guardarMensaje] = useState('');

  
   // configuracion del modal de material-ui
   const [ modalStyle ] =useState(getModalStyle);
   const [open, setOpen] =useState(false);

   const classes = useStyles();

   const handleOpen = () => {
       setOpen(true);
   } 

   const handleClose = () => {
       setOpen(false);
       guardarMensaje(null);
   }






  

    // estamos creando el state 
    const [contratomonto, guardarContratoMonto] = useState({
      amount:''
      
      
}); 

  

  const [project_id, guardarProjectid] = useState({
    project_id:''

  })
  // extraer usuario del state

  const {amount} = parseInt(contratomonto) 
  
 

  


  const crearInversion = async inversion => {
       
    const {contratomonto,projectid} = inversion;
    
   
    
    var amount=document.getElementById('amount').value;
  
    try {
        const respuesta = await clienteAxios.post('/api/client/investments/create',{amount,project_id});
        
      
      
      guardarMensaje(respuesta.data.message); 

      setTimeout(() => {
        guardarMensaje(null);
    },50000);
        
    } catch (error) {
      guardarMensaje(error.response.data.message);
      setTimeout(() => {
        guardarMensaje(null);
        setOpen(false);
    },50000);
       
    }
}




// funcion de numero

function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}



  const onChange = e => {

      // voy a colocar guardar usuario en el state
      var x = parseFloat(document.getElementById("amount").value);
      
      document.getElementById("totalarecibir").innerHTML ="0";
      if (x!="")
        {
            var porcentaje_diario=modalInfo.porcentaje_diario;
            var dias=modalInfo.dias;
            var calculo=parseFloat(dias)*parseFloat(porcentaje_diario)*parseFloat(x);
            calculo=(calculo/100) + parseFloat(x);
            if (!Number.isNaN(calculo))       
            document.getElementById("totalarecibir").innerHTML = currencyFormat(calculo);
        }
        else
        document.getElementById("totalarecibir").innerHTML ="0";
      
      guardarContratoMonto({
        [e.target.name] : parseFloat(e.target.value)
        
          
      }) 

  }


  /// cuando el usuario quiere iniciar seseion
  const onSubmit = e => {
      e.preventDefault(); 

      guardarCargando(true);

      crearInversion({ contratomonto,project_id});

  }
    








      

      
      // const productosdeinversion = ( cargando ) ? <Spinner/> :  
                                          
     



    return (  


      <Fade in={open}
        onClose={handleClose}
        timeout={1000}
        
        
        >        

  <Fragment>

          
  { cantidad > 0 ? <>

        <Col sm="12" xl="12" lg="12" md="12" className="height-fix-productos-inversion">
              <Card >
              <div> 



       <CardHeader> 
            
       <div className="header-container-pro">

         <div>
         <CardTitle tag="h2" className="card-title-fix">Productos de Inversión </CardTitle>
                  <small className="fix-mov-title mb-1"> Escoge tu oferta preferida</small>  
           
         </div> 


         <div> 
             
         <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    className="MobileSteper-Fix"

                    nextButton={
                      <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        Siguiente
                        
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        
                        Atrás
                      </Button>
                    }
                  />

         </div>


        </div>

                  


                  
                </CardHeader>
             
                <CardBody > 


                    <Row>
        <Col>
           
        <div className={classes.root}>
      
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents 
        className="proyectos-fix-steper"
        interval={90000}

        

      >
        {proyecto.map((step, index) => (
          <div key={step.id}  className="proyectos-fix-steper"> 
            {Math.abs(activeStep - index) <= 2 ? ( 

                  

                    <div className="row step-fix-panther">
                          <div className="grid-progress col-12"> 
                                                <div className="progress-joe-label mb-0"> 
                                                                
                                                                
                                                                <span className="text-center">{step.amount_invertido} $ </span>
                                                                <span className="text-center w-100">{step.percentage_investment} <strong>%</strong> de participación de la oferta</span>
                                                                <span className="text-center">{step.amount_proyecto} $</span>
                                                                  
                                                  </div>      

                                                  
                                                  <div className="progress-joe mb-0"> 
                                                                
                                                                
                                                                <p className="text-center">{step.percentage_investment} % </p>
                                                                <div className={classes.rootlinear}>
                                                                  <LinearProgress variant="determinate" value={`${parseInt(step.percentage_investment)}`} />
                                                                  
                                                                </div>
                                                                <p className="text-center">100% </p>
                                                                  
                                                  </div>   

                                                  <div className="progress-joe-label "> 
                                                                
                                                                
                                                                <span className="text-center "> Invertido </span>
                                                                <span className="text-center"></span>
                                                                <span className="text-center"> Total</span>
                                                                  
                                                </div>       
                                        </div>

                          <div className=" col-12  ">  


                                          <div className="grid-imagen-info-prod">

                                                <div className="elemento1">

                                                <div >{step.name} <br/>  
                                                  <span>
                                                  Fecha Fin:  {step.end_date} 
                                                  </span>   
                                                </div>

                                                </div>

                                                <div className="elemento2"> 

                                                <div>

                                                                  {step.imagen === "" ? <div className=""> 
                                                                        <img src={DefaultPanther} className="text-dark img-fix-productos"/>


                                                                    </div> : 
                                                                    <div className=""> 
                                                                        <img src={step.imagen} className="text-dark img-fix-productos"/>


                                                                    </div>
                                                                  }

                                                  </div> 

                                                                            
                        
                                                                            <div className="col-12  ">
                                                                              {step.name === "" ? <Spinner/> : <>

                                                                                        <h2 className="text-description">Descripción</h2>
                                                                                        <p className="pr-3 text-muted">
                                                                                        {step.description}
                                                                                      
                                                                                        </p> </>
                                                                                }
                                                                                        
                                                                                            </div>


                                                                                      
                                                  
                                                </div>  
                                            
                                            
                                            
                                          </div>


                                          <div className="btn-box-invest">
                                            <button
                                                            color="link"
                                                            title=""
                                                            type="button"
                                                            className="btn btn-primario btn-block btn-panther-power btn-login mt-0 btn-primario w-100 animation-lead p-2"
                                                            onClick={ () => {
                                                              handleOpen();
                                                              guardarProjectid(step.id) 
                                                              setModalInfo(step);
                                                            }
                                                            }
                                                            
                                                          > 
                                                    
                                                    ¡Invertir ahora!
                                                  </button> 
                                           </div>



                                          <div className="grid-productos-container">

                                                <div className="elemento1">

                                                <p className="text-center h2">Información adicional del proyecto <strong> {step.nrocontrato}</strong>  </p> 

                                                </div>

                                                <div className="elemento2"> 

                                                                            <div  className="text-center "> 
                                                                                  <p>Tipo de Oferta:   <strong> {step.tipo}</strong>   </p>
                                                                                  <p>Monto: <strong> {step.amount_proyecto} $ </strong> </p>
                                                                                  
                                                                            </div>  
                                                                            <div className="text-center">
                                                                                <p>Fecha de Rendimiento: <strong> {step.end_date} </strong> </p>
                                                                            <p>Monto Invertido <strong> {step.amount_invertido} $ </strong> </p> 
                                                                                  
                                                                                  
                                                                            </div>

                                                   

                                                                            <div className="text-center">
                                                                            
                                                                                  
                                                                                  <p>Tiempo Total de Contrato <strong> {step.dias} d. </strong> </p>
                                                                                  <p>Tiempo Restante de Contrato <strong> {step.tiemporemanente} d. </strong> </p>
                                                                                  
                                                                            </div>
                                                  
                                                </div>

                                             

                                                <div className="elemento4"> 
                                                    
                                                      <div>  
                                                            <i class="tim-icons icon-lock-circle"></i>
                                                            <p>Clasificación: <strong> {step.risk} </strong>  </p>
                                                      </div>

                                                      <div>  
                                                              <i class="tim-icons icon-single-02"></i> 
                                                              <p>Participantes: <strong> {step.total_inversores} </strong> </p>
                                                      </div>

                                                      <div>  
                                                              <i class="tim-icons icon-shape-star"></i>
                                                              <p> Garantía: <strong> {step.warranty} </strong> </p>
                                                      </div> 

                                                      <div>  
                                                              <i class="tim-icons icon-chart-bar-32"></i>
                                                              <p> Tasa de Interés: <strong> {step.tax_referential_client} % </strong> </p>
                                                      </div>



                                                  
                                                </div> 


                                                

                                          </div> 

                                          

                                          
                                          


                                          {/* <div className="container panther-proyect-info"> 

                                                    <div className="row text-help-proyectos"> 
                                                    
                                                    <Col xs="12" sm="12"> 
                                                    
                                                    <p className="text-center hh2">Información adicional del proyecto </p> 
                                                                              

                                                                              </Col>  

                                                        <Col xs="6" sm="6" className="col-help">
                                                                              <div  className="text-center "> 
                                                                                  <p>Tipo de Oferta:   <strong> {step.tipo}</strong>   </p>
                                                                                  <p>Monto: <strong> {step.amount_proyecto} $ </strong> </p>
                                                                                  <p>Monto Invertido <strong> {step.amount_invertido} $ </strong> </p> 
                                                                                  
                                                                                  


                                                                              </div>

                                                                              </Col>   
                                                                              <Col xs="6" sm="6" className="col-help"> 
                                                                            <div className="text-center">
                                                                            
                                                                                  <p>Fecha de Rendimiento: <strong> {step.end_date} </strong> </p>
                                                                                  <p>Tiempo Total de Contrato <strong> {step.dias} d. </strong> </p>
                                                                                  <p>Tiempo Restante de Contrato <strong> {step.tiemporemanente} d. </strong> </p>
                                                                                  
                                                                            </div>
                                                                            

                                                                            </Col>  
                                                                            <Col xs="12" sm="12" className="text-center fix-interes"> 
                                                                            
                                                                            <p>Tasa de Interés: <strong> {step.tax_referential_client} % </strong> </p>
                                                                              

                                                                              </Col>  



                                                                            <Col xs="4" sm="4" className="text-center iconhelp-productos"> 
                                                                            
                                                                            <i class="tim-icons icon-lock-circle"></i>
                                                                            <p>Riesgo: <strong> {step.risk} </strong>  </p>
                                                                            </Col>  
                                                                            <Col xs="4" sm="4" className="text-center iconhelp-productos"> 
                                                                            <i class="tim-icons icon-single-02"></i> 
                                                                            
                                                                            <p>Participantes: <strong> {step.total_inversores} </strong> </p>
                                                                            </Col>  
                                                                            <Col xs="4" sm="4" className="text-center iconhelp-productos"> 
                                                                            <i class="tim-icons icon-shape-star"></i>
                                                                            <p> Garantía: <strong> {step.warranty} </strong> </p>
                                                                            </Col>  

                                                                 </div>
                                                </div> */}

                                                  
                                          
                                          


                                          </div> 

                                          

                                          
                                                
                                         
                                          
                                          

                                          

                                         

                                                  

                          </div>

                                      ) : null}
                      </div>
        ))}
      </AutoPlaySwipeableViews>
     
    </div>
        
        
        </Col>

                      
                    
                    </Row>




                
                </CardBody> 

          
        </div> 



              </Card>
            </Col>   
                  </>

                    : 


                         <Col sm="12" xl="12" lg="12" md="12" className="height-fix-productos-inversion">
              <Card >
              <div>
                 
             
                <CardBody className="d-flex justify-content-center" > 
                
                 <Row className="box-depositos-seleccion-alerta justify-content-center col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  "> 
                        <Col  sm="12" xl="12" lg="12" md="12" className=" panther-open-titulo-alerta border-panther"> 
                
                        
                                    {cantidad === 0 ?  <> 
                                    
                                            <i className="tim-icons  icon-spaceship animation-icon-in mb-1"/>
                                                    <h2 className="animation-icon-in-dos mb-4">Productos</h2>
                                                      <h3 class="animate-raya-03 mb-2">En breve se cargarán los productos</h3>
                                                      <p className="text-center animation-icon-in-cuatro ">www.panther7.com | Proyecto de oferentes Panther 7</p>
                                                      
                                                        </> :
                                                      <Spinner/>
                                                      
                                                      }
                                      </Col>
                                                  
                                      </Row>                                         
                  </CardBody> 

                                  
                     </div> 



              </Card>
            </Col>   





                        
                                                         }

            
            <ModalPrimario  modalInfo={modalInfo} 
                                                      onSubmit={onSubmit} 
                                                      onChange={onChange}
                                                      amount={amount}
                                                      handleOpen={handleOpen}      
                                                      handleClose={handleClose} 
                                                      open={open} 
                                                      mensaje={mensaje}         
                                                      cargando={cargando}    
                                                      guardarCargando={guardarCargando} 
                                                      apiload={apiload}                     
                                                      ApiLoad={ApiLoad}
                                                      
                                                      /> 
       



                        </Fragment>
        </Fade>




    );
}
 
export default ProductosDeInversion;