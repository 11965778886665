
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Carbonbadge from "react-carbonbadge"

// reactstrap components
import { Container, Row, Nav, NavItem, NavLink } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <span className="carbon-badge d-none"><Carbonbadge darkMode={true} /></span>
      <Container fluid>
       <Nav>
       
           {/* <NavItem>
            <NavLink href="https://www.panther7.com/?ref=bdr-user-archive-footer">Panther 7</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://www.panther7.com/presentation?ref=bdr-user-archive-footer">About Us</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://www.panther7.com/blog?ref=bdr-user-archive-footer">Blog</NavLink>
          </NavItem> */} 
          <>
          </>
        </Nav>
        <div className="copyright text-dark text-right">
        
          © {new Date().getFullYear()} 
          {" "}
          
          <a className="text-prisma-footer"
            href="https://www.strprisma.com/"
            target="_blank"
          >
            
             Todos los derechos Reservados Str Prisma
          </a>{" "}
          Ultimate Robo-advisor  
        </div>
      </Container>
    </footer>
  );
}
 
export default Footer;

