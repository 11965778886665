import React, { useReducer } from 'react';
import AuthContext from './authContext.js';
import AuthReducer from './authReducer.js';

import clienteAxios from '../../config/axios.js';
import tokenAuth from '../../config/token.js';


import {
        OBTENER_USUARIO,
        LIMPIAR_ALERTA,
        USUARIO_AUTENTICADO,
        USUARIO_AUTENTICADO_ERROR,
        CERRAR_SESION,

        LOGIN_EXITOSO,
        LOGIN_ERROR,

        OLVIDO_PASSWORD,
        OLVIDO_PASSWORD_ERROR,

        REGISTRO_EXITOSO, 
        REGISTRO_ERROR,

        LISTAR_MIS_DATOS,
        LISTAR_MIS_DATOS_ERROR,

        CAMBIAR_PASSWORD,
        CAMBIAR_PASSWORD_ERROR,

        ACTUALIZAR_DATOS,
        ACTUALIZAR_DATOS_ERROR,

        LISTADO_CONTRATOS,
        LISTADO_CONTRATOS_ERROR,

        USER_STATUS,

        RETIRO_LIST,
        RETIRO_LIST_ERROR ,
        
        DEPOSIT_LIST,
        DEPOSIT_LIST_ERROR, 

        CONTENIDO_PROYECTOS,
        BANNER_PROYECTOS,

        CONTENIDO_INVERSIONES,
        BANNER_INVERSIONES,

        CONTENIDO_OFERTAS,
        BANNER_OFERTAS,

        LISTA_NACIONALES,
        LISTA_NACIONALES_ERROR,

        LISTA_INTERNACIONALES,
        LISTA_INTERNACIONALES_ERROR,

        LISTA_REMESAS,
        LISTA_REMESAS_ERROR,

       TODOS_TUS_RETIROS_ERROR,
        TODOS_TUS_RETIROS,

        CANTIDAD_RETIROS,
        CANTIDAD_DEPOSITOS,

        TODOS_TUS_DEPOSITOS_ERROR,
        TODOS_TUS_DEPOSITOS,

        FILENAME,
        
        LISTA_OFERTAS,
        LISTA_OFERTAS_TOTALES_PAGINAS,

        LISTA_OFERENTE,
        LISTA_OFERENTE_TOTALES_PAGINAS,

        MIS_INVERSIONES_ACTIVAS,
        MIS_INVERSIONES_ACTIVAS_TOTALES_PAGINAS,

        MIS_INVERSIONES_VENDIDAS,
        MIS_INVERSIONES_VENDIDAS_TOTALES_PAGINAS,

        FILTROS_PROYECTOS, 
        FILTROS_INVERSIONES,

        PROYECTOS_PRIMARIOS,
        
        PROYECTOS_PRIMARIOS_CANTIDAD,
        PROYECTOS_PRIMARIOS_TOTAL_PAGINA,
        PROYECTOS_PRIMARIOS_PAGINA,

        PROYECTOS_SECUNDARIOS,

        PROYECTOS_SECUNDARIOS_CANTIDAD,
        PROYECTOS_SECUNDARIOS_PAGINA,
        PROYECTOS_SECUNDARIOS_TOTAL_PAGINA,

        FILTRO_PROYECTOS_PAIS,
        FILTRO_PROYECTOS_TIPO_OFERTA,
        FILTRO_PROYECTOS_EMISOR,


        SALDOS_DASH,
        ULTIMOS_MOVIMIENTOS,

        ULTIMOS_MOVIMIENTOS_CANTIDAD,
        ULTIMOS_MOVIMIENTOS_PAGE,
        ULTIMOS_MOVIMIENTOS_TOTAL_PAGE,

        ULTIMOS_MOVIMIENTOS_CSV, 
        ULTIMOS_MOVIMIENTOS_PDF,

        DEMO_STATUS,

        VALORTOTAL,
        EQUIDADTOTAL,
        MARGENLIBRETOTAL,
        FLOTANTE,
        GANANCIADIARIA,
        GANANCIAESPERADA,
        FLOTANTEHOY,
        MARGENLIBREHOY,
        CAMBIAOACTUAL,

        COLLAPSE_TRUE,
        COLLAPSE_FALSE,

        CARGANDO_PRISMA,
        CARGANDO_PRISMA_X,

        CARGANDO_PRISMA_NULL,
        CARGANDO_PRISMA_X_NULL,

        FAQ_DATA,
        GALLERY_ADU_CLIENT,

        LAST_CONTRACT
      
        


} from '../../types'; 


/// vamos a definir a nuestro state

const AuthState = ({children}) => {

    ///inicializar un state 
    const initialState = {
        token: typeof window !== 'undefined' ? localStorage.getItem('token') : null,
        // token: null,
        autenticado: null,
        usuario:null,
        mensaje:null,
        status:null,
        depositos:null,
        retiros:null,
        saldos:null,

        proyectos_primario:null,
        proyectos_secundarios:null,

        mis_inversiones_activas:null,
        mis_inversiones_activas_totales_paginas:null,

        mis_inversiones_vendidas:null,
        mis_inversiones_vendidas_totales_paginas:null,

        mis_ofertas_inversor:null,
        mis_ofertas_inversor_total_paginas:null,
        mis_ofertas_oferente:null,


        contenido_proyectos:null,
        banner_proyectos:null,
        contenido_inversiones:null,
        banner_inversiones:null,
        contenido_ofertas:null,
        banner_ofertas:null,

        cuentas_nacionales:null,
        cuentas_internacionales:null,
        cuentas_remesas:null,
        filename:null, 

        filtro_proyectos:null,
        filtro_proyectos_pais:null,
        filtro_proyectos_tipo_oferta:null,
        filtro_proyectos_emisor:null,
        filtro_inversiones:null,

        proyectos_secundarios_cantidad:null,
        proyectos_secundarios_total_pagina:null,
        proyectos_secundarios_pagina:null,


        proyectos_primario_total_pagina:null,
        proyectos_primario_cantidad:null,
        proyectos_primario_pagina:null,

        ultimos_movimientos:null,
        ultimos_movimientos_cantidad:null,
                
        ultimos_movimientos_page:null,
        ultimos_movimientos_total_page:null,

        ultimos_movimientos_csv:null,
        ultimos_movimientos_pdf:null,


        valor_total:null,
        equidad_total:null,
        margin_libre_total:null,
        flotante:null,
        ganancia_diaria:null,
        ganancia_esperada:null,
        flotante_hoy:null,
        margen_libre_hoy:null,
        cambio_actual:null,

        collapse:true,


        demo_status:"no-active",

        prisma_load:false,
        recargandoapp:false,

        faq_data:null,
        gallery_data:null,
        last_contract:null,
        
        
    }

    // siempre va a utilizar 2 parametros el 
    // el reducer que quieras usar y el state inicial
    const [ state, dispatch ] = useReducer(AuthReducer,initialState);

    // las funciones 

    // funcion para registrar usuario


    const userStatus = async () =>{

        

        try {
            const respuesta = await clienteAxios.post('/api/client/getstatus');


            

            dispatch({
                type:USER_STATUS,
                payload: respuesta.data.data
            }) ;
            
            
            // una ves que sea un registro exitoso, vamos a obtener el usuario
            

        } catch (error) {
             

            dispatch({
                type: USER_STATUS,
                payload:error.response.data.message
            }) 

        } 
        

        // setTimeout(() => {
        //     dispatch({
        //         type: LIMPIAR_ALERTA,
        //     })
        // },4000);

        

     }   

     const CollapseNav = () => {
            dispatch({
                    type:COLLAPSE_FALSE,
                    payload:false
            })
     }


     const ExpandNav = () => {
        dispatch({
                type:COLLAPSE_TRUE,
                payload:true
        })

    } 


    const PrismaNoLoad = () => {
        dispatch({
                type:CARGANDO_PRISMA_X,
                payload:false
        })
    }


    const PrismaLoad = () => {
        dispatch({
                type:CARGANDO_PRISMA,
                payload:true
        })

    } 

    const PrismaNoLoadApp = () => {
        dispatch({
                type:CARGANDO_PRISMA_X_NULL,
                payload:false
        })
    }


    const PrismaLoadApp = () => {
        dispatch({
                type:CARGANDO_PRISMA_NULL,
                payload:true
        })

    } 


    const Faq = async datos => {


        try{
            const respuesta = await clienteAxios.post('/api/setting/faq')

            dispatch({
                type:FAQ_DATA,
                payload:respuesta.data.data
            });
        } catch (error) {
            return
        }



    }


     
     const Movimientos = async datos => { 

      

  

        try {
            
            const respuesta = await clienteAxios.post("/api/client/balance/list")
            
            
            dispatch({
                type:ULTIMOS_MOVIMIENTOS,
                payload:respuesta.data.data
            });

            dispatch({
                type:ULTIMOS_MOVIMIENTOS_CANTIDAD,
                payload:parseInt(respuesta.data.cantidad)
            });

            dispatch({
                type:ULTIMOS_MOVIMIENTOS_PAGE,
                payload:respuesta.data.page
            });

            dispatch({
                type:ULTIMOS_MOVIMIENTOS_TOTAL_PAGE,
                payload:respuesta.data.total_pages
            });
            // dispatch({
            //     type:ULTIMOS_MOVIMIENTOS_CSV,
            //     payload:respuesta.data.csv
            // });

            // dispatch({
            //     type:ULTIMOS_MOVIMIENTOS_PDF,
            //     payload:respuesta.data.pdf
            // });



            
        } catch (error) {
            
            dispatch({
                type:CERRAR_SESION,
                payload:error.response.data.message
            }) ;
            
        }
    } 



    const Saldos = async datos => { 

      
        try {
            
            const respuesta = await clienteAxios.post('/api/client/dashboard');
            


            dispatch({
                type:VALORTOTAL,
                payload:respuesta.data.data.saldos[0].VALORTOTAL
            }); 

            dispatch({
                type:EQUIDADTOTAL,
                payload:respuesta.data.data.saldos[0].EQUIDADTOTAL
            }); 

            dispatch({
                type:MARGENLIBRETOTAL,
                payload:respuesta.data.data.saldos[0].MARGENLIBRETOTAL
            }); 

            dispatch({
                type:FLOTANTE,
                payload:respuesta.data.data.saldos[0].FLOTANTE
            }); 

            dispatch({
                type:GANANCIADIARIA,
                payload:respuesta.data.data.saldos[0].GANANCIADIARIA
            }); 


            dispatch({
                type:GANANCIAESPERADA,
                payload:respuesta.data.data.saldos[0].GANANCIAESPERADA
            }); 


            dispatch({
                type:FLOTANTEHOY,
                payload:respuesta.data.data.saldos[0].FLOTANTEHOY
            }); 

            
            dispatch({
                type:MARGENLIBREHOY,
                payload:respuesta.data.data.saldos[0].MARGENLIBREHOY
            }); 

            
            dispatch({
                type:CAMBIAOACTUAL,
                payload:respuesta.data.data.saldos[0].CAMBIAOACTUAL
            }); 
 
            dispatch({
                type:SALDOS_DASH,
                payload:respuesta.data.data.saldos
            });
            
        } catch (error) {
            
            dispatch({
                type:CERRAR_SESION,
                payload:error.response.data.message
            }) ;
            
        }
    } 



     const TodosProyectosPrimario = async datos => { 

      

        const busqueda = {
            proyecto:'',
            vencimiento:'',
            monto:'',
        } 

        const paginaactual = {
            page:paginaactual, 
         }



        try {
            const busquedaop = {...busqueda, ...paginaactual }
            const respuesta = await clienteAxios.post('/api/client/projects',busquedaop)
            const resultado = await clienteAxios.post('/api/projects/filtros')
            
                
               
            //////vamos a colocar el resultado del mercado primario
            dispatch({
                type:PROYECTOS_PRIMARIOS,
                payload:respuesta.data.data
            });

              dispatch({
                  type:PROYECTOS_PRIMARIOS_CANTIDAD,
                  payload:parseInt(respuesta.data.cantidad)
              });

              dispatch({
                type:PROYECTOS_PRIMARIOS_PAGINA,
                payload:respuesta.data.page

              });

              dispatch({
                type:PROYECTOS_PRIMARIOS_TOTAL_PAGINA,
                payload:respuesta.data.total_pages

              });

              /// vamos a colocar las respuestas que nos dio el filtro

              dispatch({
                type:FILTRO_PROYECTOS_PAIS,
                payload:resultado.data.data.paises
            });
            
            dispatch({
                type:FILTRO_PROYECTOS_TIPO_OFERTA,
                payload:resultado.data.data.tipo_oferta
            });
            
            dispatch({
                type:FILTRO_PROYECTOS_EMISOR,
                payload:resultado.data.data.emisor
            });


        
            
        } catch (error) {
            
            dispatch({
                type:CERRAR_SESION,
                payload:error.response.data.message
            }) ;
            
        }
    } 


    const TodosProyectosSecundario = async datos => { 

      

        const busqueda = {
            proyecto:'',
            vencimiento:'',
            monto:'',
        } 

        const paginaactual = {
            page:paginaactual, 
         }



        try {
            const busquedaop = {...busqueda, ...paginaactual }
            const respuesta = await clienteAxios.post('/api/client/projects/secundario')
            


            
            dispatch({
                type:PROYECTOS_SECUNDARIOS,
                payload:respuesta.data.data
            });

              dispatch({
                  type:PROYECTOS_SECUNDARIOS_CANTIDAD,
                  payload:parseInt(respuesta.data.cantidad)
              }); 


              dispatch({
                type:PROYECTOS_SECUNDARIOS_PAGINA,
                payload:respuesta.data.page

              });

              dispatch({
                type:PROYECTOS_SECUNDARIOS_TOTAL_PAGINA,
                payload:respuesta.data.total_pages

              });


            // dispatch({
            //     type:LISTA_OFERTAS_ACTUAL_PAGINA,
            //     payload:{
            //         page:`${paginaactualcount}`,
            //       }
            // });

            
            
        } catch (error) {
            
            dispatch({
                type:CERRAR_SESION,
                payload:error.response.data.message
            }) ;
            
        }
    } 


     




     const TodosInversionesVendidas = async datos => { 

      

        const busqueda = {
            proyecto:'',
            vencimiento:'',
            monto:'',
        } 

        const paginaactual = {
            page:paginaactual, 
         }



        try {
            const busquedaop = {...busqueda, ...paginaactual }
            const respuesta = await clienteAxios.post('/api/client/investments/sold ', busquedaop)
            


            
            dispatch({
                type:MIS_INVERSIONES_VENDIDAS,
                payload:respuesta.data.data.datos
            });

              dispatch({
                  type:MIS_INVERSIONES_VENDIDAS_TOTALES_PAGINAS,
                  payload:respuesta.data.data.totales
              });

            // dispatch({
            //     type:LISTA_OFERTAS_ACTUAL_PAGINA,
            //     payload:{
            //         page:`${paginaactualcount}`,
            //       }
            // });
            
        } catch (error) {
            
            dispatch({
                type:CERRAR_SESION,
                payload:error.response.data.message
            }) ;
            
        }
    } 





     
    const TodosInversiones = async datos => { 

      

        const busqueda = {
            proyecto:'',
            vencimiento:'',
            monto:'',
        } 

        const paginaactual = {
            page:paginaactual, 
         }



        try {
            const busquedaop = {...busqueda, ...paginaactual }
            const respuesta = await clienteAxios.post('/api/client/investments', busquedaop)
            


            
            dispatch({
                type:MIS_INVERSIONES_ACTIVAS,
                payload:respuesta.data.data.datos
            });

              dispatch({
                  type:MIS_INVERSIONES_ACTIVAS_TOTALES_PAGINAS,
                  payload:respuesta.data.data.totales
              });

            // dispatch({
            //     type:LISTA_OFERTAS_ACTUAL_PAGINA,
            //     payload:{
            //         page:`${paginaactualcount}`,
            //       }
            // });

            
            
        } catch (error) {
            
            dispatch({
                type:CERRAR_SESION,
                payload:error.response.data.message
            }) ;
            
        }
    } 





    const TodosOferentes = async datos => { 

      

        const busqueda = {
            proyecto:'',
            vencimiento:'',
            monto:'',
        } 

        const paginaactual = {
            page:paginaactual, 
         }



        try {
            const busquedaop = {...busqueda, ...paginaactual }
            const respuesta = await clienteAxios.post('/api/client/projects/propios', busquedaop)
            


            
            dispatch({
                type:LISTA_OFERENTE,
                payload:respuesta.data.data
            });

            //  dispatch({
            //      type:LISTA_OFERENTE_TOTALES_PAGINAS,
            //      payload:respuesta.data.data.totales
            //  });

            // dispatch({
            //     type:LISTA_OFERTAS_ACTUAL_PAGINA,
            //     payload:{
            //         page:`${paginaactualcount}`,
            //       }
            // });

            
            
        } catch (error) {
            
            dispatch({
                type:CERRAR_SESION,
                payload:error.response.data.message
            }) ;
            
        }
    } 






    const TodosOfertas = async datos => { 

      

        const busqueda = {
            proyecto:'',
            vencimiento:'',
            monto:'',
        } 

        const paginaactual = {
            page:paginaactual, 
         }



        try {
            const busquedaop = {...busqueda, ...paginaactual }
            const respuesta = await clienteAxios.post('/api/client/offers', busquedaop)
            const resultado = await clienteAxios.post('/api/client/investments/filtros'); 


            dispatch({
                type:FILTROS_INVERSIONES,
                payload:resultado.data.data.proyectos
            });




            
            dispatch({
                type:LISTA_OFERTAS,
                payload:respuesta.data.data.datos
            });

             dispatch({
                 type:LISTA_OFERTAS_TOTALES_PAGINAS,
                 payload:respuesta.data.data.totales
             });

            // dispatch({
            //     type:LISTA_OFERTAS_ACTUAL_PAGINA,
            //     payload:{
            //         page:`${paginaactualcount}`,
            //       }
            // });

            
            
        } catch (error) {
            
            dispatch({
                type:CERRAR_SESION,
                payload:error.response.data.message
            }) ;
            
        }
    } 





    /// aqui vamos a definir los depositos 
    const TodosDepositos = async datos => {

        try {
            
            const respuesta = await clienteAxios.post('/api/client/deposits/list')
            
            
            dispatch({
                type:TODOS_TUS_DEPOSITOS,
                payload:respuesta.data.data
            });

            dispatch({
                type:CANTIDAD_DEPOSITOS,
                payload:parseInt(respuesta.data.cantidad)
            });

            
            
        } catch (error) {
            
            dispatch({
                type:TODOS_TUS_DEPOSITOS_ERROR,
                payload:error.response.data.message
            }) ;
            
        }
    } 


    ////// vammos a cargar los depositos

    const TodosPdfDepositos = async datos => {

        try {
            
            const respuesta = await clienteAxios.post(`/api/client/deposits/pdf`);
            
            
            dispatch({
                type:FILENAME,
                payload:respuesta.data
            });

           
            
        } catch (error) {
            
            dispatch({
                type:CERRAR_SESION,
                payload:error.response.data.message
            }) ;
            
        }
    }  



    



    //// aqui vamos a llamar y a definir las apis dela lista de retiros

    const TodosRetiros = async datos => {

        try {
            
            const respuesta = await clienteAxios.post('/api/client/withdrawall/list')
            
            
            dispatch({
                type:TODOS_TUS_RETIROS,
                payload:respuesta.data.data
            });

            dispatch({
                type:CANTIDAD_RETIROS,
                payload:parseInt(respuesta.data.cantidad)
            });

            
            
        } catch (error) {
            
            dispatch({
                type:TODOS_TUS_RETIROS_ERROR,
                payload:error.response.data.message
            }) ;
            
        }
    } 








    /// aqui vamos a colocar la lista de lista de beneficiarios

    const ListaNacionales = async datos => {
       


        try {
            const propiasbank = {bank_personal:'1'}
            const respuesta = await clienteAxios.post('/api/client/beneficiarios',propiasbank)
            
            
            dispatch({
                type:LISTA_NACIONALES,
                payload:respuesta.data.data
            }) ;

            
            
        } catch (error) {
            
            dispatch({
                type:LISTA_NACIONALES_ERROR,
                payload:error.response.data.message
            }) ;
            
        }
    } 

    const ListaInternacionales = async datos => {
       


        try {
            const tercerosbank = {bank_personal:'2'}
            const respuesta = await clienteAxios.post('/api/client/beneficiarios',tercerosbank)
            
            
            dispatch({
                type:LISTA_INTERNACIONALES,
                payload:respuesta.data.data
            }) ;

            
            
        } catch (error) {
            
            dispatch({
                type:LISTA_INTERNACIONALES_ERROR,
                payload:error.response.data.message
            }) ;
            
        }
    } 


    const ListaRemesas = async datos => {
       


        try {
            const remesabank = {bank_personal:'3'}
            const respuesta = await clienteAxios.post('/api/client/beneficiarios',remesabank)
            
            
            dispatch({
                type:LISTA_REMESAS,
                payload:respuesta.data.data
            }) ;

            
            
        } catch (error) {
            
            dispatch({
                type:LISTA_REMESAS_ERROR,
                payload:error.response.data.message
            }) ;
            
        }
    }














    //////////////////// aqui vamos a colocar las funciones aisladas

    const Limpiar = async datos => {
        dispatch({type: LIMPIAR_ALERTA,
                    payload: null
        })
    } 


    const MostrarProyectos = async datos => {
        dispatch({
                    type: CONTENIDO_PROYECTOS,
                    payload: 1
        })
    } 

    const AtrasProyectos = async datos => {
        dispatch({
                    type: CONTENIDO_PROYECTOS,
                    payload: null
        })
    } 


    const MostrarInversiones = async datos => {
        dispatch({
                    type: CONTENIDO_INVERSIONES,
                    payload: 1
        })
    } 

    const AtrasInversiones = async datos => {
        dispatch({
                    type: CONTENIDO_INVERSIONES,
                    payload: null
        })
    } 



    const MostrarOfertas = async datos => {
        dispatch({
                    type: CONTENIDO_OFERTAS,
                    payload: 1
        })
    }

    const AtrasOfertas = async datos => {
        dispatch({
                    type: CONTENIDO_OFERTAS,
                    payload: null
        })
    } 


    const LimpiarMensaje = async () => {
        dispatch({
                    type: LIMPIAR_ALERTA, 
                 })

    } 
 




//////// vamos a colocar las fucniones de la galleria de los adus
           const galleryAduClient = async datos => { 

                    try{
                        const respuesta = await clienteAxios.post('/api/client/contract/get_gallery_adu');
                        dispatch({
                            type:GALLERY_ADU_CLIENT,
                            payload:respuesta.data.data
                        });
                    }  catch (error) {
                        return
                    }

                }

    /////// vamos a colocar la funcion que nos da el ultimo contrato generado
            const LastContract = async datos => {

                try {

                    const respuesta = await clienteAxios.post('/api/client/contract/get_last_contract')
                    dispatch({
                        type:LAST_CONTRACT,
                        payload:respuesta.data.data
                    })

                } catch (error) {
                    return
                }

            }




    const userStatusFun = async datos =>{

        

        try {
            const respuesta = await clienteAxios.post('/api/client/authenticate',datos);

                  


            dispatch({
                type:USER_STATUS,
                payload: respuesta.data.success.user.status
            }) ;
            
            
            // una ves que sea un registro exitoso, vamos a obtener el usuario
            

        } catch (error) {
             

            dispatch({
                type: USER_STATUS,
                payload:error.response.data.message
            }) 

        } 

        // setTimeout(() => {
        //     dispatch({
        //         type: LIMPIAR_ALERTA,
        //     })
        // },4000);

        

    }   



    /// aqui vamos a colocar el listado de depositos 
    const depositList = async datos => {
       
        const token = localStorage.getItem('token');
        

        
        try {
            const respuesta = await clienteAxios.post('/api/client/deposits/list ',datos);
            
            
            dispatch({
                type:DEPOSIT_LIST,
                payload: respuesta.data.data
            }) ;
            
        } catch (error) {
            
           return
            
        } 


    }  

/// aqui vamos a colocar el listado de retiros
    const retirosList = async datos => {
       
        const token = localStorage.getItem('token');
        

        
        try {
            const respuesta = await clienteAxios.post('/api/client/withdrawall/list ',datos);
            
            
            dispatch({
                type:RETIRO_LIST ,
                payload: respuesta.data.data
            }) ;
            
        } catch (error) {
            
            return
            
        } 

        
    }  





    // api 3
    const registrarUsuario = async datos => {

        try {
            const respuesta = await clienteAxios.post('/api/client/register', datos);
            

            dispatch ({
                type: REGISTRO_EXITOSO,
                payload: respuesta.data.message
            }),


            PrismaNoLoad()

        } catch (error) {
            dispatch({
                type: CERRAR_SESION,
                payload:error.response.data.message
            }),
            PrismaNoLoad() 

        } 

        // setTimeout(() => {
        //     dispatch({
        //         type: LIMPIAR_ALERTA,
        //     })
        // },2000);

        
    } 
    

    const usuarioAutenticado = async () => {
       
        const token = localStorage.getItem('token');
        
        

        if(token){
            tokenAuth(token);
            
        }

        try {
            const respuesta = await clienteAxios.post('/api/client/profile');
            
            
            dispatch({
                type:USUARIO_AUTENTICADO,
                payload:respuesta.data
            }) ;

            
            
        } catch (error) {
            
            dispatch({
                type:USUARIO_AUTENTICADO_ERROR,
                payload:error.response.data.message
            }) ;
            
        }
    }

    // api 1
    const iniciarSesion = async datos =>{ 


        


        try {
            const respuesta = await clienteAxios.post('/api/client/authenticate', datos);
            
            dispatch({
                type:LOGIN_EXITOSO,
                payload: respuesta.data
            }) ;
            
            
            usuarioAutenticado(),
            userStatus(),
            // PrismaNoLoadApp(),
            Saldos(),
            LastContract(),
            galleryAduClient(),
            
            
            // TodosProyectosPrimario(),
            // TodosProyectosSecundario(),
             Movimientos(),
             ListaNacionales(),
             ListaInternacionales(),
            ListaRemesas(),
             TodosRetiros(),
             TodosDepositos(),
            //  TodosPdfDepositos(),
             Faq()
            // TodosOfertas(),
            // TodosOferentes(),
            // TodosInversiones(),
            // TodosInversionesVendidas()
            
            



            
            

            
            
           
            
            // una ves que sea un registro exitoso, vamos a obtener el usuario
            

        } catch (error) {
             
            
            dispatch({
                type: CERRAR_SESION,
                payload:error.response.data.message
            }),
            PrismaNoLoad() 

            

        } 

         setTimeout(() => {
            dispatch({
                 type: LIMPIAR_ALERTA,
            })
      },100000);

      

    }   
    
    // api 2
    const olvidoPassword = async datos => {
       
        const token = localStorage.getItem('token');
        

        
        try {
            const respuesta = await clienteAxios.post('/api/client/forgotpassword ',datos);
            
            
            dispatch({
                type:OLVIDO_PASSWORD,
                payload: respuesta.data.message
            }) ;
            
        } catch (error) {
            
            dispatch({
                type:OLVIDO_PASSWORD_ERROR,
                payload:error.response.data.message
            }) ;
            
        } 

        setTimeout(() => {
            dispatch({
                type: LIMPIAR_ALERTA,
            })
        },4000);

    }   


    const resendCorreo = async datos => {
       
        
        

        
        try {
            const respuesta = await clienteAxios.post('/api/cliente/resend_email',datos);
            
            
            dispatch({
                type:OLVIDO_PASSWORD,
                payload: respuesta.data.message
            }) ;
            
        } catch (error) {
            
            dispatch({
                type:OLVIDO_PASSWORD_ERROR,
                payload:error.response.data.message
            }) ;
            
        } 

      
    }  


    /// api 4 listar datos
    const listDatos = async datos => {
       
        const token = localStorage.getItem('token');
        
        try {
            const respuesta = await clienteAxios.post('/api/client/profile');
            

            dispatch({
                type:LISTAR_MIS_DATOS,
                payload: respuesta.data
            }) ;
            
        } catch (error) {
            
            dispatch({
                type:LISTAR_MIS_DATOS_ERROR,
                payload:error.response.data.message
            }) ;
            
        }
    }  

      /// api 5 cambiar password
       const cambiarPassword = async usuario => {
       
        const {current_password,new_password,confirm_password} = usuario;

        try {
            const respuesta = await clienteAxios.post('/api/client/changepassword', {current_password,new_password,confirm_password});
             

             dispatch({
                type:CAMBIAR_PASSWORD,
                 payload: respuesta.data.message
            }) ;
            
         } catch (error) {
             
             dispatch({
                 type:CAMBIAR_PASSWORD_ERROR,
                 payload:error.response.data.message
             }) ;
            
         } 

         setTimeout(() => {
            dispatch({
                type: LIMPIAR_ALERTA,
            })
        },6000);

        }
    
    // api 6 actualizar datos 
    const actualizarDatos = async datos => { 

      
        
        
        try {
            const respuesta = await clienteAxios.post('/api/client/update',{datos  });
            
                  
                  
            dispatch({
                type:ACTUALIZAR_DATOS,
                payload:respuesta.data.message
            }) ;
            
        } catch (error) { 
            
           
            dispatch({
                type:ACTUALIZAR_DATOS_ERROR,
                payload:error.response
            }) ;
            
        }
    }  


    // api 7 listado de contratos 
    const listadoContratos = async datos => {
       
        const token = localStorage.getItem('token');
        
        try {
            const respuesta = await clienteAxios.post('/api/client/projects',{"name" : "Joel Mariscal Florez"});
            

            dispatch({
                type:LISTADO_CONTRATOS,
                payload: respuesta.data
            }) ;
            
        } catch (error) {
            
            dispatch({
                type:LISTADO_CONTRATOS_ERROR,
                payload:error.response.data.message
            }) ;
            
        }
    } 












    const CerrarSesion = () => {
       
        dispatch({
            type:CERRAR_SESION
        })
       
    }
     



 
    

    return (

        // dentro de la primera llave vamos a pasar el toquen y 
        // si el usuario esta autenticado

        // aqui le estamos pasando los datos que creamos en las funciones
            <AuthContext.Provider

            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                mensaje: state.mensaje,
                status: state.status,

                contenido_proyectos:state.contenido_proyectos,
                banner_proyectos:state.banner_proyectos,

                contenido_inversiones:state.contenido_inversiones,
                banner_inversiones:state.banner_inversiones,

                contenido_ofertas:state.contenido_ofertas,
                banner_ofertas:state.banner_ofertas,

                cuentas_nacionales:state.cuentas_nacionales,
                cuentas_internacionales:state.cuentas_internacionales,
                cuentas_remesas:state.cuentas_remesas,

                retiros:state.retiros,
                retiros_cantidad:state.retiros_cantidad,
                
                depositos:state.depositos,
                depositos_cantidad:state.depositos_cantidad,
                filename:state.filename,

                mis_ofertas_inversor:state.mis_ofertas_inversor,
                mis_ofertas_inversor_total_paginas:state.mis_ofertas_inversor_total_paginas,

                mis_ofertas_oferente:state.mis_ofertas_oferente,
                mis_ofertas_oferente_total_paginas:state.mis_ofertas_oferente_total_paginas,

                mis_inversiones_activas:state.mis_inversiones_activas,
                mis_inversiones_activas_totales_paginas:state.mis_inversiones_activas_totales_paginas,

                mis_inversiones_vendidas:state.mis_inversiones_vendidas,
                mis_inversiones_vendidas_totales_paginas:state.mis_inversiones_vendidas_totales_paginas,

                proyectos_primario:state.proyectos_primario,
                proyectos_secundarios:state.proyectos_secundarios,


                proyectos_primario_pagina:state.proyectos_primario_pagina,
                proyectos_secundarios_pagina:state.proyectos_secundarios_pagina,

                proyectos_primario_cantidad:state.proyectos_primario_cantidad,
                proyectos_secundarios_cantidad:state.proyectos_secundarios_cantidad,

                filtro_inversiones:state.filtro_inversiones,
                filtro_proyectos:state.filtro_proyectos,

                filtro_proyectos_pais:state.filtro_proyectos_pais,
                filtro_proyectos_tipo_oferta:state.filtro_proyectos_tipo_oferta,
                filtro_proyectos_emisor:state.filtro_proyectos_emisor,

                saldos:state.saldos,

                ultimos_movimientos:state.ultimos_movimientos,
                ultimos_movimientos_cantidad:state.ultimos_movimientos_cantidad,

                ultimos_movimientos_page:state.ultimos_movimientos_page,
                ultimos_movimientos_total_page:state.ultimos_movimientos_total_page,

                ultimos_movimientos_csv:state.ultimos_movimientos_csv,
                ultimos_movimientos_pdf:state.ultimos_movimientos_pdf,
                proyectos_primario_total_pagina:state.proyectos_primario_total_pagina,
                proyectos_secundarios_total_pagina:state.proyectos_secundarios_total_pagina,

                demo_status:state.demo_status,
                collapse:state.collapse,
                prisma_load:state.prisma_load,
                recargandoapp:state.recargandoapp,
                

                valor_total:state.valor_total,
                equidad_total:state.equidad_total,
                margin_libre_total:state.margin_libre_total,
                flotante:state.flotante,
                ganancia_diaria:state.ganancia_diaria,
                ganancia_esperada:state.ganancia_esperada,
                flotante_hoy:state.flotante_hoy,
                margen_libre_hoy:state.margen_libre_hoy,
                cambio_actual:state.cambio_actual,
                faq_data:state.faq_data,
                gallery_data:state.gallery_data,
                last_contract:state.last_contract,

                Faq,
                galleryAduClient,
                LastContract,

                PrismaLoad,
                PrismaNoLoad,

                PrismaLoadApp,
                PrismaNoLoadApp,

                userStatus,
                userStatusFun,
                usuarioAutenticado,
                iniciarSesion,
                olvidoPassword,
                registrarUsuario,
                listDatos,
                cambiarPassword,
                depositList,
                retirosList,
                Limpiar,
                resendCorreo,

                ListaInternacionales,
                ListaNacionales,
                ListaRemesas,

                TodosRetiros,
                TodosDepositos,
                TodosPdfDepositos,

                TodosOfertas,
                TodosOferentes,
                TodosInversiones,
                TodosInversionesVendidas,

                TodosProyectosSecundario,
                TodosProyectosPrimario,



                MostrarOfertas,
                MostrarInversiones,
                MostrarProyectos,

                AtrasProyectos,
                AtrasInversiones,
                AtrasOfertas,

                LimpiarMensaje,

                
                Saldos,
                Movimientos,
                actualizarDatos,
                listadoContratos,
                CerrarSesion,

                CollapseNav,
                ExpandNav 
                

            }} 
            
            > 
            {children}

            </AuthContext.Provider>

    )



}

export default AuthState;