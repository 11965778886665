
import React, {useContext,useEffect, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import SaldosdelCliente from './SaldodelClienteOri';
import UltimosMov from './UltimosMov';
import ProductosDeInversion from './ProductosDeInversion';
import Grafica from './Grafica';
import GraficaDoughnut from './GraficaDoughnut';
import ParticlesBackground from '../components/Particles/ParticlesBackground'

import Machine from '../components/IconsAnimation/Machine'

import { Doughnut } from "react-chartjs-2";


// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap"; 


import AuthContext from  '../context/autenticacion/authContext'  
import SaldosContext from  '../context/saldos/SaldosContext'  

import ParticleBg from '../components/IconsAnimation/ParticleBg'




const Dashboard = () => {


  

  const authContext = useContext(AuthContext);
  const { usuarioAutenticado, status, CerrarSesion, 

    valor_total,
            equidad_total,
            margin_libre_total,
            flotante,
            ganancia_diaria,
            ganancia_esperada,
            flotante_hoy,
            margen_libre_hoy,
            cambio_actual
  
  
  } = authContext; 

  
  
    
  const saldosContext = useContext(SaldosContext);
   const { saldos, consultarApiSaldo } = saldosContext;     

   const ApiLoad = () => {
    guardarApiLoad(false);
    setTimeout(() => {
      guardarApiLoad(true);
  },3);
    
    
  }

  const [ apiload, guardarApiLoad] = useState(true);
//// aqui termina el api load 



  const [ chartstate, guardarChartState ] = useState({});

  return (  

    <>
    <div className="content">  

    <div className="box-content-fx waveform-x2 waveform-color-x2">
                <ParticleBg/>

          </div>  


    <div className="container-prisma">

        <div className="grid-dash-tittle">
          <p>  Robo-advisor powered by StrPrisma </p>
        </div>

        <div className="grid-dash-prisma">

              <div className="elemento-dash-prisma-one">

                  <div className="balance-prisma-header">

                      <h2>Balance <span className="valor-help">${valor_total} </span></h2>
                      <h2>Machine Status <span className="valor-help span-green">On </span>
                      </h2>
            
            

                  </div>
                  <div className="balance-prisma-body machine-help">
                  <span className="machine-alert"> Neuronal Machine Learning ALQ 2.0 
                  <h4>$ <i class="tim-icons icon-sound-wave   icon-salir"></i> € </h4>

                  <strong>Nuestra inteligencia artificial está <br/> en este momento
                    activa, <br/> generando rendimientos para usted.

                    <small>On Trading  USD - EUR  | Rendimiento 5% Aprox</small>

                    
                  </strong>
                  </span>

                  <div className="alk-machine">
                  <Machine/>
                  </div>
                    
                  </div>
                  <div className="balance-prisma-footer">

                    


                  <div className="session-single">
                    <div className="chart-label">
                      <span className="label-dot dot-success">
                        <div className="data-dot">

                        </div>
                      </span>
                      <p>Equidad</p>
                  </div>
                  <span  className="span-saldos"> {equidad_total}  <sub>USDT</sub></span>
                        
                    </div>

                    <div className="session-single">
                      <div className="chart-label">
                        <span className="label-dot dot-info">
                        <div className="data-dot">

                        </div>
                          </span>
                          <p>Margen Libre</p>
                          
                      </div>

                          <span className="span-saldos"> {margin_libre_total} <sub>USDT</sub> </span>
                          
                          
                      </div>

                    <div className="session-single">
                      <div className="chart-label">
                        <span className="label-dot dot-warning">
                        <div className="data-dot">

                          </div>
                          </span>
                          <p>Flotante</p>
                          
                      </div>
                      <span  className="span-saldos" > {flotante} <sub>USDT</sub></span>
                      
                    </div>
                    
                  </div>



              </div>


              <div className="elemento-dash-prisma-dos">

              <ParticlesBackground/>

                        <h2 className="particles-title">Información sobre la operación diaria <br/>
                                Robo-advisor powered by Strprisma</h2>

                        <div className="grid-saldos-header">

                                    <div className="elemento-saldos-prisma-one-header">

                                    <div className="up-info">
                                                 

                                                 <div className="p-up-info">
                                                    <h3>
                                                    {ganancia_diaria} USDT
                                                    </h3> 
                                                    <p>Ganancia diaria en <span> EURUSD</span></p>

                                                   </div>

                                                   <div className="p2-up-info">
                                                   <h3>
                                                   {ganancia_esperada} USDT
                                                    </h3> 
                                                    <p>Ganancia esperada en <span> EURUSD</span></p>
                                                     
                                                   </div>


                                      </div>

                                        <div className="progress-info-bar">

                                              <div className="border-line">

                                                 

                                              </div> 

                                              <div className="down-info">
                                                 

                                                  <div className="p-down-info">

                                                    <p>Rendimiento en <span>25%</span> desde ayer</p>

                                                    </div>

                                                    <div>
                                                    <p className="p2-down-info">70%</p>
                                                      
                                                    </div>


                                              </div>
                                        </div>




                                    </div>

                                    


                                    
                        </div> 

                        <div className="grid-saldos">

                                    <div className="elemento-saldos-prisma-one"> 



                                    <div className="up-info">
                                                 

                                                 <div className="p-up-info">
                                                    <h3>
                                                    {flotante_hoy} USDT
                                                    </h3> 
                                                    <p>Flotante hoy</p>

                                                   </div>
                                      </div>

                                        <div className="progress-info-bar">

                                              <div className="border-line">

                                                 

                                              </div> 

                                              <div className="down-info">
                                                 

                                                  <div className="p-down-info">

                                                    <p>Rendimiento en <span>25%</span> desde ayer</p>

                                                    </div>

                                                    <div>
                                                    <p className="p2-down-info">70%</p>
                                                      
                                                    </div>


                                              </div>
                                        </div>

                                        
                                    </div>


                                    <div className="elemento-saldos-prisma-dos">


                                    <div className="up-info">
                                                 

                                                 <div className="p-up-info">
                                                    <h3>
                                                    {margen_libre_hoy} USDT
                                                    </h3> 
                                                    <p>Margen libre actual</p>

                                                   </div>
                                      </div>

                                        <div className="progress-info-bar">

                                              <div className="border-line-warning">

                                                 

                                              </div> 

                                              <div className="down-info">
                                                 

                                                  <div className="p-down-info">

                                                    <p>Rendimiento en <span>25%</span> desde ayer</p>

                                                    </div>

                                                    <div>
                                                    <p className="p2-down-info">33%</p>
                                                      
                                                    </div>


                                              </div>
                                        </div>

                                    </div>
                        </div> 
              </div>
        </div> 



        <div className="grid-dash-beneficio">

              <div className="elemento-dash-ben">
              <div className="text-chart-tittle">
              <p>Holder</p>
              <h5 >Gráfico / Rendimiento diario</h5>
            </div>
            
              
                  <Grafica />
                

              </div>
        </div>

    </div>

    









    {/* <div className="d-none">
      <SaldosdelCliente saldos={saldos} />

        <Row>
        <Col xs="12">
          <Grafica />
        </Col>
        </Row>  

    </div> */}

    


      {/* <div className="grid-dashboard-template d-none">

        <div className="grid-dash-element-1">
        <ProductosDeInversion apiload={apiload} ApiLoad={ApiLoad} />
        </div>
        

        

        <div className="grid-dash-element-2">
        <UltimosMov apiload={apiload} ApiLoad={ApiLoad} />
        </div>
        

        

      </div> */}



      {/* <Row className=" justify-content-center ">
      
      
        
        </Row>  */}
    </div>   


      

  </>

  );
}
 
export default Dashboard;