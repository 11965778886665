import React, { useState, Fragment } from 'react';
import clienteAxios from '../../config/axios';
import Spinner from '../../components/spinners/SpinnerWhite' 


const Selfie = ({usuario , handleOpen , guardarMensaje}) => { 



    const [ cargando, guardarCargando] = useState(false);
    const guardar = ( cargando ) ? <Spinner/> :  

    <Fragment>
    Enviar Selfie
    </Fragment> 




                            const [kyc, guardarKyc] = useState({
                                file_selfie:'',
                            });  

                            
                            const consultarApiKyc = async () => {
                                const {file_id_front,
                                    file_id_reverse,file_selfie,
                                
                                
                                } = kyc;  

                                const config = {     
                                    headers: { 'content-type': 'multipart/form-data','Accept': 'application/json' },
                                    mimeType: 'multipart/form-data',
                                } 


                                const formData = new FormData();
                        // metodo jquery
                                formData.append('file_selfie',document.getElementById('file_selfie').files[0],file_selfie);
                                

                                
                            
                                try {
                                    const respuesta = await clienteAxios.post('/api/client/kyc_update',formData,config);
                                        guardarMensaje(respuesta.data.message);
                                            handleOpen();
                                            guardarCargando(false)
                                        
                                        
                                    
                                } catch (error) {
                                    guardarMensaje(error.response.data.message);
                                            handleOpen();
                                            guardarCargando(false)
                                            
                                }

                            } 

                            const [file, setFile] = useState(undefined);

                            
                            const onChange = e => {

                                // voy a colocar guardar usuario en el state

                                guardarKyc({
                                    ...kyc,
                                    [e.target.name] : e.target.value
                                    
                                }) 

                                setFile(e.target.files[0])
                            }





                            const onSubmit = e => {

                                guardarCargando(true)
                                
                                e.preventDefault();
                                consultarApiKyc({
                        
                        
                                    file_selfie,
                                    
                                }); 

                                guardarKyc({file_selfie:''})
                        
                            
                                
                        
                            }




    return ( 
                <>
                            <div className="grid-kyc">
                            { usuario.kyc[2].status === 1 ?
                                    <h2 className="my-3"> Foto del selfie </h2> :
                                    null
                                    }
                            <div>

                            <form onSubmit={onSubmit}> 
                                <div className="">
                                    <div className="">
                                    { usuario.kyc[2].status === 1 ?

                                                <div className={`kyc-style-fild-selfie ${usuario.kyc[1].status === 3 ? ' ':' ' } `}>   
                                                <div className="form-group">
                                                    <label>Tomar Selfie <span className="text-danger">*</span> </label>
                                                    
                                                    <div className="custom-file-panther">
                                                    
                                                    { kyc ? <p className="text-white text-center bg-info">  {kyc.file_selfie}</p> : null}
                                                    <label className="panther-input-file" htmlFor="file_selfie"> 
                                                    <i className="tim-icons icon-camera-18 p-2" /> añadir foto</label>


                                                    <input type="file"
                                                    id="file_selfie"
                                                    name="file_selfie"
                                                    onChange={onChange}
                                                    value={kyc.file_selfie} 
                                                    required
                                                className="custom-file-input-panther"/> 

                                                    
                                                    </div>
                                                        
                                                </div> 


                                                </div> : null

                                                    }  


                            {  usuario.kyc[2].status === 2 ?
                                                                <div className="w-100 icon-check-war"> 

                                                                    <strong>
                                                                    <i className="tim-icons icon-bulb-63 p-2" /> 

                                                                        </strong>


                                                                    </div>

                                                                : null
                                                                } 


                                                            {  usuario.kyc[2].status === 3 ?
                                                                <div className="w-100 icon-check"> 

                                                                    <strong>
                                                                    <i className="tim-icons icon-check-2 p-2" /> 

                                                                        </strong>


                                                                    </div>

                                                                : null
                                                                } 


                                            { usuario.kyc[2].status === 0 ?

                                            <>

                                            <div className="w-100 icon-check-del mb-1"> 

                                            <strong>
                                            <i className="tim-icons icon-simple-remove p-1" /> 
                                                </strong>
                                            </div>


                                                <div className={`kyc-style-fild-id ${usuario.kyc[2].status === 3 ? ' ':' ' } `}>   
                                                    <div className="form-group">
                                                    <label className="text-center  w-100 danger-prisma">Tu documento ha sido rechazado por alguna razón, 
                                                    fecha vencida o documento no claramente expuesto, 
                                                    <br/> <strong> ¡Porfavor vuelvalo a cargar una vez más! Muchísimas Gracias!</strong> <span className="text-danger">*</span></label>
                                                    <div className="custom-file-panther">
                                                
                                                    { kyc ? <p className="text-white text-center bg-info">  {kyc.file_selfie}</p> : null}
                                                
                                                    <label className="panther-input-file danger-prisma" htmlFor="file_document">  
                                                        <i className="tim-icons icon-badge p-2 " />  añadir foto selfie nuevamente</label>

                                                        <input type="file"
                                                    id="file_selfie"
                                                    name="file_selfie"
                                                    onChange={onChange}
                                                    value={kyc.file_selfie} 
                                                    required
                                                className="custom-file-input-panther"/> 

                                                    

                                                </div> 






                                                </div>

                                            </div> 


                                            </>
                                            : null   }    






                                     </div>
                                

                                
                                
                                </div>  

                                { usuario.kyc[2].status === 0 ?
               
               <button className="btn-small-prisma w-100" color="primary" onSubmit={onSubmit}>
               {guardar}
             </button> 
                         : null

                     }     


                                { usuario.kyc[2].status === 1 ?
               
               <button className="btn-small-prisma w-100" color="primary" onSubmit={onSubmit}>
               {guardar}
             </button> 
                         : null

                     }      
                 { usuario.kyc[2].status === 2 ?
                             
                             <p className="mt-2 text-center kyc-bold"> Documento en revisión</p>
                                         : null

                                     }    

                 { usuario.kyc[2].status === 3 ?
                             
                             <p className="mt-2 text-center kyc-bold"> Documento aprobado</p>
                                         : null

                                     }    


                            
                                
                                
                                </form>



                            </div>

                            </div>
                                
            </>
     );
}
 
export default Selfie;