import { useLottie } from "lottie-react";
import Particle from "../IconsAnimation/ParticleBg.json";
 
const ParticleBg = () => {
  const options = {
    animationData: Particle,
    loop: true,
    autoplay: true,
    height: 100,
    color:'#5f63f2',
  };
 
  const { View } = useLottie(options);
 
  return View;
};
 
export default ParticleBg;

