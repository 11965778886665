
import React  from "react";
import ReactDOM from "react-dom";

import App from './App';



// aqui voy a colocar por mientras el admin viejo de referencia
// importanto el context de autenticacion

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";

// // exportando la libreria de tablas de boostrap 
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

// ahora vamos a importar lka libreria de paginacion par aque funcione
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

// ahora vamos a importar el cdn de react-boostrap
// import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render( 
    
  <App/>


 , 
  document.getElementById("root"));

{
  // console.log(process.env.REACT_APP_BACKEND_URL)
};


  