import React from 'react';
import ParticleBg from '../../components/IconsAnimation/ArtBlack'


////// esta es la ventana contenedora del modal para la informacion


const GalleryInfo = ({modalInfo, guardarModalOpen, modalopen}) => {
    return (  

        <> 
        

        

                        <div className={`modal-box-gallery animation-entrada-modal ${modalopen === true ?'':''}`}> 
                        
                        

                        <div className="modal-manual"> 

                                    <div className="box-content-fx-black waveform-x4">
                                            <ParticleBg/>

                                            </div> 

                                    <div className="cancelar-modal">
                                        <p onClick={
                                        () => {guardarModalOpen(false)
                                        }
                                            }>
                                        Regresar a mi Galeria de Arte
                                                </p>        
                                        </div>
                                        

                                            
                                        <div className="modal-gallery-info">
                                                <div className="gallery-elemento-2">
                                                
                                                <div className="hitman-text"> 
                                                <p >
                                            StrPrisma Team, Construyo una formula basada en expresiones algebraicas que interpreta las transacciones del mercado de valores en 
                                            colores, el sueño del Robot Conciente, la Conciencia del Mercado en ese momento. 
                                            <br/>
                                            <br/>
                                            Segmentada y automatizada en procesos en serie, el código algoritmico es ejecutado cada vez que se compra una pieza de arte,
                                            ejecutando en tiempo real más de 500 lineas de servicios para construir este arte unico para usted ( ADU ) 
                                            <br/>
                                            <br/>
                                            Generando piezas unicas proveenientes de nuestra inteligencia artificial ALQ.20, está actual colección lleva el Nombre
                                            del Origen, son 809 piezas y usted tiene la #{modalInfo.adu}. <br/>
                                            <br/>
                                            Provenientes de más de 7,000 transacciones en el ultimo periodo de ejecución del Robot , los colores simbolizan cosas,
                                            el numero de capas simbolizan y cuentan historias, para más información relevante visita nuestros canales de comunicación
                                            strprisma.com
                                                </p>


                                                </div>

                                                <div className="boton-footer">
                                                        <a href={ `${modalInfo.url}`} rel="noopener noreferrer" target="_blank"><strong className=" final-btn-prisma-mob "> Mostrar ADU #{modalInfo.adu} </strong></a>

                                                </div>
                                                
                                                </div>

                                                
                                                <div className="gallery-elemento-1">
                                                <img src={modalInfo.url} />
                                                </div>
                                                {/* <div className="gallery-elemento-3">
                                                        
                                                </div> */}
                                            </div>  

                                            



                                            <div className="final-footer">

                                        <p>StrPrisma 
                                                                <strong>El Origen</strong>
                                                        </p>

                                                        <p>Fecha
                                                                
                                                                <strong>{modalInfo.fecha_venta}</strong>
                                                        </p>
                                                        <p>Número de ADU
                                                                        
                                                                <strong>{modalInfo.adu}</strong>

                                                        </p>
                                                        <p>Rareza
                                                                
                                                                <strong>5.1.1</strong>
                                                        </p>

                                        </div> 

                                        <div className="hash-exp">
                                                <div>
                                                <p>
                                            PrivateKey : <br/> 
                                            <strong> 0xcf83e1357eefb8bdf1542850d66d8007d620e4050b5715dc83f4a921d36ce9ce47d0d13c5d85f2b0ff8318d2877eec2f63b931bd47417a81a538327af927da3e</strong>
                                            </p>

                                                </div>
                                            
                                        </div> 

                                        
                                <div className="footer-modal">  

                                <p className="text-center">Colores
                                                                
                                                                <strong className="grid-color">
                                                                        <span style={{background:`${modalInfo.color1}`}}>{modalInfo.nombre1}</span>
                                                                        <span style={{background:`${modalInfo.color2}`}}>{modalInfo.nombre2}</span>
                                                                        <span style={{background:`${modalInfo.color3}`}}>{modalInfo.nombre3}</span>
                                                                        <span style={{background:`${modalInfo.color4}`}}>{modalInfo.nombre4}</span>
                                                                        <span style={{background:`${modalInfo.color5}`}}>{modalInfo.nombre5}</span>
                                                                        <span style={{background:`${modalInfo.color6}`}}>{modalInfo.nombre6}</span>
                                                                        <span style={{background:`${modalInfo.color7}`}}>{modalInfo.nombre7}</span>
                                                                </strong>

                                                        </p>


                                </div>                            
                        </div>

                        









                </div>
         
        

       

        </>

    );
}
 
export default GalleryInfo;