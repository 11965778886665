import React, { useState, Fragment } from 'react'; 
import clienteAxios from '../../config/axios'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
  } from "reactstrap"; 


  
import EditModal from '@material-ui/core/Modal' 
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 

  
import Modal from '@material-ui/core/Modal' 
import ModalCancelar from '@material-ui/core/Modal' 

import Spinner from '../../components/spinners/Spinner'



/// fade del modal
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';  




function getModalStyle() {
    const top = 50 ;
    const left = 50;
  
 
  }
  
  
  const useStyles = makeStyles((theme) => ({
    root:{
        background: 'non',
        backgroundColor: 'none',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'hidden',
    },
    paper: {
      
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      
      margin: '20% 1% 10% 1%',
    },
  }));
  
  
  


const CuentasBancariasPropias = ({cuentaspropias,apiload,ApiLoad, ListaNacionales}) => { 



/// aqui vamos a colocar el loader 
const [ cargando, guardarCargando] = useState(false);

const guardar = ( cargando ) ? <Spinner/> :  
                                        
<Fragment>
  Desactivar cuenta
  </Fragment> 





/////////////////////// extra
    const [open, setOpen] =useState(false);

    const handleOpen = () => {
        setOpen(true);
    } 
   
    const handleClose = () => {
        setOpen(false);
        guardarMensaje(null);
    }


    const [modalInfo, setModalInfo] = useState([]);

    const [cancelaroferta, guardarCancelarOferta] = useState("");


///////////////////// extra


    const classes = useStyles(); 
    const [ modalStyle ] =useState(getModalStyle);
      
   ///edit modal//////////////////////////////////
        const [openedit, setOpenEdit] =useState(false);

        
        const handleOpenEdit = () => {
        setOpenEdit(true);
        } 

        const handleCloseEdit = () => {
        setOpenEdit(false);
        guardarMensaje(null);
        
        }

    ////////////// edita modal fin 


    //// aqui vamos a colocar el mensaje y su estado
    const [mensaje, guardarMensaje] = useState('');

        const [cuenta, guardarCuenta] =useState(''); 


        const borrarOferta = async () => {
       
  
                
          try {
            const respuesta = await clienteAxios.post(`/api/client/beneficiario_delete/${cancelaroferta}`);
              
            guardarCargando(false);
            guardarMensaje(respuesta.data.message); 
            abrirMensaje();
        
            
          } catch (error) {
            guardarCargando(false);
            guardarMensaje(error.response.data.message);
         
          }
        } 




       /// aqui vamos a colocar el modal de mensaje
 const [ modalmensaje, setModalMensaje ] = useState(false);
 /// aqui vamos a colocar los verdaderos manejadores en funciones

 const abrirMensaje = () => {
  setModalMensaje(true);
  
 }
 
 const cerrarMensaje = () => {
  setModalMensaje(false);
    guardarMensaje(false);
 } 


      

  const onSubmitCancelar = e => {
    guardarCargando(true);
      e.preventDefault(); 

      
      
      borrarOferta(cancelaroferta);

  }




    
    return (  


        
        <>  

        {   cuentaspropias.length > 0 ?
            cuentaspropias.map(cuenta => (
                <div className="animation-cuentas account-card-box" key={cuenta.id}      
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                          timeout: 900,
                      }}>
                                  
                <div className="account-card account-bank ">


                    <div className="elemento1" >
                            <i className="fas fa-university  account-bank-icon"></i>
                            <p>Bancaria</p>
                    </div>

                    <div className="elemento2">
                                  
                                    <p className="text-4 font-weight-500 mb-1">{cuenta.bank_name}</p>
                                    <p className="text-4 opacity-9 mb-1">XXXX-{cuenta.bank_account}</p>
                                    <p className="m-0">Aprobada <span className="text-3"><i className="fas fa-check-circle"></i></span></p>
                                    <p className="text-4 font-weight-500 mb-1">{cuenta.bank_client}</p>
                                    <p className="text-4 font-weight-500 mb-1">{cuenta.bank_type}</p>
                                
                    </div>
                     
                    <div className="account-card-overlay "> 
                        <p className="pr-5"    onClick={ () => {
                         handleOpen()
                         guardarCuenta(cuenta)
                      }}>

                    <span className="mr-1"><i className="fas fa-share"></i></span>Ver detalles</p> 
                        <p 
                            onClick={ () => {
                              handleOpenEdit();
                              guardarCancelarOferta( `${cuenta.id}`)
                              setModalInfo(cuenta); 
                            }
                            }
                        >
                          <span className="mr-1">
                          <i className="fas fa-minus-circle">  
                          </i>
                          </span>Borrar</p> 
                      </div>
                       
                    </div> 

                    
                
                </div>

            ) ) : (
                <p className="animation-cuentas text-center w-100"> Sin cuenta bancaria </p>
            )


        }  

            <Modal
                    
                    className={classes.modal}
                    open={open} 
                    
                    
                    disableScrollLock={true}
                    
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 900,
                    }}
                    >
                    <Fade in={open}
                    timeout={154}
                    onClose={handleClose}>
                        
                    <div className="container-grid">
                              <div  className={`modal-kyc-welcome padding-modal`}> 

                              <div className="bg-modal-header">   
                                          <div  className=" fix-cuentaspropias-title ">
                                                <h1 className="m-0 bold-tittle text-white">Cuenta de beneficiario Nacional  </h1>
                                                <p className="pl-2 text-white"> (Detalle de la cuenta ) de {cuenta.bank_client}</p>
                                          </div>

                                          <div >
                                            <div className="cancel-box-btn">
                                                <div className="cancel-btn-panther" onClick={ () => (
                                                  handleClose()
                                                )}> 
                                                    <i className="tim-icons icon-simple-remove"/>
                                                </div>
                                            </div>
                                          </div>    
                              </div>
                        <div >
                                           
                                                        
                                   < div className="form-group form-control-deposito-container">
                                  <div className="modal-grid-row"> 

                                  <div>  
                                    
                                        <label className="panther-retiro-label">Titular de la cuenta <span className="text-success  mt-5">*</span> </label>
                                          <input 
                                            type="text"
                                            placeholder="Identificacion Destinatario"
                                            id="bank_client"
                                            name="bank_client"
                                            value={cuenta.bank_client}
                                          className=" form-control" required="" />


                                  </div>

                                  <div>  
                                      <label className="panther-retiro-label">Número de la cuenta<span className="text-success  mt-3">*</span> </label>
                                      <input 
                                        type="text"
                                        placeholder="e.g. Smith Rhodes"
                                        id="bank_account"
                                        name="bank_account"
                                        value={cuenta.bank_account}
                                      className=" form-control" required="" onchange="cambiar(this.value)"/>
                                  </div>

                                  <div> 
                                    <label className="panther-retiro-label">Tipo de cuenta<span className="text-success  mt-5">*</span> </label>
                                    <input 
                                      type="text"
                                      placeholder="Identificacion Destinatario"
                                      id="bank_type"
                                      name="bank_type"
                                      value={cuenta.bank_type}
                                      className=" form-control" required=""/>
                                  </div> 

                                  <div>   

                                  <div className="text-center text-4 text-white my-4 bank-icon-grid"> 
                                  <i className="fas fa-university  account-bank-icon-max"></i>
                                  </div>


                                  </div>

                                  <div>   

                                  <button className=" btn mt-0 btn-block btn-info btn-panther-power" onClick={ () => {
                                      handleOpenEdit();
                                      
                                      guardarCancelarOferta( `${cuenta.id}`)
                                      
                                      setModalInfo(cuenta);
                                      handleClose();
                                      
                                    }


                                    }>
                                        Borrar cuenta
                                        </button>
                                    </div>                                   
                              </div> 
                              </div>
                        </div> 
                    </div>
                    </div>

                  
                    </Fade>
                    </Modal> 

                    <ModalCancelar 
                            open={openedit}
                            className="modal-responsive-fix"
                        >
                             <div className="container-grid">
                              <div  className={`modal-kyc-welcome padding-modal`}> 
                                  <form onSubmit={onSubmitCancelar}> 
                                  <div className="bg-modal-header">   
                                          <div  className=" fix-cuentaspropias-title fix-cancelarcuenta-title ">
                                          <h5 className="title display-4 mb-0 text-left">  Estas seguro que deseas desactivar tu beneficiario de BANCOS NACIONALES? </h5>
                                          </div>

                                          <div >
                                            <div className="cancel-box-btn">
                                                <div className="cancel-btn-panther" onClick={ () => (
                                                  handleCloseEdit()
                                                )}> 
                                                    <i className="tim-icons icon-simple-remove"/>
                                                </div>
                                            </div>
                                          </div>    
                                      </div>

                                      < div className="form-group form-control-deposito-container">
                                      <div className="modal-grid-row"> 

                                      <div> 
                                          <h1 className="cancel-ofert-body"> Estas a un solo Click de desactivar a  <span>{modalInfo.bank_client}</span> con el numero de cuenta PRISMA <span>{modalInfo.bank_account} </span>         
                                                        de email  <span>{modalInfo.bank_email}</span> </h1>

                                      </div>

                                      <div>
                                            <button
                                                                        title=""
                                                                        type="submit"
                                                                        className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1">
                                                                          {guardar}
                                                                        
                                                                      </button>
                                      </div>

                                      </div>    
                                      </div>
                                            
                                            </form>
                                      </div>        
                                  </div>
                        </ModalCancelar> 


                        { mensaje ? <Modal
                                
                                className={classes.modal}
                                open={modalmensaje} 
                                mensaje={mensaje}
                                ListaNacionales={ListaNacionales}   
                                
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 900,
                                }}
                              >
                                <Fade in={modalmensaje}
                                timeout={154}
                                >     
                      <div className="container-grid">
                              <div  className={`modal-kyc-welcome padding-modal`}> 
                                  <div className="form-group form-control-deposito-container">
                                      <div className="modal-grid-row"> 

                                      <div> 
                                            <p className="text-center display-4 title my-3 font-light-weight-modal"> {mensaje}</p>

                                      </div>

                                      <div>
                                         <button className="  btn mt-0 btn-block btn-info my-2 btn-panther-power" onClick={ () => (
                                                                            ApiLoad(),
                                                                            handleCloseEdit(),
                                                                            handleClose(),
                                                                            ListaNacionales(),
                                                                            guardarMensaje(null)
                                                                          )}>
                                                                    Aceptar
                                          </button>
                                      </div>
                                      </div>    
                                      </div>
                                      </div>        
                      </div>
                                                        </Fade>
                                                      </Modal> : null}



                                                              </>


    );
}
 
export default CuentasBancariasPropias;