
import React, { useContext, useState, useEffect, Fragment } from 'react';
import clienteAxios from '../config/axios';
import { makeStyles } from '@material-ui/core/styles'  
import Modal from '@material-ui/core/Modal'
import ModalNacional from '@material-ui/core/Modal'
import ModalInternacional from '@material-ui/core/Modal'
import ModalTodosRetiros from '@material-ui/core/Modal'

import ModalDos from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom'; 

import  GraficaDoughnut from './GraficaDoughnut';

import BootstrapTable from 'react-bootstrap-table-next'
import ModalTerceros from '@material-ui/core/Modal'


//// aqui vamos a importal los stylos del check
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox'; 

import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import NumberFormat from 'react-number-format';


import Spinner from '../components/spinners/Spinner' 

// aqui vamos a colocar el context , de donde viene
import AuthContext from '../context/autenticacion/authContext'
import ModalDireccionesMaster from '../components/Modales/ModalDireccionesMaster';



/// vammos a llamar a los modales 

import ModalRemesadora from '@material-ui/core/Modal'

import AduGeneratorRetiro from './AduGeneratorRetiro'
import RetiroAdu from './RetiroAdu'

import ParticleBg from '../components/IconsAnimation/ParticleBg'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Form,
  Input,
  Row,
  Col,
  Container,
  
} from "reactstrap"; 


  
function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    
    boxShadow: theme.shadows[5],
    
  },
   
  
}));



const Retiro = () => {  

  
const [opendireccion, setOpenDireccion] =useState(false);



const handleOpenDireccion = () => {
    setOpenDireccion(true);
} 

const handleCloseDireccion = () => {
    setOpenDireccion(false);;
} 



///// aqui estamos colocando el estado del modal 
const [openlist, setOpenList] =useState(false);



const handleOpenList = () => {
    setOpenList(true);
} 

const handleCloseList = () => {
    setOpenList(false);
    guardarMensaje(null);
}










  /// aqui vamos a colocar el check para todas las cosas, el manejador de eventos
  // aqui vamos a colocar el stado del check
const [state, setState] = React.useState({
    
  check: false,
  
});

  const handleChangeCheck = (event) => { 
  
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  
  const {  check } = state;




 /// aqui vamos a colocar el modal de mensaje
 const [ modalmensaje, setModalMensaje ] = useState(false);
 /// aqui vamos a colocar los verdaderos manejadores en funciones

 const abrirMensaje = () => {
  setModalMensaje(true);
 }
 
 const cerrarMensaje = () => {
  setModalMensaje(false);
    guardarMensaje(false);
 } 


 // aqui vamos a colocar los manejadores de los modales, que abren y se cierran
const [ modalremesadora, setModalRemesadora ] = useState(false);
/// aqui vamos a colocar los verdaderos manejadores en funciones

const abrirRemesadora = () => {
     setModalRemesadora(true);
}

const cerrarRemesadora = () => {
    setModalRemesadora(false);
   guardarMensaje(false);
}





  /// aqui vamos a colocar el modal de la remesadora
  const [ modalterceros, setModalTerceros ] = useState(false);
  /// aqui vamos a colocar los verdaderos manejadores en funciones
 
  const abrirTerceros = () => {
       setModalTerceros(true);
  }
  
  const cerrarTerceros = () => {
     setModalTerceros(false);
     guardarMensaje(false);
  } 




  //// qui va a estar el submit de los beneficiarios
  
const onSubmitTerceros = e => {
  e.preventDefault();
  elGuardarCargando(true);





  consultarApiRetiroRemesa({
    amount,
    type,
    remesa_nombres,
    remesa_apellidos,
    remesa_identificacion,
    remesa_tipoidentificacion,
    remesa_country,
    remesa_provincia,
    remesa_ciudad,
    remesa_direccion,
    remesa_telefono

  });



} 







  
  const [ elcargando, elGuardarCargando] = useState(false);

  const elguardar = ( elcargando ) ? <Spinner/> :  
                                          
  <Fragment>
    Solicitar
    </Fragment> 





///// aqui vamos a terminar de colocar los estados del modal tercero /////////

const [ modalnacional, setModalNacional ] = useState(false); 


const abrirNacional = () => {
  setModalNacional(true);
}

const cerrarNacional = () => {
  setModalNacional(false);
  guardarMensaje(null);
 
} 


const [ modalinternacional, setModalInternacional ] = useState(false); 


const abrirInternacional = () => {
  setModalInternacional(true);
}

const cerrarInternacional = () => {
  setModalInternacional(false);
  guardarMensaje(null);

} 



/// aqui vamos a colocar los manejadores de eventos para el check
const handleChangeCheckInt = (event) => { 
  setState({ ...state, [event.target.name]: event.target.checkint });
   checkenable();
}; 

const {  checkint } = state;
const errorint = [checkint].filter((v) => v).length !== 2 || [checkint].setValueInt(0) ;

  ///////////////////// aqui estamos colocando los manejadores de eventos del tap
  const [valueint, setValueInt] = React.useState(0);

  const handleChangeInt = (event, newValueInt) => {
    setValueInt(newValueInt);
  };
  
  const handleChangeIndexInt = (index) => {
    setValueInt(index);
  };  


  const checkenable = () => { 
    if (valueint === 0 ) {
          setValueInt(1)
      } ;
      if (valueint === 1 ) {
        setValueInt(0)
                  }
  }
  
  










  
const [ modalStyle ] =useState(getModalStyle);
const [open, setOpen] =useState(false);

const classes = useStyles();

const handleOpen = () => {
    setOpen(true);
} 

const handleClose = () => {
    setOpen(false);;
}

 

const [ listaretiros , guardarListaRetiros ]= useState([{
  id:'',
  fecha:'',
  note:'',
  currency_tag: '',
  amount:'',
  estado:'',

}]);




// aqui vamos a poner el modal vacio
  const [ cuentas, setCuentas ] = useState([

    {
      id: '',
      client_id: '',
      bank_name: '',
      bank_account: '',
      bank_swift:'',
      created_at: '',
      updated_at: '',
      bank_client: '',
      bank_status:'',
      active:'',
      country_id:'',
  }

  ]); 

  
  
  const [ cuentaspropias, setCuentasPropias ] = useState([

    {
      id: '',
      client_id: '',
      bank_name: '',
      bank_account: '',
      bank_swift:'',
      created_at: '',
      updated_at: '',
      bank_client: '',
      bank_status:'',
      active:'',
      country_id:'',
      bank_type:'',
      
  }

   ]); 


   const { 
    country_id,bank_client,bank_swift, bank_name, bank_account, bank_type, bank_aba,
    bank_apellidos,provincia,ciudad,direccion_cliente,bank_personal,
      tipo_identificacion, identificacion,telefono,
      
} = cuentaspropias; 

   


  
  const [ cuentasterceros, setCuentasTerceros ] = useState([

    {
      id: '',
      client_id: '',
      bank_name: '',
      bank_account: '',
      bank_swift:'',
      created_at: '',
      updated_at: '',
      bank_client: '',
      bank_status:'',
      active:'',
      country_id:'',
      bank_type:'',
      
  }

  ]);  

  



  const [ cuentasremesas, setCuentasRemesas ] = useState([

    {
      id: '',
      client_id: '',
      bank_name: '',
      bank_account: '',
      bank_swift:'',
      created_at: '',
      updated_at: '',
      bank_client: '',
      bank_status:'',
      active:'',
      country_id:''
  }

   ]); 

   


   
 const [beneficiario, guardarBen] = useState("");   
  
 const [elbeneficiario, guardarBeneficiarioid] = useState({
   beneficiario_id:``,
 
 }) 
 



       /// const api load 

       const ApiLoad = () => {
        guardarApiLoad(false);
        setTimeout(() => {
          guardarApiLoad(true);
      },3);
        
        
      }
    
      const [ apiload, guardarApiLoad] = useState(true);
    //// aqui termina el api load 
    
    
    

  const [ recargardata, setRecargarData  ] = useState(false); 


  const [cantidad, guardarCantidades ] = useState({});
    
    
  const authContext = useContext(AuthContext);
  const { cuentas_internacionales,cuentas_nacionales, cuentas_remesas, retiros_cantidad,retiros,ListaNacionales,ListaInternacionales, ListaRemesas,TodosRetiros, demo_status, usuario, usuarioAutenticado } = authContext;  

  
    
      

    
  useEffect((props) => { 

    
    const consultarApiBeneficiarios = async () => {
     
      
    // const propiasbank = {bank_personal:'1'}
    // const tercerosbank = {bank_personal:'2'}
    // const remesabank = {bank_personal:'3'}
   


      // const respuesta = await clienteAxios.post('/api/client/beneficiarios')
      // const respuestapropias = await clienteAxios.post('/api/client/beneficiarios',propiasbank)
      // const respuestaterceros = await clienteAxios.post('/api/client/beneficiarios',tercerosbank)
      // const respuestaremesa = await clienteAxios.post('/api/client/beneficiarios',remesabank)
      
       
      // setCuentas(respuesta.data.data)
      // setCuentasTerceros(respuestaterceros.data.data)
      // setCuentasPropias(respuestapropias.data.data)
      // setCuentasRemesas(respuestaremesa.data.data)


      setCuentasTerceros(cuentas_internacionales)
     setCuentasPropias(cuentas_nacionales)
     setCuentasRemesas(cuentas_remesas)
      
      
    }

    consultarApiBeneficiarios()

    const consultarApiListaDeRetiros = async () => {
      // const {amount, note, currency_tag, estado, created_at
    
      
      // } = listaretiros; 

      // const respuesta = await clienteAxios.post('/api/client/withdrawall/list')

      guardarListaRetiros(retiros)
      guardarCantidades(retiros_cantidad)
      
        
      
    }

    consultarApiListaDeRetiros()

    
    
  }, [!apiload,cuentas_internacionales,cuentas_nacionales,cuentas_remesas,retiros_cantidad,retiros]) 


 


  


const [pin, guardarPin ] = useState("");


const [mensaje, guardarMensaje] = useState({
message:""
})

const [retiro, guardarRetiro] = useState({
  
beneficiario_id:'',
monto:'',
amount:'',
type: '1',
ventanilla:'',



});   


const [ addben, guardarAddBen ] =useState({
      contry_id:'',
      bank_client:'',
      bank_swift:'',
      bank_name:'',
      bank_account:'',
      bank_personal:'',
      bank_type:'',
      bank_aba:'',
      bank_apellidos:'',
      provincia:'',
      ciudad:'',
      direccion_cliente:'',
      tipo_identificacion:'',
      identificacion:'',
      telefono:'',
})

  

const [cuentabancaria, setCuentaBancaria ] = useState({
  contry_id:'',
  bank_client:'',
  bank_swift:'',
  bank_name:'',
  bank_account:'',
  bank_personal:'',
  bank_type:'',
  telefono:'',
  direccion_cliente:'',
  ciudad:'',
  provincia:'',
  identificacion:'',
  tipo_identificacion:'',
  bank_apellidos:'',
  
  

  

});





const consultarApiRetiroRemesa = async () => { 

  

  const {
      amount,
     type,
     remesa_nombres,
     remesa_apellidos,
     remesa_identificacion,
     remesa_tipoidentificacion,
     remesa_country,
     remesa_provincia,
     remesa_ciudad,
     remesa_direccion,
     remesa_telefono
  
    
    } = retiro;  

 
 
  

  try {
      const respuesta = await clienteAxios.post('/api/client/withdrawall/create',{
        amount,
        type,
        remesa_nombres,
        remesa_apellidos,
        remesa_identificacion,
        remesa_tipoidentificacion,
        remesa_country,
        remesa_provincia,
        remesa_ciudad,
        remesa_direccion,
        remesa_telefono
    
      
      });

            guardarPin(respuesta.data.data.pin)
            guardarMensaje(respuesta.data.message);
            setRecargarData(true);
            elGuardarCargando(false)
            abrirMensaje()
            
      
  } catch (error) {
      
      
      guardarMensaje(error.response.data.message);
              
              setTimeout(() => {
                  guardarMensaje(null);
              },6000);
  }


}  









const consultarApiRetiroTransferencia = async (props) => { 

  
  
 
  

  try {
      const respuesta = await clienteAxios.post('/api/client/withdrawall/create',retiro);

            
            guardarMensaje(respuesta.data.message);
            guardarPin(respuesta.data.data.pin)
            setRecargarData(true);
            elGuardarCargando(false)
            abrirMensaje()
            
            
      
  } catch (error) {
      
    
      guardarMensaje(error.response.data.data.message);
              
              setTimeout(() => {
                  guardarMensaje(null);
              },6000);
  }


 }   




 ///// aqui vamos a colo0ar las apis de retiro

 


const consultarApiAddAccPersonal = async (props) => { 

  const bank_personal = "1"

  const {country_id,bank_client,bank_swift, bank_name, bank_account,bank_type} = addben; 

  

  const respuesta = await clienteAxios.post('/api/client/beneficiario_create ',
  {country_id,bank_client,bank_swift, bank_name, bank_account, bank_type,bank_personal}) 

    
  
    guardarMensaje(respuesta.data.message);
    abrirMensaje()
    
    

       
      





    }  


    ////// aqui vamos a colocar el submit
    const onSubmitNacional = e => {
      e.preventDefault();
    
    
    
    
    
      // luego lo vamos a pasar al action
    
      consultarApiAddAccPersonal({
                
        country_id,
        bank_client,
        bank_swift,
        bank_name,
        bank_account,
        bank_type,
        bank_aba,
        
        
    });
    
    
    } 

  
    const consultarApiAddAccTerceros = async () => { 

      const bank_personal = "2"
    
      const {country_id,bank_client,bank_swift, bank_name, bank_account,bank_type,bank_aba} = addben; 
    
    
      const respuesta = await clienteAxios.post('/api/client/beneficiario_create ',
      {country_id,bank_client,bank_swift, bank_name, bank_account, bank_type,bank_personal,bank_aba}) 
    
    
        
        guardarMensaje(respuesta.data.message);
        abrirMensaje()
        
    
           
          
    
    
    
    
    
         }  

         /// aqui vamos koa COLOCAR EL SUBMIT DEL OTRO  
         const onSubmitInternacional = e => {
          e.preventDefault();
        
        
        
        
        
          // luego lo vamos a pasar al action
        
          consultarApiAddAccTerceros({
                    
            country_id,
            bank_client,
            bank_swift,
            bank_name,
            bank_account,
            bank_type,
            bank_aba,
            
        });
        
        
        } 
      



           
    const consultarApiAddAccRemesas = async (props) => { 

      const bank_personal = "3"
    
      const { 
              country_id,
              bank_client,
              bank_swift,
              bank_name,
              bank_account,
              bank_type,
              bank_aba,
              bank_apellidos,
              provincia,
              ciudad,
              direccion_cliente,
              tipo_identificacion,
              identificacion,
              telefono,
              
      } = addben; 
      
      
    
      const respuesta = await clienteAxios.post('/api/client/beneficiario_create',
      { country_id,
        bank_client,
        bank_swift,
        bank_name, 
        bank_account, 
        bank_type,
        bank_personal,
        bank_aba,
        bank_client,
        bank_apellidos,
        country_id,
        provincia,
        ciudad,
        direccion_cliente,
        bank_personal,
        tipo_identificacion,
        identificacion,
        telefono,
      
      }) 
    
    
        
        guardarMensaje(respuesta.data.message);
        
    
        abrirMensaje()
          
    
    
    
    
    
        } 


        

const onSubmitRemesas = e => {
  e.preventDefault();
  // luego lo vamos a pasar al action
      consultarApiAddAccRemesas({
          bank_client,
          bank_apellidos,
          country_id,
          provincia,
          ciudad,
          direccion_cliente,
          bank_personal,
          tipo_identificacion,
          identificacion,
          telefono,
      });
} 


const onChangeRem = e => {
  
  guardarAddBen({
    ...addben,
    [e.target.name] : e.target.value 
}) }

const onChange = e => {
  // voy a colocar guardar usuario en el state
  guardarRetiro({
      ...retiro,
      [e.target.name] : e.target.value
  }) 
} 


const [crearretirostate, setCrearRetiroState] =useState({});

const crearretiro = {...elbeneficiario, ...retiro };


const [venta, guardarVenta] = useState({

  amount:'',
  type: '',
  date:'',
  
  
  });  


  const [ mostrar, setMostrar ] = useState(false);


const [modalInfo, setModalInfo] = useState([]);



const onSubmitTransferencia = e => {
  elGuardarCargando(true)
  e.preventDefault() 






    
      consultarApiRetiroTransferencia({
        retiro
    
    
      }); 
    
    
    
        



} 



 useEffect( ()=>{
  guardarAddBen({bank_client:`${usuario.name}`});
 },[]);


 const [ retiromenu, guardarRetiroMenu] = useState(true);
 const [ venderadu, guardarVenderAdu] = useState(false);


 const Profit = () => {

  guardarRetiroMenu(false);
  guardarVenderAdu(false);

 }

 const Vender = () => {

  guardarRetiroMenu(false);
  guardarVenderAdu(true);

 } 



 const [montosave, guardarMontoSave] = useState('')


const [multiplicador, guardarMultiplicador] =useState()




//////// en el componente de contrato, necesitamos colocar la logica, fuera porque vamos a hacerle reset en algunas acciones
const [btndinamic, guardarBtnDinamic] = useState('')
const [contrato, guardarContrato] = useState({
      amount:'',
  })

const [participaciones, guardarParticipacion] = useState({
          multiplicador:`0`,
})


 const montosavereset = e => {
      e.preventDefault()
      guardarMontoSave('')
      guardarBtnDinamic('')
      guardarContrato({
          amount: '',
      })

      guardarParticipacion({
          multiplicador:`0`,
      })
  }
  

 

  









  return (
    <>
      <div className="content"> 

      <div className="box-content-fx waveform-x4">
                <ParticleBg/>

                </div>  

      <div className="prisma-clip">


        <p>Retiro</p>

    </div>


        <div className="grid-dash-prisma-retiro">

                      

                    <div className="elemento-dash-prisma-one-retiro">

                      <div className="retiro-prisma-body">

                      <div className=""> 

                      
                      






                      {retiro.type === "" ? <p className="mb-3 icon-maestro    "> Todos tus retiros</p> : null }
                          {retiro.type === "1" ? <p className="mb-3 icon-maestro     "> Transferencia Nacional</p> : null }
                          {retiro.type === "2" ? <p className="mb-3 icon-maestro     "> Transferencia Internacional</p> : null }
                          


                        

                          <p className={retiro.type === "" ? ' nav-active-prisma-profile on-click-active ' : 'on-click-active' }   onClick={ () => { guardarRetiro({...retiro, type:'',}),guardarAddBen({bank_client:`${usuario.name}`}); }}>
                            <span><i class="tim-icons icon-bank"></i></span>
                            <strong>Todos mis retiros</strong>
                            </p> 

                            <p className={retiro.type === "1" ? ' nav-active-prisma-profile monto-dolares-dos on-click-active ' : 'on-click-active' } onClick={ () => { guardarRetiro({...retiro, type:'1',}),guardarAddBen({bank_client:`${usuario.name}`});
                          
                          }}> <span><i class="tim-icons icon-bank"></i></span><strong> Transferencia nacional</strong></p> 
                            {/* <p className={retiro.type === "2" ? '  nav-active-prisma-profile monto-dolares-dos on-click-active ' : 'on-click-active' } onClick={ () => { guardarRetiro({...retiro, type:'2',}), guardarAddBen({bank_client:`${usuario.name}`}); }}>  <span> <i class="tim-icons icon-bank"></i></span> <strong>Transferencia internacional</strong> </p>  
                          {/* <div className={retiro.type === "3" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarRetiro({...retiro, type:'3',}) }}> <i class="tim-icons icon-bank panther-icon-style"></i> <p>Remesadora</p> </div> */}



                          </div> 

                      </div>

                      
                      

                      

                      

                      


                    </div>


                    <div className="elemento-dash-prisma-dos-retiro"> 

                    { retiro.type === "" ?  <>

                                    {cantidad > 0 ? <>
                                    
                                    
                                                
                                                    
                                                    <div className=" shadow-sm rounded py-3 mb-3 retiros-tabla-box " >


                                                                                                      


                                     

                                      <div className=" grid-retiros-fix grid-container-super-depositos  depositos-tabla-grid-bg">


                                    
                                                    <div className="" data-tooltip="Código de referencia del retiro">
                                                                                            <strong className="">
                                                                                            CÓDIGO
                                                                                            </strong>
                                                                                            </div>
                                                                                            <div className="" data-tooltip="Fecha del retiro"> 
                                                                                            
                                                                                            <strong className="">
                                                                                              FECHA
                                                                                            </strong>
                                                                                            
                                                                                            </div>

                                                                                            <div className="justify-content-flex-start" data-tooltip="Nombre del titular beneficiario"> 
                                                                                            
                                                                                            <strong className=" align-items-flex-start">
                                                                                            BENEFICIARIO
                                                                                            </strong>
                                                                                            
                                                                                            </div>

                                                                                            <div className="justify-content-flex-start" data-tooltip="Información de retiro"> 
                                                                                            
                                                                                            <strong className=" align-items-flex-start">
                                                                                            INFORMACION
                                                                                            </strong>
                                                                                            
                                                                                            </div>
                                                                                            <div className="" data-tooltip="Moneda"> 

                                                                                            <strong className=" align-items-flex-start">
                                                                                            MONEDA

                                                                                            </strong>
                                                                                            
                                                                                            </div>
                                                                                            <div className="" data-tooltip="Monto">

                                                                                            <strong className="">
                                                                                            MONTO 
                                                                                            </strong>
                                                                                            
                                                                                            </div>
                                                                                            <div className="" data-tooltip="Estado del movimiento"> 
                                                                                            <strong className=" align-items-flex-end ">
                                                                                            ESTADO DEL MOVIMIENTO
                                                                                            </strong>
                                                                                            
                                                                                            </div>
                                                                                        
                                                                      
                                                                                      </div>


                                                             
                                  {listaretiros.map(listadeposito => (
                                                                                    <>


                                                                                                            
                                                                                                            <div key={listadeposito.id}>

                                                                                                            <div  className=" animation-op grid-container-super-depositos grid-retiros-fix depositos-hover-tabla" onClick={ ()=> {
                                                                                                                
                                                                                                              handleOpenList();
                                                                                                               setModalInfo(listadeposito);
                                                                                                            }}>
                                            
                                                                                                                                                          
                                                                                                                                                                
                                                                                                                                                        <div className="" >
                                                                                                                                                              <strong className="line-fix">
                                                                                                                                                              {listadeposito.id}
                                                                                                                                                              </strong>
                                                                                                                                                              </div>
                                                                                                                                                              <div className="" > 
                                                                                                                                                              
                                                                                                                                                              <strong className="line-fix">
                                                                                                                                                              {listadeposito.fecha}
                                                                                                                                                              </strong>
                                                                                                                                                              
                                                                                                                                                              </div>

                                                                                                                                                              <div className="" > 

                                                                                                                                                                <strong className="line-fix align-items-flex-start">
                                                                                                                                                                {listadeposito.beneficiario}
                                                                                                                                                                </strong>
                                                                                                                                                                
                                                                                                                                                                </div>
                                                                                                                                                              <div className="" > 
                                            
                                                                                                                                                              <strong className="line-fix">
                                                                                                                                                              {listadeposito.tipo}
                                                                                                                                                              </strong>
                                                                                                                                                              
                                                                                                                                                              </div>
                                                                                                                                                              <div className="" > 
                                            
                                                                                                                                                              <strong className="line-fix ">
                                                                                                                                                              {listadeposito.currency_tag}
                                                                                                                                                              </strong>
                                                                                                                                                              
                                                                                                                                                              </div>
                                                                                                                                                              <div className="" >
                                            
                                                                                                                                                              <strong className="line-fix">
                                                                                                                                                              {listadeposito.monto}
                                                                                                                                                              </strong>
                                                                                                                                                              
                                                                                                                                                              </div>
                                                                                                                                                              <div className="text-right" > 
                                                                                                                                                              <strong className="line-fix  ">
                                                                                                                                                              {listadeposito.estado}
                                                                                                                                                              </strong>
                                                                                                                                                              
                                                                                                                                                              </div>
                                                                                                                                                              
                                                                                                                                        
                                                                                                                                                        </div>

                                                                                                                                                        </div>
                                            
                                                                                                                                                        
                                                                                                        
                                                

                                

                                </>
                                                                        ) )}

                                        </div>


                                                        
                                                            
                                                            
                                                            

                                                  </>      
                                                  :

                                                  <div className=" grid-blank">

                                                      
                                                      <div className=" panther-open-titulo-alerta border-panther"> 

                                                      
                                                    {cantidad === 0 ?  <> 
                                                    
                                                    <i className="tim-icons  icon-bullet-list-67 animation-icon-in mb-1"/>
                                                            <h2 className="animation-icon-in-dos mb-3">Sin retiros</h2>
                                                              <h3 class="animate-raya-03 mb-2">Todavía no haz hecho ningún retiro</h3>
                                                              <p className="text-center animation-icon-in-cuatro ">Para retirar dinero de su cuenta StrPrisma, porfavor seleccione el tipo de envío y siga intuitivamente los pasos </p>
                                                                </> :
                                                              <Spinner/>
                                                              
                                                                                        }
                                                          </div>
                                                                                    
                                                          

                                                          </div>
                                      }
                          </>
                          :null} 


                                      { retiro.type === "1" ? 

                                      <>

                                      { retiromenu === true ? 
                                      <>    

                                        

                                                      <div className="grid-retiros-select">

                                        <p onClick={ () => {                       
                                          Profit()
                                          }}
                                        
                                        >Retirar mis beneficios</p>

                                        <p 
                                        onClick={ () => {                       
                                          Vender()
                                          }}
                                        
                                        
                                        >Vender A.D.U's</p>


                                      </div> 
                                      <div className="box-general">
                    <img alt="..." src={require("../assets/img/logo-bg.svg")} />

                </div>

                                    <div className="box-info-retiros">
                                          <h2> 
                                              Seleccione su tipo de retiro a realizar
                                          </h2>

                                          <p>Puedes seleccionar entre vender tus piezas de artes o simplemente retirar tu ganancia  <br/>
                                            y seguir haciendo tu dinero rendir
                                          </p>


                                      </div>
                                      

                                      </>
                                                      : 
                                                      <>

                                                      { venderadu === true ?

                                                      <>
                                                      <div>
                                                      <p className="atras-retiro"> 
                                                                <span  onClick={ ()=>{
                                                                    guardarRetiroMenu(true);
                                                                }}>Atrás</span>     <small>al menú</small>
                                                                  
                                                                  
                                                                  </p>    

                                                                  <AduGeneratorRetiro
                                                                    usuario={usuario}
                                                                    usuarioAutenticado={usuarioAutenticado}
                                                                    montosave={montosave}
                                                                    guardarMontoSave={guardarMontoSave}
                                                                    guardarBtnDinamic={guardarBtnDinamic}
                                                                    btndinamic={btndinamic}
                                                                    montosavereset={montosavereset}
                                                                    guardarContrato={guardarContrato}
                                                                    contrato={contrato}
                                                                    participaciones={participaciones}
                                                                    guardarParticipacion={guardarParticipacion}
                                                                    guardarMultiplicador={guardarMultiplicador}
                                                                    multiplicador={multiplicador}
                                                                    /> 


                                                                    <RetiroAdu

                                                                      usuario={usuario} 
                                                                                                                        
                                                                      montosave={montosave}
                                                                      guardarMontoSave={guardarMontoSave}
                                                                      

                                                                      guardarContrato={guardarContrato}
                                                                      guardarBtnDinamic={guardarBtnDinamic}
                                                                      guardarParticipacion={guardarParticipacion}
                                                                      multiplicador={multiplicador}
                                                                                                                                      
                                                                            />

               



                                                      </div>




                                                      </>

                                                              :  

                                                              <div>            
                                                                <p className="atras-retiro"> 
                                                                <span  onClick={ ()=>{
                                                                    guardarRetiroMenu(true);
                                                                }}>Atrás</span>     <small>al menú</small>
                                                                  
                                                                  
                                                                  </p>                                                          
                                                              <div className="grid-header-retiro-ben " >
                                                              <h3 className="text-left mb-0 seleccione-ben animate-raya-03 mb-2"> Transferencia nacional </h3> 
                                                              <span className="plus-help-panther" onClick={ () => {                       
                                                                abrirNacional()
                                                                }}>  Añadir beneficiario  <span>+</span> 
                                                              </span> 
                                                              </div>
        
        
                                                              <div className="grid-header-retiro-ben-header-list header-retiro-style"> 
                                                                <p>Banco</p>
                                                                <p>Cuenta</p>
                                                                <p>Titular</p>
                                                                <p className="text-center">Tipo</p>
                                                                <p >Selección</p>
                                                              </div>    
                                                            <table className="table tabla_beneficiarios">
                                                              <tbody> 
                                                              {cuentaspropias.map(cuenta => ( 
                                                                <>
                                                              <div key={cuenta.id} className="grid-header-retiro-ben-header-list "> 
                                                                <p>{cuenta.bank_client}</p>
                                                                <p>{cuenta.bank_name}</p>
                                                                <p>{cuenta.bank_account}</p>
                                                                <p className="text-center">{cuenta.bank_type}</p>
                                                                <p 
                                                                className="btn-retiro"
                                                                onClick={ () => {
                                                                  handleOpen();
                                                                  setModalInfo(cuenta);
                                                                  // guardarBen(cuenta.id);
                                                                  
                                                                  guardarRetiro({
                                                                    ...retiro, beneficiario_id:cuenta.id,
                                                                  
                                                                  })
                                                                }}>Retirar &raquo; </p>
                                                              </div>
                                                              </>
                                                                        ) )}
                                                                        </tbody>
                                                              </table>                                              
                                                            
                                                      </div>
                                                       

                                                      }

                                                      </>


                                      }
                                       

                                     


                                      

                                        </>  
                                          :null }  


                                            { retiro.type === "2" ? 

                                                <div> 
                                                                    

                                                                    
                                                                        <div className="grid-header-retiro-ben  " >


                                                                        <h3 className="text-left mb-0 seleccione-ben animate-raya-03 mb-2"> Transferencia internacional </h3> 
                                                                        <span className="plus-help-panther" onClick={ () => {                       
                                                                          abrirInternacional()
                                                                          }}>  Añadir beneficiario  <span>+</span> 
                                                                        </span> 
                                                                        </div>


                                                                        <div className="grid-header-retiro-ben-header-list header-retiro-style"> 
                                                                          <p>Banco</p>
                                                                          <p>Cuenta</p>
                                                                          <p>Titular</p>
                                                                          <p className="text-center">Tipo</p>
                                                                          <p >Selección</p>
                                                                          
                                                                        
                                                                        </div>
                                                                  
                                                                      
                                                                      <table className="table tabla_beneficiarios">
                                                                      
                                                                      
                                                                        <tbody> 
                                                                        {cuentasterceros.map(cuenta => ( 
                                                                          <>

                                                                        <div key={cuenta.id} className="grid-header-retiro-ben-header-list "> 
                                                                          <p>{cuenta.bank_client}</p>
                                                                          <p>{cuenta.bank_name}</p>
                                                                          <p>{cuenta.bank_account}</p>
                                                                          <p className="text-center">{cuenta.bank_type}</p>
                                                                          <p 
                                                                          className="btn-retiro"
                                                                          onClick={ () => {
                                                                            handleOpen();
                                                                            setModalInfo(cuenta);
                                                                            // guardarBen(cuenta.id);
                                                                            
                                                                            guardarRetiro({
                                                                              ...retiro, beneficiario_id:cuenta.id,
                                                                            
                                                                            })
                                                                          }}>Retirar &raquo; </p>
                                                                          
                                                                        
                                                                        </div>
                                                                          
                                                                      
                                                                                      {/* <tr key={cuenta.id}>
                                                                                        <td>{cuenta.bank_name}</td>
                                                                                        <td>{cuenta.bank_client}</td>
                                                                                        <td>{cuenta.bank_account}</td>
                                                                                        <td>{cuenta.bank_type}</td>
                                                                                        <td >        <button
                                                                          title=""
                                                                          type="button"
                                                                          className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power-sm mt-1"
                                                                          onClick={ () => {
                                                                            handleOpen();
                                                                            setModalInfo(cuenta);
                                                                            // guardarBen(cuenta.id);
                                                                            
                                                                            guardarRetiro({
                                                                              ...retiro, beneficiario_id:cuenta.id,
                                                                            
                                                                            })
                                                                          }}
                                                                        > Continuar &raquo; </button>
                                                                                      </td>
                                                                                        
                                                                                      </tr>   */}
                                                                                        
                                                                  
                                                                        </>
                                                                                  ) )}

                                                                  
                                                                                  </tbody>

                                                                        </table> 

                                                                      

                                                      
                                                                        </div>


                                                                  
                                                              



                                                    
                                                    :null }  


                                          




                            
                    </div>

                    

      </div> 



                    


        <Form> 
              <div className="row d-flex justify-content-center "> 
        {/* <Col md="2">
        </Col>  */}

        <Col md="12" className="deposito-col-box d-none"> 
          <Card className="mb-1">
            <CardHeader>
           
              <p className="retiro-type-fix">Seleccione el tipo de retiro a realizar</p>
            </CardHeader>
            <CardBody> 

            <div className="grid-container"> 

              <div className={retiro.type === "" ? 'active-selection' : '' }   onClick={ () => { guardarRetiro({...retiro, type:'',}) }} ><p >Todos mis retiros</p> <span className="text-center w-100">Listado de retiros </span></div>

              
                
              <div className={retiro.type === "1" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarRetiro({...retiro, type:'1',}),guardarAddBen({bank_client:`${usuario.name}`}); }}> <i class="tim-icons icon-bank panther-icon-style"></i> <p>Transferencia nacional</p> </div>
              <div className={retiro.type === "2" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarRetiro({...retiro, type:'2',}),guardarAddBen({bank_client:`${usuario.name}`}); }}> <i class="tim-icons icon-bank panther-icon-style"></i> <p>Transferencia internacional</p> </div>
              {/* <div className={retiro.type === "3" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarRetiro({...retiro, type:'3',}) }}> <i class="tim-icons icon-bank panther-icon-style"></i> <p>Remesadora</p> </div> */}



              </div> 

              

              {retiro.type === "" ? <p className="text-center   animation-icon-in-dos"><i class="tim-icons icon-bank"></i> Sus retiros hechos hasta la fecha </p> : null }
              {retiro.type === "1" ? <p className="text-center  animation-icon-in-dos"> Para hacer depósitos a cuentas Nacionales en Panamá...</p> : null }
              {retiro.type === "2" ? <p className="text-center  animation-icon-in-dos"> Para hacer depósitos a cuentas Internacionales en todo el mundo...</p> : null }
              {retiro.type === "3" ? <p className="text-center  animation-icon-in-dos"> Para hacer depósitos por remesadora...</p> : null }
             

           
                



 
               
              
            </CardBody>
            <CardFooter className="pb-0 pt-0 d-none">
            <p class="text-muted mt-1 font-fix-deposito-14 ">Comisiones<span class="float-right d-flex align-items-center">
              {/* <del>1.00 USD</del>  */}
              <span class="bg-success text-1 text-white font-weight-500 rounded d-inline-block px-2 line-height-4 ml-2">Gratis</span>
              </span></p> 

              <hr className="my-1 border-info"/> 
              <p class="font-weight-500">El monto será acreditada por su valor integral menos costos bancarios. <span class="text-3 float-right" id="montoretiro"></span></p>
            </CardFooter>
          
          </Card>

        
          

        </Col> 
        
       

       


        {/* <Col md="2">
        </Col>  */}

        
        

        
        
        </div> 

       

                  

                  
        

        { retiro.type === "3" ? 

                  <>
<Row className="justify-content-center ">
<Col md="12"> 


                
            <Card>
                    <CardHeader className="retiro-tittle">
                      <h3>
                      Remesadora
                      </h3>

                    </CardHeader>
                    <CardBody>

                    <Row>
                <Col md="12">
                <div className="card p-3 mt-2 registrar-deposito ">
                                            

                                            <div className="acc-deposito-container">
                                              <h2> Información complementaria </h2>
                                              </div> 

                                              <ol>
                                              <li>  
                                                  <div className="term mt-8 mb-1 mt-1">
                                                  <h5>El beneficiario debera dirigirse a la casa de remesas Master Money más cercano. Ver listado de oficinas <strong className="master-click"onClick={ ()=> {
                                        handleOpenDireccion()
                                      }}>Master Money</strong> </h5>
                                                  </div>

                                              </li> 

                                              <li>
                                                  <div className="term my-1">
                                                  <h5>El beneficiario deberá  ir con una identificación vigente.</h5>
                                                  </div>
                                              </li>

                                              <li>
                                                <div className="term my-1">
                                                <h5>El beneficiario deberá notificarle al operador Master Money el numero de autorización <span className="giro-text"> Giro Auxiliar </span> ( PantherPIN ) <strong className="pin"> {pin ? <p className="panther-pin-box">{pin}</p> : null}</strong> </h5>
                                                </div>
                                              </li> 
                                             
                                            
                                              <li>
                                                  <div className="term my-1">
                                                  <h5>Toda la información será enviada vía correo electrónico</h5>
                                                  </div>
                                              </li>


                                            </ol>
                                                
                                                {pin ? <p className="text-white panther-pin-box"> <span className="giro-text"> Giro Auxiliar </span> PantherPIN <strong className="pin">{pin}</strong> </p> : null} 
                                        
                                            
                                            </div> 

                  <FormGroup> 

                     <Container> 
                

            {/* </div> */} 
            <div className="d-flex  justify-content-between align-items-center " onClick={ () => {
                            
                                                                  
                            abrirRemesadora()
                        
                      
                      }}>
                                      <h3 className="text-left mb-0 seleccione-ben"> Seleccione beneficiario </h3> 
                                      <span className="plus-help-panther"> Añadir beneficiario <i class="tim-icons icon-simple-remove"></i> </span> 
                                      </div>
                      <hr class="my-1 border-info"/> 
                      <table className="table tabla_beneficiarios">
                        <thead>
                          <tr>
                              <th className="sortable">
                                  NOMBRES
                              </th>
                              <th className="sortable">
                                  APELLIDOS
                              </th>
                              <th className="sortable">
                                  TIPO ID
                              </th>
                              <th className="sortable ">
                                  IDENTIFICACION
                              </th>
                              <th className="sortable ">
                                  PAIS
                              </th>
                              <th className="sortable text-center">
                                  SELECCION
                              </th>
                              
          
                          </tr>
                        </thead> 
                      
                        <tbody> 

                        {cuentasremesas.map(cuenta => (
                          <>
                          
                      
                                      <tr key={cuenta.id}>
                                        <td>{cuenta.bank_client}</td>
                                        <td>{cuenta.bank_apellidos}</td>
                                        <td>{cuenta.tipo_identificacion}</td>
                                        <td>{cuenta.identificacion}</td>
                                        <td>{cuenta.pais}</td>
                                        <td >        <button
                          title=""
                          type="button"
                          className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power-sm mt-1"
                          onClick={ () => {
                            handleOpen();
                            setModalInfo(cuenta);
                            
                            
                            guardarRetiro({
                              ...retiro, beneficiario_id:cuenta.id,
                            
                            })
                          }}
                        > Continuar &raquo; </button>
                                       </td>
                                        
                                      </tr>  
                                        
                  
                        </>
                                  ) )}

                  
                                  </tbody>

                        </table> 

                      

        
        </Container>

                
                  </FormGroup>
                
                </Col>
              </Row>

                    </CardBody> 


                   
               



            </Card>
      </Col>
    </Row>


                 {/* <Row className="justify-content-center ">
                 <Col md="12">
                             <Card>
                             <CardHeader className="retiro-tittle">
                                       <h3>
                                         Remesadora
                                       </h3>
   
                                     </CardHeader>
                                     <CardBody>
   
                                     <Row>
                                     <Col md="12">
                                        <div className="card p-3 mt-2 registrar-deposito ">
                                            

                                              <div className="acc-deposito-container">
                                                <h2> Esta solicitud sera sometida a revision. Espere correo de confirmación de retiro. </h2>
                                                </div> 

                                                <ol>
                                                <li>  
                                                    <div className="term mt-8 mb-1 mt-1">
                                                    <h5>De ser aprobada el beneficiario debera dirigirse a la casa de remesas Master Money más cercano. Ver listado de oficinas <a href="https://mastermoneypty.com/?page_id=1099" target="_blank" rel="noopener noreferrer" > <strong>Master Money</strong> </a></h5>
                                                    </div>

                                                </li> 

                                                <li>
                                                    <div className="term my-1">
                                                    <h5>El beneficiario deberá  ir con una identificación vigente.</h5>
                                                    </div>
                                                </li>

                                                <li>
                                                  <div className="term my-1">
                                                  <h5>El beneficiario deberá notificarle al operador Master Money el numero de autorización (PantherPIN) <strong className="pin"> {pin ? <p className="panther-pin-box">{pin}</p> : null}</strong> </h5>
                                                  </div>
                                                </li> 
                                               
                                              
                                                <li>
                                                    <div className="term my-1">
                                                    <h5>Toda la información será enviada vía correo electrónico</h5>
                                                    </div>
                                                </li>


                                              </ol>
                                                  
                                                  {pin ? <p className="text-white panther-pin-box">PantherPIN <strong className="pin">{pin}</strong> </p> : null} 
                                          
                                              
                                              </div> 


               
                  
                  
                  </Col>
                               </Row>
   
                                     </CardBody>
                                     <CardFooter>
                                
                                         <p className="btn btn-primary btn-block"  onClick={ () => {
                                            abrirTerceros();
                                         }} >Continuar</p>
   
                                     </CardFooter>
   
   
   
   
                             </Card>
                       </Col> 
                  
                     </Row>  */}

                     </>
        :null} 



        { retiro.type === "4" ? 

            <Row className="justify-content-center ">
            <Col md="12">
                        <Card>
                                <CardHeader>
                                  <h3 className="mb-0">
                                    BitCoin
                                  </h3>
                                  <p>Una opcion interesante para retirar el dinero mediante tu cartera de BTC<span className="text-success"> *  </span></p>
                                  <hr className="my-1 border-info"/> 

                                </CardHeader>
                                <CardBody>

                                
                                      <Row>
                                        <Col md="12">
                                          <FormGroup>
                                          < div className="form-group form-control-deposito-container">
                                      <label className="deposito-label-between" for="youSend">
                                        <p>Monto <span className="text-danger">*</span></p>
                                        <p>Elegir Moneda <span className="text-success"> *  </span></p>
                                        </label> 
                                      

                                      <div className="input-group">
                                        <div className="input-group-prepend "> <span className="input-deposito-moneda input-group-text">$</span> </div>
                                        <input type="text" className="deposito-form-control-props-input form-control"   
                                        placeholder="Digite el monto $$$ "
                                        id="amount"
                                        name="amount"
                                        onChange={onChange}
                                        
                                        
                                        />
                                        <div className="input-group-append input-group-append-deposito  p-0 custom-select "> 
                                        <select id="youSendCurrency" data-style="custom-select bg-transparent border-0" data-container="body" data-live-search="true" className="selectpicker form-control p-0 custom-select-deposito bg-transparent" required="" tabIndex="-98">
                                                <optgroup label="Moneda" className="bg-primary">
                                                   
                                                    <option data-icon="btc" data-subtext="BTC" value="">BTC &nbsp;</option>
                                                </optgroup>
                                              </select>
                                        </div>
                                      </div>
                                    </div>

                                        
                                          </FormGroup>
                                        
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col  md="12">
                                        <label className="display-4">Direccion BTC a Enviar <span className="text-success">*</span> </label>
                                          <input 
                                            type="text"
                                            id="fecha"
                                            name="fecha"
                                            placeholder="Coloque la Llave Privada de su Cartera BTC"
                                            onChange={onChange}
                                            value={retiro.fecha} 
                                            
                                            
                                            
                                          
                                          className=" form-control" required="" onchange="cambiar(this.value)"/>
              
                                        </Col>
                                    
                                        
                                      </Row> 

 
               
              
            </CardBody>
                                <CardFooter>
            <p class="text-muted mt-1 font-fix-deposito-14 ">Total comisiones<span class="float-right d-flex align-items-center">
              
              <span class="bg-info text-1 text-white font-weight-500 rounded d-inline-block px-2 line-height-4 ml-2">0.0001 BTC</span>
              </span></p> 

              <hr className="my-1 border-info"/> 
              <p class="font-weight-500">Total a pagar <span class="bg-success text-1 text-white font-weight-500 rounded d-inline-block px-2 line-height-4 ml-2 float-right">0.005 BTC</span></p>
              <button className="mt-4 btn btn-primary btn-block" type="submit">Continuar</button>
            </CardFooter> 

            




                        </Card>
                  </Col>

                  <div className="col-12">
                  <p className="ondev"><span className="ondevspan"> Bienvenido a la Version <strong className="ondevstrong">Beta</strong> de Panther7 <br/> </span>FUNCIONALIDAD EN DESARROLLO </p>       
                </div>
                </Row>        
        :null}

        




        </Form>
      </div> 



        
      <ModalDos 
                            open={open}
                            className="bg-modal-retiro"
                          
                        >
                            <div style={modalStyle} className={`  card Modal-inversion-retiro modal-power-up-retiro`}>
                            <form onSubmit={onSubmitTransferencia}>
                            <CardHeader > 
                            <Container>
                                <Row>
                                  <Col xs="12" sm="12" md="12" lg="12" >
                                  {retiro.type === "1" ? <p className="text-fix-retiros-modal">Transferencia nacional</p> : null}  
                                  {retiro.type === "2" ? <p className="text-fix-retiros-modal">Transferencia internacional</p> : null}
                                  {retiro.type === "3" ? <p className="text-fix-retiros-modal">Transferencia remesa</p> : null}
                                    <h5 className="title display-4 mb-0 text-center"> Solicitud de retiro a {modalInfo.bank_client} </h5>
                                  </Col>
                             
                                </Row>
                              </Container>
                                {/* <h3 className="title display-4 text-center mis-inversiones-h5 ">Transferencia ACH a  {modalInfo.bank_client} </h3> */}
                                
                            </CardHeader>

                            <div className="container-fluid row d-flex seudo-hermano">
                            
                     
                            <div  className="col-4">
                            <div className=" btn mt-0 btn-block btn-info btn-panther-power btn-primario"  onClick={() => (  
                                                                                              handleClose(),
                                                                                              elGuardarCargando(false),
                                                                                              guardarRetiro({
                              ...retiro, amount:'',
                            
                            })

                              )}>
                                
                                
                                                          <div className="cancel-btn-panther text-fix-btn"> 
                                                              <i className="tim-icons icon-simple-remove"/>
                                                          </div>
                                                      
                              </div>
                            
                            </div>
                                
                     
                      </div>  
                                  <CardBody className="card-body-fix">
                                        
                              
                                            

                                 
                                                  
                                                
                                                             
                                <Row >

                                
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">


                                         
                                     
                                                 
                                      
                                    
                                      { retiro.amount ? <p className="monto-dolares-alerta animation-icon-in-dos montoplus"> <span className="number-retiro-span"> <NumberFormat value={retiro.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/> </span> es el monto que va a enviar </p> : <p className="monto-dolares-alerta animation-icon-in-cuatro montoless mb-1">Aún no ha ingresado un monto a enviar
                                       </p>} 

                                       <div className="monto-dolares animate-raya-04"> 

                                              
                                    
                                 
                                       <input type="text" className="deposito-form-control-props-input  input-monto-modal"   
                                                      placeholder="Ingrese la cantidad..."
                                                      id="amount"
                                                      name="amount"
                                                      onChange={onChange}
                                                      value={modalInfo.amount} 
                                                      required
                                                      


                                                      
                                                      />
                                      
                                                 </div> 

                        
                                             

                                    </Col> 

                                    
                              </Row> 
                           
                                         
                                    <Row className="d-flex justify-content-center"> 
                                      <Col sm="10" md="10" className=" static-height"> 
                       
                                                        
                                                        <FormGroup>
                                   < div className="form-group form-control-deposito-container animation-icon-in-cuatro">


                                 

                                 { retiro.type === "1" || retiro.type === "2" ?   <Row>
                                                                          
                                                                          
                                        
                                                                          <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                                                          <label className="panther-retiro-label">Tipo de cuenta <span className="text-success  ">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                placeholder="Identificacion Destinatario"
                                                                                id="note"
                                                                                name="note"
                                                                                
                                                                                value={modalInfo.bank_type}
                                                                                
                                                                              
                                                                              className=" form-control" required=""/>
                                                                    
                                                                          </Col> 

                                                                          <hr className="my-1 border-info"/> 

                                                                          <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                                                          <label className="panther-retiro-label">Titular de la cuenta <span className="text-success  ">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                placeholder="Identificacion Destinatario"
                                                                                id="note"
                                                                                name="note"
                                                                                value={modalInfo.bank_client}
                                                                                
                                                                                
                                                                              
                                                                              className=" form-control" required="" />
                                                                          </Col>
                                                                      
                                                                      </Row>   
                                        :null }
                                 

                                          { retiro.type === "2" ? <Row>
                                    
                                    
   
                                          <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                              
                                      <label className="panther-retiro-label">Pais del banco<span className="text-success  ">*</span> </label>
                                          <input 
                                            type="text"
                                            placeholder="e.g. ABCDE12345"
                                            id="note"
                                            name="note"
                                            value={"Panama"}
                                            
                                            
                                          
                                          className=" form-control" required="" />
                                    </Col> 

                                    <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                    <label className="panther-retiro-label">Swift <span className="text-success  ">*</span> </label>
                                      <input 
                                        type="text"
                                        placeholder="e.g. ABCDE12345"
                                        id="note"
                                        name="note" 
                                        
                                        value={modalInfo.bank_swift}
                                        
                                      
                                      className=" form-control" required="" />
                                    </Col> 
                                    <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                    <label className="panther-retiro-label">Aba <span className="text-success ">*</span> </label>
                                      <input 
                                        type="text"
                                        placeholder="e.g. ABCDE12345"
                                        id="note"
                                        name="note" 
                                        
                                        value={modalInfo.bank_aba}
                                        
                                      
                                      className=" form-control" required="" />
                                    </Col> 

                                    

                                
                                 </Row>  :  null } 



                                 { retiro.type === "1" ? 
                                 
                                 
                                 
                                 <Row> 

                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-retiro-label">Número de la cuenta<span className="text-success  ">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="e.g. Smith Rhodes"
                                          id="note"
                                          name="note"
                                          value={modalInfo.bank_account}
                                          
                                          
                                        
                                        className=" form-control" required="" onchange="cambiar(this.value)"/>
                                            </Col> 

                                    <hr className="my-1 border-info"/> 

                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-retiro-label">Nombre del Banco<span className="text-success  ">*</span> </label>
                                      <input 
                                        type="text"
                                        placeholder="e.g. ABCDE12345"
                                        id="note"
                                        name="note"
                                        value={modalInfo.bank_name}
                                        
                                        
                                      
                                      className=" form-control" required="" />

                                    </Col>  

                                    
                                    
   
                                    
                                 <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                              
                                              <label className="panther-retiro-label">Pais del banco<span className="text-success  ">*</span> </label>
                                                  <input 
                                                    type="text"
                                                    placeholder="e.g. ABCDE12345"
                                                    id="note"
                                                    name="note"
                                                    value={"Panama"}
                                                    
                                                    
                                                  
                                                  className=" form-control" required="" />
                                            </Col> 

                                
                                 </Row>  :  null } 


                                 { retiro.type === "3" ? 
                                 
                                 
                                 
                

                                 
                                 <Row className="justify-content-center d-flex">  

                              <Col  md="12">
                                                <label className="mt-2 tipo-fix-font">Selecciona el tipo de envio <span className="text-danger">*</span></label>
                                              <select  className="custom-select form-control "  
                                                      id="ventanilla"
                                                      name="ventanilla"
                                                      onChange={onChange}
                                                      value={modalInfo.ventanilla} 
                                              
                                              >
                                            
                                              
                                              
                                              <option value="SI" >Retiro en taquilla</option>
                                              <option value="NO">Deposito a cuenta Bancaria </option>
                                              
                                              {/* <option value="4">Bticoin</option> */}
                                              
                                          
                                            </select>
                                              </Col>
 

                                        {/* <div  className="col-sm-6 col-md-6 col-6"> 

                                                                                                                                        
                                        <FormControlLabel
                                              
                                              control={<Checkbox className="checkbox-fix" checked={checkint} onChange={handleChangeCheckInt} name="checkint"  
                                              
                                              color="primary" 


                                              
                                              />}
                                              label="Retiro en taquilla? "
                                              

                                          />  


                                          



                                        </div>  

                                        <div  className="col-sm-6 col-md-6 col-6"> 

                                                                                              
                                        <FormControlLabel
                                              
                                              control={<Checkbox className="checkbox-fix" checked={checkint} onChange={handleChangeCheckInt} name="checkint"  
                                              
                                              color="primary" 


                                              
                                              />}
                                              label="Retiro a cuenta Bancaria ? "
                                              

                                          /> 






                                        </div>  
 */}


                                          <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                          <label className="panther-retiro-label">Nombres <span className="text-success  mt-5">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                
                                                                                id="note"
                                                                                name="note"
                                                                                
                                                                                value={modalInfo.bank_client
                                                                                }
                                                                                
                                                                              
                                                                              className=" form-control" required=""/>
                                                                    
                                                                          </Col> 

                                                                          <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                          <label className="panther-retiro-label">Apellidos <span className="text-success  mt-5">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                
                                                                                id="note"
                                                                                name="note"
                                                                                
                                                                                value={modalInfo.bank_apellidos}
                                                                                
                                                                              
                                                                              className=" form-control" required=""/>
                                                                    
                                                                          </Col> 

                                                                          <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                          <label className="panther-retiro-label">{modalInfo.tipo_identificacion} <span className="text-success  mt-5">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                
                                                                                id="note"
                                                                                name="note"
                                                                                
                                                                                value={modalInfo.identificacion}
                                                                                
                                                                              
                                                                              className=" form-control" required=""/>
                                                                    
                                                                           </Col>  

                                                                           
                                                                          <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                          <label className="panther-retiro-label">Pais <span className="text-success  mt-5">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                
                                                                                id="note"
                                                                                name="note"
                                                                                
                                                                                value={modalInfo.pais}
                                                                                
                                                                              
                                                                              className=" form-control" required=""/>
                                                                    
                                                                          </Col>   


                                                                          
                                                                          <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                          <label className="panther-retiro-label">Provincia <span className="text-success  mt-5">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                
                                                                                id="note"
                                                                                name="note"
                                                                                
                                                                                value={modalInfo.provincia}
                                                                                
                                                                              
                                                                              className=" form-control" required=""/>
                                                                    
                                                                          </Col>  





                                                                          <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                          <label className="panther-retiro-label">Ciudad <span className="text-success  mt-5">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                
                                                                                id="note"
                                                                                name="note"
                                                                                
                                                                                value={modalInfo.ciudad}
                                                                                
                                                                              
                                                                              className=" form-control" required=""/>
                                                                    
                                                                           </Col>  
                                                                           
                                                                        

                                                                           <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                          <label className="panther-retiro-label">Dirección <span className="text-success  mt-5">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                
                                                                                id="note"
                                                                                name="note"
                                                                                
                                                                                value={modalInfo.direccion_cliente}
                                                                                
                                                                              
                                                                              className=" form-control" required=""/>
                                                                    
                                                                          </Col>  




                                                                          <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                          <label className="panther-retiro-label">Telefono <span className="text-success  mt-5">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                
                                                                                id="note"
                                                                                name="note"
                                                                                
                                                                                value={modalInfo.telefono }
                                                                                
                                                                              
                                                                              className=" form-control" required=""/>
                                                                    
                                                                          </Col>  



                                
                                 </Row>  :  null }
                                 
                                 
                                 
                                 
                                 
                       
                       
                       
   
                              </div> 



                                 
                                   </FormGroup>


                                                        
                                                    </Col>       
                                            </Row>

                                    

                                  </CardBody>  

                                  { demo_status === "active" ? 

                                  <CardFooter> 
                                  <div className="row justify-content-center">


                                        <div  className="col-sm-12 col-md-6 col-12"> 
                                        Esto es una plataforma de demostración, el saldo y las operaciones no son reales.

                                        <button className="btn btn-primario btn-block btn-panther-power"  type="submit" disabled>
                                          {elguardar}
                                        </button>
                                        
                                        </div>
                                            

                                  </div>


                                  </CardFooter> :   <CardFooter> 
                                  <div className="row justify-content-center">
                                
                                  
                                        <div  className="col-sm-12 col-md-6 col-12"> 
                                        

                                        <button className="btn btn-primario btn-block btn-panther-power"  type="submit" >
                                          {elguardar}
                                        </button>
                                        
                                        </div>
                                            
                            
                                  </div>


                                  </CardFooter>
                                  
                                
                                
                                }
                                
                                  

                                  

                              
                                </form>
                            </div>
      </ModalDos> 

                        <ModalTerceros
                    
                          className={classes.modal}
                          open={modalterceros}
                          onClose={cerrarTerceros}
                          
                          BackdropComponent={Backdrop}
                          BackdropProps={{
                              timeout: 900,
                          }}
                        >
                    <Fade in={modalterceros}
                    timeout={154}
                    onClose={cerrarTerceros}
                    
                    >
                        <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                        <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}> 
                        
                        <form onSubmit={onSubmitTerceros} >
  
                        


                        <CardHeader className="bg-modal-header"> 
                              <Container>
                                <Row>
                                  <Col xs="10" sm="10" md="10" lg="10" className=" fix-cuentaspropias-title ">
                                    <h5 className="title display-4 mb-0">Retiro por remesa </h5>
                                  </Col>
                                  <Col xs="2" sm="2" md="2" lg="2">
                                    <div className="cancel-box-btn">
                                        <div className="cancel-btn-panther" onClick={ () => (
                                          cerrarTerceros()
                                        )}> 
                                            <i className="tim-icons icon-simple-remove"/>
                                        </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                                  
                          </CardHeader>
                        
                        <CardBody className="tarjeta-modal-fix">
                                            <Row className="d-flex justify-content-center">
                                            
                                                    <Col sm="12" md="11" className=" static-height"> 
                        {/* aqui esta la clase */}
                        
                                                        
                                                        <FormGroup>
                                   < div className="form-group form-control-deposito-container">
                                     


                                        
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">


                                      < div className="form-group form-control-deposito-container">
                                                    <label className="deposito-label-between" for="youSend">
                                                      <p>Deseas enviar <span className="text-danger">*</span></p>
                                                      <p>Elegir Moneda <span className="text-success"> *  </span></p>
                                                      </label> 
                                                    
                        
                                                    <div className="input-group">
                                                      <div className="input-group-prepend "> <span className="input-deposito-moneda input-group-text">$</span> </div>
                                                      <input type="text" className="deposito-form-control-props-input form-control"   
                                                      placeholder="$$$$$$$"
                                                      id="amount"
                                                      name="amount"
                                                      onChange={onChange}
                                                      value={retiro.amount} 
                                                      required
                                                      
                                                      />
                                                      <div className="input-group-append input-group-append-deposito  p-0 custom-select "> 
                                                      <select id="youSendCurrency" data-style="custom-select bg-transparent border-0" data-container="body" data-live-search="true" className="selectpicker form-control p-0 custom-select-deposito bg-transparent" required="" tabindex="-98">
                                                              <optgroup label="Moneda" className="bg-primary">
                                                                  <option data-icon="currency-flag currency-flag-usd mr-1" data-subtext="USD" selected="selected" value="">USD&nbsp;</option>
                                                                  <option data-icon="btc" data-subtext="BTC" value="">BTC &nbsp;</option>
                                                              </optgroup>
                                                            </select>
                                                      </div>
                                                    </div> 
                        

                        
                                             </div>  

                                    </Col> 
                              </Row> 
                           
                                 

                                <Row>
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Nombres <span className="text-danger mt-3">*</span> </label>
                                          <input 
                                            type="text"
                                            placeholder="Escriba todos sus nombres"
                                            id="remesa_nombres"
                                            name="remesa_nombres"
                                            onChange={onChange}
                                            value={retiro.remesa_nombres}
                                            
                                            
                                          
                                          className=" form-control" required/>
                                      </Col> 

                                      <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Apellidos <span className="text-danger mt-3">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="Escriba todos sus apellidos"
                                          id="remesa_apellidos"
                                          name="remesa_apellidos"
                                          onChange={onChange}
                                          value={retiro.remesa_apellidos}
                                          
                                          
                                        
                                        className=" form-control" required/>

                                    </Col> 

                           
                                 
                                 </Row> 
                                 <Row>
                                   
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6"> 

                                  <label className="panther-cuenta-bancaria">Tipo de Identificacion <span className="text-danger mt-0">*</span></label>
                                          <select 
                                            id="remesa_tipoidentificacion"
                                            name="remesa_tipoidentificacion"
                                            onChange={onChange}
                                            value={retiro.remesa_tipoidentificacion}
                                            className="form-control  pointer-cursor">
                                          <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                                          <option value="Pasaporte">Pasaporte</option>
                                                <option value="Cedula">Cedula/DNI</option>
                                                <option value="Licencia">Licencia</option>
                                                
                                                
                                          
                                          </select> 
                                        

                                  </Col> 

                                  <hr className="my-1 border-info"/> 
                                 <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Identificacion <span className="text-danger mt-3">*</span> </label>
                                          <input 
                                            type="text"
                                            placeholder="Escriba su ID"
                                            id="remesa_identificacion"
                                            name="remesa_identificacion"
                                            onChange={onChange}
                                            value={retiro.remesa_identificacion}
                                            
                                            
                                          
                                          className=" form-control" required/>
                                      </Col>
                                    
                                 
                                 </Row> 
                                 {/* //////////////////////////// una linea */}

                                 <Row>

                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label className="panther-cuenta-bancaria">Pais <span className="text-danger mt-3">*</span> </label>
                                          <input 
                                            type="text"
                                            placeholder="Nombde del pais"
                                            id="remesa_country"
                                            name="remesa_country"
                                            onChange={onChange}
                                            value={retiro.remesa_country}
                                            
                                            
                                          
                                          className=" form-control" required/>
                                      </Col>
                                
                                 </Row> 

                                 {/* /////////////////// termina la linea */}
         

                                 <Row>
                                    
                                    
   
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Provincia <span className="text-danger mt-3">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="Nombre de la ciudad"
                                          id="remesa_provincia"
                                          name="remesa_provincia"
                                          onChange={onChange}
                                          value={retiro.remesa_provincia}
                                          
                                          
                                        
                                        className=" form-control" required/>

                                    </Col> 

                                    <hr className="my-1 border-info"/> 

                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Ciudad <span className="text-danger mt-3">*</span> </label>
                                          <input 
                                            type="text"
                                            placeholder="Nombre de la ciudad"
                                            id="remesa_ciudad"
                                            name="remesa_ciudad"
                                            onChange={onChange}
                                            value={retiro.remesa_ciudad}
                                            
                                            
                                          
                                          className=" form-control" required/>
                                      </Col>
                                
                                 </Row> 
                                 <Row>
                                    
                                    
   
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Direccion <span className="text-danger mt-3">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="Direccion"
                                          id="remesa_direccion"
                                          name="remesa_direccion"
                                          onChange={onChange}
                                          value={retiro.remesa_direccion}
                                          
                                          
                                        
                                        className=" form-control" required/>

                                    </Col> 
                                    <hr className="my-1 border-info"/> 
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Telefono <span className="text-danger mt-3">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="Numero de telefono"
                                          id="remesa_telefono"
                                          name="remesa_telefono"
                                          onChange={onChange}
                                          value={retiro.remesa_telefono}
                                          
                                          
                                        
                                        className=" form-control" required/>

                                    </Col> 
                                
                                 </Row> 
                                            
                  


   
                              </div> 



                                 
                                   </FormGroup>

                                  
                                                        
                                                    </Col>       
                                            </Row>
                                        </CardBody> 
                                        <CardFooter>
                            <div className="row justify-content-center">
                                
                          
                                <div  className="col-sm-12 col-md-6 col-12"> 
                                
                                

                                
                                
                                
                                
                                <button className="btn btn-primario btn-block btn-panther-power"  type="submit" >
                                  {elguardar}
                                </button>
                                
                                </div>
                                    
                            
                            </div>
                        </CardFooter> 
                        

                             
                                    
                        
                        </form>
                    </div>

                        </div>

                    </div>
                        </div>
                    </Fade>
                    
                    </ModalTerceros> 

                    { mensaje ? <Modal
                                
                                className={classes.modal}
                      open={modalmensaje} 
                      mensaje={mensaje}
                      ListaRemesas={ListaRemesas}
                      ListaNacionales={ListaNacionales}
                      ListaInternacionales={ListaInternacionales}

                      TodosRetiros={TodosRetiros}
                                        
                      onClose={cerrarMensaje}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 900,
                      }}>
                                <Fade in={modalmensaje}
                                timeout={154}
                                onClose={cerrarMensaje}
                                >     
                              
                              <div className="container-grid">
                              <div  className={`modal-kyc-welcome padding-modal`}> 
                                  <div className="form-group form-control-deposito-container">
                                      <div className="modal-grid-row"> 

                                      <div> 
                                            <p className="text-center display-4 title my-3 font-light-weight-modal"> {mensaje}</p>

                                      </div>

                                      <div>
                                         <button className="  btn mt-0 btn-block btn-info my-2 btn-panther-power" 
                                         onClick={ () => (
                                          cerrarTerceros(),
                                          cerrarMensaje(),
                                          cerrarInternacional(),
                                          cerrarNacional(),
                                          cerrarRemesadora(),
                                          handleClose(),
                                          ApiLoad(), 
                                          guardarAddBen({}),
                                          guardarBeneficiarioid({}),
                                          ListaNacionales(),
                                          ListaInternacionales(),
                                          ListaRemesas(),
                                          TodosRetiros()
                                                                                    
                                        )}
                                         
                                         >
                                                                    Aceptar
                                          </button>
                                      </div>
                                      </div>    
                                      </div>
                                      </div>        
                      </div>
                                                        </Fade>
                                                      </Modal> : null}

 <ModalRemesadora
                    
                    className={classes.modal}
                    open={modalremesadora}
                    onClose={cerrarRemesadora}
                    
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 900,
                    }}
                    >
                    <Fade in={modalremesadora}
                    timeout={154}
                    onClose={cerrarRemesadora}
                    
                    >
                        <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                        <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}> 
                        
                        <form onSubmit={onSubmitRemesas} >
  
                        


                        <CardHeader className="bg-modal-header"> 
                              <Container>
                                <Row>
                                  <Col xs="10" sm="10" md="10" lg="10" className=" fix-cuentaspropias-title ">
                                    <h5 className="title display-4 mb-0">Agregar beneficiario remesadora</h5>
                                  </Col>
                                  <Col xs="2" sm="2" md="2" lg="2">
                                    <div className="cancel-box-btn">
                                        <div className="cancel-btn-panther" onClick={ () => (
                                          cerrarRemesadora()
                                        )}> 
                                            <i className="tim-icons icon-simple-remove"/>
                                        </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                                  
                          </CardHeader>
                        
                        <CardBody className="tarjeta-modal-fix">
                                            <Row className="d-flex justify-content-center">
                                            
                                                    <Col sm="12" md="11" className=" static-height"> 
                        {/* aqui esta la clase */}
                        
                                                        
                                                        <FormGroup>
                                   < div className="form-group form-control-deposito-container">  

                                   <Row>
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Nombres <span className="text-danger mt-3">*</span> </label>
                                          <input 
                                            type="text"
                                            placeholder="Escriba todos sus nombres"
                                            id="bank_client"
                                            name="bank_client"
                                            onChange={onChangeRem}
                                            value={addben.bank_client}
                                            
                                            
                                          
                                          className=" form-control" required/>
                                      </Col> 

                                      <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Apellidos <span className="text-danger mt-3">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="Escriba todos sus apellidos"
                                          id="bank_apellidos"
                                          name="bank_apellidos"
                                          onChange={onChangeRem}
                                          value={addben.bank_apellidos}
                                          
                                          
                                        
                                        className=" form-control" required/>

                                    </Col> 

                           
                                 
                                 </Row> 
                                 <Row>
                                   
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6"> 

                                  <label className="panther-cuenta-bancaria">Tipo de Identificacion <span className="text-danger mt-0">*</span></label>
                                          <select 
                                            id="tipo_identificacion"
                                            name="tipo_identificacion"
                                            onChange={onChangeRem}
                                            value={addben.tipo_identificacion}
                                            className="form-control  pointer-cursor">
                                          <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                                          <option value="Pasaporte">Pasaporte</option>
                                                <option value="Cedula">Cedula/DNI</option>
                                                <option value="Licencia">Licencia</option>
                                                
                                                
                                          
                                          </select> 
                                        

                                  </Col> 

                                  <hr className="my-1 border-info"/> 
                                 <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Identificacion <span className="text-danger mt-3">*</span> </label>
                                          <input 
                                            type="text"
                                            placeholder="Escriba su ID"
                                            id="identificacion"
                                            name="identificacion"
                                            onChange={onChangeRem}
                                            value={addben.identificacion}
                                            
                                            
                                          
                                          className=" form-control" required/>
                                      </Col>
                                    
                                 
                                 </Row> 

                                 {/* //////////////////////////// una linea */}

                                 <Row>

                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label className="panther-cuenta-bancaria">País<span className="text-danger mt-5">*</span> </label>
                                       <select className="form-control  pointer-cursor" id="country_id" name="country_id" value={country_id} onChange={onChangeRem} required>
                                <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                                                        <option value="144">Afganistán</option>
                                                        <option value="114">Albania</option>
                                                        <option value="18">Alemania</option>
                                                        <option value="98">Algeria</option>
                                                        <option value="145">Andorra</option>
                                                        <option value="119">Angola</option>
                                                        <option value="4">Anguilla</option>
                                                        <option value="147">Antigua y Barbuda</option>
                                                        <option value="207">Antillas Holandesas</option>
                                                        <option value="91">Arabia Saudita</option>
                                                        <option value="5">Argentina</option>
                                                        <option value="6">Armenia</option>
                                                        <option value="142">Aruba</option>
                                                        <option value="1">Australia</option>
                                                        <option value="2">Austria</option>
                                                        <option value="3">Azerbaiyán</option>
                                                        <option value="80">Bahamas</option>
                                                        <option value="127">Bahrein</option>
                                                        <option value="149">Bangladesh</option>
                                                        <option value="128">Barbados</option>
                                                        <option value="9">Bélgica</option>
                                                        <option value="8">Belice</option>
                                                        <option value="151">Benín</option>
                                                        <option value="10">Bermudas</option>
                                                        <option value="7">Bielorrusia</option>
                                                        <option value="123">Bolivia</option>
                                                        <option value="79">Bosnia y Herzegovina</option>
                                                        <option value="100">Botsuana</option>
                                                        <option value="12">Brasil</option>
                                                        <option value="155">Brunéi</option>
                                                        <option value="11">Bulgaria</option>
                                                        <option value="156">Burkina Faso</option>
                                                        <option value="157">Burundi</option>
                                                        <option value="152">Bután</option>
                                                        <option value="159">Cabo Verde</option>
                                                        <option value="158">Camboya</option>
                                                        <option value="31">Camerún</option>
                                                        <option value="32">Canadá</option>
                                                        <option value="130">Chad</option>
                                                        <option value="81">Chile</option>
                                                        <option value="35">China</option>
                                                        <option value="33">Chipre</option>
                                                        <option value="82">Colombia</option>
                                                        <option value="164">Comores</option>
                                                        <option value="112">Congo (Brazzaville)</option>
                                                        <option value="165">Congo (Kinshasa)</option>
                                                        <option value="166">Cook, Islas</option>
                                                        <option value="84">Corea del Norte</option>
                                                        <option value="69">Corea del Sur</option>
                                                        <option value="168">Costa de Marfil</option>
                                                        <option value="36">Costa Rica</option>
                                                        <option value="71">Croacia</option>
                                                        <option value="113">Cuba</option>
                                                        <option value="22">Dinamarca</option>
                                                        <option value="169">Djibouti, Yibuti</option>
                                                        <option value="103">Ecuador</option>
                                                        <option value="23">Egipto</option>
                                                        <option value="51">El Salvador</option>
                                                        <option value="93">Emiratos árabes Unidos</option>
                                                        <option value="173">Eritrea</option>
                                                        <option value="52">Eslovaquia</option>
                                                        <option value="53">Eslovenia</option>
                                                        <option value="28">España</option>
                                                        <option value="55">Estados Unidos</option>
                                                        <option value="68">Estonia</option>
                                                        <option value="121">Etiopía</option>
                                                        <option value="175">Feroe, Islas</option>
                                                        <option value="90">Filipinas</option>
                                                        <option value="63">Finlandia</option>
                                                        <option value="176">Fiyi</option>
                                                        <option value="64">Francia</option>
                                                        <option value="180">Gabón</option>
                                                        <option value="181">Gambia</option>
                                                        <option value="21">Georgia</option>
                                                        <option value="105">Ghana</option>
                                                        <option value="143">Gibraltar</option>
                                                        <option value="184">Granada</option>
                                                        <option value="20">Grecia</option>
                                                        <option value="94">Groenlandia</option>
                                                        <option value="17">Guadalupe</option>
                                                        <option value="185">Guatemala</option>
                                                        <option value="186">Guernsey</option>
                                                        <option value="187">Guinea</option>
                                                        <option value="172">Guinea Ecuatorial</option>
                                                        <option value="188">Guinea-Bissau</option>
                                                        <option value="189">Guyana</option>
                                                        <option value="16">Haiti</option>
                                                        <option value="137">Honduras</option>
                                                        <option value="73">Hong Kong</option>
                                                        <option value="14">Hungría</option>
                                                        <option value="25">India</option>
                                                        <option value="74">Indonesia</option>
                                                        <option value="140">Irak</option>
                                                        <option value="26">Irán</option>
                                                        <option value="27">Irlanda</option>
                                                        <option value="215">Isla Pitcairn</option>
                                                        <option value="83">Islandia</option>
                                                        <option value="228">Islas Salomón</option>
                                                        <option value="58">Islas Turcas y Caicos</option>
                                                        <option value="154">Islas Virgenes Británicas</option>
                                                        <option value="24">Israel</option>
                                                        <option value="29">Italia</option>
                                                        <option value="132">Jamaica</option>
                                                        <option value="70">Japón</option>
                                                        <option value="193">Jersey</option>
                                                        <option value="75">Jordania</option>
                                                        <option value="30">Kazajstán</option>
                                                        <option value="97">Kenia</option>
                                                        <option value="34">Kirguistán</option>
                                                        <option value="195">Kiribati</option>
                                                        <option value="37">Kuwait</option>
                                                        <option value="196">Laos</option>
                                                        <option value="197">Lesotho</option>
                                                        <option value="38">Letonia</option>
                                                        <option value="99">Líbano</option>
                                                        <option value="198">Liberia</option>
                                                        <option value="39">Libia</option>
                                                        <option value="126">Liechtenstein</option>
                                                        <option value="40">Lituania</option>
                                                        <option value="41">Luxemburgo</option>
                                                        <option value="85">Macedonia</option>
                                                        <option value="134">Madagascar</option>
                                                        <option value="76">Malasia</option>
                                                        <option value="125">Malawi</option>
                                                        <option value="200">Maldivas</option>
                                                        <option value="133">Malí</option>
                                                        <option value="86">Malta</option>
                                                        <option value="131">Man, Isla de</option>
                                                        <option value="104">Marruecos</option>
                                                        <option value="201">Martinica</option>
                                                        <option value="202">Mauricio</option>
                                                        <option value="108">Mauritania</option>
                                                        <option value="42">México</option>
                                                        <option value="43">Moldavia</option>
                                                        <option value="44">Mónaco</option>
                                                        <option value="139">Mongolia</option>
                                                        <option value="117">Mozambique</option>
                                                        <option value="205">Myanmar</option>
                                                        <option value="102">Namibia</option>
                                                        <option value="206">Nauru</option>
                                                        <option value="107">Nepal</option>
                                                        <option value="209">Nicaragua</option>
                                                        <option value="210">Níger</option>
                                                        <option value="115">Nigeria</option>
                                                        <option value="212">Norfolk Island</option>
                                                        <option value="46">Noruega</option>
                                                        <option value="208">Nueva Caledonia</option>
                                                        <option value="45">Nueva Zelanda</option>
                                                        <option value="213">Omán</option>
                                                        <option value="19">Países Bajos, Holanda</option>
                                                        <option value="87">Pakistán</option>
                                                        <option value="124">Panamá</option>
                                                        <option value="88">Papúa-Nueva Guinea</option>
                                                        <option value="110">Paraguay</option>
                                                        <option value="89">Perú</option>
                                                        <option value="178">Polinesia Francesa</option>
                                                        <option value="47">Polonia</option>
                                                        <option value="48">Portugal</option>
                                                        <option value="246">Puerto Rico</option>
                                                        <option value="216">Qatar</option>
                                                        <option value="13">Reino Unido</option>
                                                        <option value="65">República Checa</option>
                                                        <option value="138">República Dominicana</option>
                                                        <option value="49">Reunión</option>
                                                        <option value="217">Ruanda</option>
                                                        <option value="72">Rumanía</option>
                                                        <option value="50">Rusia</option>
                                                        <option value="242">Sáhara Occidental</option>
                                                        <option value="223">Samoa</option>
                                                        <option value="219">San Cristobal y Nevis</option>
                                                        <option value="224">San Marino</option>
                                                        <option value="221">San Pedro y Miquelón</option>
                                                        <option value="225">San Tomé y Príncipe</option>
                                                        <option value="222">San Vincente y Granadinas</option>
                                                        <option value="218">Santa Elena</option>
                                                        <option value="220">Santa Lucía</option>
                                                        <option value="135">Senegal</option>
                                                        <option value="226">Serbia y Montenegro</option>
                                                        <option value="109">Seychelles</option>
                                                        <option value="227">Sierra Leona</option>
                                                        <option value="77">Singapur</option>
                                                        <option value="106">Siria</option>
                                                        <option value="229">Somalia</option>
                                                        <option value="120">Sri Lanka</option>
                                                        <option value="141">Sudáfrica</option>
                                                        <option value="232">Sudán</option>
                                                        <option value="67">Suecia</option>
                                                        <option value="66">Suiza</option>
                                                        <option value="54">Surinam</option>
                                                        <option value="234">Swazilandia</option>
                                                        <option value="56">Tadjikistan</option>
                                                        <option value="92">Tailandia</option>
                                                        <option value="78">Taiwan</option>
                                                        <option value="101">Tanzania</option>
                                                        <option value="171">Timor Oriental</option>
                                                        <option value="136">Togo</option>
                                                        <option value="235">Tokelau</option>
                                                        <option value="236">Tonga</option>
                                                        <option value="237">Trinidad y Tobago</option>
                                                        <option value="122">Túnez</option>
                                                        <option value="57">Turkmenistan</option>
                                                        <option value="59">Turquía</option>
                                                        <option value="239">Tuvalu</option>
                                                        <option value="62">Ucrania</option>
                                                        <option value="60">Uganda</option>
                                                        <option value="111">Uruguay</option>
                                                        <option value="61">Uzbekistán</option>
                                                        <option value="240">Vanuatu</option>
                                                        <option value="95">Venezuela</option>
                                                        <option value="15">Vietnam</option>
                                                        <option value="241">Wallis y Futuna</option>
                                                        <option value="243">Yemen</option>
                                                        <option value="116">Zambia</option>
                                                        <option value="96">Zimbabwe</option>
                   </select> 
                                      </Col>
                                
                                 </Row> 

                                 {/* /////////////////// termina la linea */}
                                 <Row> 
                                    
                                    
   
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Provincia <span className="text-danger mt-3">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="Nombre de la ciudad"
                                          id="provincia"
                                          name="provincia"
                                          onChange={onChangeRem}
                                          value={addben.provincia}
                                          
                                          
                                        
                                        className=" form-control" required/>

                                    </Col> 

                                    <hr className="my-1 border-info"/> 

                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Ciudad <span className="text-danger mt-3">*</span> </label>
                                          <input 
                                            type="text"
                                            placeholder="Nombre de la ciudad"
                                            id="ciudad"
                                            name="ciudad"
                                            onChange={onChangeRem}
                                            value={addben.ciudad}
                                            
                                            
                                          
                                          className=" form-control" required/>
                                      </Col>
                                
                                 </Row> 
                                 <Row>
                                    
                                    
   
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Direccion <span className="text-danger mt-3">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="Direccion"
                                          id="direccion_cliente"
                                          name="direccion_cliente"
                                          onChange={onChangeRem}
                                          value={addben.direccion_cliente}
                                          
                                          
                                        
                                        className=" form-control" required/>

                                    </Col> 
                                    <hr className="my-1 border-info"/> 
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Telefono <span className="text-danger mt-3">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="Numero de telefono"
                                          id="telefono"
                                          name="telefono"
                                          onChange={onChangeRem}
                                          value={addben.telefono}
                                          
                                          
                                        
                                        className=" form-control" required/>

                                    </Col> 
                                
                                 </Row> 
                                            
                                     
                           
                          


                             

                                
                              <FormControlLabel
                                                control={<Checkbox className="checkbox-fix" checked={check} onChange={handleChangeCheck} name="check" required 
                                                
                                                color="primary"
                                                 
                                                 />}
                                                label="Confirmo los detalles de la cuenta bancaria suministrados."
                                                
                                
                                            />


                                {/* TERMINA otro valor , telefono destinatario */} 


   
                              </div> 



                                 
                                   </FormGroup>

                                  
                                                        
                                                    </Col>       
                                            </Row>
                                        </CardBody> 
                                        <CardFooter>
                            <div className="row justify-content-center">
                                
                          
                                <div  className="col-sm-12 col-md-6 col-12"> 
                                
                                

                                
                                
                                

                                <input type="submit" className=" btn mt-0 btn-block btn-info btn-panther-power" value="Añadir"/>
                                
                                </div>
                                    
                            
                            </div>
                        </CardFooter> 
                        

                             
                                    
                        
                        </form>
                    </div>

                        </div>

                    </div>
                        </div>
                    </Fade>
                    
                    </ModalRemesadora> 

                    <ModalNacional
                              
                              className={classes.modal}
                              open={modalnacional}
                              
                              disableScrollLock={true}
                              
                              onClose={cerrarNacional}
                              closeAfterTransition
                              BackdropComponent={Backdrop}
                              BackdropProps={{
                                  timeout: 900,
                              }}
                              >
                              <Fade in={modalnacional}
                              timeout={154}
                              onClose={cerrarNacional}
                              
                              >  


                <div className="container-grid">
                              <div  className={`modal-kyc-welcome padding-modal`}> 
                              <form onSubmit={onSubmitNacional} > 
                                      <div className="bg-modal-header">   
                                          <div  className=" fix-cuentaspropias-title ">
                                            <h5 className="title display-4 mb-0">Agregar beneficiario nacional</h5>
                                          </div>

                                          <div >
                                            <div className="cancel-box-btn">
                                                <div className="cancel-btn-panther" onClick={ () => (
                                                  cerrarNacional()
                                                )}> 
                                                    <i className="tim-icons icon-simple-remove"/>
                                                </div>
                                            </div>
                                          </div>    
                                      </div>

                                      <div className="form-group form-control-deposito-container">
                                          <div className="modal-grid-row"> 

                                                    <div> 
                                                        <label className="panther-cuenta-bancaria">Tipo de cuenta <span className="text-danger mt-5">*</span> </label>
                                                          <select className="form-control  pointer-cursor" id="bank_type" name="bank_type" value={addben.bank_type} onChange={onChangeRem} required>
                                                          <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                                                                                  <option value="AHORRO">Ahorro</option>
                                                                                  <option value="CORRIENTE">Corriente</option>
                                                                              
                                                        </select> 
                                                    </div>

                                                    <div>
                                                        <label className="panther-cuenta-bancaria">Banco <span className="text-danger mt-3">*</span> </label>
                                                        <input 
                                                          type="text"
                                                          placeholder="Nombre del banco"
                                                          id="bank_name"
                                                          name="bank_name"
                                                          onChange={onChangeRem}
                                                          value={addben.bank_name}
                                                          
                                                          
                                                          
                                                        
                                                        className=" form-control" required/>
                                                    </div>


                                                    <div>
                                                            <label className="panther-cuenta-bancaria">Titular de la cuenta<span className="text-danger mt-3">*</span> </label>
                                                            <input 
                                                              type="text"
                                                              placeholder="e.g. Smith Rhodes"
                                                              id="bank_client"
                                                              name="bank_client"
                                                              onChange={onChangeRem}
                                                              value={addben.bank_client}
                                                              disabled
                                                              
                                                              
                                                            
                                                            className=" form-control" required />
                                                    </div>


                                                    <div>
                                                          <label className="panther-cuenta-bancaria">Número de la cuenta<span className="text-danger mt-5">*</span> </label>
                                                          <input 
                                                            type="text"
                                                            placeholder="e.g. ABCDE12345"
                                                            id="bank_account"
                                                            name="bank_account"
                                                            value={addben.bank_account}
                                                            onChange={onChangeRem}
                                                          className=" form-control" required/>
                                                    </div>

                                                    <div> 
                                                    <FormControlLabel
                                                          control={<Checkbox className="checkbox-fix" checked={check} onChange={handleChangeCheck} name="check" required 
                                                          
                                                          color="primary"
                                                          
                                                          />}
                                                          label="Confirmo los detalles de la cuenta bancaria suministrados son correctos."
                                                          
                                          
                                                      />
                                                    </div>

                                                    <div> 
                                                                  <div >
                                                                        <div> 
                                                                            <input type="submit" className=" btn mt-0 btn-block btn-info btn-panther-power" value="Añadir"/>
                                                                        
                                                                        </div>
                                                                  </div>
                                                    </div>


                                          </div>
                                      </div>
                              </form>
                              </div>
                              </div>

                              </Fade>
                              
                              </ModalNacional> 

                              <ModalInternacional
                                    className={classes.modal}
                                    open={modalinternacional}
                                    onClose={cerrarInternacional}
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 900,
                                    }}
                                    >
                                    <Fade in={modalinternacional}
                                    timeout={154}
                                    onClose={cerrarInternacional}    
                              > 


                                  <div className="container-grid">
                                                          <div  className={`modal-kyc-welcome padding-modal`}> 
                                                          <form onSubmit={onSubmitInternacional} >
                                                              <div className="bg-modal-header">   
                                                                    <div  className=" fix-cuentaspropias-title ">
                                                                      <h5 className="title display-4 mb-0">Agregar beneficiario internacional</h5>
                                                                    </div>

                                                                    <div >
                                                                      <div className="cancel-box-btn">
                                                                          <div className="cancel-btn-panther" onClick={ () => (
                                                                            cerrarInternacional()
                                                                          )}> 
                                                                              <i className="tim-icons icon-simple-remove"/>
                                                                          </div>
                                                                      </div>
                                                                    </div>    
                                                            </div>
                                                          
                                                          <div >
                                                                              
                                                            < div className="form-group form-control-deposito-container">
                                                                  <div className="modal-grid-row-2">
                                                                      <div >
                                                                        <label className="panther-cuenta-bancaria">Tipo de cuenta <span className="text-danger mt-5">*</span> </label>
                                                                            <select className="form-control  pointer-cursor" id="bank_type" name="bank_type" value={addben.bank_type} onChange={onChangeRem} required>
                                                                            <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                                                                                                    <option value="AHORRO">Ahorro</option>
                                                                                                    <option value="CORRIENTE">Corriente</option>
                                                                                                
                                                                          </select> 
                                                                        </div>
                                                                      
                                    
                                                                      <div>
                                                                      <label className="panther-cuenta-bancaria">País del banco <span className="text-danger mt-5">*</span> </label>
                                                                        <select className="form-control  pointer-cursor" id="country_id" name="country_id" value={addben.country_id} onChange={onChangeRem} required>
                                                                      <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                                                                                          <option value="144">Afganistán</option>
                                                                                          <option value="114">Albania</option>
                                                                                          <option value="18">Alemania</option>
                                                                                          <option value="98">Algeria</option>
                                                                                          <option value="145">Andorra</option>
                                                                                          <option value="119">Angola</option>
                                                                                          <option value="4">Anguilla</option>
                                                                                          <option value="147">Antigua y Barbuda</option>
                                                                                          <option value="207">Antillas Holandesas</option>
                                                                                          <option value="91">Arabia Saudita</option>
                                                                                          <option value="5">Argentina</option>
                                                                                          <option value="6">Armenia</option>
                                                                                          <option value="142">Aruba</option>
                                                                                          <option value="1">Australia</option>
                                                                                          <option value="2">Austria</option>
                                                                                          <option value="3">Azerbaiyán</option>
                                                                                          <option value="80">Bahamas</option>
                                                                                          <option value="127">Bahrein</option>
                                                                                          <option value="149">Bangladesh</option>
                                                                                          <option value="128">Barbados</option>
                                                                                          <option value="9">Bélgica</option>
                                                                                          <option value="8">Belice</option>
                                                                                          <option value="151">Benín</option>
                                                                                          <option value="10">Bermudas</option>
                                                                                          <option value="7">Bielorrusia</option>
                                                                                          <option value="123">Bolivia</option>
                                                                                          <option value="79">Bosnia y Herzegovina</option>
                                                                                          <option value="100">Botsuana</option>
                                                                                          <option value="12">Brasil</option>
                                                                                          <option value="155">Brunéi</option>
                                                                                          <option value="11">Bulgaria</option>
                                                                                          <option value="156">Burkina Faso</option>
                                                                                          <option value="157">Burundi</option>
                                                                                          <option value="152">Bután</option>
                                                                                          <option value="159">Cabo Verde</option>
                                                                                          <option value="158">Camboya</option>
                                                                                          <option value="31">Camerún</option>
                                                                                          <option value="32">Canadá</option>
                                                                                          <option value="130">Chad</option>
                                                                                          <option value="81">Chile</option>
                                                                                          <option value="35">China</option>
                                                                                          <option value="33">Chipre</option>
                                                                                          <option value="82">Colombia</option>
                                                                                          <option value="164">Comores</option>
                                                                                          <option value="112">Congo (Brazzaville)</option>
                                                                                          <option value="165">Congo (Kinshasa)</option>
                                                                                          <option value="166">Cook, Islas</option>
                                                                                          <option value="84">Corea del Norte</option>
                                                                                          <option value="69">Corea del Sur</option>
                                                                                          <option value="168">Costa de Marfil</option>
                                                                                          <option value="36">Costa Rica</option>
                                                                                          <option value="71">Croacia</option>
                                                                                          <option value="113">Cuba</option>
                                                                                          <option value="22">Dinamarca</option>
                                                                                          <option value="169">Djibouti, Yibuti</option>
                                                                                          <option value="103">Ecuador</option>
                                                                                          <option value="23">Egipto</option>
                                                                                          <option value="51">El Salvador</option>
                                                                                          <option value="93">Emiratos árabes Unidos</option>
                                                                                          <option value="173">Eritrea</option>
                                                                                          <option value="52">Eslovaquia</option>
                                                                                          <option value="53">Eslovenia</option>
                                                                                          <option value="28">España</option>
                                                                                          <option value="55">Estados Unidos</option>
                                                                                          <option value="68">Estonia</option>
                                                                                          <option value="121">Etiopía</option>
                                                                                          <option value="175">Feroe, Islas</option>
                                                                                          <option value="90">Filipinas</option>
                                                                                          <option value="63">Finlandia</option>
                                                                                          <option value="176">Fiyi</option>
                                                                                          <option value="64">Francia</option>
                                                                                          <option value="180">Gabón</option>
                                                                                          <option value="181">Gambia</option>
                                                                                          <option value="21">Georgia</option>
                                                                                          <option value="105">Ghana</option>
                                                                                          <option value="143">Gibraltar</option>
                                                                                          <option value="184">Granada</option>
                                                                                          <option value="20">Grecia</option>
                                                                                          <option value="94">Groenlandia</option>
                                                                                          <option value="17">Guadalupe</option>
                                                                                          <option value="185">Guatemala</option>
                                                                                          <option value="186">Guernsey</option>
                                                                                          <option value="187">Guinea</option>
                                                                                          <option value="172">Guinea Ecuatorial</option>
                                                                                          <option value="188">Guinea-Bissau</option>
                                                                                          <option value="189">Guyana</option>
                                                                                          <option value="16">Haiti</option>
                                                                                          <option value="137">Honduras</option>
                                                                                          <option value="73">Hong Kong</option>
                                                                                          <option value="14">Hungría</option>
                                                                                          <option value="25">India</option>
                                                                                          <option value="74">Indonesia</option>
                                                                                          <option value="140">Irak</option>
                                                                                          <option value="26">Irán</option>
                                                                                          <option value="27">Irlanda</option>
                                                                                          <option value="215">Isla Pitcairn</option>
                                                                                          <option value="83">Islandia</option>
                                                                                          <option value="228">Islas Salomón</option>
                                                                                          <option value="58">Islas Turcas y Caicos</option>
                                                                                          <option value="154">Islas Virgenes Británicas</option>
                                                                                          <option value="24">Israel</option>
                                                                                          <option value="29">Italia</option>
                                                                                          <option value="132">Jamaica</option>
                                                                                          <option value="70">Japón</option>
                                                                                          <option value="193">Jersey</option>
                                                                                          <option value="75">Jordania</option>
                                                                                          <option value="30">Kazajstán</option>
                                                                                          <option value="97">Kenia</option>
                                                                                          <option value="34">Kirguistán</option>
                                                                                          <option value="195">Kiribati</option>
                                                                                          <option value="37">Kuwait</option>
                                                                                          <option value="196">Laos</option>
                                                                                          <option value="197">Lesotho</option>
                                                                                          <option value="38">Letonia</option>
                                                                                          <option value="99">Líbano</option>
                                                                                          <option value="198">Liberia</option>
                                                                                          <option value="39">Libia</option>
                                                                                          <option value="126">Liechtenstein</option>
                                                                                          <option value="40">Lituania</option>
                                                                                          <option value="41">Luxemburgo</option>
                                                                                          <option value="85">Macedonia</option>
                                                                                          <option value="134">Madagascar</option>
                                                                                          <option value="76">Malasia</option>
                                                                                          <option value="125">Malawi</option>
                                                                                          <option value="200">Maldivas</option>
                                                                                          <option value="133">Malí</option>
                                                                                          <option value="86">Malta</option>
                                                                                          <option value="131">Man, Isla de</option>
                                                                                          <option value="104">Marruecos</option>
                                                                                          <option value="201">Martinica</option>
                                                                                          <option value="202">Mauricio</option>
                                                                                          <option value="108">Mauritania</option>
                                                                                          <option value="42">México</option>
                                                                                          <option value="43">Moldavia</option>
                                                                                          <option value="44">Mónaco</option>
                                                                                          <option value="139">Mongolia</option>
                                                                                          <option value="117">Mozambique</option>
                                                                                          <option value="205">Myanmar</option>
                                                                                          <option value="102">Namibia</option>
                                                                                          <option value="206">Nauru</option>
                                                                                          <option value="107">Nepal</option>
                                                                                          <option value="209">Nicaragua</option>
                                                                                          <option value="210">Níger</option>
                                                                                          <option value="115">Nigeria</option>
                                                                                          <option value="212">Norfolk Island</option>
                                                                                          <option value="46">Noruega</option>
                                                                                          <option value="208">Nueva Caledonia</option>
                                                                                          <option value="45">Nueva Zelanda</option>
                                                                                          <option value="213">Omán</option>
                                                                                          <option value="19">Países Bajos, Holanda</option>
                                                                                          <option value="87">Pakistán</option>
                                                                                          <option value="124">Panamá</option>
                                                                                          <option value="88">Papúa-Nueva Guinea</option>
                                                                                          <option value="110">Paraguay</option>
                                                                                          <option value="89">Perú</option>
                                                                                          <option value="178">Polinesia Francesa</option>
                                                                                          <option value="47">Polonia</option>
                                                                                          <option value="48">Portugal</option>
                                                                                          <option value="246">Puerto Rico</option>
                                                                                          <option value="216">Qatar</option>
                                                                                          <option value="13">Reino Unido</option>
                                                                                          <option value="65">República Checa</option>
                                                                                          <option value="138">República Dominicana</option>
                                                                                          <option value="49">Reunión</option>
                                                                                          <option value="217">Ruanda</option>
                                                                                          <option value="72">Rumanía</option>
                                                                                          <option value="50">Rusia</option>
                                                                                          <option value="242">Sáhara Occidental</option>
                                                                                          <option value="223">Samoa</option>
                                                                                          <option value="219">San Cristobal y Nevis</option>
                                                                                          <option value="224">San Marino</option>
                                                                                          <option value="221">San Pedro y Miquelón</option>
                                                                                          <option value="225">San Tomé y Príncipe</option>
                                                                                          <option value="222">San Vincente y Granadinas</option>
                                                                                          <option value="218">Santa Elena</option>
                                                                                          <option value="220">Santa Lucía</option>
                                                                                          <option value="135">Senegal</option>
                                                                                          <option value="226">Serbia y Montenegro</option>
                                                                                          <option value="109">Seychelles</option>
                                                                                          <option value="227">Sierra Leona</option>
                                                                                          <option value="77">Singapur</option>
                                                                                          <option value="106">Siria</option>
                                                                                          <option value="229">Somalia</option>
                                                                                          <option value="120">Sri Lanka</option>
                                                                                          <option value="141">Sudáfrica</option>
                                                                                          <option value="232">Sudán</option>
                                                                                          <option value="67">Suecia</option>
                                                                                          <option value="66">Suiza</option>
                                                                                          <option value="54">Surinam</option>
                                                                                          <option value="234">Swazilandia</option>
                                                                                          <option value="56">Tadjikistan</option>
                                                                                          <option value="92">Tailandia</option>
                                                                                          <option value="78">Taiwan</option>
                                                                                          <option value="101">Tanzania</option>
                                                                                          <option value="171">Timor Oriental</option>
                                                                                          <option value="136">Togo</option>
                                                                                          <option value="235">Tokelau</option>
                                                                                          <option value="236">Tonga</option>
                                                                                          <option value="237">Trinidad y Tobago</option>
                                                                                          <option value="122">Túnez</option>
                                                                                          <option value="57">Turkmenistan</option>
                                                                                          <option value="59">Turquía</option>
                                                                                          <option value="239">Tuvalu</option>
                                                                                          <option value="62">Ucrania</option>
                                                                                          <option value="60">Uganda</option>
                                                                                          <option value="111">Uruguay</option>
                                                                                          <option value="61">Uzbekistán</option>
                                                                                          <option value="240">Vanuatu</option>
                                                                                          <option value="95">Venezuela</option>
                                                                                          <option value="15">Vietnam</option>
                                                                                          <option value="241">Wallis y Futuna</option>
                                                                                          <option value="243">Yemen</option>
                                                                                          <option value="116">Zambia</option>
                                                                                          <option value="96">Zimbabwe</option>
                                                    </select> 

                                                                      </div>
                                                                  
                                                                  </div>

                                                                  <div className="modal-grid-row">

                                                                            <div> 
                                                                                  <label className="panther-cuenta-bancaria">Banco <span className="text-danger mt-3">*</span> </label>
                                                                                  <input 
                                                                                    type="text"
                                                                                    placeholder="Nombre del banco"
                                                                                    id="bank_name"
                                                                                    name="bank_name"
                                                                                    onChange={onChangeRem}
                                                                                    value={addben.bank_name}
                                                                                  className=" form-control" required/>
                                                                              </div>


                                                                              <div> 

                                                                                                  
                                                                        {/* TERMINA otro valor , otro campo */} 
                                                                                <label className="panther-cuenta-bancaria">Titular de la cuenta<span className="text-danger mt-3">*</span> </label>
                                                                                  <input 
                                                                                    type="text"
                                                                                    placeholder="e.g. Smith Rhodes"
                                                                                    id="bank_client"
                                                                                    name="bank_client"
                                                                                    onChange={onChangeRem}
                                                                                    value={addben.bank_client}
                                                                                    disabled
                                                                                  className=" form-control" required />
                                                                                              
                                                                                {/* TERMINA otro valor , otro campo */} 

                                                                              </div>


                                                                              <div>

                                                                                  <label className="panther-cuenta-bancaria">Número de la cuenta<span className="text-danger mt-5">*</span> </label>
                                                                                  <input 
                                                                                    type="text"
                                                                                    placeholder="e.g. ABCDE12345"
                                                                                    id="bank_account"
                                                                                    name="bank_account"
                                                                                    value={addben.bank_account}
                                                                                    onChange={onChangeRem}
                                                                                    
                                                                                    
                                                                                  
                                                                                  className=" form-control" required/>
                                                                              </div>


                                                                    </div>

                                                                    <div className="modal-grid-row-3">
                                                                        

                                                                        <div> 

                                                                              <label className="panther-cuenta-bancaria">Swift <span className="text-danger mt-5">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                placeholder="e.g. ABCDE12345"
                                                                                id="bank_swift"
                                                                                name="bank_swift"
                                                                                onChange={onChangeRem}
                                                                                value={addben.bank_swift}
                                                                                
                                                                                
                                                                              
                                                                              className=" form-control" required/>

                                                                        </div>

                                                                        <div>   

                                                                        <label className="panther-cuenta-bancaria">Aba <span className="text-danger mt-5">*</span> </label>
                                                                        <input 
                                                                          type="text"
                                                                          placeholder="Aba"
                                                                          id="bank_aba"
                                                                          name="bank_aba"
                                                                          onChange={onChangeRem}
                                                                          value={addben.bank_aba}
                                                                          
                                                                          
                                                                        
                                                                        className=" form-control" required/>


                                                                        </div>


                                                                        <div>

                                                                        <label className="panther-cuenta-bancaria">Iban  </label>
                                                                        <input 
                                                                          type="text"
                                                                          placeholder="Iban"
                                                                          id="iban"
                                                                          name="iban"
                                                                          onChange={onChangeRem}
                                                                          value={addben.iban}
                                                                        className=" form-control" />
                                                                          
                                                                        </div>

                                                                      </div>

                                                                              
                                                                {/* TERMINA otro valor , otro campo */} 


                                                              <div className="modal-grid-row"> 


                                                                <div> 

                                                                <FormControlLabel
                                                                                  control={<Checkbox className="checkbox-fix" checked={check} onChange={handleChangeCheck} name="check" required 
                                                                                  color="primary"
                                                                                  />}
                                                                                  label="Confirmo los detalles de la cuenta bancaria suministrados." />


                                                                </div>

                                                                <div> 

                                                                              <div >
                                                                                    <div> 
                                                                                        <input type="submit" className=" btn mt-0 btn-block btn-info btn-panther-power" value="Añadir"/>
                                                                                    
                                                                                    </div>
                                                                              </div>


                                                                </div>


                                                                </div>
                                                                </div> 
                                                            </div> 
                                                          </form>
                                                      </div>
                                                    </div>
                            </Fade>
                            </ModalInternacional> 
                    <ModalDireccionesMaster 
      opendireccion={opendireccion}
      handleCloseDireccion={handleCloseDireccion}
      handleOpenDireccion={handleOpenDireccion}

/> 


<ModalTodosRetiros
                              className={classes.modal}
                              open={openlist}
                              handleCloseList={handleCloseList}
                              modalInfo={modalInfo}
                              BackdropComponent={Backdrop}
                              BackdropProps={{
                                timeout: 900,
                              }}
                              >
                              <Fade in={openlist}
                              timeout={154}
                              handleCloseList={handleCloseList}
                              
                              
                              >  


                <div className="container-grid deposito-modal-informacion">
                              <div  className={`modal-kyc-welcome padding-modal`}> 
                              
                                      <div className="bg-modal-header">   
                                          <div  className=" fix-cuentaspropias-title ">
                                            <h5 className="title display-4 mb-0">Retiro <p> #{modalInfo.id}  </p> </h5>
                                          </div>

                                          <div >
                                            <div className="cancel-box-btn">
                                                <div className="cancel-btn-panther" onClick={ () => (
                                                  handleCloseList()
                                                )}> 
                                                    <i className="tim-icons icon-simple-remove"/>
                                                </div>
                                            </div>
                                          </div>    
                                      </div>

                                      <div className="form-group form-control-deposito-container">
                                          <div className="modal-grid-row"> 

                                                    <div> 

                                                      <p> 
                                                      Monto 
                                                  <span>{modalInfo.monto}</span>
                                                      </p>
                                                       
                                                    </div>

                                                    <div>

                                                    <p> 
                                                        
                                                    Moneda 
                                                    <span>{modalInfo.currency_tag}</span>
                                                      </p>

                                                       
                                                    </div>



                                                    <div>

                                                    <p> 
                                                    Fecha 
                                                    <span>{modalInfo.fecha}</span>
                                                        
                                                        </p>
                                                          
                                                    </div>


                                                    <div>

                                                    <p> 
                                                      Tipo de retiro 
                                                      <span>{modalInfo.tipo}</span>
                                                        
                                                        </p>

                                                          
                                                    </div>

                                                

                                                    <div> 

                                                    <p> 
                                                    Estado de retiro 
                                                    <span> {modalInfo.estado}</span>
                                                        
                                                        </p>
                                                    
                                                    </div>


                                          </div>
                                      </div>
                              
                              </div>
                              </div>

                              </Fade>
                              
                              </ModalTodosRetiros> 



                  



    </>
  );
}
 
export default Retiro;