import React, { useState, useEffect} from 'react';
import { Line, Bar,ChartjsAreaChart, Doughnut } from "react-chartjs-2"; 


import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
  } from "reactstrap";

import clienteAxios from 'config/axios'

function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


const GraficaDoughnut = (props) => { 

  
  



const [chartData, setChartData ] = useState({});
    
  const chart = (canvas) => {
    

    let userMontos = [100,500,300];
    let userFechas = ['Ciclo 1', 'Ciclo 2' , 'Ciclo 3'];

    // let montos = currencyFormat(userMontos);
    // let fechas = currencyFormat(userFechas);

    
    

    clienteAxios
      .post("/api/client/investments/grafica")
      .then(res => {
        
        

       // for (const dataObj of res.data.data) {


        //  userMontos.push(dataObj.amount);
        //   format(dataObj.amount)
          
       // }

      //  for (const dataObj of res.data.label) {
            
       //     userFechas.push(dataObj.fecha);
       //   }

        //   console.log(userFechas, userMontos);
        setChartData(
                
                    {
                        labels:userFechas,
                        
                        datasets: [

                            {
                                
                                fill: true,
                                backgroundColor: ['#20c997', '#5f63f2', '#fa8b0c'],
                                
                                borderColor: "#fa8b0c",
                                borderWidth: 0,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                pointBackgroundColor: "#1f8ef1",
                                pointBorderColor: "rgba(255,255,255,0)",
                                pointHoverBackgroundColor: "#1f8ef1",
                                pointBorderWidth: 20,
                                pointHoverRadius: 4,
                                pointHoverBorderWidth: 15,
                                pointRadius: 4,
                                width:400,
                                data: userMontos,
                                
                              }    
                              
                        ],
                        
                        
                    } 

                    
        )



      })
      .catch(err => {
        console.log(err);
      });
    
  };



    
  useEffect(() => {
    chart();
  }, []);



    



    


    return (
      
        
       
          <div className="chart-area-doung">
          <Doughnut
          data={chartData}
          options={
            
            
            {
            maintainAspectRatio: true,
            cutoutPercentage: 60,
            
            elements: {
              z: 9999,
            },
            legend: {
              display: false,
            },
            hover: {
              mode: 'index',
              intersect: false,
            },
            tooltips: {
              mode: 'label',
              intersect: false,
              backgroundColor: '#5f63f2',
              position: 'average',
              enabled: true,
              
            
            
            },
           
          }}

        
          
        />
          </div>
     



      );
}
 
export default GraficaDoughnut;