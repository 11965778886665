import React, { useContext, useState, useEffect, Fragment } from 'react';
import clienteAxios from '../../config/axios';

import CambiarPassword from './CambiarPassword'
import AuthContext from '../../context/autenticacion/authContext'



import Modal from '@material-ui/core/Modal'
import ActualizarDatosModal from '@material-ui/core/Modal'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';  
import PropTypes from 'prop-types';


/// aqui vamos a colocar el loader
import Spinner from '../spinners/Spinner'


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Container,

    
  } from "reactstrap"; 

  import styled from 'styled-components'


  /// aqui vamos a poner los estilos para el tap 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#00ff9d',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);








  //// responsive
const Div = styled.div `
padding: 0rem !important;
margin: 0.2rem 0rem 2.0rem 0rem !important;
display:flex !important;
flex-direction:row;
justify-content:center;





@media(min-width:992px) {
  display:none !important;
  justify-content:center;
}



`;

// pantalla grande
const DivModal = styled.div `

display:none !important;
flex-direction:row;





@media(min-width:992px) {
  display:block!important;
  
}



`; 



function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
})); 




/// aqui van los estilos de los tabs
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    alignItems:'center',
    marginRight: theme.spacing(0),
    borderRadius:'30px',
    minWidth:'200px',
    transition:'all 500ms ease',
    padding:'0px 5px 0px 5px',
     margin:'0px 4px 0px 4px',
     alignItems:'center',
  
    '&:focus': {
      opacity: 0.8,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#005eff',
      borderRadius:'30px'
      
      
    },
    '&:hover': {
      opacity: 0.6,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#000',
      borderRadius:'30px'
    },

  }
  
  ,
}))((props) => <Tab disableRipple {...props} />);
///// aqui terminan los estilos del tap





   


  
const ActualizarPerfilPro = ({ openpro, guardarOpenPro ,usuario ,setValue ,listDatos, guardarAppBar, guardarAppBarKyc, appbar, appbarkyc }) => { 


  
  const theme = useTheme();
  const [valuedostres, setValueDosTres] = React.useState(0);
  
  
  
    const handleChange = (event, newValue) => {
      setValueDosTres(newValue);
    };
  
    const handleChangeIndexTres = (index) => {
      setValueDosTres(index);
    }; 



 const [profilechange, guardarProfileChange] = useState(usuario); 
 
 const [profilechangeahora, guardarProfileChangeAhora] = useState({
     id: '',
     name:'',
     email:'',
     client_type:'',
     identity:'',
     birthday_date:'',
     total_rent:'',
     status:'',
     profession_id:'',
     user_id:'',
     telephone:'',
     representative_name:'',
     representative_cpf:'',
     company_activity:'',
     created_at:'',
     updated_at:'',
     address_street:'',
     address_number:'',
     address_city:'',
     address_zipcode:'',
     address_zone:'',
     address_country:'',
     is_ofertante:'',
     comercial_name:'',
     referido:'',
     cpf_no_activo:'',
     cpf:'',
     first_name:'',
     last_name:'',
     middle_name:'',

 }); 


 const [openaction, setOpenAction] =useState(false);

 

 useEffect( ()=> {
   if(usuario.paso === 0) {
    guardarOpenPro(true);

   }
  
 },[])



///// aqui vamos a definir el modal que va a cambiar la configuracion del usuario 

const abrirAction = () => {
    setOpenAction(true);
} 

const cerrarAction = () => {
    setOpenAction(false);;
}



    
  


  const [ cargando, guardarCargando] = useState(false);
  

  const guardar = ( cargando ) ? <Spinner/> :  
                                          
                                          <Fragment>
                                            Actualizar
                                            </Fragment> 





    
    const [ modalStyle ] =useState(getModalStyle);
        const [open, setOpen] =useState(false);
    
        const classes = useStyles();
    
        const handleOpen = () => {
            setOpen(true);
        } 
    
        const handleClose = () => {
            setOpen(false);;
        }
    

    


    const authContext = useContext(AuthContext);
    const { actualizarDatos, usuarioAutenticado } = authContext; 

    

    const [mensaje, guardarMensaje] = useState('')




    

    
   
      
      const consultarApiUpdate = async (profilechange) => {

        const {id,
          name,email,referrer,client_type,identity,
          birthday_date,total_rent,status,
          profession_id,user_id,telephone,
          representative_name,representative_cpf,
          company_activity,created_at,updated_at,
          address_street,address_number,address_city,
          address_zipcode,address_zone,address_country,is_ofertante,
          comercial_name,referido,cpf_no_activo,
          cpf,first_name,last_name,middle_name,direccion_cliente
          
            
            } = profilechange; 
        
    

      const respuesta = await clienteAxios.post('/api/client/update',{id,
        name,email,referrer,client_type,identity,
        birthday_date,total_rent,status,
        profession_id,user_id,telephone,
        representative_name,representative_cpf,
        company_activity,created_at,updated_at,
        address_street,address_number,address_city,
        address_zipcode,address_zone,address_country,is_ofertante,
        comercial_name,referido,cpf_no_activo,
        cpf,first_name,last_name,middle_name,direccion_cliente
        
          
          })
      
      
          
      guardarMensaje(respuesta.data.message);
      handleOpen();
      
      
      
    //   setTimeout(() => {
    //     guardarMensaje(null);
    //     guardarCargando(false);
    // },6000);
      
      
    } 

      /// aqui vamos a crear el manejador de eventos secundario

 

    

    const onChange = e => {

      guardarProfileChangeAhora({
        ...profilechangeahora,
        [e.target.name] : e.target.value

    })

        // voy a colocar guardar usuario en el state

        guardarProfileChange({
            ...profilechange,
            [e.target.name] : e.target.value

        })
    }

    

    const onSubmit = e => {
        e.preventDefault();
        guardarCargando(true);
        consultarApiUpdate(profilechange);

    } 



    ///// aqui vamos a colocar toda la logica de los eventos
    const Cerrar = () =>{
      guardarOpenPro(false)
    }

    const cerrarActionPro = () => {
      guardarOpenPro(false)
      guardarMensaje(null),
      guardarCargando(false),
      guardarProfileChangeAhora({ 
      id: '',
      name:'',
      email:'',
      c:'',
      client_type:'',
      identity:'',
      birthday_date:'',
      total_rent:'',
      status:'',
      profession_id:'',
      user_id:'',
      telephone:'',
      representative_name:'',
      representative_cpf:'',
      company_activity:'',
      created_at:'',
      updated_at:'',
      address_street:'',
      address_number:'',
      address_city:'',
      address_zipcode:'',
      address_zone:'',
      address_country:'',
      is_ofertante:'',
      comercial_name:'',
      referido:'',
      cpf_no_activo:'',
      cpf:'',
      first_name:'',
      last_name:'',
      middle_name:'',
    }),
    // guardarProfileChange(usuario),
    setValueMaster(1)
      
      
    }

    const [valuemaster, setValueMaster] = useState(1);

    const paso1 = e => {
      e.preventDefault()
      setValueMaster(1);
    } 

    const paso2 = e => {
      e.preventDefault()
      setValueMaster(2);
    } 

    const paso3 = e => {
      e.preventDefault()
      setValueMaster(3);
    }

    const paso4 = e => {
      e.preventDefault()
      setValueMaster(4);
    }

    const paso5 = e => {
      e.preventDefault()
      setValueMaster(5);
    }

    const paso6 = e => {
      e.preventDefault()
      setValueMaster(6);
    } 

    const paso7 = e => {
      e.preventDefault()
      setValueMaster(7);
    }

    const paso8 = e => {
      e.preventDefault()
      setValueMaster(8);
    
    }

    const paso9 = e => {
      e.preventDefault()
      setValueMaster(9);
    }


    const paso10 = e => {
      e.preventDefault()
      setValueMaster(10);
    } 

    const paso11 = e => {
      e.preventDefault()
      setValueMaster(11);
    }

    const paso12 = e => {
      e.preventDefault()
      setValueMaster(12);
    }

    const paso13 = e => {
      e.preventDefault()
      setValueMaster(13);
    }

    const paso14 = e => {
      e.preventDefault()
      setValueMaster(14);
    }


    


    ///


    return (  
        ///////////

        <Fragment> 
                        
        {openpro === true ? 
                    <div className="datos-grid">
                                

                    <form className="center" onSubmit={onSubmit}>
                          {  usuario.paso === 0 ? 
                          null : 
                          <>

                          
                        <div className="cancel-btn-JIMF" onClick={ ()=>{
                          if(usuario.paso !== 0){
                             cerrarActionPro()
                          }
                        }}> 
                        <div className="cancel-btn-panther text-fix-btn"> 
                                                                      <i className="tim-icons icon-simple-remove"/>
                        </div>
                          
                        </div>
                          
                          
                          </>


                            
                          }

                        <div className="elemento1">

                        <div className="header">
                          <h3 >Actualización de datos personales <strong>{valuemaster === 14 ? 'Paso Final' : `Paso ${valuemaster}`} </strong> </h3>
                              <p>Complete su información personal con datos actualizados y fidedignos.</p>

                          </div>

                        

                        </div>          










                        { valuemaster === 1 ?

                        <div className="elemento2" value={valuemaster}>

                        <div className="form-group">
                                                                      
                                                                      

                                                                      { !usuario.comercial_name ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.comercial_name ? 'd-none' : 'd-block' }`}>Aún no tiene información de la empresa </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.comercial_name ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                                      { profilechangeahora.comercial_name ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando tu información laboral </p> : null} 
                                                                      <label>¿Cual es la empresa donde usted labora?</label> 
                                                                      <input
                                                                      className="placeholder-complete form-control animate-raya-03"
                                                                        
                                                                        type="text"
                                                                        id="comercial_name"
                                                                        name="comercial_name"
                                                                        onChange={onChange}
                                                                        value={profilechange.comercial_name}
                                                                        placeholder={usuario.comercial_name}
                                                                    
                                                                    />
                        </div>

                        { profilechangeahora.comercial_name ? 
                        <button className="btn btn-primary" onClick={paso2} >Siguente pregunta </button> :
                        <button className="btn btn-primary" disabled>Siguente pregunta </button>
                        
                        } 

                        </div>          :null} 


                        { valuemaster === 2 ?

                          <div className="elemento2" value={valuemaster}>

                          <div className="form-group">
                                                                        
                                                                        

                                                                        { !usuario.referrer ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${usuario.referrer ? 'd-none' : 'd-block' }`}>Aún no tiene información de la empresa </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.comercial_name ? 'd-none' : 'd-block' }`}> Generado automaticamente </p>} 
                                                                        { usuario.referrer ? <p className="alerta-texto-profile-green">Este es tu código unico de referido APUNTALO y compartelo para recibir beneficios futuros </p> : null} 
                                                                        <label>¿Sabias que tienes un código de referido?</label> 
                                                                        <input
                                                                        className="placeholder-complete form-control animate-raya-03"
                                                                          
                                                                        type="referrer"
                                                                        id="referrer"
                                                                        name="referrer"
                                                                        onChange={onChange}
                                                                        value={usuario.referrer}
                                                                        disabled
                                                                      
                                                                      />
                          </div>

                          { usuario.referrer ? 
                          <div className="btn-pasos-box" >
                            <button className="btn btn-primary" onClick={paso1} >Regresar a pregunta 1 </button> 
                            <button className="btn btn-primary" onClick={paso3} >Siguente pregunta </button> 

                          </div>
                          
                          :
                          <button className="btn btn-primary" disabled>Siguente pregunta </button>

                          } 

                          </div>          :null}



                          { valuemaster === 3 ?

                              <div className="elemento2" value={valuemaster}>

                              <div className="form-group">
                                                                            
                                                                            

                            { profilechangeahora.name ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando tu nombre de usuario </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.name ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                              <label className={` ${profilechange.identity ? '' : ''} `}> ¿Este es su Nombre y apellido? { usuario.name ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                                            <input
                                                                            className="placeholder-complete form-control animate-raya-03"
                                                                              
                                                                            type="text"
                                                                            id="name"
                                                                            name="name"
                                                                            onChange={onChange}
                                                                            value={profilechange.name}
                                                                            placeholder={usuario.name}
                                                                            required
                                                                            disabled

                                                                            
                                                                          />
                              </div>

                              { usuario.referrer ? 
                              <div className="btn-pasos-box" >
                                <button className="btn btn-primary" onClick={paso2} >Regresar a pregunta 2 </button> 
                                <button className="btn btn-primary" onClick={paso4} >Siguente pregunta </button> 

                              </div>

                              :
                              <button className="btn btn-primary" disabled>Siguente pregunta </button>

                              } 

                              </div>   :null
                              
                              } 


                              

                                            { valuemaster === 4 ?

                                              <div className="elemento2" value={valuemaster}>

                                              <div className="form-group">
                                                                                            
                                                                                            

                                              { profilechangeahora.identity ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando la informacion de tu ID </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.identity ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                              <label className={` ${profilechangeahora.name  ? '' : ''} `}>¿Cual es su documento de identidad? { usuario.identity ? <span className="alerta-texto-profile-green">* </span> : null}</label>
                                                                                            <input
                                                                                            className="placeholder-complete form-control animate-raya-03"                                                
                                                                                            type="text"
                                                                                            id="identity"
                                                                                                name="identity"
                                                                                                onChange={onChange}
                                                                                                value={profilechange.identity}
                                                                                                placeholder={usuario.identity}
                                                                                                required

                                                                                            
                                                                                          />
                                              </div>

                                              { usuario.referrer ? 
                                              <div className="btn-pasos-box" >
                                                <button className="btn btn-primary" onClick={paso3} >Regresar a pregunta 3 </button> 
                                                <button className="btn btn-primary" onClick={paso5} >Siguente pregunta </button> 

                                              </div>

                                              :
                                              <button className="btn btn-primary" disabled>Siguente pregunta </button>

                                              } 

                                              </div>   :null

                                              } 


                                                    { valuemaster === 5 ?
                                                    <div className="elemento2" value={valuemaster}>


                                                      {usuario.birthday_date ? 
                                                                                                      
                                                                                                      <div className="form-group">
                                                                                                  
                                                                                                  
                                                                                                      { profilechangeahora.birthday_date ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando la informacion de su fecha de nacimiento </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.birthday_date ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                                                                      <label>¿Cual es su fecha de Nacimiento? { usuario.birthday_date ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                                                                                                                <input
                                                                                                                                                className="placeholder-complete form-control animate-raya-03"                                                
                                                                                                                                                type="text"
                                                                                                                                              id="birthday_date"
                                                                                                                                              name="birthday_date"
                                                                                                                                              onChange={onChange}
                                                                                                                                              value={profilechange.birthday_date}
                                                                                                                                              placeholder={usuario.birthday_date}
                                                                                                                                              required
                                              
                                                                                                                                                
                                                                                                                                              />
                                                                                                      </div>
                                                                                                        :  
                                                                                                        
                                                                                                        
                                                                                                        <div className="form-group">
                                                                                                            { !usuario.birthday_date ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.birthday_date ? 'd-none' : 'd-block' }`}> Aún no ha agregado su fecha de nacimiento </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.birthday_date ? 'd-none' : 'd-block' }`}> Agregada correctamente </p>} 
                                                                                                            { profilechangeahora.birthday_date ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando su fecha de nacimiento </p> : null} 
                                                                                                            <label>¿Cual es su fecha de Nacimiento? { usuario.birthday_date ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                                                                            <input
                                                                                                            
                                                                                                            
                                                                                                            className="placeholder-complete"
                                                                                                            type="date"
                                                                                                            id="birthday_date"
                                                                                                            name="birthday_date"
                                                                                                            onChange={onChange}
                                                                                                            value={profilechange.birthday_date}
                                                                                                            placeholder={usuario.birthday_date}
                                                                                                            required
                                                                                                            
                                                                                                            
                                                                                                            />
                                                                                                        </div>

                                                }





                                                    

                                                    { profilechangeahora.birthday_date || profilechange.birthday_date ? 
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso4} >Regresar a pregunta 4 </button> 
                                                                            <button className="btn btn-primary" onClick={paso6} >Siguente pregunta </button> 

                                                                          </div>

                                                                          :
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso4} >Regresar a pregunta 4 </button> 
                                                                            <button className="btn btn-primary" onClick={paso6}  >Siguente pregunta </button> 

                                                                          </div>

                                                                          } 

                                                  

                                                    </div>   :null

                                                    } 


                                          { valuemaster === 6 ?
                                                    <div className="elemento2" value={valuemaster}>
                                                    <div className="form-group">
                                                                                                  
                                                                                                  
                                                        { profilechangeahora.email ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando su correo electrónico </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.email ? 'd-none' : 'd-block' }`}> Agregado correctamente <strong>(No puedes editar, click al boton siguiente)</strong> </p>} 
                                                        <label>¿Cual es su Correo electrónico? { usuario.email ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                                                                  <input
                                                                                                  className="placeholder-complete form-control animate-raya-03"                                                
                                                                                                  type="email" 
                                                                                                  id="email"
                                                                                                  name="email"
                                                                                                  onChange={onChange}
                                                                                                  value={usuario.email}
                                                                                                  required
                                                                                                  disabled

                                                                                                  
                                                                                                />
                                                    </div>

                                                    { profilechangeahora.email ? 
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso5} >Regresar a pregunta 5 </button> 
                                                                            <button className="btn btn-primary" onClick={paso7} >Siguente pregunta </button> 

                                                                          </div>

                                                                          :
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso5} >Regresar a pregunta 5 </button> 
                                                                            <button className="btn btn-primary" onClick={paso7}  >Siguente pregunta </button> 

                                                                          </div>

                                                                          } 

                                                    </div>   :null

                                                    } 


                                                    
                                          { valuemaster === 7 ?
                                                    <div className="elemento2" value={valuemaster}>
                                                    <div className="form-group">
                                                                                                  
                                                                                                  
                                                        { profilechangeahora.telephone ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando su número (#) de telefono </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.telephone ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                        <label>¿Cual es su número (#) de telefono/celular? { usuario.telephone ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                                                                  <input
                                                                                                  className="placeholder-complete form-control animate-raya-03"                                                
                                                                                                  type="text" 
                                                                                                  id="telephone"
                                                                                                  name="telephone"
                                                                                                  onChange={onChange}
                                                                                                  value={profilechange.telephone}
                                                                                                  placeholder={usuario.telephone}
                                                                                                  required

                                                                                                  
                                                                                                />
                                                    </div> 


                                                    { profilechangeahora.telephone ? 
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso6} >Regresar a pregunta 6 </button> 
                                                                            <button className="btn btn-primary" onClick={paso8} >Siguente pregunta </button> 

                                                                          </div>

                                                                          :
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso6} >Regresar a pregunta 6 </button> 
                                                                            <button className="btn btn-primary" disabled  >Siguente pregunta </button> 

                                                                          </div>

                                                                          } 

                                                   

                                                    </div>   :null

                                                    } 


                                  { valuemaster === 8 ?
                                                    <div className="elemento2" value={valuemaster}>
                                                    <div className="form-group">
                                                                                                  
                                                                                                  
                                                        { profilechangeahora.address_street ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Está actualizando su dirección principal </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_street ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                        <label>¿Cual es su dirección principal ? { usuario.address_street ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                                                                  <input
                                                                                                  className="placeholder-complete form-control animate-raya-03"                                                
                                                                                                  type="text"
                                                                                                  id="address_street"
                                                                                                      name="address_street"
                                                                                                      onChange={onChange}
                                                                                                      value={profilechange.address_street}
                                                                                                      placeholder={usuario.address_street}
                                                                                                      required

                                                                                                  
                                                                                                />
                                                    </div> 

                                                    

                                                    


                                                      { profilechangeahora.address_street  ? 
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso7} >Regresar a pregunta 7 </button> 
                                                                            <button className="btn btn-primary" onClick={paso9} >Siguente pregunta </button> 

                                                                          </div>

                                                                          :
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso7} >Regresar a pregunta 7 </button> 
                                                                            <button className="btn btn-primary" disabled  >Siguente pregunta </button> 

                                                                          </div>

                                                                          } 

                                                    </div>   :null

                                                    }  




                                                            { valuemaster === 9 ?
                                                                          <div className="elemento2" value={valuemaster}>
                                                                          <div className="form-group">
                                                                                                                        
                                                                                                                        
                                                                          { !usuario.address_number ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_number ? 'd-none' : 'd-block' }`}> Aún no ha agregado su dirección complementaria </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_number ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                                          { profilechangeahora.address_number ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando dirección complementaria  </p> : null} 
                                                                              <label>¿Cual es su dirección complementaria # Calle ? { usuario.address_number ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                                                                                        <input
                                                                                                                        className="placeholder-complete form-control animate-raya-03"                                                
                                                                                                                        type="text"
                                                                                                                        id="address_number"
                                                                                                                            name="address_number"
                                                                                                                            onChange={onChange}
                                                                                                                            value={profilechange.address_number}
                                                                                                                            placeholder={usuario.address_number}
                                                                                                                            required
                                                                                                                      />
                                                                              </div>

                                                                          { profilechangeahora.address_number ? 
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso8} >Regresar a pregunta 8 </button> 
                                                                            <button className="btn btn-primary" onClick={paso10} >Siguente pregunta </button> 

                                                                          </div>

                                                                          :
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso8} >Regresar a pregunta 8 </button> 
                                                                            <button className="btn btn-primary" disabled  >Siguente pregunta </button> 

                                                                          </div>

                                                                          } 

                                                                          </div>   :null

                                                                          } 


                                                                { valuemaster === 10 ?
                                                                          <div className="elemento2" value={valuemaster}>
                                                                          <div className="form-group">
                                                                                                                        
                                                                                                                        
                                                                          { !usuario.address_zipcode ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_zipcode ? 'd-none' : 'd-block' }`}> Aún no ha agregado su código postal </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_zipcode ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                                          { profilechangeahora.address_zipcode ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando el código postal  </p> : null} 
                                                                              <label>¿Cual es el código postal en donde se encuentra ? { usuario.address_zipcode ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                                                                                        <input
                                                                                                                        className="placeholder-complete form-control animate-raya-03"                                                
                                                                                                                        type="text"
                                                                                                                        id="address_zipcode"
                                                                                                                            name="address_zipcode"
                                                                                                                            onChange={onChange}
                                                                                                                            value={profilechange.address_zipcode}
                                                                                                                            placeholder={usuario.address_zipcode}
                                                                                                                            required
                                                                                                                      />
                                                                              </div>

                                                                          { profilechangeahora.address_zipcode ? 
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso9} >Regresar a pregunta 9 </button> 
                                                                            <button className="btn btn-primary" onClick={paso11} >Siguente pregunta </button> 

                                                                          </div>

                                                                          :
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso9} >Regresar a pregunta 9 </button> 
                                                                            <button className="btn btn-primary" disabled  >Siguente pregunta </button> 

                                                                          </div>

                                                                          } 

                                                                          </div>   :null

                                                                          } 


                                                            { valuemaster === 11 ?
                                                                          <div className="elemento2" value={valuemaster}>
                                                                          <div className="form-group">
                                                                                                                        
                                                                                                                        
                                                                          { !usuario.address_country ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_country ? 'd-none' : 'd-block' }`}> Aún no ha agregado su país </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_country ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                                          { profilechangeahora.address_country ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando país  </p> : null} 
                                                                              <label>¿Cual es el país de donde usted pertenece? { usuario.address_country ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                                                                                        <input
                                                                                                                        className="placeholder-complete form-control animate-raya-03"                                                
                                                                                                                        type="text"
                                                                                                                        id="address_country"
                                                                                                                            name="address_country"
                                                                                                                            onChange={onChange}
                                                                                                                            value={profilechange.address_country}
                                                                                                                            placeholder={usuario.address_country}
                                                                                                                            required
                                                                                                                      />
                                                                              </div>

                                                                          { profilechangeahora.address_country ? 
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso10} >Regresar a pregunta 10 </button> 
                                                                            <button className="btn btn-primary" onClick={paso12} >Siguente pregunta </button> 

                                                                          </div>

                                                                          :
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso10} >Regresar a pregunta 10 </button> 
                                                                            <button className="btn btn-primary" onClick={paso12} >Siguente pregunta </button> 

                                                                          </div>

                                                                          } 

                                                                          </div>   :null

                                                                          } 


                                                            { valuemaster === 12 ?
                                                                          <div className="elemento2" value={valuemaster}>
                                                                          <div className="form-group">
                                                                                                                        
                                                                                                                        
                                                                          { !usuario.address_zone ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_zone ? 'd-none' : 'd-block' }`}> Aún no ha agregado su provincia </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_zone ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                                         { profilechangeahora.address_zone ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando provincia  </p> : null} 
                                                                              <label>¿Cual es la provincia de donde usted pertenece? { usuario.address_zone ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                                                                                        <input
                                                                                                                        className="placeholder-complete form-control animate-raya-03"                                                
                                                                                                                        type="text"
                                                                                                                        id="address_zone"
                                                                                                                            name="address_zone"
                                                                                                                            onChange={onChange}
                                                                                                                            value={profilechange.address_zone}
                                                                                                                            placeholder={usuario.address_zone}
                                                                                                                            required
                                                                                                                      />
                                                                              </div>

                                                                          { profilechangeahora.address_zone ? 
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso11} >Regresar a pregunta 11 </button> 
                                                                            <button className="btn btn-primary" onClick={paso13} >Siguente pregunta </button> 

                                                                          </div>

                                                                          :
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso11} >Regresar a pregunta 11 </button> 
                                                                            <button className="btn btn-primary" disabled  >Siguente pregunta </button> 

                                                                          </div>

                                                                          } 

                                                                          </div>   :null

                                                                          } 


                                                                      { valuemaster === 13 ?
                                                                          <div className="elemento2" value={valuemaster}>
                                                                          <div className="form-group">
                                                                                                                        
                                                                                                                        
                                                                          { !usuario.address_city ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_city ? 'd-none' : 'd-block' }`}> Aún no ha agregado su ciudad </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_city ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                                          { profilechangeahora.address_city ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando ciudad  </p> : null} 
                                                                              <label>¿Cual es la ciudad de donde usted pertenece? { usuario.address_city ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                                                                                        <input
                                                                                                                        className="placeholder-complete form-control animate-raya-03"                                                
                                                                                                                        type="text"
                                                                                                                        id="address_city"
                                                                                                                            name="address_city"
                                                                                                                            onChange={onChange}
                                                                                                                            value={profilechange.address_city}
                                                                                                                            placeholder={usuario.address_city}
                                                                                                                            required
                                                                                                                      />
                                                                              </div>

                                                                          { profilechangeahora.address_city ? 
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso12} >Regresar a pregunta 12 </button> 
                                                                            <button className="btn btn-primary" onClick={paso14} >Finalizar </button> 

                                                                          </div>

                                                                          :
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso12} >Regresar a pregunta 12 </button> 
                                                                            <button className="btn btn-primary" disabled  >Siguente pregunta </button> 

                                                                          </div>

                                                                          } 

                                                                          </div>   :null }  


                                                                          { valuemaster === 14 ?
                                                                          <div className="elemento2" value={valuemaster}>
                                                                          <div className="form-group">
                                                                                                                        
                                                                                                                        
                                                                          
                                                                              <label>¡Cuestionario Finalizado! ¡Muchisímas Gracias!  </label>
                                                                                                                       <p>Recuerde que todos los datos proporcionado por usted, son extremadamente confidenciales
                                                                                                                         StrPrisma, no recopila datos y los hace publicos, <br/> los datos aquí ingresados deben ser reales 
                                                                                                                         para la efectiva activacion de su cuenta! ¡Muchisímas Gracias! StrPrisma Team & Robot
                                                                                                                       </p>
                                                                              </div>

                                                                          
                                                                          <div className="btn-pasos-box" >
                                                                            <button className="btn btn-primary" onClick={paso13} >Regresar a la ultima pregunta </button> 
                                                                            <button className="btn btn-primary" type="submit" >Enviar </button> 

                                                                          </div>

                                                                          

                                                                          

                                                                          </div>   :null } 







                                      

















                                                                                { valuemaster === 14 ?
                                                                              <div className="elemento3 elemento14"> 
                                                                                <span className={` ${ valuemaster === 14 ? 'active-bar-elemento3':''}`} />
                                                                                </div>



                                                                            :

                        <div className="elemento3">

                          <span className={` ${valuemaster >= 1 ? 'active-bar-elemento3': ''}   ${valuemaster === 1 ? 'select-bar-elemento3': ''} `} />

                          <span className={` ${valuemaster >= 2 ? 'active-bar-elemento3': ''}   ${valuemaster === 2 ? 'select-bar-elemento3': ''} `} />

                          <span className={` ${valuemaster >= 3 ? 'active-bar-elemento3': ''}   ${valuemaster === 3 ? 'select-bar-elemento3': ''} `} />
                          
                          <span className={` ${valuemaster >= 4 ? 'active-bar-elemento3': ''}   ${valuemaster === 4 ? 'select-bar-elemento3': ''} `} />

                          <span className={` ${valuemaster >= 5 ? 'active-bar-elemento3': ''}   ${valuemaster === 5 ? 'select-bar-elemento3': ''} `} />

                          <span className={` ${valuemaster >= 6 ? 'active-bar-elemento3': ''}   ${valuemaster === 6 ? 'select-bar-elemento3': ''} `} />

                          <span className={` ${valuemaster >= 7 ? 'active-bar-elemento3': ''}   ${valuemaster === 7 ? 'select-bar-elemento3': ''} `} />
                          
                          <span className={` ${valuemaster >= 8 ? 'active-bar-elemento3': ''}   ${valuemaster === 8 ? 'select-bar-elemento3': ''} `} />

                          <span className={` ${valuemaster >= 9 ? 'active-bar-elemento3': ''}   ${valuemaster === 9 ? 'select-bar-elemento3': ''} `} />

                          <span className={` ${valuemaster >= 10 ? 'active-bar-elemento3': ''}   ${valuemaster === 10 ? 'select-bar-elemento3': ''} `} />

                          <span className={` ${valuemaster >= 11 ? 'active-bar-elemento3': ''}   ${valuemaster === 11 ? 'select-bar-elemento3': ''} `} />

                          <span className={` ${valuemaster >= 12 ? 'active-bar-elemento3': ''}   ${valuemaster === 12 ? 'select-bar-elemento3': ''} `} />

                          <span className={` ${valuemaster >= 13 ? 'active-bar-elemento3': ''}   ${valuemaster === 13 ? 'select-bar-elemento3': ''} `} />


                        </div>
            }


                      

                    </form>          

                    </div>          
                    :null}





    { mensaje ? <Modal
                                
                                className={classes.modal}
                                open={open} 
                                mensaje={mensaje}
                                cerrarActionPro={cerrarActionPro}
                                usuarioAutenticado={usuarioAutenticado}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 900,
                                }}
                              >
                                <Fade in={mensaje}
                                timeout={154}
                                >     
                      <div className="container-grid">
                              <div  className={`modal-kyc-welcome padding-modal`}> 
                                  <div className="form-group form-control-deposito-container">
                                      <div className="modal-grid-row"> 

                                      <div> 
                                            <p className="text-center display-4 title my-3 font-light-weight-modal"> {mensaje}</p>

                                      </div>

                                      <div>
                                         <button className="  btn mt-0 btn-block btn-info my-2 btn-panther-power" onClick={ e => (
                                                                e.preventDefault(),
                                                                usuarioAutenticado(),
                                                                cerrarActionPro()
                                                                )}>
                                                                    Aceptar
                                          </button>
                                      </div>
                                      </div>    
                                      </div>
                                      </div>        
                      </div>
                                                        </Fade>
                                                      </Modal> : null}



      {/* { mensaje ? <Modal
   
   className={`mov ${classes.modal}`}
   open={open} 
         
   mensaje={mensaje}
   listDatos={listDatos}            
   
   cerrarActionPro={cerrarActionPro}


   guardarAppBar={guardarAppBar} 
   guardarAppBarKyc={guardarAppBarKyc} 
   appbar={appbar} 
   appbarkyc={appbarkyc}
   
   closeAfterTransition
   BackdropComponent={Backdrop}
   BackdropProps={{
     timeout: 900,
   }}
 >
   <Fade in={open}
   timeout={154}
   onClose={handleClose}>
    <div className="container">
   <div className="row">
     <div className="col-12 d-flex justify-content-center">
     <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>
     
     <CardBody >
                         <Row className="d-flex justify-content-center">
                                 <Col sm="12" md="6"> 
                                     <p className="text-center display-4 title font-light-weight-modal"> {mensaje}</p>
                                 </Col>       
                         </Row>
                       </CardBody> 
                       <CardFooter>
        <div className="row">
             
        <div  className="col-4">
             </div>
             <div  className="col-4">
             <button className=" btn mt-0 btn-block btn-info"  onClick={() => {
                                                                               
                                                                                 guardarAppBar({
                                                                                   ...appbar,
                                                                                   primero:0,
                                                                                   segundo:1
                                                                                 }),
                                                                               guardarAppBarKyc({
                                                                                 ...appbarkyc,
                                                                                 primero:0,
                                                                                 segundo:1
                                                                               }),
                                                                               guardarMensaje(null),
                                                                               guardarCargando(false),
                                                                               handleClose(),
                                                                               
                                                                               cerrarAction(),
                                                                               cerrarActionPro(),
                                                                               
                                                                               setValueMaster(1),
                                                                               guardarProfileChangeAhora({ 
                                                                                id: '',
                                                                                name:'',
                                                                                email:'',
                                                                                c:'',
                                                                                client_type:'',
                                                                                identity:'',
                                                                                birthday_date:'',
                                                                                total_rent:'',
                                                                                status:'',
                                                                                profession_id:'',
                                                                                user_id:'',
                                                                                telephone:'',
                                                                                representative_name:'',
                                                                                representative_cpf:'',
                                                                                company_activity:'',
                                                                                created_at:'',
                                                                                updated_at:'',
                                                                                address_street:'',
                                                                                address_number:'',
                                                                                address_city:'',
                                                                                address_zipcode:'',
                                                                                address_zone:'',
                                                                                address_country:'',
                                                                                is_ofertante:'',
                                                                                comercial_name:'',
                                                                                referido:'',
                                                                                cpf_no_activo:'',
                                                                                cpf:'',
                                                                                first_name:'',
                                                                                last_name:'',
                                                                                middle_name:'',
                                                                              }),
                                                                              
                                                                              usuarioAutenticado()
}}>
                 
                 Aceptar
               </button>
              
             </div>
                  
        <div  className="col-4">
             </div>
        </div>
     </CardFooter>
                       
                  
     

   </div>

     </div>

   </div>
    </div>
   </Fade>
 </Modal> : null} */}

    </Fragment>
    );
}
 
export default ActualizarPerfilPro;