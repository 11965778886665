import React, { useState, useEffect} from 'react';
import { Line, Bar,ChartjsAreaChart } from "react-chartjs-2"; 


import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
  } from "reactstrap";

import clienteAxios from 'config/axios'

function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


const Grafica = (props) => { 

  
  



const [chartData, setChartData ] = useState({});
    
  const chart = (canvas) => {
    

    let userMontos = [];
    let userFechas = [];

    // let montos = currencyFormat(userMontos);
    // let fechas = currencyFormat(userFechas);

    
    

    clienteAxios
      .post("/api/client/investments/grafica")
      .then(res => {
        
        // console.log(res.data.data[0].amount);
        // console.log(res.data.data);
        // console.log(res.data.label);

        // console.log(res);

        for (const dataObj of res.data.data) {


          userMontos.push(dataObj.amount);
        //   format(dataObj.amount)
          
        }

        for (const dataObj of res.data.label) {
            
            userFechas.push(dataObj.fecha);
          }

        //   console.log(userFechas, userMontos);
        setChartData(
                
                    {
                        labels:userFechas,
                        datasets: [

                            {
                                label: "StrPrisma Robo-Advisor por un monto de ",
                                fill: true,
                                
                                borderWidth: 1,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                pointBackgroundColor: "#fff",
                                pointBorderColor: "rgba(255,255,255,0)",
                                pointHoverBackgroundColor: "#3bfe90",
                                BackgroundHoverColor:'#3bfe90',
                                pointBorderWidth: 20,
                                pointHoverRadius: 10,
                                pointHoverBorderWidth: 15,
                                pointRadius: 1,
                                data: userMontos,
                                fontSize: '14px',
                                backgroundColor: [
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  
                              ],
                              borderColor: [
                                '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                                  '#c3ffe6',
                                  '#000478d4',
                              ]
                                
                                
                              }    
                              
                        ],
                        
                        
                    } 

                    
        )



      })
      .catch(err => {
        console.log(err);
      });
    
  };



    
  useEffect(() => {
    chart();
  }, []);



    



    


    return (
        <div className="">
        
          
        <div>
          <div className="chart-area">
          <Bar
          data={chartData}
          options={{
            backgroundColor: "#000",
            maintainAspectRatio: true,
            elements: {
              z: 9999,
            },

            
            legend: {
              display: false,

              labels: {
                
                defaultFontSize: '14px'
              }

             
            },
            hover: {
              mode: 'index',
              intersect: true,
            },
            tooltips: {
              mode: 'label',
              intersect: true,
              backgroundColor: '#5f63f2',
              position: 'average',
              enabled: true,
              
            
            
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    color: '#e5e9f2',
                    borderDash: [3, 3],
                    zeroLineColor: '#e5e9f2',
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 3],
                  },
                  ticks: {
                    beginAtZero: true,
                    fontSize: 13,
                    fontColor: '#182b49',
                  
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    display: true,
                    zeroLineWidth: 2,
                    zeroLineColor: 'transparent',
                    color: 'transparent',
                    z: 1,
                    tickMarkLength: 0,
                  },
                  ticks: {
                    padding: 10,
                  },
                },
              ],
            },
          }}

          // height={window.innerWidth <= 575 ? 500 : 86}
          
        />
          </div>
        </div>
      </div>



      );
}
 
export default Grafica;