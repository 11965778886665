import { useLottie } from "lottie-react";
import Scroll from "../IconsAnimation/scrolldown.json";
 
const ScrollDown = () => {
  const options = {
    animationData: Scroll,
    loop: true,
    autoplay: true,
    height: 20,
    color:'#5f63f2',
  };
 
  const { View } = useLottie(options);
 
  return View;
};
 
export default ScrollDown;

