import React, {useState, useEffect, Fragment } from 'react';
import clienteAxios from '../config/axios';
import Spinner from '../components/spinners/SpinnerWhite' ;
import NumberFormat from 'react-number-format';
import ScrollDown from '../components/IconsAnimation/ScrollDown'
import ArtIcon from '../components/IconsAnimation/ArtGenerator'
import ParticleBg from '../components/IconsAnimation/ParticleBg'




const Final = ({usuario,usuarioAutenticado, userStatus, depositos}) => { 

    const [artes, guardarArtes]  = useState([]);
    

    const [ iconaprob, guardarIconAprob] = useState(false);

    const [ elcargando, elGuardarCargando] = useState(false);

    


    const elguardar = ( elcargando ) ? <Fragment>
                    <div className="box-icon-add-load">
                                    <ArtIcon/>


                    </div>
                    <div className="box-cargando">
                        
                        
                    <strong>Cargando....</strong> <br/>
                    <div className="elemento1-yep"> 
                            
                        </div>

                        <br/>

                        Nuestra inteligencia Artificial, <br/> esta generando
                        tus Artes unicos en este momento. 
                        

                    </div>

    </Fragment> :  null
    const [ cargando, guardarCargando] = useState(false);

        const guardar = ( cargando ) ? <Spinner/> :  
    
        <Fragment>
            Generar Contrato
        </Fragment> 



    const [ mensaje, guardarMensaje]  = useState('')

    const [contrato, guardarContrato] = useState({
        amount:'',
    })


    const [participaciones, guardarParticipacion] = useState({
            multiplicador:`0`,
        })

     const [multiplicador, guardarMultiplicador] =useState()
    ////manejador de evento de las partcipaciones
    ////    aqui voy a colocar la participacion///

         useEffect(() => {
             guardarMultiplicador(
                 parseInt(parseInt(participaciones.multiplicador) * 500)
             )
         }, [participaciones])





         useEffect(() => {

             guardarContrato({
                 amount: `${multiplicador}`,
             })
            
         }, [multiplicador])


    //// aqui vamos a guardar el url

    const [btndinamic, guardarBtnDinamic] = useState('')
    const [montosave, guardarMontoSave] = useState('')
    const [ adupasos, guardarAduPasos] = useState({
        primero:0,
        segundo:0,
    })

    
    
    

    /// aqui vamos a hacer la funcion 

    const consultarApiFinal = async () => {
        try{
            const respuesta = await clienteAxios.post(`/api/client/aprobacion`);
            

        }
        catch(error) {
            // guardarMensaje(error.response.data.message)
            

        }
    }


    const consultarArtesCliente = async () => {
        try{
            const respuesta = await clienteAxios.post(`/api/client/contract/get_adu_client`);
            guardarArtes(respuesta.data.data);
            elGuardarCargando(false),
            
            guardarAduPasos({
                ...adupasos,
                primero:0,
                segundo:1,
            })

            

        }
        catch(error) {
            guardarMensaje(error.response.data.message)
            guardarAduPasos({
                ...adupasos,
                primero:1,
                segundo:0,
            }),
            
            guardarIconAprob(false),
            elGuardarCargando(false)
            

        }
    }




    const Arte = e =>{
        e.preventDefault(),
        elGuardarCargando(true),
        guardarIconAprob(true),
        consultarArtesCliente()
            //  usuarioAutenticado()
            // userStatus() 
    }



    const Activar = e =>{
        e.preventDefault(),        
            consultarApiFinal(),
            usuarioAutenticado(),
            userStatus() 
    }


   

    return (  


            <>



            

        { iconaprob === false ?

        

            <>   

            <div className="box-content-fx-fit-sm ">
                <ParticleBg/>

                </div>  
                 

                <div className="box-icon-add">
                        <ScrollDown/>
                        </div>

                    <div className="contrato-dream grid-wizard mt-0"> 


                                    <div className={`   elemento3 first  ${usuario.paso >= 6 ? 'active':'select'}  `}> 
                                                                    {/* <span>  
                                                                            <i className="tim-icons icon-paper">
                                                                            </i>

                                                                            <h4> </h4>
                                                                        </span> */}

                                                                        
                                                                        
                                                                        <h3 className="pt-0"> Aprobación Final </h3>


                                                                        <p>
                                                                        {` ${usuario.paso >= 6 ? '¡Muchas Gracias!':'Dele Click al Botón para Generar su colección de artes'}  `}
                                                                            
                                                                            
                                                                            </p>

                                    </div> 

                                                            


                        
                    </div> 
        </> : <>
                    
                        


        </>

                                                                    }




                        
                        

                        {/* <div className="box-icon-add-load">
                        <ArtIcon/>
                        </div> */}

                        {elguardar} 

                        
                    { adupasos.segundo === 1 ?  

                    <>
                    <h1 className="text-center">    

                    Esta es tu colección de artes 


                    </h1>

                    <div className="artes-fill">
                    <button className=" btn final-btn-prisma-mob" 
                    onClick={Activar}>
                        Activar Cuenta
                    </button> 
                    </div>
                    
                    </>
                    : null
                    }

                        <div className="adu-section">

                        

                        <div className="adu-box-placeholder">
                        {artes.map(artesss => {

                            return (
                                <>
                                <div className="adu-box" key={artesss.id}>

                                    <img src={artesss.url} />

                                </div>


                                </>

                            )
                                

                        })}

                        </div>

                        </div>


                        



                        

                      { elcargando === false ? 

                            <> 
                                { adupasos.segundo === 0 ?  
                                    <> 

                                    {   depositos !== null ? 
                                    
                                    <>                                     
                                            {
                                                usuario.paso === 5  ?
                                                    
                                                    <div className="artes-fill">
                                                    <button className=" final-btn-prisma-mob " 
                                                    onClick={Arte}>
                                                        Generar colección de artes
                                                    </button>

                                                    <small className="text-warn">
                                                                {mensaje ? <p>  {`${mensaje}`}</p> :null}
                                                            </small>

                                                    </div> : 
                                                    <div className="artes-fill">
                                                    <button className=" final-btn-prisma-mob block-cursor " 
                                                    >
                                                        Depósito sin aprobar
                                                    </button>

                                                            <small className="text-warn">
                                                                Espere la Aprobación de su depósito,
                                                                para generar su colección de Adu´s.
                                                            </small>
                                                    </div>
                                                
                                                }



                                    </> : null


                                    }





                                        



                                    </> : null
                                }
                            </>
                            
                                : null
                      } 




            </>


    );
}
 
export default Final;