import React, { useState } from 'react';



const Wizardprofile = ({usuario}) => {




        


    return ( 
        <> 


        { usuario.paso === 0 ? 

                        <div className="grid-wizard">

                                        <div className="elemento1 first corner-fx select"> 

                                        <span>  
                                            <i className="tim-icons icon-single-02">
                                            </i>

                                            <h4> </h4>
                                        </span>

                                        
                                        <h3> Datos personales </h3>
                                        <p>Llena la información de tus datos personales</p>
                                    </div>

                                    <div className="elemento2  first blank"> 
                                        <span>  
                                            <i className="tim-icons icon-badge">
                                            </i>

                                            <h4> </h4>
                                        </span>
                                        
                                        
                                        <h3> Centro de verificación </h3>
                                        <p>Complete el proceso de validación de usuario ( KYC ) </p>
                                    </div>

                                    <div className="elemento3 first blank"> 
                                        <span>  
                                                <i className="tim-icons icon-paper">
                                                </i>

                                                <h4> </h4>
                                            </span>

                                            
                                            
                                            <h3> Contratos </h3>
                                            <p>Descargue y complete el contrato de participación por su inversión</p>
                                    </div>

                                    <div className="elemento4 final blank"> 
                                    <span>  
                                        <i className="tim-icons icon-bank">
                                            </i>

                                            <h4> </h4>
                                        </span>

                                        
                                        
                                        
                                        <h3> Cuenta bancaria </h3>
                                        <p>Agregue su cuenta bancaria para sus retiro de beneficios </p>
                                        
                                    </div>

                                    <div className="elemento5 final blank"> 
                                            <span>  
                                                <i className="tim-icons icon-coins">
                                                </i>

                                                <h4> </h4>
                                            </span>

                                            
                                            
                                            <h3> Depósito </h3>
                                            <p>Reporte el depósito inicial en StrPrisma asociado a su contrato de participación</p>
                                    </div> 


                                    <div className="elemento6 final blank corner-fx"> 

                                        <span>  
                                        <i className="tim-icons icon-check-2">
                                            </i>

                                            <h4> </h4>
                                        </span>

                                        
                                        
                                        <h3> Aprobación final </h3>
                                        <p>Espere aprobación final de su cuenta</p>
                                    </div>
                                    <div>
                                    </div>
                                    </div>
        
    

        :null
    
    } 


     {  usuario.paso === 1 ? 

                    <div className="grid-wizard">

                    <div className="elemento1 first corner-fx active"> 

                        <span>  
                            <i className="tim-icons icon-single-02">
                            </i>

                            <h4> </h4>
                        </span>

                        
                        <h3> Datos personales </h3>
                        <p>Llena la información de tus datos personales</p>
                    </div>

                    <div className="elemento2  first select"> 
                        <span>  
                            <i className="tim-icons icon-badge">
                            </i>

                            <h4> </h4>
                        </span>
                        
                        
                        <h3> Centro de verificación </h3>
                        <p>Complete el proceso de validación de usuario ( KYC ) </p>
                    </div>

                    <div className="elemento3 first blank"> 
                        <span>  
                                <i className="tim-icons icon-paper">
                                </i>

                                <h4> </h4>
                            </span>

                            
                            
                            <h3> Contratos </h3>
                            <p>Descargue y complete el contrato de participación por su inversión</p>
                    </div>

                    <div className="elemento4 final blank"> 

                    <span>  
                        <i className="tim-icons icon-bank">
                            </i>

                            <h4> </h4>
                        </span>

                        
                        
                        
                        <h3> Cuenta bancaria </h3>
                        <p>Agregue su cuenta bancaria para sus retiro de beneficios </p>
                       
                    </div>

                    <div className="elemento5 final blank"> 

                    <span>  
                                <i className="tim-icons icon-coins">
                                </i>

                                <h4> </h4>
                            </span>

                            
                            
                            <h3> Depósito </h3>
                            <p>Reporte el depósito inicial en StrPrisma asociado a su contrato de participación</p>

                    
                        
                    </div> 


                    <div className="elemento6 final blank corner-fx"> 

                        <span>  
                        <i className="tim-icons icon-check-2">
                            </i>

                            <h4> </h4>
                        </span>

                        
                        
                        <h3> Aprobación final </h3>
                        <p>Espere aprobación final de su cuenta</p>
                    </div>




                    <div>

                    </div>



                    </div> 

                     :null 


     }              



                                                {  usuario.paso === 2 ? 

                                                <div className="grid-wizard">

                                                <div className="elemento1 first corner-fx active"> 

                                                    <span>  
                                                        <i className="tim-icons icon-single-02">
                                                        </i>

                                                        <h4> </h4>
                                                    </span>

                                                    
                                                    <h3> Datos personales </h3>
                                                    <p>Llena la información de tus datos personales</p>
                                                </div>

                                                <div className="elemento2  first active"> 
                                                    <span>  
                                                        <i className="tim-icons icon-badge">
                                                        </i>

                                                        <h4> </h4>
                                                    </span>
                                                    
                                                    
                                                    <h3> Centro de verificación </h3>
                                                    <p>Complete el proceso de validación de usuario ( KYC ) </p>
                                                </div>

                                                <div className="elemento3 first select"> 
                                                    <span>  
                                                            <i className="tim-icons icon-paper">
                                                            </i>

                                                            <h4> </h4>
                                                        </span>

                                                        
                                                        
                                                        <h3> Contratos </h3>
                                                        <p>Descargue y complete el contrato de participación por su inversión</p>
                                                </div>

                                                <div className="elemento4 final blank"> 

                                                
                                                    <span>  
                                                    <i className="tim-icons icon-bank">
                                                        </i>

                                                        <h4> </h4>
                                                    </span>

                                                    
                                                    
                                                    
                                                    <h3> Cuenta bancaria </h3>
                                                    <p>Agregue su cuenta bancaria para sus retiro de beneficios </p>
                                                </div>

                                                <div className="elemento5 final blank"> 

                                                <span>  
                                                                            <i className="tim-icons icon-coins">
                                                                            </i>

                                                                            <h4> </h4>
                                                                        </span>

                                                                        
                                                                        
                                                                        <h3> Depósito </h3>
                                                                        <p>Reporte el depósito inicial en StrPrisma asociado a su contrato de participación</p>
                                                    
                                                </div> 


                                                <div className="elemento6 final blank corner-fx"> 

                                                    <span>  
                                                    <i className="tim-icons icon-check-2">
                                                        </i>

                                                        <h4> </h4>
                                                    </span>

                                                    
                                                    
                                                    <h3> Aprobación final </h3>
                                                    <p>Espere aprobación final de su cuenta</p>
                                                </div>




                                                <div>

                                                </div>



                                                </div> 

                                                :null 


                                                }  



                                                    
                                                {  usuario.paso === 3 ? 

                                                                <div className="grid-wizard">

                                                                <div className="elemento1 first corner-fx active"> 

                                                                    <span>  
                                                                        <i className="tim-icons icon-single-02">
                                                                        </i>

                                                                        <h4> </h4>
                                                                    </span>

                                                                    
                                                                    <h3> Datos personales </h3>
                                                                    <p>Llena la información de tus datos personales</p>
                                                                </div>

                                                                <div className="elemento2  first active"> 
                                                                    <span>  
                                                                        <i className="tim-icons icon-badge">
                                                                        </i>

                                                                        <h4> </h4>
                                                                    </span>
                                                                    
                                                                    
                                                                    <h3> Centro de verificación </h3>
                                                                    <p>Complete el proceso de validación de usuario ( KYC ) </p>
                                                                </div>

                                                                <div className="elemento3 first active"> 
                                                                    <span>  
                                                                            <i className="tim-icons icon-paper">
                                                                            </i>

                                                                            <h4> </h4>
                                                                        </span>

                                                                        
                                                                        
                                                                        <h3> Contratos </h3>
                                                                        <p>Descargue y complete el contrato de participación por su inversión</p>
                                                                </div>

                                                                <div className="elemento4 final select"> 

                                                                <span>  
                                                                <i className="tim-icons icon-bank">
                                                                    </i>

                                                                    <h4> </h4>
                                                                </span>

                                                                
                                                                
                                                                
                                                                <h3> Cuenta bancaria </h3>
                                                                <p>Agregue su cuenta bancaria para sus retiro de beneficios </p>
                                                                    
                                                                </div>

                                                                <div className="elemento5 final blank"> 
                                                                <span>  
                                                                            <i className="tim-icons icon-coins">
                                                                            </i>

                                                                            <h4> </h4>
                                                                        </span>

                                                                        
                                                                        
                                                                        <h3> Depósito </h3>
                                                                        <p>Reporte el depósito inicial en StrPrisma asociado a su contrato de participación</p>
                                                                </div> 


                                                                <div className="elemento6 final blank corner-fx"> 

                                                                    <span>  
                                                                    <i className="tim-icons icon-check-2">
                                                                        </i>

                                                                        <h4> </h4>
                                                                    </span>

                                                                    
                                                                    
                                                                    <h3> Aprobación final </h3>
                                                                    <p>Espere aprobación final de su cuenta</p>
                                                                </div>




                                                                <div>

                                                                </div>



                                                                </div> 

                                                                :null 


                                                                }  


                                                            {  usuario.paso === 4 ? 

                                                            <div className="grid-wizard">

                                                            <div className="elemento1 first corner-fx active"> 

                                                                <span>  
                                                                    <i className="tim-icons icon-single-02">
                                                                    </i>

                                                                    <h4> </h4>
                                                                </span>

                                                                
                                                                <h3> Datos personales </h3>
                                                                <p>Llena la información de tus datos personales</p>
                                                            </div>

                                                            <div className="elemento2  first active"> 
                                                                <span>  
                                                                    <i className="tim-icons icon-badge">
                                                                    </i>

                                                                    <h4> </h4>
                                                                </span>
                                                                
                                                                
                                                                <h3> Centro de verificación </h3>
                                                                <p>Complete el proceso de validación de usuario ( KYC ) </p>
                                                            </div>

                                                            <div className="elemento3 first active"> 
                                                                <span>  
                                                                        <i className="tim-icons icon-paper">
                                                                        </i>

                                                                        <h4> </h4>
                                                                    </span>

                                                                    
                                                                    
                                                                    <h3> Contratos </h3>
                                                                    <p>Descargue y complete el contrato de participación por su inversión</p>
                                                            </div>

                                                            <div className="elemento4 final active"> 
                                                            <span>  
                                                                <i className="tim-icons icon-bank">
                                                                    </i>

                                                                    <h4> </h4>
                                                                </span>

                                                                
                                                                
                                                                
                                                                <h3> Cuenta bancaria </h3>
                                                                <p>Agregue su cuenta bancaria para sus retiro de beneficios </p>
                                                            </div>

                                                            <div className="elemento5 final select"> 

                                                            <span>  
                                                                    <i className="tim-icons icon-coins">
                                                                    </i>

                                                                    <h4> </h4>
                                                                </span>

                                                                
                                                                
                                                                <h3> Depósito </h3>
                                                                <p>Reporte el depósito inicial en StrPrisma asociado a su contrato de participación</p>
                                                                
                                                            </div> 


                                                            <div className="elemento6 final blank corner-fx"> 

                                                                <span>  
                                                                <i className="tim-icons icon-check-2">
                                                                    </i>

                                                                    <h4> </h4>
                                                                </span>

                                                                
                                                                
                                                                <h3> Aprobación final </h3>
                                                                <p>Espere aprobación final de su cuenta</p>
                                                            </div>




                                                            <div>

                                                            </div>



                                                            </div> 

                                                            :null 


                                                            }  




                                                        {  usuario.paso === 5 ? 

                                                        <div className="grid-wizard">

                                                        <div className="elemento1 first corner-fx active"> 

                                                            <span>  
                                                                <i className="tim-icons icon-single-02">
                                                                </i>

                                                                <h4> </h4>
                                                            </span>

                                                            
                                                            <h3> Datos personales </h3>
                                                            <p>Llena la información de tus datos personales</p>
                                                        </div>

                                                        <div className="elemento2  first active"> 
                                                            <span>  
                                                                <i className="tim-icons icon-badge">
                                                                </i>

                                                                <h4> </h4>
                                                            </span>
                                                            
                                                            
                                                            <h3> Centro de verificación </h3>
                                                            <p>Complete el proceso de validación de usuario ( KYC ) </p>
                                                        </div>

                                                        <div className="elemento3 first active"> 
                                                            <span>  
                                                                    <i className="tim-icons icon-paper">
                                                                    </i>

                                                                    <h4> </h4>
                                                                </span>

                                                                
                                                                
                                                                <h3> Contratos </h3>
                                                                <p>Descargue y complete el contrato de participación por su inversión</p>
                                                        </div>

                                                        <div className="elemento4 final active"> 
                                                        <span>  
                                                            <i className="tim-icons icon-bank">
                                                                </i>

                                                                <h4> </h4>
                                                            </span>

                                                            
                                                            
                                                            
                                                            <h3> Cuenta bancaria </h3>
                                                            <p>Agregue su cuenta bancaria para sus retiro de beneficios </p>
                                                            
                                                        </div>

                                                        <div className="elemento5 final active"> 
                                                            <span>  
                                                                    <i className="tim-icons icon-coins">
                                                                    </i>

                                                                    <h4> </h4>
                                                                </span>

                                                                
                                                                
                                                                <h3> Depósito </h3>
                                                                <p>Reporte el depósito inicial en StrPrisma asociado a su contrato de participación</p>
                                                        </div> 


                                                        <div className="elemento6 final select corner-fx"> 

                                                            <span>  
                                                            <i className="tim-icons icon-check-2">
                                                                </i>

                                                                <h4> </h4>
                                                            </span>

                                                            
                                                            
                                                            <h3> Aprobación final </h3>
                                                            <p>Espere aprobación final de su cuenta</p>
                                                        </div>




                                                        <div>

                                                        </div>



                                                        </div> 

                                                        :null 


                                                        } 

                                                        {/* /////////////////bien /////////// */}


                                                            {  usuario.paso === 6 ? 

                                                            <div className="grid-wizard">

                                                            <div className="elemento1 first corner-fx active"> 

                                                                <span>  
                                                                    <i className="tim-icons icon-single-02">
                                                                    </i>

                                                                    <h4> </h4>
                                                                </span>

                                                                
                                                                <h3> Datos personales </h3>
                                                                <p>Llena la información de tus datos personales</p>
                                                            </div>

                                                            <div className="elemento2  first active"> 
                                                                <span>  
                                                                    <i className="tim-icons icon-badge">
                                                                    </i>

                                                                    <h4> </h4>
                                                                </span>
                                                                
                                                                
                                                                <h3> Centro de verificación </h3>
                                                                <p>Complete el proceso de validación de usuario ( KYC ) </p>
                                                            </div>

                                                            <div className="elemento3 first active"> 
                                                                <span>  
                                                                        <i className="tim-icons icon-paper">
                                                                        </i>

                                                                        <h4> </h4>
                                                                    </span>

                                                                    
                                                                    
                                                                    <h3> Contratos </h3>
                                                                    <p>Descargue y complete el contrato de participación por su inversión</p>
                                                            </div>

                                                            <div className="elemento4 final active"> 

                                                            <span>  
                                                                <i className="tim-icons icon-bank">
                                                                    </i>

                                                                    <h4> </h4>
                                                                </span>

                                                                
                                                                
                                                                
                                                                <h3> Cuenta bancaria </h3>
                                                                <p>Agregue su cuenta bancaria para sus retiro de beneficios </p>

                                                                
                                                            </div>

                                                            <div className="elemento5 final active"> 
                                                                    <span>  
                                                                        <i className="tim-icons icon-coins">
                                                                        </i>

                                                                        <h4> </h4>
                                                                    </span>

                                                                    
                                                                    
                                                                    <h3> Depósito </h3>
                                                                    <p>Reporte el depósito inicial en StrPrisma asociado a su contrato de participación</p>
                                                            </div> 


                                                            <div className="elemento6 final active corner-fx"> 

                                                                <span>  
                                                                <i className="tim-icons icon-check-2">
                                                                    </i>

                                                                    <h4> </h4>
                                                                </span>

                                                                
                                                                
                                                                <h3> Aprobación final </h3>
                                                                <p>Espere aprobación final de su cuenta</p>
                                                            </div>




                                                            <div>

                                                            </div>



                                                            </div> 

                                                            :null 


                                                            }



        




        </>
     );
}
 
export default Wizardprofile;