import React, { useContext, useState, useEffect, Fragment } from 'react';
import clienteAxios from '../config/axios';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ParticleBg from '../components/IconsAnimation/ParticleBg'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: 500,
    color: '#635f72',
    textTransform:'capitalize',
    display: 'grid',
    alignItems:'center',
  },
}));



import AuthContext from '../context/autenticacion/authContext'

const Frecuente = () => {


  
  const authContext = useContext(AuthContext);
  const {faq_data, Faq} = authContext;  

  



    const [ preguntas, guardarPreguntas ] = useState([]);


    
    useEffect( () => { 
      

        const consultarApiPreguntas = async () => { 

          try {        
            
           guardarPreguntas(faq_data)

           
            } catch (error) { 
                return

                
            
        }

        }
    
        consultarApiPreguntas()
    
    
      },[]); 




    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };

      const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);


  function createMarkup() {
    return {__html: `${question}`};
  }

  

    
    return (  

        <>
        



    <div className="content"> 

    <div className="box-content-fx waveform-x4">
                <ParticleBg/>

                </div>  
        

        {/* <div className="prisma-open mb-4">
                     <div>
                       <h3>
                         Preguntas Frecuentes  <br/>
                         <small>Ultimate Robo-advisor</small>
                       </h3>

                     </div>

     </div> */}


     <div className="prisma-clip">


                    <p> Preguntas Frecuentes</p>

                    </div>

                    


        <div className="frecuentes-open"> 

        <div className={classes.root}>

        {preguntas.map(accordion => {

            const { id, question, answer} = accordion;

            function createMarkup() {
              return {__html: `${question}`};
            }
            function createMarkupAn() {
              return {__html: `${answer}`};
            }


            return (


              <Fragment>
                <Accordion 
                expanded={expanded === id}
                onChange={handleChange(id)}
                className="Summary"
                >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="Summary-ask"
                >
                        
                        <Typography className={classes.secondaryHeading}> <div dangerouslySetInnerHTML={createMarkup()} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          className="Ask-order"
                        >
                        <Typography className="Text-Hold">
                        <div className="Ask-img"><img alt="..." src={require("../assets/img/white-logo.png")} /></div>
                          <div className="Ask-text">
                          <div dangerouslySetInnerHTML={createMarkupAn()} />
                          </div>
                        </Typography>
                        </AccordionDetails>
                        </Accordion>

              </Fragment>

                    

            )

            })}

            
           
        </div>

        </div>

        

    </div>
        </>


    );
}
 
export default Frecuente;