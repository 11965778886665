import React, { useContext, useState, useEffect } from 'react';
import AuthContext from '../../context/autenticacion/authContext';
import { Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from "history";

import NumberFormat from 'react-number-format';


const CerrarSesion = ({props}) => {

    const authContext = useContext(AuthContext);
  const { CerrarSesion,
    usuarioAutenticado,
    userStatus,
    // PrismaNoLoadApp(),
    Saldos,
    LastContract,
    usuario,
    
    
    // TodosProyectosPrimario(),
    // TodosProyectosSecundario(),
     Movimientos,
     ListaNacionales,
     ListaInternacionales,
    ListaRemesas,
     TodosRetiros,
     TodosDepositos,
    //  TodosPdfDepositos(),
     Faq,
    // TodosOfertas(),
    // TodosOferentes(),
    // TodosInversiones(),
    // TodosInversionesVendidas()
    galleryAduClient,
    collapse,
        CollapseNav,
        ExpandNav,
        
        gallery_data
  
  
  } = authContext;  


  const [artes, guardarArtes]  = useState([{
    description:'',
    url:'',
    nrocontrato:'',
    currency_code:'',
    name:'',
    tax_referential_client:'',
    end_date:'',
  }]);

  const open = () => {
    if (collapse === true) {
        CollapseNav()
    }

    if (collapse === false) {
        ExpandNav()
    }
} 



useEffect( () => { 
  const consultarArtesData = async () => { 
    try {        
      
      if(gallery_data !== []){
        guardarArtes(gallery_data)
      }

     
      } catch (error) { 
          return

          
      
  }

  }

  consultarArtesData()


},[]); 


////// metodo fallido para cambiar a la galeria //// 
////// metodo fallido para cambiar a la galeria //// 
////// metodo fallido para cambiar a la galeria //// 
////// metodo fallido para cambiar a la galeria //// 
////// metodo fallido para cambiar a la galeria //// 
////// metodo fallido para cambiar a la galeria //// 
////// metodo fallido para cambiar a la galeria //// 

useEffect( () => { 
  if(aduclickbtn === true ){
    history.push('/gallery')
  }
},[history]); 

const [aduclickbtn , guardarClickAdu] = useState(false);

const aduClick = () =>{
  {aduclickbtn === false ?  guardarClickAdu(true) : null }
} 

////// metodo fallido para cambiar a la galeria //// 
////// metodo fallido para cambiar a la galeria //// 
////// metodo fallido para cambiar a la galeria //// 
////// metodo fallido para cambiar a la galeria //// 
////// metodo fallido para cambiar a la galeria //// 
////// metodo fallido para cambiar a la galeria //// 








    return ( 
        <Route> 


        <div className="d-flex flex-row  align-items-center salir-container-2 ml-2" data-tooltip-light="VALOR DE TU COLECCIÓN JUNTO A TU CANTIDAD DE ADUS">
        <i className="tim-icons icon-coins   icon-salir-2 " />
                  <p className=" salir-2" > <NumberFormat value={ artes !== null ? parseInt(`${artes.length}`) * 500 : 0
                    

                   }  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/> </p>  
                  <i className="tim-icons icon-components   icon-salir-2 " />
                  {artes !== null ? 
                        `${artes.length}` : 0
                    }

            </div>


            <div className="d-flex flex-row  align-items-center salir-container-2 ml-2" data-tooltip-light="TU GANANCIA ESPERADA MENSUAL PUEDE IR ENTRE 5% A 7% RENDIMIENTO SOBRE EL VALOR DE TU COLECCIÓN DE ARTES">
              <i className="tim-icons icon-chart-bar-32   icon-salir-2 " />
              
                  <p className=" salir-2" > <NumberFormat value={ 
                    artes !== null ?
                    (parseInt(`${artes.length}`) * 500) * 5 / 100 : 0
                
                }  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/> </p>  
                    

            </div>



          {usuario.paso === 0 ? 
          null :
          <> 
          <div className="d-flex flex-row  align-items-center salir-container ml-2" onClick={open}>
                  <p className=" salir" > { collapse === true ? 'MOSTRAR MENÚ':'OCULTAR MENÚ' } </p>  <i className="tim-icons icon-bullet-list-67   icon-salir " />

                </div>
          
          
          
          </>
        
        
        
        }


        
        


                <div className="d-flex flex-row  align-items-center salir-container ml-2 " onClick={ 
                    
                       
                    () => { usuarioAutenticado(),
                      userStatus(),
                      // PrismaNoLoadApp(),
                      Saldos(),
                      LastContract(),
                      
                      
                      // TodosProyectosPrimario(),
                      // TodosProyectosSecundario(),
                       Movimientos(),
                       ListaNacionales(),
                       ListaInternacionales(),
                      ListaRemesas(),
                       TodosRetiros(),
                       TodosDepositos(),
                      //  TodosPdfDepositos(),
                       Faq(),
                      // TodosOfertas(),
                      // TodosOferentes(),
                      // TodosInversiones(),
                      // TodosInversionesVendidas()
                      
                      galleryAduClient() 
                    
                    
                    }}>
                  <p className=" salir" > RECARGAR </p>  <i className="tim-icons icon-refresh-02   icon-salir" />

                </div>


                <div className="d-flex flex-row  align-items-center salir-container ml-2" onClick={ 
                    
                       
                    () => { CerrarSesion() }}>
                  <p className=" salir" > SALIR </p>  <i className="tim-icons icon-user-run   icon-salir " />

                </div>
          
        {/* <button className="btn btn-sm btn-blank"
                onClick={ 
                    
                       
                    () => { CerrarSesion() }}
        
        >
           Cerrar Sesion
        </button> */}
        </Route>

     );
}
 
export default CerrarSesion;