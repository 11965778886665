import React, { useState, useContext, useEffect, Fragment } from 'react';
//// aqui vamos a importal los stylos del check
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

/// aqui vamos a definir el cliente axios para poder llamar la api
import clienteAxios from '../../config/axios'

import Modal from '@material-ui/core/Modal'

import ModalPropias from '@material-ui/core/Modal'
import ModalTerceros from '@material-ui/core/Modal'
import ModalRemesadora from '@material-ui/core/Modal'

import ModalDos from '@material-ui/core/Modal'

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';

/// aqui vamos a importar como el modal entra 
/// fade del modal
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';  

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'; 


///aqui vamos a importar el lugar de las cuentas bancarias
import CuentasBancariasPropias from './CuentasBancariasPropias' 

import CuentasBancariasTerceros from './CuentasBancariasTerceros'

import CuentasBeneficiariosRemesa  from './CuentasBeneficiariosRemesa'
import AuthContext from '../../context/autenticacion/authContext'


/// aqui voy a importar una alerta simple
import Alerta from '../Alerta';


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    Container,
    Form,
    Input,
    
    Row,
    Col
  } from "reactstrap"; 
  


  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  
  const useStyles = makeStyles((theme) => ({
    root: {
      
      width: '100%',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'hidden',
   
      
    },
    paper: {
      
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      
     
    },
  })); 
  
  const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      outline:'none',
      backgroundColor: 'transparent',
      '& > span': {
        maxWidth: 100,
        width: '100%',
        outline:'none',
        backgroundColor: '#00ff9d',
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
  
  const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      color: '#fff',
      fontWeight: theme.typography.fontWeightRegular,
      alignItems:'center',
      marginRight: theme.spacing(0),
      borderRadius:'30px',
      transition:'all 500ms ease',
      padding:'0px 5px 0px 5px',
       margin:'0px 4px 0px 4px',
       outline:'none',
       alignItems:'center',
    
      '&:focus': {
        opacity: 0.8,
        color: '#f5f5f5',
        border: 'none',
        boxShadow:'0px 0px 10px #3969f7',
        tramsition:'all 500ms ease',
        background:'#005eff',
        outline:'none',
        borderRadius:'30px'
        
        
      },
      '&:hover': {
        opacity: 0.6,
        color: '#f5f5f5',
        border: 'none',
        boxShadow:'0px 0px 10px #3969f7',
        tramsition:'all 500ms ease',
        background:'#000',
        outline:'none',
        borderRadius:'30px'
      },
  
    },
  }))((props) => <Tab disableRipple {...props} />);
  
  
  /// estilos para el carousel
  
  const styles = {
    tabs: {
      background: '#fff',
    },
    slide: {
      padding: 15,
      minHeight: 700,
      color: '#fff',
    },
    slide1: {
      
      border: '1px solid #0047ff',
      borderRadius: '12px'
    },
    slide2: {
      
      border: '1px solid #0047ff',
      borderRadius: '12px'
    }
  }; 
  


/////////////////////////////////////////////////////////////
/// aqui vamos a importar los modales

function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: ``,
    left: ``,
    
    
  };
}



const useStylesDos = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));





const CuentaBancariaRetiro = ({cuentasremesas, cuentas, setCuentas, setRecargarData, recargardata, cuentaspropias, cuentasterceros, mensajeDos, guardarMensajeDos,ApiLoad, apiload, ListaNacionales,ListaInternacionales, ListaRemesas, usuario,usuarioAutenticado}) => { 


  const authContext = useContext(AuthContext);
  const {  cuentas_nacionales } = authContext; 

  

 const [openedit, setOpenEdit] =useState(false);

 
 const handleOpenEdit = () => {
  setOpenEdit(true);
} 

const handleCloseEdit = () => {
  setOpenEdit(false);
  
}

// aqui vamos a colocar el stado del check
const [state, setState] = React.useState({
    
  check: false,
  
});

const classes = useStyles();
const theme = useTheme();
const [value, setValue] = React.useState(0);

const handleChange = (event, newValue) => {
  setValue(newValue);
};

const handleChangeIndex = (index) => {
  setValue(index);
};




/// aqui vamos a colocar los manejadores de eventos para el check
const handleChangeCheck = (event) => { 
  
  setState({ ...state, [event.target.name]: event.target.checked });
};

const {  check } = state;
const error = [check].filter((v) => v).length !== 2;







 // aqui vamos a importar los estados del modal
 const [ modalStyle ] =useState(getModalStyle); 


 
// aqui vamos a colocar los manejadores de los modales, que abren y se cierran
const [ modalremesadora, setModalRemesadora ] = useState(false);
/// aqui vamos a colocar los verdaderos manejadores en funciones

const abrirRemesadora = () => {
     setModalRemesadora(true);
}

const cerrarRemesadora = () => {
    setModalRemesadora(false);
   guardarMensaje(false);
}

///// aqui vamos a terminar de colocar los 






// aqui vamos a colocar los manejadores de los modales, que abren y se cierran
 const [ modalterceros, setModalTerceros ] = useState(false);
 /// aqui vamos a colocar los verdaderos manejadores en funciones

 const abrirTerceros = () => {
      setModalTerceros(true);
 }
 
 const cerrarTerceros = () => {
    setModalTerceros(false);
    guardarMensaje(false);
 }

///// aqui vamos a terminar de colocar los estados del modal tercero /////////

const [ modalpropias, setModalPropias ] = useState(false); 


const abrirPropias = () => {
  setModalPropias(true);
}

const cerrarPropias = () => {
  setModalPropias(false);
  guardarMensaje(null);

}

/// aqui es el final del modal de propias, con su estado y sus manejadores de cambio





 const [open, setOpen] =useState(false);

 const handleOpen = () => {
     setOpen(true);
 } 

 const handleClose = () => {
     setOpen(false);
     guardarMensaje(null);
 }

//// aqui vamos a colocar los estados 


const classesDos = useStylesDos(); 

const [mensaje, guardarMensaje] = useState('');


const [cuentabancaria, setCuentaBancaria ] = useState({
      contry_id:'',
      bank_client:'',
      bank_swift:'',
      bank_name:'',
      bank_account:'',
      bank_personal:'',
      bank_type:'',
      bank_aba:'',
      bank_apellidos:'',
      provincia:'',
      ciudad:'',
      direccion_cliente:'',
      tipo_identificacion:'',
      identificacion:'',
      telefono:'',
      iban:'',
});





/// aqui vamos a hacer destructuring
const { 
      country_id,bank_client,bank_swift, bank_name, bank_account, bank_type, bank_aba,
      bank_apellidos,provincia,ciudad,direccion_cliente,bank_personal,
        tipo_identificacion, identificacion,telefono,iban
        
} = cuentabancaria; 





const consultarApiAddAccPersonal = async () => { 

  const bank_personal = "1"

  const {country_id,bank_client,bank_swift, bank_name, bank_account,bank_type} = cuentabancaria; 

  

  const respuesta = await clienteAxios.post('/api/client/beneficiario_create ',
  {country_id,bank_client,bank_swift, bank_name, bank_account, bank_type,bank_personal}) 

    
  
    guardarMensajeDos(respuesta.data.message);
    handleOpen();
    
   
      





    } 

  
    const consultarApiAddAccTerceros = async () => { 

      const bank_personal = "2"
    
      const {country_id,bank_client,bank_swift, bank_name, bank_account,bank_type,bank_aba,iban} = cuentabancaria; 
    
    
      const respuesta = await clienteAxios.post('/api/client/beneficiario_create ',
      {country_id,bank_client,bank_swift, bank_name, bank_account, bank_type,bank_personal,bank_aba}) 
    
    
        
        guardarMensajeDos(respuesta.data.message);
        
        handleOpen();
    
          
    
    
    
    
    
         } 



           
    const consultarApiAddAccRemesas = async () => { 

      const bank_personal = "3"
    
      const { 
              country_id,
              bank_client,
              bank_swift,
              bank_name,
              bank_account,
              bank_type,
              bank_aba,
              bank_apellidos,
              provincia,
              ciudad,
              direccion_cliente,
              tipo_identificacion,
              identificacion,
              telefono,
      } = cuentabancaria; 
    
    
      const respuesta = await clienteAxios.post('/api/client/beneficiario_create',
      { country_id,
        bank_client,
        bank_swift,
        bank_name, 
        bank_account, 
        bank_type,
        bank_personal,
        bank_aba,
        bank_client,
        bank_apellidos,
        country_id,
        provincia,
        ciudad,
        direccion_cliente,
        bank_personal,
        tipo_identificacion,
        identificacion,
        telefono,
      
      }) 
        guardarMensajeDos(respuesta.data.message);
        handleOpen();
        } 





const onChange = e => {

  // voy a colocar guardar usuario en el state

  setCuentaBancaria({
      ...cuentabancaria,
      [e.target.name] : e.target.value
      
  }) 
  

  
}




const onSubmitPropias = e => {
  e.preventDefault();





  // luego lo vamos a pasar al action

  consultarApiAddAccPersonal({
            
    country_id,
    bank_client,
    bank_swift,
    bank_name,
    bank_account,
    bank_type,
    bank_aba,
    
    
});


} 


const onSubmitTerceros = e => {
  e.preventDefault();





  // luego lo vamos a pasar al action

  consultarApiAddAccTerceros({
            
    country_id,
    bank_client,
    bank_swift,
    bank_name,
    bank_account,
    bank_type,
    bank_aba,
    
    
});


} 


const onSubmitRemesas = e => {
  e.preventDefault();





  // luego lo vamos a pasar al action

  consultarApiAddAccRemesas({
            
    
      bank_client,
      bank_apellidos,
      country_id,
      provincia,
      ciudad,
      direccion_cliente,
      bank_personal,
      tipo_identificacion,
      identificacion,
      telefono,
    
    
});


} 




////// aqui vamos a colocar los eventos nuevos para la seleccion de cuentas por medio ndel tap bar

const [appbar, guardarAppBar] = useState({
  primero:1,
  segundo:0,
  tercero:0,

})

 useEffect( () => {
   setCuentaBancaria({bank_client:`${usuario.name}`});
 } , []
 );



    return ( 
    
    <>
              
              <div className="retiros-box" >                                  
                              <div className="retiros-appbar">  

                              
                              <div class="prisma-clip-2"><p> Cuenta bancaria</p></div>
                                  <h5 ><span> ( para retiros de fondos )</span></h5>
                                  
                                  <div className="grid-app-bar-simple-cuentas d-none "> 
                                        
                                     <div >
                                                               <p className={` ${appbar.primero === 1 ? 'active-selection-cuentas':''}`}
                                                                onClick={ ()=>(
                                                                  guardarAppBar(({...appbar, primero: 1, segundo: 0, tercero:0})),
                                                                  setCuentaBancaria({bank_client:`${usuario.name}`})
                                                                  
                                                                )}
                                                               
                                                               > Nacionales
                                                                <h4 >
                                                                  
                                                                </h4>
                                                               </p>

                                                               <p className={`d-none ${appbar.segundo === 1 ? 'active-selection-cuentas':''}`}
                                                                  onClick={ ()=>(
                                                                    guardarAppBar(({...appbar, primero: 0, segundo: 1,tercero:0})),
                                                                    setCuentaBancaria({bank_client:`${usuario.name}`})
                                                                  )}
                                                               
                                                               >
                                                                 Internacionales
                                                                 <h4 >
                                                                 
                                                                </h4>
                                                              </p> 
                                      </div>
                                     </div>   
                                 
                                  

                                 
                                </div>



                                <div>  
                                  <div className={`  ${appbar.primero === 1 ? 'd-block':'d-none'}`} >
                                     
                                                  {/* //// aqui vamos a colocar el modal  de las cuentas */}
                                            <div className="col-add-vertical-fix">
                                                                      {
                                                                        cuentaspropias.length === 0 ? 

                                                                        <div className="add-account-card   p-3  mb-lg-0"
                                                                        onClick={ () => {
                                                                          abrirPropias()
                                                                    }}>
                                                                      
                                                                      <div className="add-tarjeta">
                                                                        <i class="row fas fa-plus-circle" ></i>
                                                                        <p className="row d-flex align-items-center m-0 ">
                                                                          Agregar una nueva cuenta Bancaria
                                                                        </p>
                                                                      </div>
            
                                                                    </div>


                                                                        :<div className="  p-3  mb-lg-0"
                                                                      
                                                                    >
                                                                      
                                                                      <div className="add-tarjeta">
                                                                        <i class="tim-icons icon-simple-remove" ></i>
                                                                        <p className="row d-flex align-items-center m-0 ">
                                                                          No puedes agregar más cuentas bancarias ,solo puedes agregar una sola cuenta bancaria para retirar tu dinero.
                                                                        </p>
                                                                      </div>
            
                                                                    </div>
                                                                      }

                                                        



                                                      
                                                      </div>  

                                                      <CuentasBancariasPropias cuentaspropias={cuentaspropias} apiload={apiload} ApiLoad={ApiLoad}  ListaNacionales={ListaNacionales} />                      
                                              

                                     </div>
                                      <div className={` text-white ${appbar.segundo === 1 ? 'd-block':'d-none'}`} >
                                            
                                                  {/* //// aqui vamos a colocar el modal  de las cuentas */}
                                                      
                                                  <div className="col-add-vertical-fix">
                                                        
                                                        <div className="add-account-card  p-3  mb-lg-0"
                                                            onClick={ () => {
                                                              abrirTerceros()
                                                        }}
                                                        
                                                        >
                                                          
                                                          <div className="add-tarjeta">
                                                          <i class="row fas fa-plus-circle" ></i>
                                                          <p className="row d-flex align-items-center m-0 ">
                                                              Agregar una nueva cuenta Bancaria
                                                            </p>
                                                            
                                                          
                                                          </div>
                                                    
                                                            
                                                          
                                                        </div>
                                                      
                                                      </div>       
                                                      <CuentasBancariasTerceros cuentasterceros={cuentasterceros}  apiload={apiload} ApiLoad={ApiLoad}  ListaInternacionales={ListaInternacionales}/>
                 
                                              

                                     </div>

                                     {/* <div className={` text-white ${appbar.tercero === 1 ? 'd-block':'d-none'}`} >
                                          <div> 
                                                  
                                                      
                                                        <Col sm="6" md="12" lg="12" className="col-add-vertical-fix">
                                                        
                                                        <div className="add-account-card  text-white p-3  mb-lg-0"
                                                            onClick={ () => {
                            
                                                                  
                                                              abrirRemesadora()
                                                          
                                                        
                                                        }}
                                                        
                                                        >
                                                          
                                                          <div className="add-tarjeta">
                                                          <i class="row fas fa-plus-circle" ></i>
                                                          <p className="row d-flex align-items-center m-0 text-white">
                                                              Agregar un nuevo beneficiario Remesa
                                                            </p>
                                                            
                                                          
                                                          </div>
                                                    
                                                            
                                                          
                                                        </div>
                                                      
                                                      </Col>       
                                                      <CuentasBeneficiariosRemesa cuentasremesas={cuentasremesas} apiload={apiload} ApiLoad={ApiLoad}  ListaRemesas={ListaRemesas}/>
                 
                                              </div>

                                     </div> */}

                             
                               

                                </div> 
                                
                          
                              
            </div>
             

             





                            {/* ///////////////////////////////////////////////// */}

                            

                              <ModalPropias
                                        className={classes.modal}
                                        open={modalpropias}
                                        disableScrollLock={true}
                                        onClose={cerrarPropias}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                            timeout: 900,
                                        }}
                                        >
                                        <Fade in={modalpropias}
                                        timeout={154}
                                        onClose={cerrarPropias}
                                        >

                            <div className="container-grid">
                              <div  className={`modal-kyc-welcome padding-modal`}> 
                              <form onSubmit={onSubmitPropias} > 
                                      <div className="bg-modal-header">   
                                          <div  className=" fix-cuentaspropias-title ">
                                            <h5 className="title display-4 mb-0">Agregar beneficiario nacional</h5>
                                          </div>

                                          <div >
                                            <div className="cancel-box-btn">
                                                <div className="cancel-btn-panther" onClick={ () => (
                                                  cerrarPropias()
                                                )}> 
                                                    <i className="tim-icons icon-simple-remove"/>
                                                </div>
                                            </div>
                                          </div>    
                                      </div>

                                      <div className="form-group form-control-deposito-container">
                                          <div className="modal-grid-row"> 

                                                    <div> 
                                                        <label className="panther-cuenta-bancaria">Tipo de cuenta <span className="text-danger mt-5">*</span> </label>
                                                          <select className="form-control  pointer-cursor" id="bank_type" name="bank_type" value={bank_type} onChange={onChange} required>
                                                          <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                                                                                  <option value="AHORRO">Ahorro</option>
                                                                                  <option value="CORRIENTE">Corriente</option>
                                                                              
                                                        </select> 
                                                    </div>

                                                    <div>
                                                        <label className="panther-cuenta-bancaria">Banco <span className="text-danger mt-3">*</span> </label>
                                                        <input 
                                                          type="text"
                                                          placeholder="Nombre del banco"
                                                          id="bank_name"
                                                          name="bank_name"
                                                          onChange={onChange}
                                                          value={bank_name}
                                                          
                                                          
                                                        
                                                        className=" form-control" required/>
                                                    </div>


                                                    <div>
                                                            <label className="panther-cuenta-bancaria">Titular de la cuenta<span className="text-danger mt-3">*</span> </label>
                                                            <input 
                                                              type="text"
                                                              placeholder="e.g. Smith Rhodes"
                                                              id="bank_client"
                                                              name="bank_client"
                                                              onChange={onChange}
                                                              value={bank_client}
                                                              disabled
                                                              
                                                              
                                                            
                                                            className=" form-control" required />
                                                    </div>


                                                    <div>
                                                          <label className="panther-cuenta-bancaria">Número de la cuenta<span className="text-danger mt-5">*</span> </label>
                                                          <input 
                                                            type="text"
                                                            placeholder="e.g. ABCDE12345"
                                                            id="bank_account"
                                                            name="bank_account"
                                                            value={bank_account}
                                                            onChange={onChange}
                                                          className=" form-control" required/>
                                                    </div>

                                                    <div> 
                                                    <FormControlLabel
                                                          control={<Checkbox className="checkbox-fix" checked={check} onChange={handleChangeCheck} name="check" required 
                                                          
                                                          color="primary"
                                                          
                                                          />}
                                                          label="Confirmo los detalles de la cuenta bancaria suministrados son correctos."
                                                          
                                          
                                                      />
                                                    </div>

                                                    <div> 
                                                                  <div >
                                                                        <div> 
                                                                            <input type="submit" className=" btn mt-0 btn-block btn-info btn-panther-power" value="Añadir"/>
                                                                        
                                                                        </div>
                                                                  </div>
                                                    </div>


                                          </div>
                                      </div>
                              </form>
                              </div>
                              </div>


                              </Fade>  
                              </ModalPropias>  

                              









                              <ModalTerceros

                                    className={classes.modal}
                                    open={modalterceros}
                                    onClose={cerrarTerceros}
                                    
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 900,
                                    }}
                                    >
                                    <Fade in={modalterceros}
                                    timeout={154}
                                    onClose={cerrarTerceros}
                                    
                              >
                        <div className="container-grid">
                        <div  className={`modal-kyc-welcome padding-modal`}> 
                          <form onSubmit={onSubmitTerceros} >
                            <div className="bg-modal-header">   
                                  <div  className=" fix-cuentaspropias-title ">
                                    <h5 className="title display-4 mb-0">Agregar beneficiario internacional</h5>
                                  </div>

                                  <div >
                                    <div className="cancel-box-btn">
                                        <div className="cancel-btn-panther" onClick={ () => (
                                          cerrarTerceros()
                                        )}> 
                                            <i className="tim-icons icon-simple-remove"/>
                                        </div>
                                    </div>
                                  </div>    
                          </div>
                        
                        <div >
                                            
                          < div className="form-group form-control-deposito-container">
                                 <div className="modal-grid-row-2">
                                    <div >
                                      <label className="panther-cuenta-bancaria">Tipo de cuenta <span className="text-danger mt-5">*</span> </label>
                                          <select className="form-control  pointer-cursor" id="bank_type" name="bank_type" value={bank_type} onChange={onChange} required>
                                          <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                                                                  <option value="AHORRO">Ahorro</option>
                                                                  <option value="CORRIENTE">Corriente</option>
                                                              
                                        </select> 
                                      </div>
                                    
   
                                    <div>
                                    <label className="panther-cuenta-bancaria">País del banco <span className="text-danger mt-5">*</span> </label>
                                       <select className="form-control  pointer-cursor" id="country_id" name="country_id" value={country_id} onChange={onChange} required>
                                     <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                                                        <option value="144">Afganistán</option>
                                                        <option value="114">Albania</option>
                                                        <option value="18">Alemania</option>
                                                        <option value="98">Algeria</option>
                                                        <option value="145">Andorra</option>
                                                        <option value="119">Angola</option>
                                                        <option value="4">Anguilla</option>
                                                        <option value="147">Antigua y Barbuda</option>
                                                        <option value="207">Antillas Holandesas</option>
                                                        <option value="91">Arabia Saudita</option>
                                                        <option value="5">Argentina</option>
                                                        <option value="6">Armenia</option>
                                                        <option value="142">Aruba</option>
                                                        <option value="1">Australia</option>
                                                        <option value="2">Austria</option>
                                                        <option value="3">Azerbaiyán</option>
                                                        <option value="80">Bahamas</option>
                                                        <option value="127">Bahrein</option>
                                                        <option value="149">Bangladesh</option>
                                                        <option value="128">Barbados</option>
                                                        <option value="9">Bélgica</option>
                                                        <option value="8">Belice</option>
                                                        <option value="151">Benín</option>
                                                        <option value="10">Bermudas</option>
                                                        <option value="7">Bielorrusia</option>
                                                        <option value="123">Bolivia</option>
                                                        <option value="79">Bosnia y Herzegovina</option>
                                                        <option value="100">Botsuana</option>
                                                        <option value="12">Brasil</option>
                                                        <option value="155">Brunéi</option>
                                                        <option value="11">Bulgaria</option>
                                                        <option value="156">Burkina Faso</option>
                                                        <option value="157">Burundi</option>
                                                        <option value="152">Bután</option>
                                                        <option value="159">Cabo Verde</option>
                                                        <option value="158">Camboya</option>
                                                        <option value="31">Camerún</option>
                                                        <option value="32">Canadá</option>
                                                        <option value="130">Chad</option>
                                                        <option value="81">Chile</option>
                                                        <option value="35">China</option>
                                                        <option value="33">Chipre</option>
                                                        <option value="82">Colombia</option>
                                                        <option value="164">Comores</option>
                                                        <option value="112">Congo (Brazzaville)</option>
                                                        <option value="165">Congo (Kinshasa)</option>
                                                        <option value="166">Cook, Islas</option>
                                                        <option value="84">Corea del Norte</option>
                                                        <option value="69">Corea del Sur</option>
                                                        <option value="168">Costa de Marfil</option>
                                                        <option value="36">Costa Rica</option>
                                                        <option value="71">Croacia</option>
                                                        <option value="113">Cuba</option>
                                                        <option value="22">Dinamarca</option>
                                                        <option value="169">Djibouti, Yibuti</option>
                                                        <option value="103">Ecuador</option>
                                                        <option value="23">Egipto</option>
                                                        <option value="51">El Salvador</option>
                                                        <option value="93">Emiratos árabes Unidos</option>
                                                        <option value="173">Eritrea</option>
                                                        <option value="52">Eslovaquia</option>
                                                        <option value="53">Eslovenia</option>
                                                        <option value="28">España</option>
                                                        <option value="55">Estados Unidos</option>
                                                        <option value="68">Estonia</option>
                                                        <option value="121">Etiopía</option>
                                                        <option value="175">Feroe, Islas</option>
                                                        <option value="90">Filipinas</option>
                                                        <option value="63">Finlandia</option>
                                                        <option value="176">Fiyi</option>
                                                        <option value="64">Francia</option>
                                                        <option value="180">Gabón</option>
                                                        <option value="181">Gambia</option>
                                                        <option value="21">Georgia</option>
                                                        <option value="105">Ghana</option>
                                                        <option value="143">Gibraltar</option>
                                                        <option value="184">Granada</option>
                                                        <option value="20">Grecia</option>
                                                        <option value="94">Groenlandia</option>
                                                        <option value="17">Guadalupe</option>
                                                        <option value="185">Guatemala</option>
                                                        <option value="186">Guernsey</option>
                                                        <option value="187">Guinea</option>
                                                        <option value="172">Guinea Ecuatorial</option>
                                                        <option value="188">Guinea-Bissau</option>
                                                        <option value="189">Guyana</option>
                                                        <option value="16">Haiti</option>
                                                        <option value="137">Honduras</option>
                                                        <option value="73">Hong Kong</option>
                                                        <option value="14">Hungría</option>
                                                        <option value="25">India</option>
                                                        <option value="74">Indonesia</option>
                                                        <option value="140">Irak</option>
                                                        <option value="26">Irán</option>
                                                        <option value="27">Irlanda</option>
                                                        <option value="215">Isla Pitcairn</option>
                                                        <option value="83">Islandia</option>
                                                        <option value="228">Islas Salomón</option>
                                                        <option value="58">Islas Turcas y Caicos</option>
                                                        <option value="154">Islas Virgenes Británicas</option>
                                                        <option value="24">Israel</option>
                                                        <option value="29">Italia</option>
                                                        <option value="132">Jamaica</option>
                                                        <option value="70">Japón</option>
                                                        <option value="193">Jersey</option>
                                                        <option value="75">Jordania</option>
                                                        <option value="30">Kazajstán</option>
                                                        <option value="97">Kenia</option>
                                                        <option value="34">Kirguistán</option>
                                                        <option value="195">Kiribati</option>
                                                        <option value="37">Kuwait</option>
                                                        <option value="196">Laos</option>
                                                        <option value="197">Lesotho</option>
                                                        <option value="38">Letonia</option>
                                                        <option value="99">Líbano</option>
                                                        <option value="198">Liberia</option>
                                                        <option value="39">Libia</option>
                                                        <option value="126">Liechtenstein</option>
                                                        <option value="40">Lituania</option>
                                                        <option value="41">Luxemburgo</option>
                                                        <option value="85">Macedonia</option>
                                                        <option value="134">Madagascar</option>
                                                        <option value="76">Malasia</option>
                                                        <option value="125">Malawi</option>
                                                        <option value="200">Maldivas</option>
                                                        <option value="133">Malí</option>
                                                        <option value="86">Malta</option>
                                                        <option value="131">Man, Isla de</option>
                                                        <option value="104">Marruecos</option>
                                                        <option value="201">Martinica</option>
                                                        <option value="202">Mauricio</option>
                                                        <option value="108">Mauritania</option>
                                                        <option value="42">México</option>
                                                        <option value="43">Moldavia</option>
                                                        <option value="44">Mónaco</option>
                                                        <option value="139">Mongolia</option>
                                                        <option value="117">Mozambique</option>
                                                        <option value="205">Myanmar</option>
                                                        <option value="102">Namibia</option>
                                                        <option value="206">Nauru</option>
                                                        <option value="107">Nepal</option>
                                                        <option value="209">Nicaragua</option>
                                                        <option value="210">Níger</option>
                                                        <option value="115">Nigeria</option>
                                                        <option value="212">Norfolk Island</option>
                                                        <option value="46">Noruega</option>
                                                        <option value="208">Nueva Caledonia</option>
                                                        <option value="45">Nueva Zelanda</option>
                                                        <option value="213">Omán</option>
                                                        <option value="19">Países Bajos, Holanda</option>
                                                        <option value="87">Pakistán</option>
                                                        <option value="124">Panamá</option>
                                                        <option value="88">Papúa-Nueva Guinea</option>
                                                        <option value="110">Paraguay</option>
                                                        <option value="89">Perú</option>
                                                        <option value="178">Polinesia Francesa</option>
                                                        <option value="47">Polonia</option>
                                                        <option value="48">Portugal</option>
                                                        <option value="246">Puerto Rico</option>
                                                        <option value="216">Qatar</option>
                                                        <option value="13">Reino Unido</option>
                                                        <option value="65">República Checa</option>
                                                        <option value="138">República Dominicana</option>
                                                        <option value="49">Reunión</option>
                                                        <option value="217">Ruanda</option>
                                                        <option value="72">Rumanía</option>
                                                        <option value="50">Rusia</option>
                                                        <option value="242">Sáhara Occidental</option>
                                                        <option value="223">Samoa</option>
                                                        <option value="219">San Cristobal y Nevis</option>
                                                        <option value="224">San Marino</option>
                                                        <option value="221">San Pedro y Miquelón</option>
                                                        <option value="225">San Tomé y Príncipe</option>
                                                        <option value="222">San Vincente y Granadinas</option>
                                                        <option value="218">Santa Elena</option>
                                                        <option value="220">Santa Lucía</option>
                                                        <option value="135">Senegal</option>
                                                        <option value="226">Serbia y Montenegro</option>
                                                        <option value="109">Seychelles</option>
                                                        <option value="227">Sierra Leona</option>
                                                        <option value="77">Singapur</option>
                                                        <option value="106">Siria</option>
                                                        <option value="229">Somalia</option>
                                                        <option value="120">Sri Lanka</option>
                                                        <option value="141">Sudáfrica</option>
                                                        <option value="232">Sudán</option>
                                                        <option value="67">Suecia</option>
                                                        <option value="66">Suiza</option>
                                                        <option value="54">Surinam</option>
                                                        <option value="234">Swazilandia</option>
                                                        <option value="56">Tadjikistan</option>
                                                        <option value="92">Tailandia</option>
                                                        <option value="78">Taiwan</option>
                                                        <option value="101">Tanzania</option>
                                                        <option value="171">Timor Oriental</option>
                                                        <option value="136">Togo</option>
                                                        <option value="235">Tokelau</option>
                                                        <option value="236">Tonga</option>
                                                        <option value="237">Trinidad y Tobago</option>
                                                        <option value="122">Túnez</option>
                                                        <option value="57">Turkmenistan</option>
                                                        <option value="59">Turquía</option>
                                                        <option value="239">Tuvalu</option>
                                                        <option value="62">Ucrania</option>
                                                        <option value="60">Uganda</option>
                                                        <option value="111">Uruguay</option>
                                                        <option value="61">Uzbekistán</option>
                                                        <option value="240">Vanuatu</option>
                                                        <option value="95">Venezuela</option>
                                                        <option value="15">Vietnam</option>
                                                        <option value="241">Wallis y Futuna</option>
                                                        <option value="243">Yemen</option>
                                                        <option value="116">Zambia</option>
                                                        <option value="96">Zimbabwe</option>
                   </select> 

                                    </div>
                                
                                 </div>

                                 <div className="modal-grid-row">

                                          <div> 
                                                <label className="panther-cuenta-bancaria">Banco <span className="text-danger mt-3">*</span> </label>
                                                <input 
                                                  type="text"
                                                  placeholder="Nombre del banco"
                                                  id="bank_name"
                                                  name="bank_name"
                                                  onChange={onChange}
                                                  value={bank_name}
                                                className=" form-control" required/>
                                            </div>


                                            <div> 

                                                                
                                      {/* TERMINA otro valor , otro campo */} 
                                              <label className="panther-cuenta-bancaria">Titular de la cuenta<span className="text-danger mt-3">*</span> </label>
                                                <input 
                                                  type="text"
                                                  placeholder="e.g. Smith Rhodes"
                                                  id="bank_client"
                                                  name="bank_client"
                                                  onChange={onChange}
                                                  value={bank_client}
                                                  disabled
                                                className=" form-control" required />
                                                            
                                              {/* TERMINA otro valor , otro campo */} 

                                            </div>


                                            <div>

                                                <label className="panther-cuenta-bancaria">Número de la cuenta<span className="text-danger mt-5">*</span> </label>
                                                <input 
                                                  type="text"
                                                  placeholder="e.g. ABCDE12345"
                                                  id="bank_account"
                                                  name="bank_account"
                                                  value={bank_account}
                                                  onChange={onChange}
                                                  
                                                  
                                                
                                                className=" form-control" required/>
                                            </div>


                                   </div>

                                   <div className="modal-grid-row-3">
                                      

                                      <div> 

                                            <label className="panther-cuenta-bancaria">Swift <span className="text-danger mt-5">*</span> </label>
                                            <input 
                                              type="text"
                                              placeholder="e.g. ABCDE12345"
                                              id="bank_swift"
                                              name="bank_swift"
                                              onChange={onChange}
                                              value={bank_swift}
                                              
                                              
                                            
                                            className=" form-control" required/>

                                      </div>

                                      <div>   

                                      <label className="panther-cuenta-bancaria">Aba <span className="text-danger mt-5">*</span> </label>
                                      <input 
                                        type="text"
                                        placeholder="Aba"
                                        id="bank_aba"
                                        name="bank_aba"
                                        onChange={onChange}
                                        value={bank_aba}
                                        
                                        
                                      
                                      className=" form-control" required/>


                                      </div>


                                      <div>

                                      <label className="panther-cuenta-bancaria">Iban  </label>
                                      <input 
                                        type="text"
                                        placeholder="Iban"
                                        id="iban"
                                        name="iban"
                                        onChange={onChange}
                                        value={iban}
                                      className=" form-control" />
                                        
                                      </div>

                                    </div>

                                            
                              {/* TERMINA otro valor , otro campo */} 


                             <div className="modal-grid-row"> 


                              <div> 

                              <FormControlLabel
                                                control={<Checkbox className="checkbox-fix" checked={check} onChange={handleChangeCheck} name="check" required 
                                                color="primary"
                                                 />}
                                                label="Confirmo los detalles de la cuenta bancaria suministrados." />


                              </div>

                              <div> 

                                            <div >
                                                  <div> 
                                                      <input type="submit" className=" btn mt-0 btn-block btn-info btn-panther-power" value="Añadir"/>
                                                  
                                                  </div>
                                            </div>


                              </div>


                              </div>
                              </div> 
                           </div> 
                        </form>
                    </div>
                  </div>
                  
                      </Fade>  
                    </ModalTerceros> 


                    <ModalRemesadora
                    
                    className={classes.modal}
                    open={modalremesadora}
                    onClose={cerrarRemesadora}
                    
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 900,
                    }}
                    >
                    <Fade in={modalremesadora}
                    timeout={154}
                    onClose={cerrarRemesadora}
                    
                    >
                        <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                        <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}> 
                        
                        <form onSubmit={onSubmitRemesas} >
  
                        


                        <CardHeader className="bg-modal-header"> 
                              <Container>
                                <Row>
                                  <Col xs="10" sm="10" md="10" lg="10" className=" fix-cuentaspropias-title ">
                                    <h5 className="title display-4 mb-0">Agregar beneficiario remesadora</h5>
                                  </Col>
                                  <Col xs="2" sm="2" md="2" lg="2">
                                    <div className="cancel-box-btn">
                                        <div className="cancel-btn-panther" onClick={ () => (
                                          cerrarRemesadora()
                                        )}> 
                                            <i className="tim-icons icon-simple-remove"/>
                                        </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                                  
                          </CardHeader>
                        
                        <CardBody className="tarjeta-modal-fix">
                                            <Row className="d-flex justify-content-center">
                                            
                                                    <Col sm="12" md="11" className=" static-height"> 
                        {/* aqui esta la clase */}
                        
                                                        
                                                        <FormGroup>
                                   < div className="form-group form-control-deposito-container">  

                                   <Row>
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Nombres <span className="text-danger mt-3">*</span> </label>
                                          <input 
                                            type="text"
                                            placeholder="Escriba todos sus nombres"
                                            id="bank_client"
                                            name="bank_client"
                                            onChange={onChange}
                                            value={bank_client}
                                            
                                            
                                          
                                          className=" form-control" required/>
                                      </Col> 

                                      <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Apellidos <span className="text-danger mt-3">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="Escriba todos sus apellidos"
                                          id="bank_apellidos"
                                          name="bank_apellidos"
                                          onChange={onChange}
                                          value={bank_apellidos}
                                          
                                          
                                        
                                        className=" form-control" required/>

                                    </Col> 

                           
                                 
                                 </Row> 
                                 <Row>
                                   
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6"> 

                                  <label className="panther-cuenta-bancaria">Tipo de Identificacion <span className="text-danger mt-0">*</span></label>
                                          <select 
                                            id="tipo_identificacion"
                                            name="tipo_identificacion"
                                            onChange={onChange}
                                            value={tipo_identificacion}
                                            className="form-control  pointer-cursor">
                                          <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                                          <option value="Pasaporte">Pasaporte</option>
                                                <option value="Cedula">Cedula/DNI</option>
                                                <option value="Licencia">Licencia</option>
                                                
                                                
                                          
                                          </select> 
                                        

                                  </Col> 

                                  <hr className="my-1 border-info"/> 
                                 <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Identificacion <span className="text-danger mt-3">*</span> </label>
                                          <input 
                                            type="text"
                                            placeholder="Escriba su ID"
                                            id="identificacion"
                                            name="identificacion"
                                            onChange={onChange}
                                            value={identificacion}
                                            
                                            
                                          
                                          className=" form-control" required/>
                                      </Col>
                                    
                                 
                                 </Row> 

                                 {/* //////////////////////////// una linea */}

                                 <Row>

                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label className="panther-cuenta-bancaria">País<span className="text-danger mt-5">*</span> </label>
                                       <select className="form-control  pointer-cursor" id="country_id" name="country_id" value={country_id} onChange={onChange} required>
                                <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                                                        <option value="144">Afganistán</option>
                                                        <option value="114">Albania</option>
                                                        <option value="18">Alemania</option>
                                                        <option value="98">Algeria</option>
                                                        <option value="145">Andorra</option>
                                                        <option value="119">Angola</option>
                                                        <option value="4">Anguilla</option>
                                                        <option value="147">Antigua y Barbuda</option>
                                                        <option value="207">Antillas Holandesas</option>
                                                        <option value="91">Arabia Saudita</option>
                                                        <option value="5">Argentina</option>
                                                        <option value="6">Armenia</option>
                                                        <option value="142">Aruba</option>
                                                        <option value="1">Australia</option>
                                                        <option value="2">Austria</option>
                                                        <option value="3">Azerbaiyán</option>
                                                        <option value="80">Bahamas</option>
                                                        <option value="127">Bahrein</option>
                                                        <option value="149">Bangladesh</option>
                                                        <option value="128">Barbados</option>
                                                        <option value="9">Bélgica</option>
                                                        <option value="8">Belice</option>
                                                        <option value="151">Benín</option>
                                                        <option value="10">Bermudas</option>
                                                        <option value="7">Bielorrusia</option>
                                                        <option value="123">Bolivia</option>
                                                        <option value="79">Bosnia y Herzegovina</option>
                                                        <option value="100">Botsuana</option>
                                                        <option value="12">Brasil</option>
                                                        <option value="155">Brunéi</option>
                                                        <option value="11">Bulgaria</option>
                                                        <option value="156">Burkina Faso</option>
                                                        <option value="157">Burundi</option>
                                                        <option value="152">Bután</option>
                                                        <option value="159">Cabo Verde</option>
                                                        <option value="158">Camboya</option>
                                                        <option value="31">Camerún</option>
                                                        <option value="32">Canadá</option>
                                                        <option value="130">Chad</option>
                                                        <option value="81">Chile</option>
                                                        <option value="35">China</option>
                                                        <option value="33">Chipre</option>
                                                        <option value="82">Colombia</option>
                                                        <option value="164">Comores</option>
                                                        <option value="112">Congo (Brazzaville)</option>
                                                        <option value="165">Congo (Kinshasa)</option>
                                                        <option value="166">Cook, Islas</option>
                                                        <option value="84">Corea del Norte</option>
                                                        <option value="69">Corea del Sur</option>
                                                        <option value="168">Costa de Marfil</option>
                                                        <option value="36">Costa Rica</option>
                                                        <option value="71">Croacia</option>
                                                        <option value="113">Cuba</option>
                                                        <option value="22">Dinamarca</option>
                                                        <option value="169">Djibouti, Yibuti</option>
                                                        <option value="103">Ecuador</option>
                                                        <option value="23">Egipto</option>
                                                        <option value="51">El Salvador</option>
                                                        <option value="93">Emiratos árabes Unidos</option>
                                                        <option value="173">Eritrea</option>
                                                        <option value="52">Eslovaquia</option>
                                                        <option value="53">Eslovenia</option>
                                                        <option value="28">España</option>
                                                        <option value="55">Estados Unidos</option>
                                                        <option value="68">Estonia</option>
                                                        <option value="121">Etiopía</option>
                                                        <option value="175">Feroe, Islas</option>
                                                        <option value="90">Filipinas</option>
                                                        <option value="63">Finlandia</option>
                                                        <option value="176">Fiyi</option>
                                                        <option value="64">Francia</option>
                                                        <option value="180">Gabón</option>
                                                        <option value="181">Gambia</option>
                                                        <option value="21">Georgia</option>
                                                        <option value="105">Ghana</option>
                                                        <option value="143">Gibraltar</option>
                                                        <option value="184">Granada</option>
                                                        <option value="20">Grecia</option>
                                                        <option value="94">Groenlandia</option>
                                                        <option value="17">Guadalupe</option>
                                                        <option value="185">Guatemala</option>
                                                        <option value="186">Guernsey</option>
                                                        <option value="187">Guinea</option>
                                                        <option value="172">Guinea Ecuatorial</option>
                                                        <option value="188">Guinea-Bissau</option>
                                                        <option value="189">Guyana</option>
                                                        <option value="16">Haiti</option>
                                                        <option value="137">Honduras</option>
                                                        <option value="73">Hong Kong</option>
                                                        <option value="14">Hungría</option>
                                                        <option value="25">India</option>
                                                        <option value="74">Indonesia</option>
                                                        <option value="140">Irak</option>
                                                        <option value="26">Irán</option>
                                                        <option value="27">Irlanda</option>
                                                        <option value="215">Isla Pitcairn</option>
                                                        <option value="83">Islandia</option>
                                                        <option value="228">Islas Salomón</option>
                                                        <option value="58">Islas Turcas y Caicos</option>
                                                        <option value="154">Islas Virgenes Británicas</option>
                                                        <option value="24">Israel</option>
                                                        <option value="29">Italia</option>
                                                        <option value="132">Jamaica</option>
                                                        <option value="70">Japón</option>
                                                        <option value="193">Jersey</option>
                                                        <option value="75">Jordania</option>
                                                        <option value="30">Kazajstán</option>
                                                        <option value="97">Kenia</option>
                                                        <option value="34">Kirguistán</option>
                                                        <option value="195">Kiribati</option>
                                                        <option value="37">Kuwait</option>
                                                        <option value="196">Laos</option>
                                                        <option value="197">Lesotho</option>
                                                        <option value="38">Letonia</option>
                                                        <option value="99">Líbano</option>
                                                        <option value="198">Liberia</option>
                                                        <option value="39">Libia</option>
                                                        <option value="126">Liechtenstein</option>
                                                        <option value="40">Lituania</option>
                                                        <option value="41">Luxemburgo</option>
                                                        <option value="85">Macedonia</option>
                                                        <option value="134">Madagascar</option>
                                                        <option value="76">Malasia</option>
                                                        <option value="125">Malawi</option>
                                                        <option value="200">Maldivas</option>
                                                        <option value="133">Malí</option>
                                                        <option value="86">Malta</option>
                                                        <option value="131">Man, Isla de</option>
                                                        <option value="104">Marruecos</option>
                                                        <option value="201">Martinica</option>
                                                        <option value="202">Mauricio</option>
                                                        <option value="108">Mauritania</option>
                                                        <option value="42">México</option>
                                                        <option value="43">Moldavia</option>
                                                        <option value="44">Mónaco</option>
                                                        <option value="139">Mongolia</option>
                                                        <option value="117">Mozambique</option>
                                                        <option value="205">Myanmar</option>
                                                        <option value="102">Namibia</option>
                                                        <option value="206">Nauru</option>
                                                        <option value="107">Nepal</option>
                                                        <option value="209">Nicaragua</option>
                                                        <option value="210">Níger</option>
                                                        <option value="115">Nigeria</option>
                                                        <option value="212">Norfolk Island</option>
                                                        <option value="46">Noruega</option>
                                                        <option value="208">Nueva Caledonia</option>
                                                        <option value="45">Nueva Zelanda</option>
                                                        <option value="213">Omán</option>
                                                        <option value="19">Países Bajos, Holanda</option>
                                                        <option value="87">Pakistán</option>
                                                        <option value="124">Panamá</option>
                                                        <option value="88">Papúa-Nueva Guinea</option>
                                                        <option value="110">Paraguay</option>
                                                        <option value="89">Perú</option>
                                                        <option value="178">Polinesia Francesa</option>
                                                        <option value="47">Polonia</option>
                                                        <option value="48">Portugal</option>
                                                        <option value="246">Puerto Rico</option>
                                                        <option value="216">Qatar</option>
                                                        <option value="13">Reino Unido</option>
                                                        <option value="65">República Checa</option>
                                                        <option value="138">República Dominicana</option>
                                                        <option value="49">Reunión</option>
                                                        <option value="217">Ruanda</option>
                                                        <option value="72">Rumanía</option>
                                                        <option value="50">Rusia</option>
                                                        <option value="242">Sáhara Occidental</option>
                                                        <option value="223">Samoa</option>
                                                        <option value="219">San Cristobal y Nevis</option>
                                                        <option value="224">San Marino</option>
                                                        <option value="221">San Pedro y Miquelón</option>
                                                        <option value="225">San Tomé y Príncipe</option>
                                                        <option value="222">San Vincente y Granadinas</option>
                                                        <option value="218">Santa Elena</option>
                                                        <option value="220">Santa Lucía</option>
                                                        <option value="135">Senegal</option>
                                                        <option value="226">Serbia y Montenegro</option>
                                                        <option value="109">Seychelles</option>
                                                        <option value="227">Sierra Leona</option>
                                                        <option value="77">Singapur</option>
                                                        <option value="106">Siria</option>
                                                        <option value="229">Somalia</option>
                                                        <option value="120">Sri Lanka</option>
                                                        <option value="141">Sudáfrica</option>
                                                        <option value="232">Sudán</option>
                                                        <option value="67">Suecia</option>
                                                        <option value="66">Suiza</option>
                                                        <option value="54">Surinam</option>
                                                        <option value="234">Swazilandia</option>
                                                        <option value="56">Tadjikistan</option>
                                                        <option value="92">Tailandia</option>
                                                        <option value="78">Taiwan</option>
                                                        <option value="101">Tanzania</option>
                                                        <option value="171">Timor Oriental</option>
                                                        <option value="136">Togo</option>
                                                        <option value="235">Tokelau</option>
                                                        <option value="236">Tonga</option>
                                                        <option value="237">Trinidad y Tobago</option>
                                                        <option value="122">Túnez</option>
                                                        <option value="57">Turkmenistan</option>
                                                        <option value="59">Turquía</option>
                                                        <option value="239">Tuvalu</option>
                                                        <option value="62">Ucrania</option>
                                                        <option value="60">Uganda</option>
                                                        <option value="111">Uruguay</option>
                                                        <option value="61">Uzbekistán</option>
                                                        <option value="240">Vanuatu</option>
                                                        <option value="95">Venezuela</option>
                                                        <option value="15">Vietnam</option>
                                                        <option value="241">Wallis y Futuna</option>
                                                        <option value="243">Yemen</option>
                                                        <option value="116">Zambia</option>
                                                        <option value="96">Zimbabwe</option>
                   </select> 
                                      </Col>
                                
                                 </Row> 

                                 {/* /////////////////// termina la linea */}
                                 <Row> 
                                    
                                    
   
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Provincia <span className="text-danger mt-3">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="Nombre de la ciudad"
                                          id="provincia"
                                          name="provincia"
                                          onChange={onChange}
                                          value={provincia}
                                          
                                          
                                        
                                        className=" form-control" required/>

                                    </Col> 

                                    <hr className="my-1 border-info"/> 

                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Ciudad <span className="text-danger mt-3">*</span> </label>
                                          <input 
                                            type="text"
                                            placeholder="Nombre de la ciudad"
                                            id="ciudad"
                                            name="ciudad"
                                            onChange={onChange}
                                            value={ciudad}
                                            
                                            
                                          
                                          className=" form-control" required/>
                                      </Col>
                                
                                 </Row> 
                                 <Row>
                                    
                                    
   
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Direccion <span className="text-danger mt-3">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="Direccion"
                                          id="direccion_cliente"
                                          name="direccion_cliente"
                                          onChange={onChange}
                                          value={direccion_cliente}
                                          
                                          
                                        
                                        className=" form-control" required/>

                                    </Col> 
                                    <hr className="my-1 border-info"/> 
                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <label className="panther-cuenta-bancaria">Telefono <span className="text-danger mt-3">*</span> </label>
                                        <input 
                                          type="text"
                                          placeholder="Numero de telefono"
                                          id="telefono"
                                          name="telefono"
                                          onChange={onChange}
                                          value={telefono}
                                          
                                          
                                        
                                        className=" form-control" required/>

                                    </Col> 
                                
                                 </Row> 
                                            
                                     
                           
                          


                             

                                
                              <FormControlLabel
                                                control={<Checkbox className="checkbox-fix" checked={check} onChange={handleChangeCheck} name="check" required 
                                                
                                                color="primary"
                                                 
                                                 />}
                                                label="Confirmo los detalles de la cuenta bancaria suministrados."
                                                
                                
                                            />


                                {/* TERMINA otro valor , telefono destinatario */} 


   
                              </div> 



                                 
                                   </FormGroup>

                                  
                                                        
                                                    </Col>       
                                            </Row>
                                        </CardBody> 
                                        <CardFooter>
                            <div className="row justify-content-center">
                                
                          
                                <div  className="col-sm-12 col-md-6 col-12"> 
                                
                                

                                
                                
                                

                                <input type="submit" className=" btn mt-0 btn-block btn-info btn-panther-power" value="Añadir"/>
                                
                                </div>
                                    
                            
                            </div>
                        </CardFooter> 
                        

                             
                                    
                        
                        </form>
                    </div>

                        </div>

                    </div>
                        </div>
                    </Fade>
                    
                    </ModalRemesadora> 


                  



                    { mensajeDos ? 
                    
                    <ModalDos
   
                      className={classesDos.modal}
                      open={open} 
                      mensajeDos={mensajeDos}
                      ListaRemesas={ListaRemesas}
                      ListaNacionales={ListaNacionales}
                      ListaInternacionales={ListaInternacionales}
                      onClose={handleClose}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 900,
                      }}
                    >
                      <Fade in={open}
                      timeout={154}
                      onClose={handleClose}>
                       <div className="container-grid">
                              <div  className={`modal-kyc-welcome padding-modal`}> 
                                  <div className="form-group form-control-deposito-container">
                                      <div className="modal-grid-row"> 

                                      <div> 
                                            <p className="text-center display-4 title my-3 font-light-weight-modal"> {mensajeDos}</p>

                                      </div>

                                      <div>
                                         <button className="  btn mt-0 btn-block btn-info my-2 btn-panther-power" onClick={() => (
                                                                                    
                                                                                    guardarMensajeDos(null),
                                                                                    
                                                                                    ListaNacionales(),
                                                                                    ListaInternacionales(),
                                                                                    ListaRemesas(),
                                                                                    cerrarRemesadora(),
                                                                                    cerrarPropias(),
                                                                                    cerrarTerceros(),
                                                                                    handleClose(),
                                                                                    usuarioAutenticado()
                    )}>
                                                                    Aceptar
                                          </button>
                                      </div>
                                      </div>    
                                      </div>
                                      </div>        
                      </div>
                          </Fade>
                        </ModalDos> : null} 


                        

                    

                   
    </> 
    
    
    );
}
 
export default CuentaBancariaRetiro;